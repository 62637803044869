import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChannelInterface } from 'src/app/categoryAndChannel/category-channel.service';
import { Connection } from 'src/app/model/connection.model';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { EventParticipant, SluperEvent } from 'src/app/model/event.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';
import { DeleteWarningModalComponent } from './delete-warning-modal/delete-warning-modal.component';
import { WarningModalPostComponent } from './warning-modal-post/warning-modal-post.component';

@Component({
  selector: 'app-community-warnings',
  templateUrl: './community-warnings.component.html',
  styleUrls: ['./community-warnings.component.css'],
})
export class CommunityWarningsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isUserCommunityOwner: boolean = false;
  @Input() userProfile: DocumentUserProfile | undefined;
  @Input() communityItem!: SluperEvent;
  @Input() selectedChannel!: ChannelInterface | null;
  @Input() userProfilePlan!: IUserProfilePlan;
  @Input() userConnections!: Connection[];
  @Input() participants: EventParticipant[] = [];
  warnings: any[] = [];
  private buttonClickListener: any;
  selectedRange: any = null;
  calendarLocale = {
    applyLabel: 'Aplicar',
    cancelLabel: 'Limpar',
    clearLabel: 'Limpar',
    customRangeLabel: 'Intervalo Personalizado',
  };

  titlePage: string = '';
  descriptionPage: string = '';

  constructor(private renderer: Renderer2, private el: ElementRef, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.warnings = [
      {
        id: 1,
        dateHour: 'Jul 10 às 14:00',
        title: 'Título do Aviso 1',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ante nibh, fermentum sed tellus ut, feugiat sollicitudin augue. In molestie nulla vel urna cursus finibus. In rhoncus purus mauris, sit amet malesuada nisi ultricies eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
      },
      {
        id: 2,
        dateHour: 'Jul 11 às 15:00',
        title: 'Título do Aviso 2',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ante nibh, fermentum sed tellus ut, feugiat sollicitudin augue. In molestie nulla vel urna cursus finibus. In rhoncus purus mauris, sit amet malesuada nisi ultricies eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
      },
      {
        id: 3,
        dateHour: 'Jul 12 às 16:00',
        title: 'Título do Aviso 3',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ante nibh, fermentum sed tellus ut, feugiat sollicitudin augue. In molestie nulla vel urna cursus finibus. In rhoncus purus mauris, sit amet malesuada nisi ultricies eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
      },
    ];

    this.titlePage = 'Sobre está página';
    this.descriptionPage = "Esta é uma página dedicada a avisos e informações pertinentes referentes aos grupos. Aqui será postada todas e quaisquer informações que sejam relevantes para a manutenção da nossa comunidade."
  }

  ngAfterViewInit(): void {
    this.addButtonClickListener();
  }

  addButtonClickListener(): void {
    let button = this.el.nativeElement.querySelector();

    if (button) {
      this.buttonClickListener = this.renderer.listen(button, 'click', () => {
        this.handleClearDate();
      });
    } else {
      setTimeout(() => {
        this.addButtonClickListener();
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    if (this.buttonClickListener) {
      this.buttonClickListener();
    }
  }

  handleClearDate(): void {
    this.selectedRange = null;
  }

  onDateChosen(event: any) {
    if (!event.startDate || !event.endDate) {
      this.handleClearDate();
    } else {
      const startDate = event.startDate.format('YYYY-MM-DD');
      const endDate = event.endDate.format('YYYY-MM-DD');
    }
  }

  openWarningPost() {
    const dialogRef = this.dialog.open(WarningModalPostComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(
      async (result) => {
      },
      (error) => {
        console.error('Erro ao fechar modal: ', error);
      }
    )
  }

  openDeleteWarning() {
    const dialogRef = this.dialog.open(DeleteWarningModalComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(
      async (result) => {
      },
      (error) => {
        console.error('Errp ao fechar modal: ', error);
      }
    )
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { BOLD_BUTTON, EditorConfig, FONT_SIZE_SELECT, FORE_COLOR, ITALIC_BUTTON, JUSTIFY_CENTER_BUTTON, JUSTIFY_FULL_BUTTON, JUSTIFY_LEFT_BUTTON, JUSTIFY_RIGHT_BUTTON, ORDERED_LIST_BUTTON, SEPARATOR, UNDO_BUTTON, UNORDERED_LIST_BUTTON } from 'ngx-simple-text-editor';
import { firstValueFrom } from 'rxjs';
import { EventsService } from 'src/app/events/events.service';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { UserProfileService } from 'src/app/user-profile/user-profile.service';

@Component({
  selector: 'app-modal-community-rules',
  templateUrl: './modal-community-rules.component.html',
  styleUrls: ['./modal-community-rules.component.css']
})
export class ModalCommunityRulesComponent implements OnInit {
  @ViewChild('imageSelectionModal') imageSelectionModal: any;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  @Input() userProfile: DocumentUserProfile = {
    idUser: '',
    name: '',
    bio: '',
    publish: false,
    showImageProfile: false,
    parent: '',
    listURI: [],
    uriImageProfile: '',
    uriImageBackground: '',
    listSocialNetwork: [],
    listButtons: [],
    listUriImages: [],
    header: {
      text: '',
      visible: false
    },
    listProfile: [],
    listContacts: [],
    networkingControl: {
      used: 0,
      max: 0
    },
  }
  @Input() communityItem!: any
  public loading = false
  public config: EditorConfig = {
    placeholder: 'Digite os tópicos...',
    buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR,
      FONT_SIZE_SELECT, SEPARATOR, JUSTIFY_LEFT_BUTTON, JUSTIFY_CENTER_BUTTON,
      JUSTIFY_RIGHT_BUTTON, JUSTIFY_FULL_BUTTON, ORDERED_LIST_BUTTON, UNORDERED_LIST_BUTTON],
  };
  public rules = new FormControl('')
  public rulesModel = '';
  constructor(
    private activeModal: NgbActiveModal,
    private userProfileService: UserProfileService,
    private eventsService: EventsService,
    private toastrService: ToastService

  ) { }

  ngOnInit(): void {
    this.rules.setValue(this.communityItem.about || '');
    this.rulesModel = this.communityItem.about || ''
  }

  closeModal(type: boolean) {
    this.activeModal.close(type);
  }

  async handleSaveEvent() {

    try {
      if (!this.userProfile) {
        this.toastrService.show('Ocorreu um erro ao tentar atualizar a comunidade. Tente novamente mais tarde.', 'error');
        return;
      }
      this.loading = true;

      if (this.verifyForm() === false) {
        this.toastrService.show('Erro ao atualizar comunidade, verifique os campos obrigatórios.', 'error');
        this.loading = false;
        return;
      }
      this.loading = true;

      let event = {
        ...this.communityItem,
        startDate: new Date().toISOString(),
        guests: [],
        about: this.rules.value
      }
      await firstValueFrom(this.eventsService.updateCommunity(event));
      this.toastrService.show('Comunidade atualizada com sucesso!', 'success');

      setTimeout(() => {
        this.loading = false;
        this.closeModal(true);
      }, 1000);
    } catch (error) {
      this.loading = false;
      this.toastrService.show('Ocorreu um erro ao tentar atualizar a comunidade. Tente novamente mais tarde.', 'error');
    }
  }


  verifyForm(): boolean {
    this.rules.updateValueAndValidity();

    if (this.rules.valid && this.countTextChars(this.rulesModel) > 0 && this.countTextChars(this.rulesModel) <= 1500) {
      return true;
    } else {
      return false;
    }
  }

  onEditorChange(value: string) {
    this.rules.setValue(value);
  }

  countTextChars(text: string): number {
    const textWithoutHtml = text.replace(/<[^>]*>/g, '');
    return textWithoutHtml.length;
  }


}

import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEventWithParticipationStatus } from 'src/app/model/event.model';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.css']
})
export class EventCardComponent {
  @Input() event: IEventWithParticipationStatus | undefined;
  @Output() join = new EventEmitter<IEventWithParticipationStatus>();
  defaultCallingCardImage = '/assets/img/imagem_fundo_preview.png';

  getFormattedDate(date: Date | string | undefined): string {
    if (!date) return '';

    const dateObj = new Date(date);
    const day = formatDate(dateObj, 'dd', 'pt-BR');
    const month = formatDate(dateObj, 'MMMM', 'pt-BR').toUpperCase();
    const year = formatDate(dateObj, 'yyyy', 'pt-BR');

    return `${day} DE ${month}, ${year}`;
  }

  joinEvent() {
    if (!this.event) return;
    this.join.emit(this.event);
  }
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BOLD_BUTTON, EditorConfig, FONT_SIZE_SELECT, FORE_COLOR, ITALIC_BUTTON, SEPARATOR, UNDO_BUTTON } from 'ngx-simple-text-editor';
import {
  Subject,
  debounceTime,
  filter,
  map
} from 'rxjs';
import { LISTA_ESTILOS } from '../model/estilos';
import { LISTA_FONTES } from '../model/fontes';
import { IFormattedProfileData } from '../model/profile.model';
import { Estilo } from '../model/styles.model';
import { IUserCss, UserCss } from '../model/user-css.model';
import { IUserProfilePlan } from '../model/user-plan.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { PreviewModalComponent } from '../preview-modal/preview-modal.component';
import { UserProfileService } from '../user-profile/user-profile.service';

interface ISelectedProfile {
  indicator: number;
  data: IFormattedProfileData;
}

type ProfileImagesTypes = 'profile' | 'calling-card-background' | 'background'

@Component({
  selector: 'app-aparencia',
  templateUrl: './aparencia.component.html',
  styleUrls: ['./aparencia.component.css'],
})
export class AparenciaComponent implements OnInit {
  @Input() userProfile!: IUserProfileWithCSS | null;
  @Input() userProfilePlan!: IUserProfilePlan;
  @ViewChild('imageSelectionModal') imageSelectionModal: any;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  private _alert = new Subject<string>();
  alertMessage = '';
  alertType = '';

  cropMode = false;
  estilos = LISTA_ESTILOS;
  fontes = LISTA_FONTES;

  userCss: IUserCss = new UserCss();

  currentImageType: ProfileImagesTypes = 'background';
  croppedImage: any = '';
  imageChangedEvent: any = null;

  desktopLimitWidth = 840;
  desktopLimitHeight = 550;

  saveProfileLoading = false;

  uploadedProfileImage: File | null = null;
  uploadedBackgroundImage: File | null = null;
  uploadedExternalBackgroundImage: File | null = null;

  defaultProfileImage = '/assets/img/imagem_perfil.png';
  defaultCallingCardImage = '/assets/img/imagem_fundo_preview.png';

  profileStylesChangesBlocked = false;

  selectedRadius = 0;
  selectedShadow = 0;

  externalBackgrounChanged = false;
  imageProfileChanged = false;
  imageBackgroundChanged = false;

  screenWidth: any;
  screenHeight: any;

  previewModalOpen = false;
  imageSelectionModalOpen = false;

  templateBgType: 'color' | 'image' = 'color';
  isColorBgSelected = true;
  isImageBgSelected = false;

  selectedProfile!: ISelectedProfile;

  showTooltipProfile = false;
  showTooltipBackground = false;
  @ViewChild('tooltip') tooltipElement!: ElementRef;

  defaultImageSelected = false;

  nameRequired = false;

  config: EditorConfig = {
    placeholder: 'Descrição do evento',
    // buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR, FONT_SIZE_SELECT, SEPARATOR, JUSTIFY_LEFT_BUTTON, JUSTIFY_CENTER_BUTTON, JUSTIFY_RIGHT_BUTTON, JUSTIFY_FULL_BUTTON],
    buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR, FONT_SIZE_SELECT]
  };

  constructor(
    private userProfileService: UserProfileService,
    private modalService: NgbModal,
    private http: HttpClient
  ) {
    this.getScreenSize();

    setTimeout(() => {
      this.updateProfilePreviewWidth();
    }, 200)
  }

  updateProfilePreviewWidth() {
    const parent = document.querySelector('.visual-div');
    const child = document.querySelector('.profile-preview') as HTMLElement;
    if (parent && child) {
      const rect = parent.getBoundingClientRect();

      child.style.width = `${rect.width}px`;
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.updateProfilePreviewWidth();
    }, 300)

    if (this.userProfile) {
      this.profileStylesChangesBlocked = !!this.userProfile.documentUserCSS.locked;
      this.setCssProperties(this.userProfile.documentUserCSS.css);
      this.changeSelectedProfile(-1);

      this.isColorBgSelected = this.userCss.cardBackgroundImageUrl ? false : true;
      this.isImageBgSelected = this.userCss.cardBackgroundImageUrl ? true : false;
      this.templateBgType = this.userCss.cardBackgroundImageUrl ? 'image' : 'color';
    }

    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.updateProfilePreviewWidth();

    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  changeSelectedProfile(profileIndicator: number) {
    if (!this.userProfile) {
      return;
    }

    const isDefaultProfile = profileIndicator === -1;

    const templateCallingCard = this.userCss.callingCardImageUrl;

    if (isDefaultProfile) {
      let callingCardImage = this.userProfile.documentUserProfile.uriImageBackground;

      if (this.profileStylesChangesBlocked && templateCallingCard) {
        callingCardImage = templateCallingCard;
      }

      if (!callingCardImage) {
        callingCardImage = this.defaultCallingCardImage;
      }

      this.selectedProfile = {
        indicator: profileIndicator,
        data: {
          profileVisible: true,
          bio: this.userProfile.documentUserProfile.bio,
          header: this.userProfile.documentUserProfile.header,
          listButtons: this.userProfile.documentUserProfile.listButtons.map((button, index) => ({
            ...button,
            ...(button.image && ({
              image: button.image.replaceAll(' ', '%20')
            })),
            suspended: this.userProfilePlan?.profilePlanLimits.contactsInclude !== -1 && this.userProfilePlan?.profilePlanLimits.contactsInclude <= index,
          })),
          listContacts: this.userProfile.documentUserProfile.listContacts.map((c, index) => ({
            ...c,
            suspended: this.userProfilePlan?.profilePlanLimits.contactsInclude !== -1 && this.userProfilePlan?.profilePlanLimits.contactsInclude <= index
          })),
          listSocialNetwork: this.userProfile.documentUserProfile.listSocialNetwork.map((network, index) => ({ ...network, readOnly: true, suspended: this.userProfilePlan.profilePlanLimits.linksInclude !== -1 && this.userProfilePlan.profilePlanLimits.linksInclude <= index })),
          name: this.userProfile.documentUserProfile.name,
          showImageProfile: this.userProfile.documentUserProfile.showImageProfile,
          uriImageBackground: callingCardImage,
          uriImageProfile: this.userProfile.documentUserProfile.uriImageProfile || this.defaultProfileImage,
        }
      }

      return;
    }

    if (this.userProfile.documentUserProfile?.listProfile?.length < 1 || !this.userProfile.documentUserProfile?.listProfile?.length) {
      let callingCardImage = this.userCss.callingCardImageUrl;
      if (this.profileStylesChangesBlocked && templateCallingCard) {
        callingCardImage = templateCallingCard;
      }

      if (!callingCardImage) {
        callingCardImage = this.defaultCallingCardImage;
      }

      this.selectedProfile = {
        data: {
          profileVisible: true,
          listButtons: [],
          listContacts: [],
          listSocialNetwork: [],
          uriImageBackground: callingCardImage,
          uriImageProfile: this.defaultProfileImage,
          bio: '',
          header: {
            visible: true,
            text: '',
          },
          name: '',
          showImageProfile: true,
        },
        indicator: 0
      }

      return
    }

    const selectedProfileData = this.userProfile.documentUserProfile.listProfile[profileIndicator]

    let callingCardImage = selectedProfileData.uriImageBackground;

    if (this.profileStylesChangesBlocked && templateCallingCard) {
      callingCardImage = templateCallingCard;
    }

    if (!callingCardImage) {
      callingCardImage = this.defaultCallingCardImage;
    }

    this.selectedProfile = {
      data: {
        profileVisible: selectedProfileData.visible === undefined ? true : selectedProfileData.visible,
        ...selectedProfileData,
        listButtons: selectedProfileData.listButtons.map((button, index) => ({
          ...button,
          ...(button.image && ({
            image: button.image.replaceAll(' ', '%20')
          })),
          suspended: this.userProfilePlan?.profilePlanLimits.contactsInclude !== -1 && this.userProfilePlan?.profilePlanLimits.contactsInclude <= index,
        })),
        listContacts: selectedProfileData.listContacts.map((c, index) => ({
          ...c,
          suspended: this.userProfilePlan?.profilePlanLimits.contactsInclude !== -1 && this.userProfilePlan?.profilePlanLimits.contactsInclude <= index
        })),
        listSocialNetwork: selectedProfileData.listSocialNetwork.map((network, index) => ({ ...network, readOnly: true, suspended: this.userProfilePlan.profilePlanLimits.linksInclude !== -1 && this.userProfilePlan.profilePlanLimits.linksInclude <= index })),
        uriImageBackground: callingCardImage,
        uriImageProfile: selectedProfileData.uriImageProfile || this.defaultProfileImage,
      },
      indicator: profileIndicator
    }
  }

  toggleProfileVisibility() {
    this.selectedProfile.data.profileVisible = !this.selectedProfile.data.profileVisible;
  }

  changeBackgroundType(type: string) {
    if (this.templateBgType === type) {
      return;
    }

    if (this.templateBgType === 'color') {
      this.templateBgType = 'image';
      this.isColorBgSelected = false;
      this.isImageBgSelected = true;

      return;
    }

    this.isColorBgSelected = true;
    this.isImageBgSelected = false;
    this.templateBgType = 'color';

    const primaryColor = this.userCss.corPrimaria;
    const secondaryColor = this.userCss.corSecundaria;

    this.userCss.cardBackgroundImageUrl = undefined;
    this.userCss.corPrimaria = undefined;
    this.userCss.corSecundaria = undefined;

    setTimeout(() => {
      this.userCss.corPrimaria = primaryColor;
      this.userCss.corSecundaria = secondaryColor;
    }, 100);
    this.croppedImage = null;
    this.imageChangedEvent = null;
  }

  openImageSelectionModal(imageType: ProfileImagesTypes): void {
    this.imageSelectionModalOpen = true;
    this.currentImageType = imageType;
    this.imageChangedEvent = null;
    this.cropMode = false;

    const modalRef = this.modalService.open(this.imageSelectionModal, {
      size: 'lg',
      centered: true,
    });

    modalRef.result
      .then(() => {
        this.imageSelectionModalOpen = false;
      })
      .catch(() => {
        this.imageSelectionModalOpen = false;
      });
  }

  fileChangeEvent(event: any, minWidth: number, minHeight: number): void {
    const file: File = event.target.files[0];

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result as string;
      this.cropMode = true;

      image.onload = () => {
        if (image.width < minWidth || image.height < minHeight) {
          this.alertType = 'danger';
          this._alert.next(
            `A imagem precisa ter no mínimo ${minWidth}x${minHeight} pixels.`
          );

          this.removeSelectedImage()
          this.modalService.dismissAll();
          return;
        }
      }
    };

    reader.readAsDataURL(file);

    const maxSizeInBytes = 2 * 1024 * 1024;

    if (file.size <= maxSizeInBytes) {
      this.imageChangedEvent = event;
    } else {
      this.alertType = 'danger';
      this._alert.next(
        'Tamanho da arquivo é maior do que o permitido. Favor selecionar uma imagem de até 2MB.',
      );
      this.removeSelectedImage();
      this.modalService.dismissAll();
    }
  }

  removeSelectedImage() {
    this.cropMode = false;
    this.croppedImage = null;
    this.imageChangedEvent = null;
  }

  imageCropped(event: ImageCroppedEvent) {
    const blob = event.blob;
    if (blob && blob.size) {
      const reader = new FileReader();

      reader.onload = () => {
        this.croppedImage = reader.result as string;
      };

      reader.readAsDataURL(blob);
    }
  }

  confirmImageSelection(croppedImage: string): void {
    if (!croppedImage) return;

    try {
      const imageTypeToFileNamePrefix: { [key in ProfileImagesTypes]: string } = {
        'profile': `profile_image_`,
        'calling-card-background': 'background_image_',
        'background': 'external_background_image_'
      };

      const fileNamePrefix = imageTypeToFileNamePrefix[this.currentImageType];
      if (!fileNamePrefix) {
        console.warn(`Unknown image type: ${this.currentImageType}`);
        return;
      }

      const currentTime = Date.now();
      const fileName = `${fileNamePrefix}${currentTime}.jpeg`;
      const blob = this.dataURItoBlob(croppedImage);
      const blobFile = new File([blob], fileName, {
        type: 'image/jpeg',
        lastModified: currentTime,
      });
      this.updateImageState(this.currentImageType, blobFile, croppedImage);
    } catch (e) {
      this.alertType = 'danger';
      this._alert.next(
        'Ocorreu um erro ao confirmar a seleçāo de imagem, tente novamente mais tarde.',
      );
    }

    this.removeSelectedImage();
    this.modalService.dismissAll();
  }

  private updateImageState(imageType: ProfileImagesTypes, blobFile: File, croppedImage: string): void {
    switch (imageType) {
      case 'profile':
        this.uploadedProfileImage = blobFile;
        this.selectedProfile.data.uriImageProfile = croppedImage;
        this.imageProfileChanged = true;
        break;
      case 'calling-card-background':
        this.uploadedBackgroundImage = blobFile;
        this.selectedProfile.data.uriImageBackground = croppedImage;
        this.imageBackgroundChanged = true;
        break;
      case 'background':
        this.uploadedExternalBackgroundImage = blobFile;
        this.userCss.cardBackgroundImageUrl = croppedImage;
        this.externalBackgrounChanged = true;
        break;
    }
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  getBackground(estilo: Estilo): string {
    if (estilo.externo.corSecundaria)
      return (
        'linear-gradient(' +
        estilo.externo.direcaoDegrade +
        'deg, ' +
        estilo.externo.corPrincipal +
        ' 0%, ' +
        estilo.externo.corSecundaria +
        ' 100%)'
      );
    else return estilo.externo.corPrincipal;
  }

  setCssProperties(css: string) {
    if (css) {
      this.userCss = JSON.parse(css);
      this.fillSelectedShadow();
      this.fillSelectedRadius();
    } else {
      this.getDefaultStyle(0);
    }
  }

  fillSelectedShadow() {
    if (this.userCss.sombra == '0px 6px 6px 0px rgba(0, 0, 0, 0.10)') {
      this.selectedShadow = 2;
    } else if (this.userCss.sombra == '0px 8px 8px 0px rgba(0, 0, 0, 0.25)') {
      this.selectedShadow = 3;
    } else {
      this.selectedShadow = 1;
    }
  }

  fillSelectedRadius() {
    if (this.userCss.curvaBorda) {
      const curva: number = +this.userCss.curvaBorda;
      if (curva == 0) {
        this.selectedRadius = 1;
      } else if (curva > 0 && curva < 25) {
        this.selectedRadius = 2;
      } else if (curva == 25) {
        this.selectedRadius = 3;
      }
    }
  }

  getDefaultStyle(i: number) {
    if (this.profileStylesChangesBlocked) {
      return;
    }

    this.userCss.estiloPreDefinido = this.estilos[i];
    this.userCss.corPrimaria =
      this.userCss.estiloPreDefinido.externo.corPrincipal;
    this.userCss.corSecundaria =
      this.userCss.estiloPreDefinido.externo.corSecundaria;
    this.userCss.direcaoDegrade =
      this.userCss.estiloPreDefinido.externo.direcaoDegrade;
    this.userCss.preenchimento =
      this.userCss.estiloPreDefinido.interno.background;
    this.userCss.contorno = this.userCss.estiloPreDefinido.interno.borderColor;
    this.userCss.curvaBorda =
      this.userCss.estiloPreDefinido.interno.borderRadius;
    this.userCss.espessura = this.userCss.estiloPreDefinido.interno.borderWidth;
    this.userCss.fonte = 'Wix Madefor Text';
    this.userCss.fontePerfil = 'Wix Madefor Text';
  }

  changeShadow(boxShadow: string | undefined, selectedShadow: number) {
    if (this.profileStylesChangesBlocked) {
      return;
    }

    this.userCss.sombra = boxShadow;
    this.selectedShadow = selectedShadow;
  }

  changeRadius(borderRadius: string | undefined, selectedRadius: number) {
    if (this.profileStylesChangesBlocked) {
      return;
    }

    this.userCss.curvaBorda = borderRadius;
    this.selectedRadius = selectedRadius;
  }

  setNegrito() {
    if (this.profileStylesChangesBlocked) {
      return;
    }

    this.userCss.negrito = !this.userCss.negrito;
  }

  setItalico() {
    if (this.profileStylesChangesBlocked) {
      return;
    }

    this.userCss.italico = !this.userCss.italico;
  }

  setNegritoPerfil() {
    if (this.profileStylesChangesBlocked) {
      return;
    }

    this.userCss.negritoPerfil = !this.userCss.negritoPerfil;
  }

  setItalicoPerfil() {
    if (this.profileStylesChangesBlocked) {
      return;
    }

    this.userCss.italicoPerfil = !this.userCss.italicoPerfil;
  }

  changeBorderRadius(radius: any) {
    this.userCss.espessura = radius;
  }

  cancelProfileImageChange(event: Event) {
    event.stopPropagation();
    this.imageProfileChanged = false;
    this.defaultImageSelected = true;
    this.selectedProfile.data.uriImageProfile = this.defaultProfileImage;
  }

  cancelBackgroundImageChange(event: Event) {
    event.stopPropagation();
    this.imageBackgroundChanged = false;
    this.selectedProfile.data.uriImageBackground = this.defaultCallingCardImage;

    this.http.get(this.defaultCallingCardImage, { responseType: 'blob' }).subscribe(blob => {
      const file = new File([blob], 'default-calling-card-image.png', { type: 'image/png' });

      this.imageBackgroundChanged = true;
      this.uploadedBackgroundImage = file;
    });
  }

  async save() {
    if (this.externalBackgrounChanged && this.uploadedExternalBackgroundImage) {
      this.saveImageBackground();
    } else if (this.imageProfileChanged) {
      this.saveImageProfile();
    } else if (this.imageBackgroundChanged) {
      this.saveImageCallingCardBackground();
    } else {
      this.saveProfile();
    }
  }

  saveImageProfile() {
    this.saveProfileLoading = true;

    if (this.userProfile && this.uploadedProfileImage) {
      this.userProfileService
        .saveProfileImage(
          this.userProfile.documentUserProfile.idUser,
          `profile_${this.selectedProfile.indicator}`,
          this.uploadedProfileImage,
        )
        .subscribe({
          next: (response) => {
            const isDefaultProfile = this.selectedProfile.indicator === -1;

            this.imageProfileChanged = false;

            // this.selectedProfile.data.uriImageProfile = response;

            if (isDefaultProfile && this.userProfile) {
              this.userProfile.documentUserProfile.uriImageProfile = response;
            }

            if (!isDefaultProfile && this.userProfile) {
              if (!this.userProfile.documentUserProfile.listProfile) {
                this.userProfile.documentUserProfile.listProfile = [
                  {
                    visible: this.selectedProfile.data.profileVisible === undefined ? true : this.selectedProfile.data.profileVisible,
                    listSocialNetwork: this.selectedProfile.data.listSocialNetwork,
                    name: this.selectedProfile.data.name,
                    showImageProfile: this.selectedProfile.data.showImageProfile,
                    bio: this.selectedProfile.data.bio,
                    header: {
                      text: this.selectedProfile.data.header.text,
                      visible: this.selectedProfile.data.header.visible,
                    },
                    listButtons: [],
                    listContacts: [],
                    uriImageBackground: this.selectedProfile.data.uriImageBackground,
                    uriImageProfile: response,
                  }
                ]
              } else {
                this.userProfile.documentUserProfile.listProfile[this.selectedProfile.indicator].uriImageProfile = response;
              }
            }

            this.save();
          },
          error: () => {
            this.alertType = 'danger';
            this._alert.next(
              'Não foi possível salvar o perfil. Upload de imagem de Profile com erro. Contacte o administrador.',
            );

            this.saveProfileLoading = false;
          },
        });

      return;
    }

    this.imageProfileChanged = false;
    this.save();
  }

  saveImageCallingCardBackground() {
    this.saveProfileLoading = true;

    if (this.userProfile && this.uploadedBackgroundImage) {
      this.userProfileService
        .saveProfileImage(
          this.userProfile.documentUserProfile.idUser,
          `background_${this.selectedProfile.indicator}`,
          this.uploadedBackgroundImage,
        )
        .subscribe({
          next: (response) => {
            const isDefaultProfile = this.selectedProfile.indicator === -1;

            this.imageBackgroundChanged = false;

            if (isDefaultProfile && this.userProfile) {
              this.userProfile.documentUserProfile.uriImageBackground = response;
              this.userCss.callingCardImageUrl = response;
            }

            if (!isDefaultProfile && this.userProfile) {
              if (!this.userProfile.documentUserProfile.listProfile) {
                this.userProfile.documentUserProfile.listProfile = [
                  {
                    visible: this.selectedProfile.data.profileVisible === undefined ? true : this.selectedProfile.data.profileVisible,
                    listSocialNetwork: this.selectedProfile.data.listSocialNetwork,
                    name: this.selectedProfile.data.name,
                    showImageProfile: this.selectedProfile.data.showImageProfile,
                    bio: this.selectedProfile.data.bio,
                    header: {
                      text: this.selectedProfile.data.header.text,
                      visible: this.selectedProfile.data.header.visible,
                    },
                    listButtons: [],
                    listContacts: [],
                    uriImageProfile: this.selectedProfile.data.uriImageProfile,
                    uriImageBackground: response,
                  }
                ]
              } else {
                this.userProfile.documentUserProfile.listProfile[this.selectedProfile.indicator].uriImageBackground = response;
              }
            }

            this.selectedProfile.data.uriImageBackground = response;

            this.save();
          },
          error: () => {
            this.alertType = 'danger';
            this._alert.next(
              'Não foi possível salvar o perfil. Upload de imagem de Background com erro. Contacte o administrador.',
            );

            this.saveProfileLoading = false;
          },
        });
    } else {
      this.imageBackgroundChanged = false;
      this.save();
    }
  }

  saveImageBackground() {
    this.saveProfileLoading = true;

    if (this.userProfile && this.uploadedExternalBackgroundImage) {
      this.userProfileService
        .saveProfileImage(
          this.userProfile.documentUserProfile.idUser,
          `full_background_${this.selectedProfile.indicator}`,
          this.uploadedExternalBackgroundImage,
        )
        .subscribe({
          next: (response) => {
            this.externalBackgrounChanged = false;

            this.userCss.cardBackgroundImageUrl = response;

            this.save();
          },
          error: () => {
            this.alertType = 'danger';
            this._alert.next(
              'Não foi possível salvar o perfil. Upload de imagem de Background com erro. Contacte o administrador.',
            );

            this.saveProfileLoading = false;
          },
        });
    } else {
      this.imageBackgroundChanged = false;
      this.save();
    }
  }

  saveProfile() {
    if (this.userProfile) {
      if (this.selectedProfile.data && this.selectedProfile.data.name === "") {
        this.alertType = 'danger';
        this._alert.next(
          'Não foi possível salvar. Favor preencher o campo Nome.',
        );
        this.nameRequired = true;
        return;
      }
      this.nameRequired = false;
      this.saveProfileLoading = true;
      const userCssToUpdate = JSON.stringify(this.userCss);

      if (this.userProfile.documentUserCSS.css !== userCssToUpdate) {
        this.userProfile.documentUserCSS.css = userCssToUpdate;

        this.userProfileService.saveUserCss(this.userProfile.documentUserCSS).pipe(
          filter((mayBeOk: HttpResponse<object>) => mayBeOk.ok),
          map((response: HttpResponse<object>) => response.body),
        ).subscribe({
          error: () => {
            this.alertType = 'danger';
            this._alert.next(
              'Não foi possível salvar a estilizaçāo do pefil. Contacte o administrador.',
            );

            this.saveProfileLoading = false;
          },
        });
      }

      const isDefaultProfile = this.selectedProfile.indicator === -1;

      if (isDefaultProfile) {
        this.userProfile.documentUserProfile.listSocialNetwork = this.selectedProfile.data.listSocialNetwork;
        this.userProfile.documentUserProfile.name = this.selectedProfile.data.name;
        this.userProfile.documentUserProfile.showImageProfile = this.selectedProfile.data.showImageProfile;
        this.userProfile.documentUserProfile.bio = this.selectedProfile.data.bio;
        this.userProfile.documentUserProfile.uriImageBackground = this.selectedProfile.data.uriImageBackground;
        if (this.defaultImageSelected) {
          this.userProfile.documentUserProfile.uriImageProfile = "";
          this.defaultImageSelected = false;
        }
      } else {
        const indexProfileToUpdate = this.selectedProfile.indicator;

        if (!this.userProfile.documentUserProfile.listProfile || this.userProfile.documentUserProfile.listProfile.length < 1) {

          let imageProfile = this.selectedProfile.data.uriImageProfile;
          if (this.defaultImageSelected) {
            imageProfile = "";
            this.defaultImageSelected = false;
          }

          this.userProfile.documentUserProfile.listProfile = [
            {
              visible: this.selectedProfile.data.profileVisible === undefined ? true : this.selectedProfile.data.profileVisible,
              listSocialNetwork: this.selectedProfile.data.listSocialNetwork,
              name: this.selectedProfile.data.name,
              showImageProfile: this.selectedProfile.data.showImageProfile,
              bio: this.selectedProfile.data.bio,
              header: {
                text: this.selectedProfile.data.header.text,
                visible: this.selectedProfile.data.header.visible,
              },
              listButtons: [],
              listContacts: [],
              uriImageBackground: this.selectedProfile.data.uriImageBackground,
              uriImageProfile: imageProfile,
            }
          ]
        } else {
          this.userProfile.documentUserProfile.listProfile[indexProfileToUpdate].visible = this.selectedProfile.data.profileVisible === undefined ? true : this.selectedProfile.data.profileVisible;
          this.userProfile.documentUserProfile.listProfile[indexProfileToUpdate].listSocialNetwork = this.selectedProfile.data.listSocialNetwork;
          this.userProfile.documentUserProfile.listProfile[indexProfileToUpdate].name = this.selectedProfile.data.name;
          this.userProfile.documentUserProfile.listProfile[indexProfileToUpdate].showImageProfile = this.selectedProfile.data.showImageProfile;
          this.userProfile.documentUserProfile.listProfile[indexProfileToUpdate].bio = this.selectedProfile.data.bio;
          this.userProfile.documentUserProfile.listProfile[indexProfileToUpdate].uriImageBackground = this.selectedProfile.data.uriImageBackground;
          if (this.defaultImageSelected) {
            this.userProfile.documentUserProfile.listProfile[indexProfileToUpdate].uriImageProfile = "";
            this.defaultImageSelected = false;
          }
        }
      }

      this.userProfileService
        .saveDocumentUserProfile(this.userProfile.documentUserProfile)
        .pipe(
          filter((mayBeOk: HttpResponse<object>) => mayBeOk.ok),
          map((response: HttpResponse<object>) => response.body),
        )
        .subscribe({
          next: () => {
            this.alertType = 'success';
            this._alert.next('Perfil salvo com sucesso.');

            this.saveProfileLoading = false;
          },
          error: () => {
            this.alertType = 'danger';
            this._alert.next(
              'Não foi possível salvar o perfil. Contacte o administrador.',
            );

            this.saveProfileLoading = false;
          },
        });
    }
  }

  openPreviewModal() {
    if (this.screenWidth < this.desktopLimitWidth || this.screenHeight < this.desktopLimitHeight) {
      const modalRef = this.modalService.open(PreviewModalComponent, {
        fullscreen: true,
      });
      modalRef.componentInstance.userCss = this.userCss;
      modalRef.componentInstance.userProfile = this.selectedProfile;
      modalRef.componentInstance.profile = this.selectedProfile.data;
      modalRef.componentInstance.userProfilePlan = this.userProfilePlan;
      modalRef.componentInstance.showImageProfile = this.selectedProfile.data.showImageProfile;
      this.previewModalOpen = true;

      modalRef.result.then(() => {
        this.previewModalOpen = false;
      });
    }
  }

  changeHeaderVisibility() {
    this.selectedProfile.data.header.visible = !this.selectedProfile.data.header.visible;
  }

  toggleProfileImageVisible(event: any) {
    event.stopPropagation();
    this.selectedProfile.data.showImageProfile = !this.selectedProfile.data.showImageProfile;
  }

  toggleTooltipProfile(event: Event) {
    event.stopPropagation();
    this.showTooltipProfile = !this.showTooltipProfile;
  }

  toggleTooltipBackground(event: Event) {
    event.stopPropagation();
    this.showTooltipBackground = !this.showTooltipBackground;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (this.tooltipElement && !this.tooltipElement.nativeElement.contains(event.target)) {
      this.showTooltipProfile = false;
      this.showTooltipBackground = false;
    }
  }

  checkFontColor() {
    if (this.selectedProfile.data.header.text && this.selectedProfile.data.header.text.includes("#ffffff")) {
      return true;
    } else {
      return false;
    }
  }

  changeBiografia() {
  }
}

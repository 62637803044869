import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { IEventWithParticipationStatus, SluperEvent } from '../model/event.model';
import { UserProfilePlan } from "../model/user-plan.model";
import { IUserProfileWithCSS } from "../model/user-profile-css.model";
import { OpenEventService } from '../open-event.service';
import { OpenedCommunityService } from '../opened-community.service';
import { AuthService } from './../auth/auth.service';
import { ToastService } from './../new-sluper/core/service/toast.service';
import { UserProfileService } from './../user-profile/user-profile.service';
import { EventsService } from './events.service';

@Injectable({
    providedIn: 'root',
})
export class CommunityVerificationsService {

    constructor(
        private authService: AuthService,
        private toastService: ToastService,
        private openEventService: OpenEventService,
        private eventsService: EventsService,
        private openedCommunityService: OpenedCommunityService,
        private router: Router,
        private userProfileService: UserProfileService,
    ) { }



    async verifyEventAndNavigate(eventId: string): Promise<void> {
        // NO PUBLIC EVENT VERIFICAR SE É EVENTO OU COMUNIDADE
        //     SE FOR EVENTO BUSCAR A COMUNIDADE E MUDAR NA URL

        //     SE FOR COMUNIDADE MUDAR A URL
        if (!eventId) {
            this.toastService.show('Evento não foi passado como parâmetro.', 'danger');
            return;
        }

        const event = await firstValueFrom(this.eventsService.eventById(eventId));
        if (event && event.body && event.body.event) {
            let eventUrl = '';
            let currentEvent: SluperEvent = {} as SluperEvent;

            if (event.body.event.type === 'COMUNIDADE') { //Verificando se o evento é uma comunidade
                eventUrl = event.body.event.eventUrl!;
                currentEvent = event.body.event;
                await this.openEventService.setId(eventId, 'COMUNIDADE');
                this.openedCommunityService.setCommunity(eventUrl!);
                this.openedCommunityService.setMyCommunity(currentEvent!);
                this.router.navigate(['/login/', eventUrl!]);
            } else { // Se for um evento vai buscar a comunidade pelo parentID
                const data = await firstValueFrom(this.eventsService.eventById(event.body.event.parentEvent!));
                if (data && data.body && data.body.event) {
                    eventUrl = data.body.event.eventUrl!;
                    currentEvent = data.body.event;
                    await this.openEventService.setId(eventId, 'EVENTO');
                }

                const community = this.openedCommunityService.getCommunity();
                if (community) {
                    this.router.navigate(['/login/', community!]);
                } else {
                    this.router.navigate(['/login']);
                }
            }
            return;
        }

        const communityId = this.openedCommunityService.getCommunity();
        if (communityId) {
            this.router.navigate(['/login/', communityId]);
        } else {
            this.router.navigate(['/login']);
        }
    }


    beforeLoginVerification(actualParam: string | null): Promise<void> {
        // VERIFICAR SE EXISTE COMUNIDADE CASO TENHA ACTUALPARAM
        // CASO NAO TENHA IR PARA PROXIMO PASSO

        // VERIFICAR SE O USUARIO ESTA LOGADO
        // this.authService.isAuthenticated()

        // CASO NAO ESTEJA LOGADO
        // AGUARDAR FAZER LOGIN

        if (!actualParam || actualParam === '' || actualParam === 'undefined') {
            this.openedCommunityService.clearAllData();
            return Promise.resolve();
        } else {
            this.openedCommunityService.setCommunity(actualParam);
        }

        return Promise.resolve();
    }

    afterLoginVerification(actualParam: string | null) {

        //VERIFICAR SE ESTA PARTICIPANDO DA COMUNIDADE QUE ESTA TENTANDO ENTRAR

        // CASO NAO ESTEJA PARTICIPANDO, VERIFICAR SE EXISTE UM CONVITE PARA ELE PARTICIPAR
        // CASO NAO EXISTA UM CONVITE MOSTRAR O MODAL PARA ELE SELECIONAR AS COMUNIDADES QUE ELE PARTICIPA
        // FINALIZAR LOGIN E IR PAR A PAGINA DE EVENTS.COMPONENT

        // CASO EXISTA 
        // FINALIZAR LOGIN E IR PAR A PAGINA DE EVENTS.COMPONENT

        // CASO ESTEJA PARTICIPANDO
        // FINALIZAR LOGIN E IR PAR A PAGINA DE EVENTS.COMPONENT
    }

    onOpenEventComponentVerification(actualParam: string | null) {
        // PAGINA DE EVENTS.COMPONENT

        // ABRIR COMUNIDADE
        // CASO NAO ESTIVER PARTICIPANDO, VERIFICAR SE EXISTE UM CONVITE PARA ELE PARTICIPAR
        // PARTICIPAR DA COMUNIDADE

        // VERIFICAR SE TEM ALGUM EVENTO PARA ABRIR(USUARIO FOI CONVIDADO)
        // CASO TENHA ABRIR MODAL DE EVENTO

    }

    async getAllCompanies(): Promise<any[]> {
        try {
            const user = await firstValueFrom(this.userProfileService.userById(null));
            if (user && user.body) {
                const communities = await firstValueFrom(
                    this.eventsService.getFutureEvents(user.body.documentUser.idUser)
                );
                return communities.body?.eventsParticipanting.map((event: IEventWithParticipationStatus) => event.event) || []; // Retorna as comunidades ou um array vazio
            }
            return [];
        } catch (error) {
            console.error('Erro ao buscar comunidades:', error);
            throw error; // Lança o erro para ser tratado no componente
        }
    }

    async getUserPlan(): Promise<UserProfilePlan> {
        try {
            const plan = await firstValueFrom(this.userProfileService.getUserPlan());
            if (plan && plan.body && plan.body.planName) {
                return plan.body as UserProfilePlan; // Retorna como UserProfilePlan
            }
            throw new Error('Plano do usuário não encontrado ou inválido.');
        } catch (error) {
            console.error('Erro ao buscar plano:', error);
            throw error; // Lança o erro para ser tratado no componente
        }
    }

    async getUserProfile(): Promise<IUserProfileWithCSS> {
        try {
            const user = await firstValueFrom(this.userProfileService.userById(null))
            if (user && user.body) {
                return user.body as IUserProfileWithCSS; // Retorna como UserProfilePlan
            }
            throw new Error('Usuário não encontrado ou inválido.');
        } catch (error) {
            console.error('Erro ao buscar usuário:', error);
            throw error; // Lança o erro para ser tratado no componente
        }
    }


}
<!-- <div class="alert-div">
    <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
        {{ alertMessage }}
    </ngb-alert>
</div> -->
<app-loading-spinner [bgTransparent]="true" *ngIf=" loading"></app-loading-spinner>



<section id="side-bar-options">
    <div id="side-bar-options-content">

        <div id="side-bar-option-title">
            <h4>Mover para a categoria</h4>
        </div>

        <div class="side-bar-item" *ngFor="let item of categories"
            [ngClass]="{'selected-category': selectedCategory === item}" (click)="selectCategory(item)">
            <span>{{item.name || ''}}</span>
        </div>

        <div class="no-items" *ngIf="categories.length === 0">
            <span>Sem categorias</span>
        </div>

    </div>

    <div class="button-container">
        <button type="button" class="item-delete-button" (click)="closeModal(false, '')">Cancelar</button>
        <button [disabled]="!selectedCategory" type="button" class=" item-cancel-button"
            (click)="moveChannel()">Mover</button>
    </div>
</section>
import { Component } from '@angular/core';

@Component({
  selector: 'app-posts-settings',
  templateUrl: './posts-settings.component.html',
  styleUrls: ['./posts-settings.component.css']
})
export class PostsSettingsComponent {

}

import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateCategoryModalComponent } from '../create-category-modal/create-category-modal.component';
import { CreateChannelModalComponent } from '../create-channel-modal/create-channel-modal.component';

@Component({
  selector: 'app-create-channel-category-modal',
  templateUrl: './create-channel-category-modal.component.html',
  styleUrls: ['./create-channel-category-modal.component.css']
})
export class CreateChannelCategoryModalComponent {

  categoryModalData: {
    name: string
  } = {
      name: ''
    };

  channelModalData: {
    name: string,
    selectedEmoji: string | null,
    isEmojiPickerVisible: boolean,
    communityParticipantsFiltered: any[],
    sentContacts: any,
  } = {
      name: '',
      selectedEmoji: null,
      communityParticipantsFiltered: [],
      sentContacts: [{}],
      isEmojiPickerVisible: false,
    };

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) { }

  openCategoryModal() {
    const modalRef = this.modalService.open(CreateCategoryModalComponent, { centered: true, size: "xl" });
    modalRef.componentInstance.categoryName = this.categoryModalData.name;
    modalRef.componentInstance.saveCategory = (newCategoryName: string) => this.saveCategoryData(this.categoryModalData.name);
  }

  saveCategoryData(newCategoryName: string) {
    this.categoryModalData.name = newCategoryName;
    this.activeModal.close();
  }

  openChannelModal() {
    const modalRef = this.modalService.open(CreateChannelModalComponent, { centered: true, size: "xl" });
    modalRef.componentInstance.channelName = this.channelModalData.name;
    modalRef.componentInstance.selectedEmoji = this.channelModalData.selectedEmoji;
    modalRef.componentInstance.isEmojiPickerVisible = this.channelModalData.isEmojiPickerVisible;
    modalRef.componentInstance.selectedEmoji = this.channelModalData.selectedEmoji;
    modalRef.componentInstance.communityParticipantsFiltered = this.channelModalData.communityParticipantsFiltered;
    modalRef.componentInstance.sentContacts = this.channelModalData.sentContacts;
    modalRef.componentInstance.filterCommunityParticipants = this.filterCommunityParticipants.bind(this);
    modalRef.componentInstance.verifyCommunityParticipants = this.verifyCommunityParticipants.bind(this);
    modalRef.componentInstance.addCommunityParticipants = this.addCommunityParticipants.bind(this);
    modalRef.componentInstance.saveChannel = (channelModalData: any) => this.saveChannelData(this.channelModalData);
    this.activeModal.close();
  }

  filterCommunityParticipants(event: any) {
    if (event.target.value.length === 0) {
      this.channelModalData.communityParticipantsFiltered = this.channelModalData.sentContacts;
    }
  }

  verifyCommunityParticipants(person: any): boolean {
    return this.channelModalData.communityParticipantsFiltered.includes(person);
  }

  addCommunityParticipants(person: any) {
    this.channelModalData.communityParticipantsFiltered.push(person);
  }

  rmCommunityParticipants(person: any) {
    this.channelModalData.communityParticipantsFiltered.splice(this.channelModalData.communityParticipantsFiltered.indexOf(person), 1);
  }

  saveChannelData(channelModalData: any) {
    this.activeModal.close();
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-communitie-participants',
  templateUrl: './communitie-participants.component.html',
  styleUrls: ['./communitie-participants.component.css']
})
export class CommunitieParticipantsComponent {
  // @Input() communityParticipantsFiltered: any[] = [];
  // @Output() filterCommunityParticipantsEvent = new EventEmitter<any>();
  // @Output() verifyCommunitParticipantsEvent = new EventEmitter<any>();
  // @Output() addComunityParticipantsEvent = new EventEmitter<any>();
  // @Output() rmComunityParticipantsEvent = new EventEmitter<any>();
  // filterComunityParticipants(event: any) {
  //   this.filterCommunityParticipantsEvent.emit(event);
  // }

  // verifyCommunityParticipants(person: any): boolean {
  //   const isParticipant = this.communityParticipantsFiltered.includes(person);
  //    this.verifyCommunitParticipantsEvent.emit(person);
  //    return isParticipant;
  // }

  // addCommunityParticipants(person: any) {
  //   this.addComunityParticipantsEvent.emit(person);
  // }

  // rmCommunityParticipants(person: any) {
  //   this.rmComunityParticipantsEvent.emit(person);
  // }

  // addCommunityParticipants(person: any) {
  //   this.modalData.guests.push(person);
  // }

  // rmCommunityParticipants(person: any) {
  //   this.modalData.guests.splice(this.modalData.guests.indexOf(person), 1)
  // }

  // verifyCommunitParticipants(person: any) {
  //   return this.modalData.guests.includes(person)
  // }

  // filterCommunityParticipants(event: any) {
  //   if (event.target.value.length === 0) {
  //     this.communityParticipantsFiltered = this.sentContacts
  //     return
  //   }
  //   this.communityParticipantsFiltered = this.sentContacts.filter((person: any) => person.name.toLowerCase().includes(event.target.value.toLowerCase()))
  // }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { Comment, PostsService } from '../posts.service';

@Component({
  selector: 'app-answers-comment',
  templateUrl: './answers-comment.component.html',
  styleUrls: ['./answers-comment.component.css']
})
export class AnswersCommentComponent {
  @Input() answersComment!: Comment
  @Input() isUserCommunityOwner: boolean = false
  @Input() showDeleteButton: boolean = true
  @Input() userProfile: DocumentUserProfile | undefined
  @Output() deleteComment = new EventEmitter<number>();
  @Output() likeComment = new EventEmitter<number>();
  @Output() unlikeComment = new EventEmitter<number>();
  @Output() removeComment = new EventEmitter<Comment>();

  public loading = false
  public sanitizedComment: SafeHtml = '';


  constructor(
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private postsService: PostsService,
    private toastrService: ToastService
  ) { }


  ngOnInit(): void {
    this.sanitizedComment = this.sanitizer.bypassSecurityTrustHtml(this.processMentions(this.answersComment.text || ''));
  }

  processMentions(text: string): string {
    const mentionRegex = /<span class="mention" ([^>]*?)>(.*?)<\/span>/g;

    return text.replace(mentionRegex, (match, attributes, name) => {
      const idMatch = attributes.match(/data-mention-id="(.*?)"/);
      const id = idMatch ? idMatch[1] : null;

      if (id) {
        // Se tiver ID, mantém a lógica existente (link clicável)
        return `<a style="color: #7C29D9 !important; font-weight: 600; text-decoration: none;" href="https://sluper.bio/${id}" class="mention-link" target="_blank">${name}</a>`;
      } else {
        // Se não tiver ID, exibe o nome com aparência desabilitada
        return `<span style="color: gray; font-weight: 600; text-decoration: none; cursor: default;">${name}</span>`;
      }
    });
  }

  handleDeleteComment(id: number) {
    this.deleteComment.emit(id)
  }

  transformDate(date: string | Date): string {
    const meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    const data = new Date(date);
    const dia = data.getDate();
    const mes = meses[data.getMonth()];
    const hora = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');
    return `${mes} ${dia} às ${hora}:${minutos}`;
  }

  likePost(commentId: number) {
    this.likeComment.emit(commentId)
  }
  unlikePost(commentId: number) {
    this.unlikeComment.emit(commentId)
  }

  async openParticipantProfile(event: Event, id: string) {
    event.stopPropagation();
    window.open(`https://sluper.bio/${id}`);
  }


  verifyRemoveMention(): boolean {
    const mentionIdRegex = /data-mention-id="([^"]+)"/g;
    const matches = [...this.answersComment.text.matchAll(mentionIdRegex)];

    const mentionIds = matches.map(match => match[1]);

    return mentionIds.includes(this.userProfile!.idUser);
  }


  async removeMention(comment: Comment) {

    const userIdToRemove = this.userProfile!.idUser
    this.loading = true;
    try {
      const mentionRegex = /<span class="mention"[^>]*data-mention-id="([^"]+)"[^>]*>(.*?)<\/span>/g;
      let extractedMentionIds: string[] = [];
      comment.text.replace(mentionRegex, (match, mentionId) => {
        if (!extractedMentionIds.includes(mentionId)) {
          extractedMentionIds.push(mentionId);
        }
        return match;
      });
      let updatedMentionedId = comment.mentionedId && comment.mentionedId.length > 0
        ? comment.mentionedId
        : extractedMentionIds;

      const updatedText = comment.text.replace(mentionRegex, (match, mentionId, mentionText) => {
        return mentionId === userIdToRemove
          ? `<span class="mention" style="color: rgb(124, 41, 217); font-weight: 600; text-decoration: none;" contenteditable="false">${mentionText}</span>&nbsp;`
          : match;
      });

      updatedMentionedId = updatedMentionedId.filter(id => id !== userIdToRemove);

      const data: { id: number, text: string, mentionedId: string[] } = {
        id: comment.id,
        text: updatedText,
        mentionedId: updatedMentionedId
      };


      const resp = await firstValueFrom(this.postsService.updateComment(data));
      if (resp) {

        this.answersComment.text = updatedText;
        this.answersComment.mentionedId = updatedMentionedId;

        this.sanitizedComment = this.sanitizer.bypassSecurityTrustHtml(this.processMentions(this.answersComment.text || ''));
        this.removeComment.emit(this.answersComment)
      }
      else {
        this.toastrService.show('Erro ao remover a menção.', 'error');
      }
    } catch (error) {
      console.error(error);
      this.toastrService.show(
        'Ocorreu um erro ao tentar remover a menção. Tente novamente mais tarde.',
        'error'
      );
    } finally {
      this.loading = false;
    }
  }
}

<div class="other-community-card">
    <div class="image-container">
        <img *ngIf="event?.imageLink && event?.imageLink?.length" class="event-image" [src]="event?.imageLink"
            [alt]="event?.name">
        <img class="event-image" *ngIf="!event?.imageLink" [src]="defaultCallingCardImage" [alt]="event?.name">

        <div class="information">
            <h5 class="made-by">Criado por:</h5>
            <div class="made-by-container">
                <img class="made-by-image"
                    [src]="event?.organizer?.uriImageProfile ? event?.organizer?.uriImageProfile : 'assets/img/default_avatar_1.png'"
                    alt="profile-pic" />
                <span class="made-by-name pointer" (click)="openParticipantProfile($event, event?.organizer!.idUser)">{{
                    event?.organizer?.name }}</span>
            </div>
        </div>
    </div>


    <h4 class="event-title multi-line ">{{event?.name}}</h4>
    <!-- <button (click)="joinEvent()" [ngClass]="{
      'event-button join': event?.participationStatus === 'NAO_PARTICIPANDO',
      'event-button joining': event?.participationStatus === 'PARTICIPANDO',
      'event-button waiting': event?.participationStatus === 'PENDENTE_APROVACAO'
    }">
        {{
        event?.participationStatus === 'PARTICIPANDO' ? 'Participando' :
        event?.participationStatus === 'PENDENTE_APROVACAO' ? 'Aguardando Aprovação' :
        'Fazer parte'
        }}
    </button> -->

    <button (click)="joinEvent()" class="event-button join">
        Visualizar Comunidade
    </button>

</div>
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { EventsService } from './events/events.service';
import { ToastService } from './new-sluper/core/service/toast.service';

@Injectable({
    providedIn: 'root'
})
export class OpenEventService {
    private currentId: string | null = null;
    private readonly storageKey = 'modifiedEventId';

    constructor(
        private eventsService: EventsService,
        private toastService: ToastService
    ) { }

    private modifyId(id: string): string {
        // Insere caracteres extras em posições específicas do ID
        return id.split('-').join('XYZ'); // Substitui os "-" por "XYZ"
    }

    private revertId(modifiedId: string): string | null {
        // Reverte o ID ao formato original removendo "XYZ"
        return modifiedId.split('XYZ').join('-'); // Substitui "XYZ" por "-"
    }

    async setId(id: string, type: 'COMUNIDADE' | 'EVENTO'): Promise<boolean> {
        try {
            const event = await firstValueFrom(this.eventsService.eventById(id));
            if (!event.body?.event) {
                this.toastService.showError('Evento não encontrado');
                console.error('Evento nao encontrado');
                return false;
            }

            // Modifica o ID antes de salvar no localStorage
            const modifiedId = this.modifyId(id);
            localStorage.setItem(this.storageKey, modifiedId);
            localStorage.setItem('eventType', type);

            this.currentId = id;
            return true;
        } catch (error) {
            this.toastService.showError('Erro ao buscar o evento.');
            return false;
        }
    }

    getId(): string | null {
        // Recupera o ID do localStorage e reverte para o formato original
        const modifiedId = localStorage.getItem(this.storageKey);
        this.currentId = modifiedId ? this.revertId(modifiedId) : null;
        return this.currentId;
    }

    getType(): string | null {
        const type = localStorage.getItem('eventType');
        return type;
    }

    removeId(): void {
        this.currentId = null;
        localStorage.removeItem(this.storageKey);
    }
}

<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="preview-button-div">
  <button type="button" class="save-float-button" (click)="save()" translate><i
      class="bi bi-check2"></i>botaoSalvarFlutuante</button>
  <button
    *ngIf="(screenWidth < desktopLimitWidth || screenHeight < desktopLimitHeight) && !previewModalOpen && !imageSelectionModalOpen"
    type="button" class="preview-float-button" (click)="openPreviewModal()" translate>
    <i class="bi bi-binoculars"></i>botaoPreview
  </button>
</div>

<div class="body-div">
  <app-loading-spinner *ngIf="saveProfileLoading"></app-loading-spinner>

  <section class="profile-selector" *ngIf="userProfilePlan.profilePlanLimits.professionalView">
    <div [class]="selectedProfile.indicator === -1 ? 'profile-tab selected-tab' : 'profile-tab'"
      (click)="changeSelectedProfile(-1)">
      <i class="bi bi-person-fill"></i>
      <h2 class="profile-title">Perfil Pessoal</h2>
    </div>

    <div [class]="selectedProfile.indicator === 0 ? 'profile-tab selected-tab' : 'profile-tab'"
      (click)="changeSelectedProfile(0)">
      <i class="bi bi-briefcase-fill"></i>

      <h2 class="profile-title">Perfil Corporativo</h2>
    </div>
  </section>

  <section class="content-section"
    [style]="!userProfilePlan.profilePlanLimits.professionalView ? 'padding-top: 20px' : ''">
    <div class="options-div">
      <div ngbAccordion *ngIf="this.selectedProfile.indicator !== -1">
        <div ngbAccordionItem [collapsed]="false" class="collapse-container">
          <h2 ngbAccordionHeader class="collapse-container-titulo">
            <button ngbAccordionButton class="collapse-container-button" translate>links.visibility</button>
          </h2>

          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="contacts-container">
                  <div (click)="toggleProfileVisibility()" [matTooltip]="
                      selectedProfile.data.profileVisible ? 'Clique para tornar o perfil invisível' : 'Clique para tornar o perfil visível'
                    " class="profile-visibility-container">
                    <button class="profile-visibility-button" type="button">
                      <i class="bi bi-eye icon-large" *ngIf="this.selectedProfile.data.profileVisible"
                        style="font-size: 12px"></i>
                      <i class="bi bi-eye-slash icon-large" *ngIf="!this.selectedProfile.data.profileVisible"
                        style="font-size: 12px"></i>
                    </button>

                    <span>{{ selectedProfile.data.profileVisible ? 'Visível' : 'Invisível' }}</span>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="perfil-container">
        <div class="image-selector-container">
          <div class="image-container">
            <!-- <div class="image-text-container">
              <span class="image-label">Imagem de perfil</span>
            </div> -->

            <div (click)="openImageSelectionModal('profile')" class="profile-image-background"
              [style.background-image]="'url(' + this.selectedProfile.data.uriImageProfile + ')'">
              <button type="button" class="profile-image-visibility-button" (click)="toggleProfileImageVisible($event)">
                <i class="bi bi-eye icon-large" *ngIf="this.selectedProfile.data.showImageProfile"></i>
                <i class="bi bi-eye-slash icon-large" *ngIf="!this.selectedProfile.data.showImageProfile"></i>
              </button>

              <button type="button" class="delete-image-button" (click)="cancelProfileImageChange($event)">
                <i class="bi bi-x-lg"></i>
              </button>

              <button type="button" class="select-image-button">
                <img src="/assets/img/photo_camera.svg" alt="Selecionar imagem" />
              </button>

              <div class="info-image-profile"
                matTooltip="Dimensões ideias da foto: 540x540&#13;Os arquivos de imagens devem ser inferior a 2 MB"
                matTooltipClass="mat-tooltip" (click)="toggleTooltipProfile($event)">
                <i class="bi bi-info"></i>
              </div>
              <div class="tooltip-content" *ngIf="
                  showTooltipProfile &&
                  (screenWidth < desktopLimitWidth || screenHeight < desktopLimitHeight) &&
                  !previewModalOpen &&
                  !imageSelectionModalOpen
                " #tooltip>
                <p>Dimensões ideias da foto: 540x540</p>
                <p>Os arquivos de imagens devem ser inferior a 2 MB</p>
              </div>
            </div>
          </div>

          <div class="image-container">
            <!-- <div class="image-text-container">
              <span class="image-label">Plano de fundo do perfil</span>
            </div> -->

            <div (click)="openImageSelectionModal('calling-card-background')" class="profile-background"
              [style.background-image]="'url(' + this.selectedProfile.data.uriImageBackground + ')'">
              <button type="button" class="delete-image-button" (click)="cancelBackgroundImageChange($event)"
                *ngIf="!profileStylesChangesBlocked">
                <i class="bi bi-x-lg"></i>
              </button>

              <div class="info-image-background"
                matTooltip="Dimensões ideias da foto: 780x300&#13;Os arquivos de imagens devem ser inferior a 2 MB"
                matTooltipClass="mat-tooltip" (click)="toggleTooltipBackground($event)">
                <i class="bi bi-info"></i>
              </div>
              <div class="tooltip-content" *ngIf="
                  (screenWidth < desktopLimitWidth || screenHeight < desktopLimitHeight) &&
                  !previewModalOpen &&
                  !imageSelectionModalOpen &&
                  showTooltipBackground
                " #tooltip>
                <p>Dimensões ideais da foto: 780x300</p>
                <p>Os arquivos de imagens devem ser inferiores a 2 MB</p>
              </div>

              <!-- <button *ngIf="!profileStylesChangesBlocked" type="button" class="select-image-button">
                <img src="/assets/img/photo_camera.svg" alt="Selecionar imagem" />
              </button> -->
            </div>
          </div>
        </div>

        <div class="inputs-div">
          <div class="input-container">
            <label for="nome" class="nome-perfil-text" translate>meuSluper.nomePerfil</label>

            <div class="input-group nome-perfil-input">
              <div class="input-group-prepend">
                <span class="input-group-text perfil-input-prepend" id="basic-addon1"><i
                    class="bi bi-pencil"></i></span>
              </div>
              <input type="text" class="form-control perfil-input-text" id="nome" name="nome"
                [(ngModel)]="selectedProfile.data.name" maxlength="80"
                [style.border-color]="nameRequired ? 'red' : '#dee2e6'" (input)="nameRequired = false" />
            </div>
          </div>

          <div class="input-container">
            <label for="biografia" class="biografia-text" translate>meuSluper.biografia</label>

            <div class="input-group biografia-input">
              <div class="input-group-prepend">
                <span class="input-group-text perfil-input-prepend" id="basic-addon1" style="height: 64px"><i
                    class="bi bi-pencil"></i></span>
              </div>

              <textarea class="form-control perfil-input-text biography-textarea" id="biografia" name="biografia"
                [(ngModel)]="selectedProfile.data.bio" maxlength="50"></textarea>
            </div>
          </div>
        </div>
      </div>

      <div ngbAccordion>
        <div ngbAccordionItem [collapsed]="false" class="collapse-container">
          <h2 ngbAccordionHeader class="collapse-container-titulo">
            <button ngbAccordionButton class="collapse-container-button"
              translate>meuSluper.estiloFontePerfil.titulo</button>
          </h2>

          <div ngbAccordionCollapse>
            <div class="profile-css-locked-container" *ngIf="profileStylesChangesBlocked">
              <div class="lock-container">
                <i class="bi bi-lock lock-icon"></i>

                <span class="lock-label">Edição bloqueada devido a template vinculado. Contate o administrador de sua
                  licença para alterações.
                </span>
              </div>
            </div>

            <div ngbAccordionBody>
              <ng-template>
                <div class="estilo-fonte-container-content">
                  <div class="profile-font-container">
                    <label for="fonte" class="cores-labels" translate>meuSluper.estiloFontePerfil.fonte</label>
                    <select id="fonte" name="fonte" class="form-select fonte-input" [(ngModel)]="userCss.fontePerfil"
                      [disabled]="profileStylesChangesBlocked">
                      <option *ngFor="let fonte of fontes" [value]="fonte">
                        {{ fonte }}
                      </option>
                    </select>
                  </div>

                  <div class="font-styles-container">
                    <div class="profile-font-style-container">
                      <label class="cores-labels" translate>meuSluper.estiloFontePerfil.opcoesCaractere</label>
                      <div class="opcoes-caractere-div">
                        <button class="opcoes-caractere-button opcao-negrito" (click)="setNegritoPerfil()"
                          [style.background]="
                            userCss.negritoPerfil
                              ? 'var(--colors-neutrals-neutral-xx-dark, #29299b)'
                              : 'var(--colors-neutrals-neutral-x-light, #F5F5F5)'
                          " [style.color]="
                            userCss.negritoPerfil
                              ? 'var(--colors-neutrals-neutral-x-light, #F5F5F5)'
                              : 'var(--colors-neutrals-neutral-dark, #A5A5A5)'
                          "
                          [style.border]="userCss.negritoPerfil ? '0' : '1px solid var(--colors-neutrals-neutral-dark, #A5A5A5)'">
                          N
                        </button>

                        <button class="opcoes-caractere-button opcao-italico" (click)="setItalicoPerfil()"
                          [style.background]="
                            userCss.italicoPerfil
                              ? 'var(--colors-neutrals-neutral-xx-dark, #29299b)'
                              : 'var(--colors-neutrals-neutral-x-light, #F5F5F5)'
                          " [style.color]="
                            userCss.italicoPerfil
                              ? 'var(--colors-neutrals-neutral-x-light, #F5F5F5)'
                              : 'var(--colors-neutrals-neutral-dark, #A5A5A5)'
                          "
                          [style.border]="userCss.italicoPerfil ? '0' : '1px solid var(--colors-neutrals-neutral-dark, #A5A5A5)'">
                          I
                        </button>
                      </div>
                    </div>

                    <div class="profile-font-style-container">
                      <label for="cor-fonte" class="cores-labels"
                        translate>meuSluper.estiloFontePerfil.corPrincipal</label>

                      <div class="estilo-fonte-container-inputs-div">
                        <input type="color" class="form-control form-control-color input-color" id="cor-fonte"
                          name="cor-fonte" [disabled]="profileStylesChangesBlocked"
                          [(ngModel)]="userCss.corFontePerfil" />

                        <input type="text" class="input-color-text" id="cor-fonte" name="cor-fonte"
                          [(ngModel)]="userCss.corFontePerfil" [disabled]="profileStylesChangesBlocked" />
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div ngbAccordion>
        <div ngbAccordionItem [collapsed]="false" class="collapse-container">
          <h2 ngbAccordionHeader class="collapse-container-titulo">
            <button ngbAccordionButton class="collapse-container-button" translate>meuSluper.status</button>
          </h2>

          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="status containers container-row">
                  <button type="button" class="status icon-button" (click)="changeHeaderVisibility()">
                    <span *ngIf="selectedProfile.data.header.visible"><i class="bi bi-eye icon-large"></i>
                      Visível</span>
                    <span *ngIf="!selectedProfile.data.header.visible"><i class="bi bi-eye-slash icon-large">
                        Invisível</i></span>
                  </button>
                  <!-- <div class="input-group status-input">
                    <div class="input-group-prepend">
                      <span class="input-group-text perfil-input-prepend textarea-prepend" id="basic-addon1"><i
                          class="bi bi-pencil"></i></span>
                    </div>

                    <textarea type="text" name="header" id="header"
                      class="form-control status container-input headline-textarea"
                      [(ngModel)]="selectedProfile.data.header.text" maxlength="150"></textarea>
                  </div> -->
                  <st-editor style="width: 100%; word-break: break-all; border-radius: 6px;"
                    [(ngModel)]="selectedProfile.data.header.text" [config]="config"
                    [style.font-family]="userCss.fontePerfil"
                    [style.background-color]="checkFontColor() ? '#c5c5c5' : 'white'"></st-editor>


                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div ngbAccordion>
        <div ngbAccordionItem [collapsed]="false" class="collapse-container">
          <h2 ngbAccordionHeader class="collapse-container-titulo">
            <button ngbAccordionButton class="collapse-container-button"
              translate>meuSluper.estilosPreDefinidos</button>
          </h2>

          <div ngbAccordionCollapse>
            <div class="profile-css-locked-container" *ngIf="profileStylesChangesBlocked">
              <div class="lock-container">
                <i class="bi bi-lock lock-icon"></i>

                <span class="lock-label">Edição bloqueada devido a template vinculado. Contate o administrador de sua
                  licença para alterações.
                </span>
              </div>
            </div>

            <div ngbAccordionBody>
              <ng-template>
                <div class="estilos">
                  <div class="retangulo-externo" *ngFor="let estilo of estilos; let i = index"
                    (click)="getDefaultStyle(i)" [style.background]="getBackground(estilo)"
                    [style.border-radius]="estilo.externo.borderRadius"
                    [style.border-style]="estilo.externo.borderStyle" [style.border-color]="estilo.externo.borderColor"
                    [style.border-width]="estilo.externo.borderWidth">
                    <div class="retangulo-interno" [style.background]="estilo.interno.background"
                      [style.border-radius]="estilo.interno.borderRadius"
                      [style.border-style]="estilo.interno.borderStyle"
                      [style.border-color]="estilo.interno.borderColor"
                      [style.border-width]="estilo.interno.borderWidth"></div>
                    <div class="retangulo-interno" [style.background]="estilo.interno.background"
                      [style.border-radius]="estilo.interno.borderRadius"
                      [style.border-style]="estilo.interno.borderStyle"
                      [style.border-color]="estilo.interno.borderColor"
                      [style.border-width]="estilo.interno.borderWidth"></div>
                    <div class="retangulo-interno" [style.background]="estilo.interno.background"
                      [style.border-radius]="estilo.interno.borderRadius"
                      [style.border-style]="estilo.interno.borderStyle"
                      [style.border-color]="estilo.interno.borderColor"
                      [style.border-width]="estilo.interno.borderWidth"></div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div ngbAccordion>
        <div ngbAccordionItem [collapsed]="false" class="collapse-container">
          <h2 ngbAccordionHeader class="collapse-container-titulo">
            <button ngbAccordionButton class="collapse-container-button"
              translate>meuSluper.coresDeFundo.titulo</button>
          </h2>
          <div ngbAccordionCollapse>
            <div class="profile-css-locked-container" *ngIf="profileStylesChangesBlocked">
              <div class="lock-container">
                <i class="bi bi-lock lock-icon"></i>

                <span class="lock-label">Edição bloqueada devido a template vinculado. Contate o administrador de sua
                  licença para alterações.
                </span>
              </div>
            </div>

            <div ngbAccordionBody class="background-custom-section-body">
              <ng-template>
                <div class="background-container">
                  <div (click)="changeBackgroundType('color')" class="background-option-container"
                    [style]="templateBgType === 'color' ? 'background-color: #fff;' : 'background-color: #cfcfcf'">
                    <div class="background-option-title">
                      <input [(ngModel)]="isColorBgSelected" [checked]="isColorBgSelected" type="checkbox"
                        name="image-background-enabled" id="image-background-enabled"
                        (click)="changeBackgroundType('color')" />

                      <label for="image-background-enabled" class="image-background-enabled-label"> Fundo colorido
                      </label>
                    </div>

                    <div class="background-colors-inputs">
                      <div class="cores-container-div">
                        <label for="cor-principal" class="cores-labels"
                          translate>meuSluper.coresDeFundo.corPrincipal</label>
                        <div class="cores-container-inputs-div">
                          <input type="color" class="form-control form-control-color input-color" id="cor-principal"
                            name="cor-principal" [disabled]="profileStylesChangesBlocked"
                            [(ngModel)]="userCss.corPrimaria" />
                          <input type="text" class="input-color-text" [disabled]="profileStylesChangesBlocked"
                            id="cor-principal" name="cor-principal" [(ngModel)]="userCss.corPrimaria" />
                        </div>
                      </div>

                      <div class="cores-container-div">
                        <label for="cor-secundaria" class="cores-labels"
                          translate>meuSluper.coresDeFundo.corSecundaria</label>
                        <div class="cores-container-inputs-div">
                          <input type="color" class="form-control form-control-color input-color" id="cor-secundaria"
                            [disabled]="profileStylesChangesBlocked" name="cor-secundaria"
                            [(ngModel)]="userCss.corSecundaria" />
                          <input type="text" class="input-color-text" id="cor-secundaria"
                            [disabled]="profileStylesChangesBlocked" name="cor-secundaria"
                            [(ngModel)]="userCss.corSecundaria" />
                        </div>
                      </div>

                      <div class="cores-container-div">
                        <label for="direcao-degrade" class="cores-labels"
                          translate>meuSluper.coresDeFundo.direcaoDegrade</label>

                        <div class="fade-background-direction">
                          <img src="/assets/img/gradient.svg" alt="Degradê" />
                          <input type="number" class="input-color-text" id="direcao-degrade" min="0" max="360"
                            [disabled]="profileStylesChangesBlocked" name="direcao-degrade"
                            [(ngModel)]="userCss.direcaoDegrade" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="background-option-container" (click)="changeBackgroundType('image')"
                    [style]="templateBgType === 'image' ? 'background-color: #fff;' : 'background-color: #cfcfcf'">
                    <div class="background-option-title">
                      <input [(ngModel)]="isImageBgSelected" [checked]="isImageBgSelected" type="checkbox"
                        name="image-background-enabled" id="image-background-enabled"
                        (click)="changeBackgroundType('image')" />

                      <label for="image-background-enabled" class="image-background-enabled-label"> Fundo com imagem
                      </label>
                    </div>

                    <div class="background-options-content background-image-option-container">
                      <button class="background-image-selector" [disabled]="templateBgType === 'color'"
                        (click)="openImageSelectionModal('background')"
                        [style]="userCss.cardBackgroundImageUrl ? 'background-image: url(' + userCss.cardBackgroundImageUrl + ')' : ''">
                        <span class="background-image-selector-label" *ngIf="!userCss.cardBackgroundImageUrl">
                          <i class="bi bi-file-image"></i>

                          Clique aqui para selecionar uma imagem para o fundo
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div ngbAccordion>
        <div ngbAccordionItem [collapsed]="false" class="collapse-container">
          <h2 ngbAccordionHeader class="collapse-container-titulo">
            <button ngbAccordionButton class="collapse-container-button" translate>meuSluper.estiloBotao.titulo</button>
          </h2>
          <div ngbAccordionCollapse>
            <div class="profile-css-locked-container" *ngIf="profileStylesChangesBlocked">
              <div class="lock-container">
                <i class="bi bi-lock lock-icon"></i>

                <span class="lock-label">Edição bloqueada devido a template vinculado. Contate o administrador de sua
                  licença para alterações.
                </span>
              </div>
            </div>

            <div ngbAccordionBody>
              <ng-template>
                <div class="button-style-container">
                  <div class="estilo-botao-container-div">
                    <label class="cores-labels" translate>meuSluper.estiloBotao.formato</label>
                    <div class="cores-container-inputs-div">
                      <button class="formato-1" (click)="changeRadius('0', 1)" [style.background]="
                          selectedRadius === 1
                            ? 'var(--colors-neutrals-neutral-xx-dark, #29299b)'
                            : 'var(--colors-neutrals-neutral-medium, #C5C5C5)'
                        "></button>
                      <button class="formato-2" (click)="changeRadius('8', 2)" [style.background]="
                          selectedRadius === 2
                            ? 'var(--colors-neutrals-neutral-xx-dark, #29299b)'
                            : 'var(--colors-neutrals-neutral-medium, #C5C5C5)'
                        "></button>
                      <button class="formato-3" (click)="changeRadius('25', 3)" [style.background]="
                          selectedRadius === 3
                            ? 'var(--colors-neutrals-neutral-xx-dark, #29299b)'
                            : 'var(--colors-neutrals-neutral-medium, #C5C5C5)'
                        "></button>
                    </div>
                  </div>

                  <div class="estilo-botao-container-div">
                    <label for="espessura" class="cores-labels" translate>meuSluper.estiloBotao.espessura</label>
                    <input type="range" class="form-range" min="0" [disabled]="profileStylesChangesBlocked" max="5"
                      id="espessura" [value]="userCss.espessura" #range (change)="changeBorderRadius(range.value)" />
                    <span class="range-value range-min">-</span>
                    <span class="range-value range-max">+</span>
                  </div>

                  <div class="estilo-botao-container-div">
                    <label class="cores-labels" translate>meuSluper.estiloBotao.sombra</label>
                    <div class="cores-container-inputs-div">
                      <button class="sombra-1" (click)="changeShadow(undefined, 1)" [style.background]="
                          selectedShadow === 1
                            ? 'var(--colors-neutrals-neutral-xx-dark, #29299b)'
                            : 'var(--colors-neutrals-neutral-medium, #C5C5C5)'
                        "></button>
                      <button class="sombra-2" (click)="changeShadow('0px 6px 6px 0px rgba(0, 0, 0, 0.10)', 2)"
                        [style.background]="
                          selectedShadow === 2
                            ? 'var(--colors-neutrals-neutral-xx-dark, #29299b)'
                            : 'var(--colors-neutrals-neutral-medium, #C5C5C5)'
                        "></button>
                      <button class="sombra-3" (click)="changeShadow('0px 8px 8px 0px rgba(0, 0, 0, 0.25)', 3)"
                        [style.background]="
                          selectedShadow === 3
                            ? 'var(--colors-neutrals-neutral-xx-dark, #29299b)'
                            : 'var(--colors-neutrals-neutral-medium, #C5C5C5)'
                        "></button>
                    </div>
                  </div>
                </div>

                <div class="button-text-style-container">
                  <div class="preenchimento-contorno-div">
                    <div class="estilo-botao-container-div">
                      <label for="preenchimento" class="cores-labels"
                        translate>meuSluper.estiloBotao.preenchimento</label>
                      <div class="estilo-botao-container-inputs-div">
                        <input type="color" class="form-control form-control-color input-color" id="preenchimento"
                          name="preenchimento" [disabled]="profileStylesChangesBlocked"
                          [(ngModel)]="userCss.preenchimento" />
                        <input type="text" class="input-color-text" id="preenchimento" name="preenchimento"
                          [disabled]="profileStylesChangesBlocked" [(ngModel)]="userCss.preenchimento" />
                      </div>
                    </div>
                    <div class="estilo-botao-container-div">
                      <label for="contorno" class="cores-labels" translate>meuSluper.estiloBotao.contorno</label>
                      <div class="estilo-botao-container-inputs-div">
                        <input type="color" class="form-control form-control-color input-color" id="contorno"
                          [disabled]="profileStylesChangesBlocked" name="contorno" [(ngModel)]="userCss.contorno" />
                        <input type="text" class="input-color-text" id="contorno" name="contorno"
                          [(ngModel)]="userCss.contorno" [disabled]="profileStylesChangesBlocked" />
                      </div>
                    </div>
                  </div>

                  <div class="estilo-botao-container-div">
                    <label for="curva-borda" class="cores-labels" translate>meuSluper.estiloBotao.curvaBorda</label>
                    <div class="curva-borda-div">
                      <img src="/assets/img/Vector 8.svg" alt="Curva" />
                      <input type="number" class="input-color-text" id="curva-borda" min="0" max="25"
                        [disabled]="profileStylesChangesBlocked" name="curva-borda" [(ngModel)]="userCss.curvaBorda" />
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div ngbAccordion>
        <div ngbAccordionItem [collapsed]="false" class="collapse-container">
          <h2 ngbAccordionHeader class="collapse-container-titulo">
            <button ngbAccordionButton class="collapse-container-button" translate>meuSluper.estiloFonte.titulo</button>
          </h2>
          <div ngbAccordionCollapse>
            <div class="profile-css-locked-container" *ngIf="profileStylesChangesBlocked">
              <div class="lock-container">
                <i class="bi bi-lock lock-icon"></i>

                <span class="lock-label">Edição bloqueada devido a template vinculado. Contate o administrador de sua
                  licença para alterações.
                </span>
              </div>
            </div>

            <div ngbAccordionBody>
              <ng-template>
                <div class="estilo-fonte-container-content">
                  <div class="estilo-fonte-container-div">
                    <label for="fonte" class="cores-labels" translate>meuSluper.estiloFonte.fonte</label>
                    <select id="fonte" [disabled]="profileStylesChangesBlocked" name="fonte"
                      class="form-select fonte-input" [(ngModel)]="userCss.fonte">
                      <option *ngFor="let fonte of fontes" [value]="fonte">
                        {{ fonte }}
                      </option>
                    </select>
                  </div>

                  <div class="estilo-fonte-container-div">
                    <label class="cores-labels" translate>meuSluper.estiloFonte.opcoesCaractere</label>
                    <div class="opcoes-caractere-div">
                      <button class="opcoes-caractere-button opcao-negrito" (click)="setNegrito()" [style.background]="
                          userCss.negrito
                            ? 'var(--colors-neutrals-neutral-xx-dark, #29299b)'
                            : 'var(--colors-neutrals-neutral-x-light, #F5F5F5)'
                        " [style.color]="
                          userCss.negrito
                            ? 'var(--colors-neutrals-neutral-x-light, #F5F5F5)'
                            : 'var(--colors-neutrals-neutral-dark, #A5A5A5)'
                        "
                        [style.border]="userCss.negrito ? '0' : '1px solid var(--colors-neutrals-neutral-dark, #A5A5A5)'">
                        N
                      </button>
                      <button class="opcoes-caractere-button opcao-italico" (click)="setItalico()" [style.background]="
                          userCss.italico
                            ? 'var(--colors-neutrals-neutral-xx-dark, #29299b)'
                            : 'var(--colors-neutrals-neutral-x-light, #F5F5F5)'
                        " [style.color]="
                          userCss.italico
                            ? 'var(--colors-neutrals-neutral-x-light, #F5F5F5)'
                            : 'var(--colors-neutrals-neutral-dark, #A5A5A5)'
                        "
                        [style.border]="userCss.italico ? '0' : '1px solid var(--colors-neutrals-neutral-dark, #A5A5A5)'">
                        I
                      </button>
                    </div>
                  </div>

                  <div class="estilo-fonte-container-div">
                    <label for="cor-fonte" class="cores-labels" translate>meuSluper.estiloFonte.corPrincipal</label>
                    <div class="estilo-fonte-container-inputs-div">
                      <input type="color" class="form-control form-control-color input-color" id="cor-fonte"
                        name="cor-fonte" [disabled]="profileStylesChangesBlocked" [(ngModel)]="userCss.corFonte" />
                      <input type="text" class="input-color-text" id="cor-fonte"
                        [disabled]="profileStylesChangesBlocked" name="cor-fonte" [(ngModel)]="userCss.corFonte" />
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="visual-div" *ngIf="screenWidth > desktopLimitWidth && screenHeight > desktopLimitHeight">
      <app-profile-preview *ngIf="screenWidth > desktopLimitWidth && screenHeight > desktopLimitHeight"
        [userCss]="userCss" [documentUser]="userProfile?.documentUser"
        [profileImageUrl]="selectedProfile.data.uriImageProfile"
        [backgroundImageUrl]="selectedProfile.data.uriImageBackground" [bio]="selectedProfile.data.bio"
        [name]="selectedProfile.data.name" [header]="selectedProfile.data.header"
        [redesSociais]="selectedProfile.data.listSocialNetwork" [linkButtons]="selectedProfile.data.listButtons"
        [showImageProfile]="this.selectedProfile.data.showImageProfile" class="profile-preview"
        style="flex: 0"></app-profile-preview>

      <!-- <div class="save-button-container">
        <button type="button" id="salvar" name="salvar" class="botao-salvar" (click)="save()" translate>
          <i class="bi bi-save"></i>botaoSalvar
        </button>
      </div> -->
    </div>
  </section>
</div>

<ng-template #imageSelectionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" translate>meuSluper.modalImagem.titulo</h4>
    <button type="button" class="close" aria-label="Fechar" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <label class="image-upload-label" for="image-upload" *ngIf="!cropMode">
      <input id="image-upload" class="image-upload" accept="image/jpeg, image/png, image/jpg" type="file"
        (change)="fileChangeEvent($event, 1, 1)" />

      <i class="bi bi-card-image"></i>

      <span>Clique aqui para selecionar e enviar a sua foto</span>

      <span *ngIf="currentImageType === 'calling-card-background' && !cropMode" class="image-scale-orientations">
        Tamanho mínimo da imagem: 620x146
      </span>
      <span *ngIf="currentImageType === 'background' && !cropMode" class="image-scale-orientations">
        Tamanho mínimo da imagem: 1200:720
      </span>
    </label>

    <ng-container *ngIf="currentImageType === 'profile'">
      <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1" format="png"
        (imageCropped)="imageCropped($event)" [roundCropper]="true" [cropperMaxWidth]="1920"
        [cropperMaxHeight]="1080"></image-cropper>
    </ng-container>

    <ng-container *ngIf="currentImageType === 'calling-card-background'">
      <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="617 / 149"
        format="png" (imageCropped)="imageCropped($event)" [cropperMaxWidth]="1920"
        [cropperMaxHeight]="1080"></image-cropper>
    </ng-container>

    <ng-container *ngIf="currentImageType === 'background'">
      <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1280 / 832"
        format="png" (imageCropped)="imageCropped($event)" [cropperMaxWidth]="1920"
        [cropperMaxHeight]="1080"></image-cropper>
    </ng-container>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" *ngIf="cropMode" (click)="removeSelectedImage()" translate>
      meuSluper.modalImagem.remove
    </button>
    <button type="button" class="btn btn-primary" (click)="confirmImageSelection(croppedImage)" translate>
      meuSluper.modalImagem.confirmar
    </button>
  </div>
</ng-template>
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-see-announcement',
  templateUrl: './see-announcement.component.html',
  styleUrls: ['./see-announcement.component.css'],
})
export class SeeAnnouncementComponent {
  public announcement: any = '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      announcement: any;
    },
    private matDialogRef: MatDialogRef<SeeAnnouncementComponent>
  ) {}

  closeModal() {
    this.matDialogRef.close();
  }
}

import { HttpResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, filter, firstValueFrom, map } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { CommunityVerificationsService } from '../events/community-verifications.service';
import { EventDetailsComponent } from '../events/event-details/event-details.component';
import { EventsService } from '../events/events.service';
import { Connection } from '../model/connection.model';
import { DocumentUserProfile } from '../model/document-user-profile.model';
import { SluperEvent } from '../model/event.model';
import { IDocumentPreferences } from '../model/preferences.model';
import { IUserProfilePlan } from '../model/user-plan.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { OpenEventService } from '../open-event.service';
import { OpenedCommunityService } from '../opened-community.service';
import { UserAdminPlan, UserProfileService } from '../user-profile/user-profile.service';

export interface IContact {
  id: string;
  idUser: string;
  contactCompany: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  sharingDate: string;
  ip: string;
  hidden: boolean;
}

@Component({
  selector: 'app-public-event',
  templateUrl: './public-event.component.html',
  styleUrls: ['./public-event.component.css'],
})
export class PublicEventComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  private _alert = new Subject<string>();
  alertMessage = '';
  alertType = '';
  eventId = '';
  loading = true;
  userAdminPlan!: UserAdminPlan;
  userProfileComplete: IUserProfileWithCSS | null = null;
  userProfile: DocumentUserProfile | null = null;
  userProfilePlan: IUserProfilePlan = {
    planName: 'default',
    profilePlanLimits: {
      contactsInclude: 0,
      socialLinksInclude: 0,
      linksInclude: 0,
      leadsViewContacts: 0,
      dashViews: false,
      dashAccessGraph: false,
      dashAccessedLinks: false,
      professionalView: false,
      eventsView: false,
      eventsIncludeQuantity: 0,
      connectContacts: false,
      includeEvents: false,
      connectContactsView: 0,
      participateInEvents: false,
      eventsParticipantsView: false
    }
  };
  preferencesList!: IDocumentPreferences[];
  userPreferences!: IDocumentPreferences[];
  occupationsList!: IDocumentPreferences[];
  userConnections: Connection[] = [];
  showCommunity = false
  communityItem: SluperEvent = {
    id: '',
    name: '',
    website: '',
    type: '',
    country: '',
    state: '',
    city: '',
    latitude: '',
    longitude: '',
    address: '',
    startDate: '',
    endDate: '',
    numberOfParticipants: 0,
    isPublic: false,
    description: '',
    imageLink: '',
    organizer: {
      idUser: '',
      name: '',
      uriImageProfile: ''
    },
    requiresConfirmation: false,
    guests: [],
    about: '',
    useCommunityLogo: false,
    communityLogo: null
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private userProfileService: UserProfileService,
    protected jwtHelper: JwtHelperService,
    private authService: AuthService,
    private eventsService: EventsService,
    private openEventService: OpenEventService,
    private openedCommunityService: OpenedCommunityService,
    private communityVerificationsService: CommunityVerificationsService,

  ) {
    return;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const eventId = params['eventId'];
      this.loading = true;

      this.communityVerificationsService.verifyEventAndNavigate(eventId)
        .finally(() => {
          this.loading = false;
        });
    });
  }


  async fetchAllData() {
    try {
      this.loading = true; // Ativa o loading no início
      await this.fetchEvent();
      await this.fetchData();
      await this.fetchUserConnections();

      if (this.communityItem.type === 'COMUNIDADE') {
        this.showCommunity = true
      } else {
        this.openModalShowEvent();
      }
    } catch (error) {
      console.error('Error in fetchAllData', error);
    } finally {
      this.loading = false; // Desativa o loading ao final, seja sucesso ou erro
    }
  }


  async fetchEvent() {
    try {
      const eventDetails = await firstValueFrom(
        this.eventsService.eventById(this.eventId)
      );

      if (!eventDetails.body?.event) {
        this._alert.next('Evento não encontrado.');
        this.alertType = 'danger';
        return;
      }
      this.communityItem = eventDetails.body.event
    } catch (error: any) {
      this._alert.next(
        error?.message ||
        'Ocorreu um erro ao tentar carregar os detalhes do evento. Tente novamente mais tarde.'
      );
      this.alertType = 'danger';
      setTimeout(() => {
        this.logout();
      }, 3000);
    }
  }

  async fetchData() {
    try {
      const userData = await firstValueFrom(
        this.userProfileService
          .userById(null)
          .pipe(
            filter((mayBeOk: HttpResponse<IUserProfileWithCSS>) => mayBeOk.ok),
            map((response: HttpResponse<IUserProfileWithCSS>) => response.body)
          )
      );
      this.userProfileComplete = userData;
      this.userProfile = userData?.documentUserProfile ?? null;

      const userPlan = await firstValueFrom(
        this.userProfileService
          .getUserPlan()
          .pipe(
            filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
            map((response: HttpResponse<any>) => response.body)
          )
      );
      this.userProfilePlan = userPlan;
    } catch (e) {
      this.alertType = 'danger';
      this._alert.next(
        'Não foi possível buscar as informaçōes do perfil. Contacte o administrador.'
      );

      setTimeout(() => {
        this.logout();
      }, 3000);
    }
  }

  async fetchUserConnections() {
    if (!this.userProfile) {
      return;
    }

    try {
      const response = await this.eventsService
        .getUserConnections(this.userProfileComplete!.documentUser.idUser)
        .toPromise();

      const userConnections = response && response.body
        ? response.body.filter((connection) => !connection.indication)
        : null;

      if (!userConnections) {
        return;
      }

      this.userConnections = userConnections;
    } catch (error) {
      console.error('Error fetching user connections', error);
      setTimeout(() => {
        this.logout();
      }, 3000);
    }
  }


  openModalShowEvent() {
    const dialogRef = this.dialog.open(EventDetailsComponent, {
      data: {
        authorizedPage: true,
        userProfile: this.userProfile,
        currentUserConnections: this.userConnections,
        preferencesList: this.preferencesList,
        occupationsList: this.occupationsList,
        userProfilePlan: this.userProfilePlan,
        eventId: this.eventId,
        documentUser: this.userProfileComplete?.documentUser,
        userProfileComplete: this.userProfileComplete
      },
      height: '80vh',
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(async result => {
      const communityId = this.openedCommunityService.getCommunity();
      if (communityId) {
        this.router.navigate(['/login', communityId]);
      } else {
        this.router.navigate(['/login']);
      }
    }, error => {
      console.error('Erro ao fechar o modal:', error);
    });
  }

  logout() {
    this.authService.logout();
  }

  alertsCommunity(event: { message: string; type: 'success' | 'danger'; }) {
    this.alertMessage = event.message;
    this.alertType = event.type;
    this._alert.next(event.message);
  }

}

<div class="modal-personalized">
    <div class="modal-header">
        <div class="title">Sobre a Comunidade {{this.communityItem.name}} </div>
        <div class="close" (click)="closeModal()">
            <i class="bi bi-x"></i>
        </div>
    </div>

    <div class="modal-body">
        <div class="modal-container">
            <div class="rules" [innerHTML]="sanitizedBio"></div>
        </div>
    </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SluperEvent } from 'src/app/model/event.model';
import { environment } from 'src/environments/environment';

export interface UserMetrics {
  idUser: string;
  role: string;
  acessedLinksCount: number;
  profileAccessCount: number;
  socialNetworkAcessedCount: number;
  generatedCardsCount: number;
  enabledLicensesCount: number;
  stripeLicensesCount: number;
  generatedLicenses: number;
  linkedLicenses: number;
  availableLicenses: number;
  unavailableLicenses: number;
  profileAccessByWeeks: number[];
  linksClickedByWeeks: number[];
  socialNetworkByWeeks: number[];
  profileAccessCountList: number[] | null;
  socialNetworkMetrics: any[];
  topAccessedLinks: any[];
  topAccessedSocialNetworks: any[];
  updateDate: string;
}

export interface UserEngagement {
  events: number;
  connections: number;
  indications: number;
  communities: number;
}

export interface DashboardUser {
  connections: number;
  indications: number;
  eventsParticipated: number;
  listEventsOrganized: SluperEvent[];
  communityParticipating: number;
  listCommunityOrganized: SluperEvent[];
}

@Injectable({
  providedIn: 'root',
})
export class MetricsServiceService {
  baseUrl = environment.eventsApiUrl;
  baseAdmUrl = environment.administradorUrl;
  baseProfileUrl = environment.profileUrl;

  constructor(protected http: HttpClient) {}

  getDashboardAdmin(): Observable<UserEngagement> {
    return this.http.get<UserEngagement>(`${this.baseUrl}/dashboadAdmin`);
  }

  getMetricsDashboardV2(): Observable<UserMetrics> {
    return this.http.get<UserMetrics>(`${this.baseAdmUrl}/dashboard/v2`);
  }

  getMetricsDashboardUser(): Observable<DashboardUser> {
    return this.http.get<DashboardUser>(`${this.baseUrl}/dashboadUser`);
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OpenedCommunityService } from './opened-community.service';

@Injectable({
  providedIn: 'root'
})
export class AppNavigateService {
  constructor(
    private router: Router,
    private openedCommunityService: OpenedCommunityService
  ) { }

  navegarLogin() {
    const communityId = this.openedCommunityService.getCommunity();
    if (communityId) {
      this.router.navigate(['/login', communityId]);
    } else {
      this.router.navigate(['/login']);
    }
  }

  navegarCadastro() {
    this.router.navigate(['/cadastro']);
  }

  navegarChaveAtivacao(license?: string) {
    if (license) {
      this.router.navigate([`/chave-ativacao/${license}`]);
    } else {
      this.router.navigate(['/chave-ativacao']);
    }
  }

  navegarEnvioEmailCriacaoConta() {
    this.router.navigate(['/confirmacao-criacao-conta']);
  }

  navegarConfiguracaoCadastro() {
    this.router.navigate(['/configuracao-cadastro']);
  }

  navegarUserProfile(company?: string) {
    const communityId = this.openedCommunityService.getCommunity();
    if (communityId) {
      this.router.navigate([`/comunidade/${communityId}`]);
    } else if (company) {
      this.router.navigate([`/comunidade/${company}`]);
    } else {
      this.router.navigate(['/comunidade']);
    }
  }

  navegarCadastroPerfilUsuario() {
    this.router.navigate(['/cadastro-perfil-usuario']);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventParticipant } from 'src/app/model/event.model';

export interface SelectableEventParticipant extends EventParticipant {
  selected: boolean;
}

@Component({
  selector: 'app-add-members-modal',
  templateUrl: './add-members-modal.component.html',
  styleUrls: ['./add-members-modal.component.css'],
})
export class AddMembersModalComponent implements OnInit {
  selectedMembers: SelectableEventParticipant[] = [];
  membersFiltered: SelectableEventParticipant[] = [];
  categoryParticipants: SelectableEventParticipant[] = [];
  isAdded!: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      allMembers: EventParticipant[],
      categoryParticipants: EventParticipant[],
    },
    private dialogRef: MatDialogRef<AddMembersModalComponent>,
  ) { }

  ngOnInit(): void {
    this.categoryParticipants = this.data.categoryParticipants.map(member => ({
      ...member,
      selected: true
    }));

    this.membersFiltered = this.data.allMembers
      .filter((member: EventParticipant) =>
        !this.categoryParticipants.some((participant: EventParticipant) => participant.idUser === member.idUser)
      )
      .map(member => ({
        ...member,
        selected: false
      }));
  }

  closeModal(success: boolean = false): void {
    if (success) {
      this.dialogRef.close(this.selectedMembers);
    } else {
      this.dialogRef.close();
    }
  }

  searchMembersByName(event: Event) {
    const input = event.target as HTMLInputElement;
    const filterValue = input.value.toLowerCase();
    if (filterValue.length === 0) {
      this.membersFiltered = this.data.allMembers.map(member => ({
        ...member,
        selected: this.selectedMembers.some((participant: EventParticipant) => participant.idUser === member.idUser)
      }));
    } else {
      this.membersFiltered = this.data.allMembers
        .filter((member: EventParticipant) => member.name.toLowerCase().includes(filterValue))
        .map(member => ({
          ...member,
          selected: this.selectedMembers.some((participant: EventParticipant) => participant.idUser === member.idUser)
        }));
    }
  }

  toggleMemberSelection(member: SelectableEventParticipant) {
    member.selected = !member.selected;
    if (member.selected) {
      this.selectedMembers.push(member);
    } else {
      this.selectedMembers = this.selectedMembers.filter(m => m.idUser !== member.idUser);
    }
  }

  handleSelectEverybody() {
    this.membersFiltered.forEach(member => (member.selected = true));
    this.selectedMembers = this.membersFiltered
  }
}

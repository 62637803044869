<app-loading-spinner [bgTransparent]="true" *ngIf="loading"></app-loading-spinner>
<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="body-div">
  <div class="content-div">
    <span class="title-text">Business</span>

    <form>
      <section class="top-table-section">
        <div class="search-container">
          <div class="input-group inputs search-input-container">
            <div class="input-group-prepend">
              <span class="input-group-text input-prepend" id="basic-addon1"><i class="bi bi-search"></i></span>
            </div>

            <input type="text" class="form-control input-text" id="filtro" name="filtro" (keyup)="applyFilter($event)"
              #input>
          </div>

          <div class="licence-generation-container">
            <button class="licence-generation-button" (click)="openVinculaLicencaModal()">
              <i class="bi bi-qr-code"></i>
              Vincular Licenças
            </button>
          </div>
        </div>

        <div class="licencas-div margin-top">
          <span class="licencas-text">Licenças disponíveis: {{ licencasDisponiveis }}/{{ totalLicencas
            }}</span>
          <span class="licencas-text">Licenças vinculadas: {{ linkedLicensesCounter }}</span>
        </div>
      </section>

      <section class="cards-section">
        <div class="card" *ngFor="let license of linkedLicenses">
          <div *ngIf="license.documentUserProfile !== null" class="card-div">
            <div class="card-img-top"
              [style.background-image]="checkBackgroundImage(license.documentUserProfile.uriImageBackground)">
              <div class="card-action" ngbDropdown>
                <button class="icon-button card-action-button" id="actionsDropdown" ngbDropdownToggle>
                  <i class="bi bi-gear-fill icon-large"></i>
                </button>

                <div ngbDropdownMenu aria-labelledby="actionsDropdown">
                  <button class="card-action-menu-button" ngbDropdownItem
                    (click)="unlinkLicense(license.documentLicense.license, license.documentUser.email)">
                    <i class="bi bi-x-circle-fill icon-medium"></i>

                    Desvincular Licença
                  </button>

                  <button class="card-action-menu-button" ngbDropdownItem
                    (click)="openSluperCoinModal(license.documentUser.idUser)">
                    <i class="bi bi-coin icon-medium"></i>

                    Sluper Coin
                  </button>
                </div>
              </div>

              <div class="card-profile-image-container">
                <img [src]="checkProfileImage(license.documentUserProfile.uriImageProfile)" alt="Profile Image"
                  class="card-profile-image">
              </div>
            </div>

            <div class="card-body">
              <h5 class="card-title">{{ license.documentUserProfile.name }}</h5>
              <h6 class="card-subtitle text-muted" [innerHTML]="license.documentLicense.license">{{
                license.documentUserProfile.bio }}</h6>
              <div class="social-networks-container">
                <button type="button" class="icon-button"
                  *ngIf="containsSocialNetwork('instagram', license.documentUserProfile.listSocialNetwork)" disabled><i
                    class="bi bi-instagram"></i></button>
                <button type="button" class="icon-button"
                  *ngIf="containsSocialNetwork('whatsapp', license.documentUserProfile.listSocialNetwork)" disabled><i
                    class="bi bi-whatsapp"></i></button>
                <button type="button" class="icon-button"
                  *ngIf="containsSocialNetwork('telegram', license.documentUserProfile.listSocialNetwork)" disabled><i
                    class="bi bi-telegram"></i></button>
                <button type="button" class="icon-button"
                  *ngIf="containsSocialNetwork('linkedin', license.documentUserProfile.listSocialNetwork)" disabled><i
                    class="bi bi-linkedin"></i></button>
                <button type="button" class="icon-button"
                  *ngIf="containsSocialNetwork('facebook', license.documentUserProfile.listSocialNetwork)" disabled><i
                    class="bi bi-facebook"></i></button>
                <button type="button" class="icon-button"
                  *ngIf="containsSocialNetwork('twitter', license.documentUserProfile.listSocialNetwork)" disabled><i
                    class="bi bi-twitter-x"></i></button>
                <button type="button" class="icon-button"
                  *ngIf="containsSocialNetwork('youtube', license.documentUserProfile.listSocialNetwork)" disabled><i
                    class="bi bi-youtube"></i></button>
                <button type="button" class="icon-button"
                  *ngIf="containsSocialNetwork('tik tok', license.documentUserProfile.listSocialNetwork)" disabled><i
                    class="bi bi-tiktok"></i></button>
                <button type="button" class="icon-button"
                  *ngIf="containsSocialNetwork('twitch', license.documentUserProfile.listSocialNetwork)" disabled><i
                    class="bi bi-twitch"></i></button>
                <button type="button" class="icon-button"
                  *ngIf="containsSocialNetwork('pinterest', license.documentUserProfile.listSocialNetwork)" disabled><i
                    class="bi bi-pinterest"></i></button>
              </div>
              <button type="button" class="icon-button logo-button"
                (click)="openSluperLink(license.documentUserProfile.listURI[0].link)">
                <img src="/assets/img/Blue_Pure.png" alt="Logo" class="logo-size">
              </button>
            </div>

            <div class="email-license-section">
              <span class="card-text">{{ license.documentUser.email.toLowerCase() }}</span>
              <button type="button" class="icon-button" placement="left" [ngbPopover]="license.documentLicense.license"
                popoverClass="my-custom-class">
                <i class="bi bi-qr-code icon-large"></i>
              </button>
            </div>
          </div>

          <div *ngIf="license.documentUserProfile === null" class="card-div">
            <div class="card-img-top" [style.background-image]="checkBackgroundImage(null)">
              <div class="card-profile-image-container">
                <img [src]="checkProfileImage(null)" alt="Profile Image" class="card-profile-image">

                <div class="card-action" ngbDropdown>
                  <button class="card-action-button" id="actionsDropdown" ngbDropdownToggle>
                    <i class="bi bi-gear-fill icon-large"></i>
                  </button>

                  <div ngbDropdownMenu aria-labelledby="actionsDropdown">
                    <button class="card-action-menu-button" ngbDropdownItem
                      (click)="unlinkLicense(license.documentLicense.license, license.documentUser.email)">
                      <i class="bi bi-x-circle-fill icon-medium"></i>

                      Desvincular Licença
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body incomplete-card">
              <h5 class="card-subtitle text-muted">Perfil em construção</h5>
            </div>

            <div class="email-license-section">
              <span class="card-text">{{ license.documentUser.email.toLowerCase() }}</span>
              <button type="button" class="icon-button" placement="left" [ngbPopover]="license.documentLicense.license"
                popoverClass="my-custom-class">
                <i class="bi bi-qr-code icon-large"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</div>
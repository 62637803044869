import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  BOLD_BUTTON,
  EditorConfig,
  FONT_SIZE_SELECT,
  FORE_COLOR,
  ITALIC_BUTTON,
  JUSTIFY_CENTER_BUTTON,
  JUSTIFY_FULL_BUTTON,
  JUSTIFY_LEFT_BUTTON,
  JUSTIFY_RIGHT_BUTTON,
  ORDERED_LIST_BUTTON,
  SEPARATOR,
  UNDO_BUTTON,
  UNORDERED_LIST_BUTTON,
} from 'ngx-simple-text-editor';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';

@Component({
  selector: 'app-warning-modal-post',
  templateUrl: './warning-modal-post.component.html',
  styleUrls: ['./warning-modal-post.component.css'],
})
export class WarningModalPostComponent implements OnInit {
  @Input() inputType: 'warning' | 'comment' = 'warning';
  config: EditorConfig = {
    placeholder:
      this.inputType === 'warning'
        ? 'Sobre o que você quer falar ?'
        : 'Digite uma resposta...',
    buttons: [
      UNDO_BUTTON,
      SEPARATOR,
      BOLD_BUTTON,
      ITALIC_BUTTON,
      FORE_COLOR,
      FONT_SIZE_SELECT,
      JUSTIFY_LEFT_BUTTON,
      JUSTIFY_CENTER_BUTTON,
      JUSTIFY_RIGHT_BUTTON,
      JUSTIFY_FULL_BUTTON,
      ORDERED_LIST_BUTTON,
      UNORDERED_LIST_BUTTON,
    ],
  };

  inputsData: {
    title: string;
    description: string;
    image?: string;
    imageFile?: File;
    movie?: string;
    file?: string;
    fileFile?: File;
  } = {
    title: '',
    description: '',
  };

  changeImageEvent: File | null = null;

  public title = new FormControl('');
  public description = new FormControl('', Validators.required);
  public showInputMovie = false;

  constructor(private dialogRef: MatDialogRef<WarningModalPostComponent>, private toastrService: ToastService) {}

  ngOnInit(): void {
    this.config.placeholder =
      this.inputType === 'warning'
        ? 'Sobre o que você quer falar ?'
        : 'Digite uma resposta...';
  }

  onEditorChange(value: string): void {
    this.description.setValue(value);
    this.inputsData.description = value;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = value;
  }

  cancelModal() {
    this.dialogRef.close();
  }

  handleImageChange(event: any) {
    const file = event.target.files[0];
    this.changeImageEvent = null;

    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      if (!validImageTypes.includes(file.type)) {
        this.toastrService.show('Por favor, selecione um arquivo de imagem válido', 'warning');
        return;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          this.deleteItem();
          this.showInputMovie = false;
          this.inputsData.imageFile = file;
          this.inputsData.image = e.target.result;
          this.changeImageEvent = event;
        };
      };

      reader.readAsDataURL(file);
    }
  }

  openPdfInput() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/pdf';
    input.onchange = (event: any) => {
      const file = event.target.files[0];
      if (file) {
        const maxSize = 1 * 1024 * 1024;
        if (file.size > maxSize) {
          this.toastrService.show('O tamanho máximo do arquivo é 1 MB.', 'warning');
          return;
        }
        this.deleteItem();
        this.showInputMovie = false;
        this.inputsData.fileFile = file;

        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.inputsData.file = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  }

  handleVideoInput() {
    this.showInputMovie = !this.showInputMovie;
    if(!this.showInputMovie) {
      this.inputsData.movie = '';
    } else {
      this.deleteItem();
    }
  }

  deleteItem() {
    this.inputsData.image = '';
    this.inputsData.imageFile = undefined;
    this.inputsData.movie = '';
    this.inputsData.file = '';
    this.inputsData.fileFile = undefined;
    this.changeImageEvent = null;
  }

  templateImageCropped(event: any): void {
    const blob = event.blob;

    if (blob && blob.size) {
      const croppedFile = new File([blob], this.inputsData.imageFile?.name || 'cropped_image.png', {
        type: blob.type,
      });

      const reader = new FileReader();
      reader.onload = (e) => {
        if (!e.target || !e.target.result) return;
        if (typeof e.target.result === 'string') {
          this.showInputMovie = false;
          this.inputsData.imageFile = croppedFile;
          this.inputsData.image = e.target.result;
        }
      };
      reader.readAsDataURL(croppedFile);
    }
  }

  // handleKeyDown(event: KeyboardEvent): void {

  // }
}

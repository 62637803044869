import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SluperEvent } from 'src/app/model/event.model';

@Component({
  selector: 'app-modal-show-bio',
  templateUrl: './modal-show-bio.component.html',
  styleUrls: ['./modal-show-bio.component.css']
})
export class ModalShowBioComponent implements OnInit {
  @Input() communityItem!: SluperEvent
  public sanitizedBio: SafeHtml = '';

  constructor(
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.sanitizedBio = this.sanitizer.bypassSecurityTrustHtml(this.communityItem?.description || '');
  }

  closeModal() {
    this.activeModal.close();
  }
}

<div class="container-fluid" id="company-advertisement">
    <div class="page-title">
        <div class="d-flex align-items-center">
            <i class="bi bi-chevron-left"></i>
            <h4 id="mention-title">Vitrine / adicionar empresa</h4>
        </div>

        <div class="buttons-container">
            <button class="outline-button" (click)="handleCloseCompanyAdvertisement()">Cancelar</button>
            <button class="highlight-button" (click)="submitForm()">Salvar</button>
        </div>
    </div>

    <form [formGroup]="advertisementForm" class="form">
        <!-- Banner -->
        <div class="input-item input-banner">
            <h4 class="company-advertisement-title">Adicione o banner para o anuncio</h4>
            <div class="card-banner" *ngIf="!imageItems.image" (click)="openImageSelectionModal()">
                <i class="bi bi-image"></i>
                <span>Adicionar banner</span>
            </div>

            <ng-container *ngIf="imageItems.image">
                <img [src]="imageItems.image" alt="Foto do banner" class="image-banner"
                    (click)="openImageSelectionModal()">
            </ng-container>

            <div *ngIf="advertisementForm.get('banner')?.touched && advertisementForm.get('banner')?.invalid"
                class="error-message">
                Banner é obrigatório.
            </div>
        </div>

        <!-- Toggle -->
        <div class="input-item input-toggle">
            <h4 class="company-advertisement-title">Anúncio destaque?</h4>
            <ng-toggle [labels]="toggleConfig.labels" [switchColor]="toggleConfig.switchColor"
                [color]="toggleConfig.color" [(ngModel)]="toggleConfig.value" [disabled]="toggleConfig.disabled"
                [fontColor]="toggleConfig.fontColor" [textAlign]="toggleConfig.textAlign"
                [fontSize]="toggleConfig.fontSize" [width]="toggleConfig.width" [height]="toggleConfig.height"
                [margin]="toggleConfig.margin" formControlName="isFeatured">
            </ng-toggle>
        </div>

        <div class="row">
            <!-- Coluna Esquerda -->
            <div class="col-lg-6">
                <!-- Nome da Empresa -->
                <div class="input-item input-name">
                    <h4 class="company-advertisement-title">Nome da empresa</h4>
                    <input type="text" placeholder="Ex: Sluper" class="form-control" formControlName="companyName">
                    <div *ngIf="advertisementForm.get('companyName')?.touched && advertisementForm.get('companyName')?.invalid"
                        class="error-message">
                        Nome da empresa é obrigatório.
                    </div>
                </div>

                <!-- Descrição -->
                <div class="input-item input-description">
                    <h4 class="company-advertisement-title">Descrição</h4>
                    <textarea rows="10" class="form-control" formControlName="description"></textarea>
                    <div *ngIf="advertisementForm.get('description')?.touched && advertisementForm.get('description')?.invalid"
                        class="error-message">
                        Descrição deve ter no mínimo 10 caracteres.
                    </div>
                </div>

                <!-- Contato -->
                <div class="input-item input-contact">
                    <h4 class="company-advertisement-title">Contato</h4>
                    <input type="text" placeholder="Email ou telefone" class="form-control" formControlName="contact">
                    <div *ngIf="advertisementForm.get('contact')?.touched && advertisementForm.get('contact')?.invalid"
                        class="error-message">
                        Informe um email ou número de telefone válido.
                    </div>
                </div>
            </div>

            <!-- Coluna Direita -->
            <div class="col-lg-6">
                <!-- Período de Exibição -->
                <div class="input-item input-time">
                    <h4 class="company-advertisement-title">Período de exibição</h4>
                    <div class="instructions-container date">
                        <div class="input-instruction" style="flex: 1;">
                            <input [min]="minDate" type="date" formControlName="startDate" class="form-control">
                            <span>Início</span>
                            <div *ngIf="advertisementForm.get('startDate')?.touched && advertisementForm.get('startDate')?.hasError('invalidStartDate')"
                                class="error-message">
                                A data inicial deve ser igual ou posterior à data atual.
                            </div>
                            <div *ngIf="advertisementForm.get('startDate')?.touched && advertisementForm.get('startDate')?.hasError('invalidStart')"
                                class="error-message">
                                A data inicial deve ser válida.
                            </div>
                        </div>
                        <span>à</span>
                        <div class="input-instruction" style="flex: 1;">
                            <input [min]="minDate" type="date" formControlName="endDate" class="form-control">
                            <span>Caso haja apenas uma data, deixe este campo vazio</span>
                            <div *ngIf="advertisementForm.get('endDate')?.touched && advertisementForm.get('endDate')?.hasError('invalidEnd')"
                                class="error-message">
                                A data final não pode ser anterior à data inicial.
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Horário -->
                <div class="input-item input-time">
                    <h4 class="company-advertisement-title">Horário</h4>
                    <div class="instructions-container">
                        <div class="input-instruction" style="flex: 1;">
                            <input type="time" formControlName="startTime" class="form-control">
                            <span>Início</span>
                            <div *ngIf="advertisementForm.get('startTime')?.touched && advertisementForm.get('startTime')?.hasError('invalidStart')"
                                class="error-message">
                                O horário inicial deve ser válido e no futuro.
                            </div>
                        </div>
                        <span>à</span>
                        <div class="input-instruction" style="flex: 1;">
                            <input type="time" formControlName="endTime" class="form-control"
                                (change)="changeEndTime($event)">
                            <span>Fim</span>
                            <div *ngIf="advertisementForm.get('endTime')?.touched && advertisementForm.get('endTime')?.hasError('invalidEnd')"
                                class="error-message">
                                O horário final não pode ser anterior ao inicial.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<ng-template #imageSelectionModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" translate>meuSluper.modalImagem.titulo</h4>
        <button type="button" class="close" aria-label="Fechar" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <label class="image-upload-label" for="image-upload" *ngIf="!imageConfig.cropMode">
            <input id="image-upload" class="image-upload" accept="image/jpeg, image/png, image/jpg" type="file"
                (change)="fileChangeEventImage($event, 1, 1)" />
            <i class="bi bi-card-image"></i>
            <span>Clique aqui para selecionar e enviar a sua foto</span>
            <span *ngIf="!imageConfig.cropMode" class="image-scale-orientations">
                <strong>A imagem deve ter no máximo 1080 x 1080</strong>
            </span>
        </label>

        <ng-container *ngIf="imageConfig.cropMode">
            <image-cropper [imageChangedEvent]="imageItems.temporaryEvent" [maintainAspectRatio]="true"
                [aspectRatio]="16 / 9" format="png" [cropperMaxWidth]="1080" [cropperMaxHeight]="1080"
                (imageCropped)="imageCropped($event)"></image-cropper>
        </ng-container>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" *ngIf="imageConfig.cropMode" (click)="removeSelectedImage()"
            translate>
            meuSluper.modalImagem.remove
        </button>
        <button type="button" class="btn btn-primary" (click)="confirmImageSelection()" translate>
            meuSluper.modalImagem.confirmar
        </button>
    </div>
</ng-template>
<section id="event-header-component">
  <div id="event-header-card">
    <div id="card-info-and-button-options">
      <div id="event-card-infos">
        <div id="event-card-user-infos">
          <h4>Olá, {{ userProfile?.name }}</h4>
          <span id="event-card-user-infos-description" *ngIf="!verifyUserCanCreate()">Aqui estão alguns indicadores
            sobre o seu perfil Sluper</span>
          <!-- <span id="event-card-user-infos-premium" *ngIf="verifyUserCanCreate()">Usuário Premium</span> -->

          <img *ngIf="verifyUserCanCreate()" src="../../../assets/img/icone-premium-sluper.png"
            alt="icone-premium-sluper">
        </div>
        <div id="event-card-events-infos">
          <div class="event-card-info-details">
            <div class="event-calendar-icon-container">
              <img class="event-calendar-icon" src="assets\img\new-header-calendar-icon.svg" alt="Ícone calendário" />
            </div>
            <div class="event-details">
              <h6 class="event-details-title">Seu próximo Evento</h6>

              <div class="d-flex gap">
                <span class="event-general-info">
                  {{ nextEvents[0] ? getFormattedDate(nextEvents[0].startDate) : 'Não há próximo evento' }}
                </span>
                <span *ngIf="nextEvents[0]" class="event-apart-info">{{ nextEvents[0].startDate | date: 'HH:mm'
                  }}</span>
              </div>
            </div>
          </div>

          <div class="event-card-info-details">
            <div class="event-calendar-icon-container">
              <img class="event-calendar-icon" src="assets\img\new-header-people-icon.svg" alt="Ícone calendário" />
            </div>
            <div class="event-details">
              <h6 class="event-details-title">Conexões feitas</h6>

              <div class="d-flex gap">
                <span class="event-general-info">
                  {{
                  userConnections.length
                  ? userConnections.length + ' ' + (userConnections.length === 1 ? 'usuário' : 'usuários')
                  : '0 usuário'
                  }}
                </span>

                <span *ngIf="userConnections.length" class="event-apart-info-link"
                  (click)="handleOpenCommunityMembersModal()">Ver</span>
              </div>
            </div>
          </div>

          <div class="event-card-info-details">
            <div class="event-calendar-icon-container">
              <img class="event-calendar-icon" src="assets\img\new-header-people-icon.svg" alt="Ícone calendário" />
            </div>
            <div class="event-details">
              <h6 class="event-details-title">Comunidades em que participa</h6>

              <div class="d-flex gap">
                <span class="event-general-info">
                  {{
                  nextCommunitys.length
                  ? nextCommunitys.length + ' ' + (nextCommunitys.length === 1 ? 'comunidade' : 'comunidades')
                  : '0 comunidade'
                  }}
                </span>

                <span (click)="openModalSelectCommunity()" *ngIf="nextCommunitys&& nextCommunitys.length > 1"
                  class="event-apart-info-link">Ver</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="complete-profile">
          <div class="info">
            <i class="bi bi-person-circle"></i>
            <span class="title">Complete o seu Perfil Profissional</span>
            <span class="arrow">></span>
            <span class="description">Crie uma Bio da sua empresa</span>
          </div>
          <div class="action">
            <a href="#" class="link">Complete as configurações</a>
            <span id="arrow-2" class="arrow">></span>
          </div>
        </div> -->
      </div>
      <div id="event-card-become-premium" *ngIf="!verifyUserCanCreate()">
        <h4 id="card-option-title">Você está perdendo conexões importantes!</h4>
        <span id="card-option-description">Crie comunidades, eventos e se conecte com quem realmente importa.</span>
        <button id="card-option-button" (click)="redirectToSluper()">Torne-se Sluper Premium</button>
      </div>
      <div id="event-card-options" *ngIf="verifyUserCanCreate()">
        <!-- <button id="event-button-create-event" *ngIf="allowedToCreateEvent" (click)="createItem('event')">Criar Evento</button> -->
        <button id="event-button-create-community" *ngIf="allowedToCreateCommunity" (click)="createItem('community')">
          Criar Comunidade
        </button>
      </div>
    </div>
    <div id="event-complete-profile" *ngIf="showCompleteDocument" (click)="handleRedirectToSettings('opcoes')">
      <div id="event-complete-profile-left">
        <img id="event-complete-person-icon" src="assets\img\person-outline.svg" alt="Ícone pessoa">
        <span id="complete-profile-title">
          {{ message1 }}
        </span>
        <span id="complete-profile-description">
          <i class="bi bi-chevron-right"></i>
          <!-- {{ message2 }} -->
        </span>
      </div>
      <!-- <div id="event-complete-profile-right">
        <span id="complete-profile-settings" (click)="handleRedirectToSettings('opcoes')">
          Complete as configurações
          <i class="bi bi-chevron-right"></i>
        </span>
      </div> -->
    </div>
  </div>
</section>
import { Component } from '@angular/core';

@Component({
  selector: 'app-channels-categories-settings',
  templateUrl: './channels-categories-settings.component.html',
  styleUrls: ['./channels-categories-settings.component.css']
})
export class ChannelsCategoriesSettingsComponent {

}

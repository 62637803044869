<app-loading-spinner *ngIf="loading"></app-loading-spinner>



<div id="community-manager-data-screen">

    <div class="stats">
        <div class="stat-item stat-item-highlight">
            <div class="stat-item-left">
                <h3>999</h3>
                <span>pontos</span>
            </div>
            <div class="stat-item-right">
                <h3>PONTOS</h3>
                <h4>Faça mais conexões para ganhe pontos
                    e troque por benefícios!
                </h4>
                <span>Saba mais</span>
            </div>
        </div>
        <div class="stat-item">
            <div class="stat-item-left">
                <img src="assets/img/store-card-people-icon.png" alt="Ícone de pessoas">
            </div>
            <div class="stat-item-right">
                <span>Usuários Ativos</span>
                <h4>999</h4>
                <span class="stat-item-link" (click)="redirectToPage('sentContacts')">Ver</span>
            </div>
        </div>
        <div class="stat-item">
            <div class="stat-item-left">
                <img src="assets/img/store-card-people-icon.png" alt="Ícone de pessoas">
            </div>
            <div class="stat-item-right">
                <span>Eventos Criados</span>
                <h4>{{dashboardUser.listEventsOrganized ?dashboardUser.listEventsOrganized.length : '0'}}</h4>
                <span class="stat-item-link"
                    *ngIf=" dashboardUser.listEventsOrganized && dashboardUser.listEventsOrganized.length > 0"
                    (click)="redirectToPage('events')">Ver</span>
            </div>
        </div>
        <div class="stat-item-without-image">
            <div class="stat-item-top">
                <img src="../../../../../assets//img/manager-data-people-icon.png" alt="">
                <span>Conexões feitas</span>
            </div>
            <div class="stat-item-center">
                <h3>{{dashboardUser.connections ? dashboardUser.connections : '0'}}</h3>
                <div class="circle-highlight">
                    <i class="bi bi-arrow-up-short"></i>
                    <span>999%</span>
                </div>
            </div>
            <div class="stat-item-bottom">
                <span>+999</span>
                <span> do que no último mês</span>
            </div>
        </div>
        <div class="stat-item-without-image">
            <div class="stat-item-top">
                <img src="../../../../../assets//img/manager-data-people-icon.png" alt="">
                <span>N° Acessos QR Code</span>
            </div>
            <div class="stat-item-center">
                <h3>999</h3>
                <div class="circle-highlight">
                    <i class="bi bi-arrow-down-short"></i>
                    <span>999%</span>
                </div>
            </div>
            <div class="stat-item-bottom">
                <span>-999</span>
                <span> do que no último mês</span>
            </div>
        </div>
        <div class="stat-item-without-image">
            <div class="stat-item-top">
                <img src="../../../../../assets//img/manager-data-people-icon.png" alt="">
                <span>N° Acessos Link Direto</span>
            </div>
            <div class="stat-item-center">
                <h3>{{userMetrics.profileAccessCount}}</h3>
                <div class="circle-highlight">
                    <i class="bi bi-arrow-up-short"></i>
                    <span>999%</span>
                </div>
            </div>
            <div class="stat-item-bottom">
                <span>+999</span>
                <span> do que no último mês</span>
            </div>
        </div>
        <div class="stat-item-without-image">
            <div class="stat-item-top">
                <img src="../../../../../assets//img/manager-data-people-icon.png" alt="">
                <span>Eventos participados</span>
            </div>
            <div class="stat-item-center">
                <h3>{{dashboardUser.eventsParticipated ? dashboardUser.eventsParticipated : '0'}}</h3>
                <div class="circle-highlight">
                    <i class="bi bi-arrow-up-short"></i>
                    <span>999%</span>
                </div>
            </div>
            <div class="stat-item-bottom">
                <span>+999</span>
                <span> do que no último mês</span>
            </div>
        </div>
    </div>

    <div class="grid-container">
        <!-- Gráfico de Troca de Mensagens -->
        <div class="chart-item">
            <h3>Troca de mensagens</h3>
            <div class="divider"></div>
            <apx-chart [series]="messageExchangeSeries" [chart]="messageExchangeChart" [xaxis]="messageExchangeXAxis"
                [colors]="messageExchangeColors"></apx-chart>
        </div>

        <!-- Gráfico de Engajamento em Comunidades -->
        <div class="chart-item">
            <h3>Engajamentos em Comunidades</h3>
            <div class="divider"></div>
            <apx-chart [series]="communityEngagementSeries" [chart]="communityEngagementChart"
                [labels]="communityEngagementLabels" [colors]="communityEngagementColors"></apx-chart>


            <div class="chart-infos">
                <div class="chart-info">
                    <div class="info-left">
                        <i class="bi bi-hand-thumbs-up"></i>
                        <span>Curtidas</span>
                    </div>
                    <div class="info-right">
                        <h5>180</h5>
                        <div class="circle-highlight">
                            <i class="bi bi-arrow-up-short"></i>
                            <span>20%</span>
                        </div>
                    </div>
                </div>
                <div class="chart-info">
                    <div class="info-left">
                        <i class="bi bi-chat"></i>
                        <span>Comentários</span>
                    </div>
                    <div class="info-right">
                        <h5>17</h5>
                        <div class="circle-highlight">
                            <i class="bi bi-arrow-up-short"></i>
                            <span>10%</span>
                        </div>
                    </div>
                </div>
                <div class="chart-info">
                    <div class="info-left">
                        <i class="bi bi-file-text"></i>
                        <span>Postagens</span>
                    </div>
                    <div class="info-right">
                        <h5>3</h5>
                        <div class="circle-highlight">
                            <i class="bi bi-arrow-down-short"></i>
                            <span>4%</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="grid-container full">
        <div class="chart-item">
            <h3>Feedback dos Usuários</h3>
            <div class="divider"></div>
            <div class="chart-row">
                <apx-chart [series]="userFeedbackSeries" [chart]="userFeedbackChart" [legend]="userFeedbackLegend"
                    [labels]="userFeedbackLabels" [colors]="userFeedbackColors"></apx-chart>
                <div class="feedback-card-center">
                    <div class="feedback-left">
                        <h3>66%</h3>
                        <span>Usuários de sua rede que responderam o questionário</span>
                    </div>
                    <div class="feedback-right">
                        <div class="circle-highlight">
                            <i class="bi bi-arrow-up-short"></i>
                            <span>10%</span>
                        </div>
                        <span style="color:#24A15C;">+85 <span style="color: #7B7B7B;">usuários comparado ao mês
                                anterior</span></span>
                    </div>
                </div>
                <div class="feedback-summary">
                    <div class="feedback-summary-item">
                        <div class="summary-left">
                            <div style="background-color: #1ACF6C;"></div>
                            <span>Gostaram</span>
                        </div>
                        <div class="summary-right">
                            <img src="../../../../../assets//img/manager-data-people-icon.png" alt="">
                            <h5>200</h5>
                            <span>66%</span>
                        </div>
                    </div>
                    <div class="feedback-summary-item">
                        <div class="summary-left">
                            <div style="background-color: #F4D21A;"></div>
                            <span>Neutro</span>
                        </div>
                        <div class="summary-right">
                            <img src="../../../../../assets//img/manager-data-people-icon.png" alt="">
                            <h5>200</h5>
                            <span>66%</span>
                        </div>
                    </div>
                    <div class="feedback-summary-item">
                        <div class="summary-left">
                            <div style="background-color: #EF4848;"></div>
                            <span>Não gostaram</span>
                        </div>
                        <div class="summary-right">
                            <img src="../../../../../assets//img/manager-data-people-icon.png" alt="">
                            <h5>200</h5>
                            <span>66%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AlterarCorFundoModalComponent } from './alterar-cor-fundo-modal/alterar-cor-fundo-modal.component';
import { CadastroConcluidoModalComponent } from './cadastro-concluido-modal/cadastro-concluido-modal.component';
import { ChaveAtivacaoInputComponent } from './chave-ativacao-input/chave-ativacao-input.component';
import { CodigoVerificacaoComponent } from './codigo-verificacao/codigo-verificacao.component';
import { HeaderInicialComponent } from './header-inicial/header-inicial.component';
import { HeaderPrincipalComponent } from './header-principal/header-principal.component';
import { ProfileCoverComponent } from './profile-cover/profile-cover.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { SluperBackgroundComponent } from './sluper-background/sluper-background.component';



@NgModule({
  declarations: [SluperBackgroundComponent, HeaderInicialComponent, CodigoVerificacaoComponent,
    ChaveAtivacaoInputComponent, CadastroConcluidoModalComponent, HeaderPrincipalComponent, ProfilePictureComponent, AlterarCorFundoModalComponent, ProfileCoverComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule
  ],
  exports: [
    SluperBackgroundComponent,
    HeaderInicialComponent,
    CodigoVerificacaoComponent,
    ChaveAtivacaoInputComponent,
    CadastroConcluidoModalComponent,
    HeaderPrincipalComponent,
    ProfilePictureComponent,
    ProfileCoverComponent,
    AlterarCorFundoModalComponent
  ]
})
export class ComponentsModule { }

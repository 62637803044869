import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, firstValueFrom, map } from 'rxjs';
import { AppNavigateService } from 'src/app/app-navigate.service';
import { ChaveAtivacaoInputComponent } from 'src/app/new-sluper/core/components/chave-ativacao-input/chave-ativacao-input.component';
import { LoadingService } from 'src/app/new-sluper/core/service/loading.service';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { LoginService } from '../../login/login.service';
import { CadastroStorageService } from '../cadastro-storage.service';

@Component({
  selector: 'app-chave-ativacao',
  templateUrl: './chave-ativacao.component.html',
  styleUrls: ['./chave-ativacao.component.css']
})
export class ChaveAtivacaoComponent implements OnInit {
  @ViewChild(ChaveAtivacaoInputComponent) codigoVerificacao!: ChaveAtivacaoInputComponent;
  license: string | null = null;
  constructor(
    private appNavigateService: AppNavigateService,
    private loginService: LoginService,
    public cadastroStorageService: CadastroStorageService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const parametro = params['parametro'];
      this.license = parametro;
      this.cadastroStorageService.license = parametro;
      if (parametro) {
        // Chamada para validar o código do parâmetro
        this.loginService.validarLicensa(parametro).subscribe((response) => {
          if (response) {
            this.verifyEmailAndNameStorage()
          } else {
            this.appNavigateService.navegarLogin();
          }
        });
      }
    });
  }


  verifyEmailAndNameStorage() {
    const email = localStorage.getItem('email');
    const username = localStorage.getItem('username');
    if (!email || !username) {
      this.router.navigate(['/cadastro']);
      return
    }
  }

  async ativar() {
    try {
      const isValid = await this.validateVerificationKey();
      if (!isValid) {
        this.toastService.showError("Código de verificação inválido.");
        return;
      }
      await this.finishFirstAccess();
      this.appNavigateService.navegarConfiguracaoCadastro();
    } catch (err) {
      console.error("Erro na execução de ativar:", err);
      this.toastService.showError("Erro inesperado durante a ativação.");
    }
  }


  private async validateVerificationKey(): Promise<boolean> {
    try {
      this.loadingService.show();
      const codigoVerf = String(this.codigoVerificacao.getCodigoVerificacao()).toUpperCase();
      this.cadastroStorageService.codigoVerificacao = codigoVerf;
      const validateResponse = await firstValueFrom(this.loginService.validateVerificationKey(codigoVerf));
      if (!validateResponse) {
        console.warn("Código de verificação inválido.");
        return false;
      }

      return true;
    } catch (err) {
      console.error("Erro ao validar o código de verificação:", err);
      return false;
    } finally {
      this.loadingService.hide();
    }
  }

  private async finishFirstAccess() {
    const email = this.cadastroStorageService.email;
    const codigoVerificacao = this.cadastroStorageService.codigoVerificacao;
    const license = this.cadastroStorageService.license;

    try {
      if (!this.license || !codigoVerificacao || !email) {
        throw new Error("Campos obrigatórios não preenchidos: license, código de verificação ou email.");
      }

      const response = await firstValueFrom(this.loginService.criaPrimeiroAcesso(license, codigoVerificacao, email));
      if (response && response.status === 200) {
      } else {
        console.error('Erro ao finalizar primeiro acesso: Resposta inválida');
        throw new Error("Erro inesperado durante a finalização do primeiro acesso.");
      }
    } catch (err: any) {
      console.error("Erro ao finalizar primeiro acesso:", err.error);
      if (err.error === "Email já está em uso") {
        this.toastService.showError('O email inserido ja esta em uso');
      }
      throw err.error;
    }
  }

  reenviarChave() {
    const email = this.cadastroStorageService.email;

    if (email) {
      this.loadingService.show();

      this.loginService.generateLicense(email)
        .pipe(
          filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
          map((response: HttpResponse<any>) => response.body)
        )
        .subscribe({
          next: () => {
            this.loadingService.hide();
            this.toastService.showInfo("Foi enviado um link e um código de verificação para o seu email.")
            this.appNavigateService.navegarChaveAtivacao();
          },
          error: (err) => {
            this.loadingService.hide();
            this.toastService.showError("Erro ao gerar o código. Tente novamente.")
          }
        });
    } else {
      this.toastService.showWarning("Por favor, preencha todos os campos corretamente")
    }
  }

  pedirAjuda() {

  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Post } from 'src/app/communities/post/posts.service';
import { environment } from 'src/environments/environment';

export interface FavoritesResponse {
  currentPage: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
  items: Post[];
}
@Injectable({
  providedIn: 'root'
})
export class MentionServiceService {
  baseUrl = environment.eventsApiUrl;
  baseAdmUrl = environment.administradorUrl;
  baseProfileUrl = environment.profileUrl;
  baseUrlCSharp = environment.eventsApiUrlCSharp;

  constructor(
    protected http: HttpClient,
  ) { }


  getMentionsPosts(data: {
    userId: string;
    communityId?: string;
    startDate?: string;
    endDate?: string;
    pageNumber: number;
    pageSize: number;
  }): Observable<FavoritesResponse> {
    const params = new URLSearchParams();

    params.append('UserId', data.userId);
    if (data.communityId) params.append('CommunityId', data.communityId);
    if (data.startDate) params.append('StartDate', data.startDate);
    if (data.endDate) params.append('EndDate', data.endDate);
    params.append('pageNumber', data.pageNumber.toString());
    params.append('pageSize', data.pageSize.toString());

    const url = `${this.baseUrlCSharp}/EventPost?${params.toString()}`;

    return this.http.get<FavoritesResponse>(url);
  }

}

<app-loading-spinner [bgTransparent]="true" *ngIf="loading"></app-loading-spinner>

<div class="user-post-modal-body">
  <div class="user-post-container">
    <div class="user-post">
      <img *ngIf="userProfile?.uriImageProfile && userProfile!.uriImageProfile.length > 0"
        [src]="userProfile?.uriImageProfile" alt="profile-pic" />
      <img *ngIf="!userProfile?.uriImageProfile" src="/assets/img/default_avatar_1.png" alt="profile-pic" />

      <h3 class="username">{{ userProfile?.name }}</h3>
    </div>

    <!-- <input *ngIf="inputType === 'post'" [formControl]="title" type="text" placeholder="Digite um título para o post..."
      class="post-title" /> -->
    <!-- <span class="error-message" *ngIf="title.invalid && title.touched && inputType === 'post'"> O titulo é obrigatório.
    </span>-->

    <div class="editor-container">
      <st-editor style="width: 100%" [(ngModel)]="inputsData.description" (ngModelChange)="onEditorChange($event)"
        [config]="config" (keydown)="handleKeyDown($event)"></st-editor>
      <span class="error-message"
        *ngIf="(description.value?.length === 0 || description.invalid) && description.touched">
        A descrição é obrigatória.
      </span>


      <ul *ngIf="showMentionDropdown" class="mention-dropdown">
        <li *ngFor="let participant of filteredParticipants; let i = index" #mentionItem
          [class.active]="i === activeMentionIndex" (click)="selectParticipant(participant)">
          <img [src]="participant.uriImageProfile && participant.uriImageProfile.length > 0
          ? participant.uriImageProfile : '/assets/img/default_avatar_1.png'" alt="Lista de participantes">
          <span> {{ participant.name }}</span>
        </li>
      </ul>


    </div>

    <div class="user-post-attachment" *ngIf="inputType === 'post'">
      <div class="user-attachment">
        <i class="bi bi-images" (click)="imageInput.click()"></i>
        <i class="bi bi-camera-video" (click)="handleVideoInput()"></i>
        <i class="bi bi-paperclip" (click)="openPdfInput()"></i>
        <input #imageInput type="file" accept="image/*" style="display: none" (change)="handleImageChange($event)" />
      </div>

    </div>

    <div class="input-data" *ngIf="inputType === 'post'">
      <div class="input-data-item" *ngIf="inputsData.image">
        <image-cropper [imageChangedEvent]="changeImageEvent" [maintainAspectRatio]="true" [aspectRatio]="16 / 9"
          format="png" (imageCropped)="templateImageCropped($event)" [cropperMaxWidth]="720"></image-cropper>
        <i (click)="deleteItem()" class="bi bi-x-lg positioned-absolute"></i>
      </div>
      <div class="input-data-item" *ngIf="inputsData.file">
        <i id="imageFileInput" class="bi bi-file-earmark-arrow-down"></i>
        <label class="multi-line" for="imageFileInput">{{ inputsData.fileFile?.name }}</label>
        <i (click)="deleteItem()" class="bi bi-trash-fill"></i>
      </div>
      <div class="input-data-item" *ngIf="showInputMovie">
        <input placeholder="Insira o link do video" type="url" id="imageMovieInput" [(ngModel)]="inputsData.movie" />
        <i (click)="deleteItem(); showInputMovie = false" class="bi bi-trash-fill"></i>
      </div>
    </div>

    <div class="modal-community-info-buttons">
      <button type="button" class="cancel-button" (click)="closeModal()">Cancelar</button>
      <button type="button" class="save-button"
        (click)="inputType === 'post' ? saveCommunityPost() : createCommentPost()">Publicar</button>
    </div>
  </div>
</div>
import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEventWithParticipationStatus } from 'src/app/model/event.model';
import { EventsService } from '../events.service';

@Component({
  selector: 'app-community-card',
  templateUrl: './community-card.component.html',
  styleUrls: ['./community-card.component.css']
})
export class CommunityCardComponent {
  @Input() event: IEventWithParticipationStatus | undefined;
  @Output() join = new EventEmitter<IEventWithParticipationStatus>();

  getFormattedDate(date: Date | string | undefined): string {
    if (!date) return '';

    const dateObj = new Date(date);
    const day = formatDate(dateObj, 'dd', 'pt-BR');
    const month = formatDate(dateObj, 'MMMM', 'pt-BR').toUpperCase();
    const year = formatDate(dateObj, 'yyyy', 'pt-BR');

    return `${day} DE ${month}, ${year}`;
  }

  constructor(private eventsService: EventsService) {

  }
  joinEvent() {

    if (!this.event) return;
    this.join.emit(this.event);
  }

  async openParticipantProfile(event: Event, id: string) {
    event.stopPropagation();
    window.open(`https://sluper.bio/${id}`);
  }
}

import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { BusinessService } from 'src/app/business/business.service';
import { LISTA_ESTILOS } from 'src/app/model/estilos';
import { LISTA_FONTES } from 'src/app/model/fontes';
import { Estilo } from 'src/app/model/styles.model';

export interface IUserCss {
  corPrimaria?: string;
  corSecundaria?: string;
  direcaoDegrade?: string;
  formato?: string;
  espessura?: string;
  sombra?: string;
  preenchimento?: string;
  contorno?: string;
  curvaBorda?: string;
  fonte?: string;
  negrito?: boolean;
  italico?: boolean;
  corFonte?: string;
  fontePerfil?: string;
  negritoPerfil?: boolean;
  italicoPerfil?: boolean;
  corFontePerfil?: string;
  cardBackgroundImageUrl?: string;
  callingCardImageUrl?: string;
  loginImageUrl?: string;
  headerImageUrl?: string;
  backgroundLoginImageUrl?: string;
}

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.css'],
})
export class CreateTemplateComponent implements OnInit {
  fonts = LISTA_FONTES;
  defaultBackgroundStyles = LISTA_ESTILOS;

  @Output() templateSaved = new EventEmitter<boolean>();
  @Output() backToManageView = new EventEmitter<boolean>();
  @Output() templateCreationError = new EventEmitter<string>();

  templateName = '';
  domain = '';
  defaultTemplate = false;
  templateCss: IUserCss = {};
  changeImageEvent: any = null;
  croppedBgImage: any = null;
  cardBackgroundImage?: File;
  loginImage?: File;
  headerImage?: File;
  backgroundLoginImage?: File;
  callingCardBackgroundImage?: File;
  cardImage?: File;
  templateBgImgSelctionModalOpen = false;
  templateBgType: 'color' | 'image' = 'color';
  isColorBgSelected = true;
  isImageBgSelected = false;
  imageTypeToBeUploaded = '';
  openedSection = 'templateInfo';

  toggleSection(section: string) {
    this.openedSection = section;
  }

  @ViewChild('templateBgImgSelctionModal') templateBgImgSelctionModal: any;

  ngOnInit(): void {
  }

  constructor(
    private businessService: BusinessService,
    private modalService: NgbModal,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {
    this.templateCss = {
      corPrimaria: "#9d96f8",
      corSecundaria: "#fb9965",
      direcaoDegrade: "285",
      sombra: "",
      espessura: "0px",
      preenchimento: "#ffffff",
      contorno: "#ffffff",
      curvaBorda: "8",
      fonte: "Wix Madefor Text",
      corFonte: "#000000",
      negrito: false,
      italico: false,
      fontePerfil: "Wix Madefor Text",
      corFontePerfil: "#ffffff",
      negritoPerfil: false,
      italicoPerfil: false
    };

    return;
  }

  changeTextStyle(style: 'bold' | 'italic') {
    if (style === 'bold') {
      this.templateCss.italicoPerfil = false;
      this.templateCss.negritoPerfil = !this.templateCss.negritoPerfil;

      return;
    }

    this.templateCss.italicoPerfil = !this.templateCss.italicoPerfil;
    this.templateCss.negritoPerfil = false;
  }

  changeTextLinkStyle(style: 'bold' | 'italic') {
    if (style === 'bold') {
      this.templateCss.italico = false;
      this.templateCss.negrito = !this.templateCss.negrito;

      return;
    }

    this.templateCss.italico = !this.templateCss.italico;
    this.templateCss.negrito = false;
  }

  changeLinkButtonShadow(s: string) {
    this.templateCss.sombra = s;
  }

  changeLinkButtonRadius(r: string) {
    this.templateCss.curvaBorda = r;
  }

  applyDefaultBackgroundStyle(i: number) {
    this.changeBackgroundType('color');

    const defaultStyle = this.defaultBackgroundStyles[i];

    this.templateCss.corPrimaria = defaultStyle.externo.corPrincipal;
    this.templateCss.corSecundaria = defaultStyle.externo.corSecundaria;
    this.templateCss.direcaoDegrade = defaultStyle.externo.direcaoDegrade;
    this.templateCss.preenchimento = defaultStyle.interno.background;
    this.templateCss.contorno = defaultStyle.interno.borderColor;
    this.templateCss.curvaBorda = defaultStyle.interno.borderRadius;
    this.templateCss.espessura = defaultStyle.interno.borderWidth;
    this.templateCss.fonte = 'Wix Madefor Text';
    this.templateCss.fontePerfil = 'Wix Madefor Text';
    this.templateCss.cardBackgroundImageUrl = undefined;
  }

  getBackground(style?: Estilo): string {
    if (style) {
      if (style?.externo.corSecundaria) {
        return (
          'linear-gradient(' +
          style.externo.direcaoDegrade +
          'deg, ' +
          style.externo.corPrincipal +
          ' 0%, ' +
          style.externo.corSecundaria +
          ' 100%)'
        );
      }

      return style.externo.corPrincipal;
    }

    if (this.templateCss.corSecundaria) {
      return (
        'linear-gradient(' +
        this.templateCss.direcaoDegrade +
        'deg, ' +
        this.templateCss.corPrimaria +
        ' 0%, ' +
        this.templateCss.corSecundaria +
        ' 100%)'
      );
    }

    return this.templateCss.corPrimaria || '#9d96f8';
  }

  changeLinksWidth(w: string) {
    this.templateCss.espessura = w;
  }

  changeBackgroundType(type: string) {
    if (this.templateBgType === type) return;

    if (this.templateBgType === 'color') {
      this.templateBgType = 'image';
      this.isColorBgSelected = false;
      this.isImageBgSelected = true;

      return;
    }

    this.isColorBgSelected = true;
    this.isImageBgSelected = false;
    this.templateBgType = 'color';
    this.templateCss.cardBackgroundImageUrl = undefined;
    this.croppedBgImage = null;
    this.changeImageEvent = null;
  }

  backToManageTemplates() {
    this.backToManageView.emit(true);
  }

  async saveTemplate() {
    if (!this.templateName) {
      this.templateCreationError.emit('Nome do template é obrigatório.');

      return;
    }

    if (this.templateCss.cardBackgroundImageUrl && this.cardBackgroundImage) {
      try {
        const response = await firstValueFrom(this.businessService.uploadFile(this.cardBackgroundImage))

        this.templateCss.cardBackgroundImageUrl = response;
      } catch (e: any) {
        this.templateCreationError.emit(e.error.message || 'Erro ao subir imagem de fundo do template.');

        return;
      }
    }

    if (this.templateCss.callingCardImageUrl && this.callingCardBackgroundImage) {
      try {
        const response = await firstValueFrom(this.businessService.uploadFile(this.callingCardBackgroundImage));

        this.templateCss.callingCardImageUrl = response;
      } catch (e: any) {
        this.templateCreationError.emit(e.error.message || 'Erro ao subir imagem de fundo do cartao de visitas.');

        return;
      }
    }

    if (this.templateCss.loginImageUrl && this.loginImage) {
      try {
        const response = await firstValueFrom(this.businessService.uploadFileTemplate(this.loginImage));

        this.templateCss.loginImageUrl = response;
      } catch (e: any) {
        this.templateCreationError.emit(e.error.message || 'Erro ao subir imagem de login.');

        return;
      }
    }

    if (this.templateCss.headerImageUrl && this.headerImage) {
      try {
        const response = await firstValueFrom(this.businessService.uploadFileTemplate(this.headerImage));

        this.templateCss.headerImageUrl = response;
      } catch (e: any) {
        this.templateCreationError.emit(e.error.message || 'Erro ao subir imagem do cabeçalho.');

        return;
      }
    }

    if (this.templateCss.backgroundLoginImageUrl && this.backgroundLoginImage) {
      try {
        const response = await firstValueFrom(this.businessService.uploadFileTemplate(this.backgroundLoginImage));
        this.templateCss.backgroundLoginImageUrl = response;
      } catch (e: any) {
        this.templateCreationError.emit(e.error.message || 'Erro ao subir imagem de background do login.');

        return;
      }
    }

    const payload = {
      css: JSON.stringify(this.templateCss),
      name: this.templateName,
      domain: this.domain,
      defaultTemplate: this.defaultTemplate
    }


    this.businessService.saveTemplate(payload).subscribe(
      {
        next: (_res) => {
          this.templateSaved.emit(true);
        },
        error: (e: any) => {
          this.templateCreationError.emit(e.error.message || 'Erro ao subir criar template.');
        }
      }
    );
  }

  openUploadTemplateImages(type: 'background' | 'card' | 'login' | 'header' | 'background-login') {
    this.imageTypeToBeUploaded = type;
    this.templateBgImgSelctionModalOpen = true;

    const modalRef = this.modalService.open(this.templateBgImgSelctionModal, {
      size: 'lg',
      centered: true,
    });
    modalRef.result
      .then(() => {
        this.templateBgImgSelctionModalOpen = false;
      })
      .catch(() => {
        this.templateBgImgSelctionModalOpen = false;
      });
  }

  removeCallingCardImage() {
    this.templateCss.callingCardImageUrl = undefined;
    this.callingCardBackgroundImage = undefined;
  }

  templateBgImageChanged(event: any): void {
    const file: File = event.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB

    if (file.size <= maxSizeInBytes) {
      this.changeImageEvent = event;
    } else {
      // this.alertType = 'danger';
      // this._alert.next(
      // 'Tamanho da arquivo é maior do que o permitido. Favor selecionar uma imagem de até 2MB.',
      // );
    }
  }

  templateImageCropped(event: any): void {
    const blob = event.blob;
    if (blob && blob.size) {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (!e.target || !e.target.result) return;

        var image = new Image();
        image.src = e.target.result as string;

        this.croppedBgImage = reader.result as string;

        if (this.imageTypeToBeUploaded === 'card') {
          image.onload = () => {
            const targetAspectRatio = 617 / 149;

            const imageAspectRatio = image.width / image.height;
            const tolerance = 2;

            if (Math.abs(targetAspectRatio - imageAspectRatio) > tolerance) {
              alert("A imagem deve ter proporção de 617x149.");
            }
          };
        } else if (this.imageTypeToBeUploaded === 'login') {
          image.onload = () => {
            const targetAspectRatio = 320 / 116;

            const imageAspectRatio = image.width / image.height;
            const tolerance = 2;

            if (Math.abs(targetAspectRatio - imageAspectRatio) > tolerance) {
              alert("A imagem deve ter proporção de 320x116.");
            }
          }
        } else if (this.imageTypeToBeUploaded === 'header') {
          image.onload = () => {
            const targetAspectRatio = 125 / 45;

            const imageAspectRatio = image.width / image.height;
            const tolerance = 2;

            if (Math.abs(targetAspectRatio - imageAspectRatio) > tolerance) {
              alert("A imagem deve ter proporção de 125x45.");
            }
          }
        } else if (this.imageTypeToBeUploaded === 'background-login') {
          image.onload = () => {
            const targetAspectRatio = 490 / 563;

            const imageAspectRatio = image.width / image.height;
            const tolerance = 2;

            if (Math.abs(targetAspectRatio - imageAspectRatio) > tolerance) {
              alert("A imagem deve ter proporção de 490x563.");
            }
          }
        }
      };

      reader.readAsDataURL(blob);
    }
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  confirmTemplateImageCropped(croppedImage: string): void {
    if (croppedImage) {
      const blob = this.dataURItoBlob(croppedImage);

      if (this.imageTypeToBeUploaded === 'background') {
        this.templateCss.cardBackgroundImageUrl = this.croppedBgImage;

        this.cardBackgroundImage = new File(
          [blob],
          'profile_image_' + Date.now() + '.jpeg',
          {
            type: 'image/jpeg',
            lastModified: new Date().getTime(),
          },
        );
      }

      if (this.imageTypeToBeUploaded === 'card') {
        this.templateCss.callingCardImageUrl = this.croppedBgImage;

        this.callingCardBackgroundImage = new File(
          [blob],
          'profile_image_' + Date.now() + '.jpeg',
          {
            type: 'image/jpeg',
            lastModified: new Date().getTime(),
          },
        );
      }

      if (this.imageTypeToBeUploaded === 'login') {
        this.templateCss.loginImageUrl = this.croppedBgImage;

        this.loginImage = new File(
          [blob],
          'profile_image_' + Date.now() + '.jpeg',
          {
            type: 'image/jpeg',
            lastModified: new Date().getTime(),
          },
        );
      }

      if (this.imageTypeToBeUploaded === 'header') {
        this.templateCss.headerImageUrl = this.croppedBgImage;

        this.headerImage = new File(
          [blob],
          'profile_image_' + Date.now() + '.jpeg',
          {
            type: 'image/jpeg',
            lastModified: new Date().getTime(),
          },
        );
      }

      if (this.imageTypeToBeUploaded === 'background-login') {
        this.templateCss.backgroundLoginImageUrl = this.croppedBgImage;

        this.backgroundLoginImage = new File(
          [blob],
          'profile_image_' + Date.now() + '.jpeg',
          {
            type: 'image/jpeg',
            lastModified: new Date().getTime(),
          },
        );
      }

      this.changeImageEvent = null;

      this.modalService.dismissAll();
    }
  }

  openPreview() {

    const loginImageUrl = this.loginImage ? URL.createObjectURL(this.loginImage) : '';
    const backgroundLoginImageUrl = this.backgroundLoginImage ? URL.createObjectURL(this.backgroundLoginImage) : '';

    sessionStorage.setItem('loginImageUrl', loginImageUrl);
    sessionStorage.setItem('backgroundLoginImageUrl', backgroundLoginImageUrl);

    window.open('/login-preview', '_blank');
  }
}

<div class="payees-container">
    <div class="payees-header">
        <h3>Situação Cadastral dos Pagantes</h3>
        <input matInput [(ngModel)]="dataSource.filter" type="text" placeholder="Procurar pagantes" />
    </div>

    <div class="table-wrapper">
        <mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Nome</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <div class="truncate-text">{{ item.name }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="category">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Categoria</mat-header-cell>
                <mat-cell *matCellDef="let item">{{ item.category }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <div class="d-flex align-items-center justify-content-center w-100">
                        {{
                        item.status === 0 ? '🟢' : item.status === 1 ? '🔴' : '🟡'
                        }}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="situation">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Situação</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <div class="d-flex align-items-center justify-content-center w-100">
                        <div [ngClass]="getSituationClass(item.situation)" class="situation-card">
                            {{item.situation}}
                        </div>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="price">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Preço</mat-header-cell>
                <mat-cell *matCellDef="let item">{{ item.price | currency:'BRL' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="limitDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Data Limite</mat-header-cell>
                <mat-cell *matCellDef="let item">{{ item.limitDate | date:'dd/MM/yyyy' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Ações</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <div class="d-flex align-items-center  w-100">
                        <mat-icon [matMenuTriggerFor]="menu" class="cursor">more_vert</mat-icon>
                    </div>
                    <!-- Menu de Ações -->
                    <mat-menu #menu="matMenu">
                        <!-- Ativar Usuário -->
                        <button mat-menu-item *ngIf="item.status === 1" (click)="enableUser(item)">
                            <i class="mx-2 bi bi-check-circle text-success"></i>
                            <span>Ativar Usuário</span>
                        </button>

                        <!-- Desativar Usuário -->
                        <button mat-menu-item *ngIf="item.status === 0 || item.status === 2" (click)="unableUser(item)">
                            <i class="mx-2 bi bi-ban text-danger"></i>
                            <span>Desativar Usuário</span>
                        </button>

                        <!-- Enviar Email de Cobrança -->
                        <button mat-menu-item *ngIf="item.status === 1 || item.status === 2"
                            (click)="sendPaymentEmail(item)">
                            <i class="mx-2 bi bi-envelope text-warning"></i>
                            <span>Enviar E-mail de Cobrança</span>
                        </button>
                    </mat-menu>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
    </div>
</div>

<td>

</td>
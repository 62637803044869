import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-warning-modal',
  templateUrl: './delete-warning-modal.component.html',
  styleUrls: ['./delete-warning-modal.component.css']
})
export class DeleteWarningModalComponent {

}

<div class="modal-container">
  <div class="modal-body">
    <section class="event-fields-section">
      <div class="input-container">
        <div class="search-person-container">
          <label for="searchPersonInput" class="input-label">Adicionar participantes da comunidade: </label>
          <input id="searchPersonInput" maxlength="50" (input)="filterCommunityParticipants($event)"
            placeholder="Procurar pessoas" class="form-control" />
        </div>
        <div class="people-container">
          <div class="connection-request-card" *ngFor="let person of communityParticipantsFiltered">
            <img *ngIf="person.connection.uriImageProfile  && person.connection.uriImageProfile !== ''"
              [src]="person.connection.uriImageProfile" alt="">
            <img *ngIf="!person.connection.uriImageProfile  || !person.connection.uriImageProfile.length"
              src="/assets/img/imagem_perfil.png" alt="">

            <div class="request-info">
              <div class="info">
                <span class="request-name">{{person.connection.name}}</span>
                <span class="request-profession multi-line"
                  [innerHTML]="person.connection.presentation">{{person.connection.presentation}}</span>
              </div>
            </div>
            <i *ngIf="verifyCommunityParticipants(person)" class="btn bi bi-dash-square-fill quantity-people-button"
              (click)="rmCommunityParticipants(person)"></i>
            <i *ngIf="!verifyCommunityParticipants(person)" class="btn bi bi-plus-square-fill quantity-people-button"
              (click)="addCommunityParticipants(person)"></i>
          </div>
        </div>
      </div>
    </section>
    <div *ngIf="communityParticipantsFiltered.length === 0">
      <span class="no-items">Nenhum participante encontrado</span>
    </div>
  </div>
  <div class="modal-footer">
    <button class="item-delete-button" (click)="closeModal(false)">
      Cancelar
    </button>
    <button class="item-cancel-button" (click)="handleSelectEverybody()">
      Adicionar Todos
    </button>
    <button class="item-cancel-button" (click)="handleSelect()">
      Adicionar Selecionado
    </button>
  </div>
</div>
<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="body-div">
  <section class="tab-selector">
    <div [class]="selectedTab === 0 ? 'tab selected-tab' : 'tab'" (click)="changeSelectedTab(0)">
      <i class="bi bi-briefcase-fill"></i>

      <h2 class="tab-title">Conexōes e Indicações</h2>
    </div>

    <div [class]="selectedTab === 1 ? 'tab selected-tab' : 'tab'" (click)="changeSelectedTab(1)">
      <i class="bi bi-person-fill"></i>

      <h2 class="tab-title">Contatos recebidos</h2>
    </div>

    <div [class]="selectedTab === 3 ? 'tab selected-tab' : 'tab'" (click)="changeSelectedTab(3)">
      <i class="bi bi-globe"></i>

      <h2 class="tab-title">Contatos públicos</h2>
    </div>
  </section>

  <section class="content-container">
    <div class="contacts-list-container" *ngIf="selectedTab === 1">
      <app-loading-spinner *ngIf="loadingContacts"></app-loading-spinner>

      <div class="vertical-connections-list-container" *ngIf="!loadingContacts && sentContacts.length > 0">
        <div class="button-content">
          <button class="highlight-button" (click)="downloadContatoCSV()"><i class="bi bi-filetype-csv"></i>Exportar
            para CSV</button>
        </div>

        <div class="table-content">
          <div class="contact-card-container" *ngFor="let contact of sentContacts">
            <div class="header-container">
              <div class="contact-name-container">
                <span class="contact-name">{{ contact.hidden ? 'Alguém na empresa ' + contact.contactCompany :
                  contact.contactName }}</span>

                <button *ngIf="!contact.hidden" (click)="generateVcardContact(contact)"
                  class="contact-connection-action-button" matTooltip="Gerar v-card">
                  <i class="bi bi-person-vcard"></i>
                </button>

                <button class="hidden-lead-indicator-button" *ngIf="contact.hidden" (click)="openPlanExceededModal()">
                  <i class="bi bi-lock-fill"></i>
                </button>
              </div>

              <div class="contact-company-container">
                <span class="contact-company">{{ contact.contactPosition ? contact.contactPosition + ' na ' +
                  contact.contactCompany : contact.contactCompany }}</span>
              </div>
            </div>

            <div class="contact-infos-container">
              <div class="contact-phone-container">
                <i class="bi bi-telephone phone-contact-icon"></i>

                <span [class]="contact.hidden ? 'hidden-contact' : 'contact-phone'">{{
                  contact.hidden ? '(XX) ZZZXX-XZZX' : contact.contactPhone ? contact.contactPhone : '-'
                  }}</span>
              </div>

              <div class="contact-email-container">
                <i class="bi bi-envelope-at-fill contact-icon bi--spin"></i>

                <span [class]="contact.hidden ? 'hidden-contact' : 'contact-email'">{{
                  contact.hidden ? 'ficticio@email.com' : contact.contactEmail ? contact.contactEmail : '-'
                  }}</span>
              </div>
            </div>

            <div class="sent-at-date">
              <span>Enviado em {{ contact.sharingDate | date: 'dd/MM/yyyy' }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="no-contacts-dispplayed" *ngIf="sentContacts.length < 1">
        <span class="no-contacts-dispplayed-label">Você ainda nāo recebeu nenhum contato</span>
      </div>
    </div>

    <div class="connections-list-container" *ngIf="selectedTab === 0">
      <app-loading-spinner *ngIf="loadingConnections"></app-loading-spinner>

      <div class="vertical-connections-list-container">
        <div class="filters" *ngIf="userConnectionsHistory.length > 0 || userIndicationsHistory.length > 0">
          <label class="title">Filtros:</label>

          <div class="filters-inputs">
            <input type="text" id="name" name="name" class="name-input" placeholder="Nome" [(ngModel)]="connectionName"
              (input)="onSelectChange()"
              [ngClass]="!(userProfile && userProfile.documentUser && userProfile.documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !userProfilePlan.profilePlanLimits.connectContacts || (userProfilePlan.profilePlanLimits.connectContactsView > 0 && userConnections.length >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''">

            <ng-select class="select-preferences" [items]="preferencesList" [multiple]="true" [closeOnSelect]="false"
              [searchable]="false" bindLabel="description" bindValue="id" placeholder="Interesses"
              [(ngModel)]="selectedPreferences" [hideSelected]="true" (change)="onSelectChange()"
              [ngClass]="!(userProfile && userProfile.documentUser && userProfile.documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !userProfilePlan.profilePlanLimits.connectContacts || (userProfilePlan.profilePlanLimits.connectContactsView > 0 && userConnections.length >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''"></ng-select>
          </div>

          <div class="filters-inputs">
            <ng-select class="select-preferences" [items]="occupationsList" [multiple]="true" [closeOnSelect]="false"
              [searchable]="false" bindLabel="description" bindValue="id" placeholder="Áreas de Ocupação"
              [(ngModel)]="selectedOccupations" [hideSelected]="true" (change)="onSelectChange()"
              [ngClass]="!(userProfile && userProfile.documentUser && userProfile.documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !userProfilePlan.profilePlanLimits.connectContacts || (userProfilePlan.profilePlanLimits.connectContactsView > 0 && userConnections.length >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''"></ng-select>

            <div class="filters-button-content">
              <button class="highlight-button" (click)="downloadConexoeCSV()"
                [ngClass]="!(userProfile && userProfile.documentUser && userProfile.documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !userProfilePlan.profilePlanLimits.connectContacts || (userProfilePlan.profilePlanLimits.connectContactsView > 0 && userConnections.length >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''">
                <i class="bi bi-filetype-csv"></i>Exportar para CSV</button>
            </div>
          </div>
        </div>

        <header class="title-container">
          <h1 class="title" translate>contacts.connectionsRequest</h1>
        </header>

        <div class="participants-list" *ngIf="!loadingConnections && pendingConnectionRequests.length > 0">
          <div *ngFor="let connection of pendingConnectionRequests; let i = index" class="card">
            <div class="connection-card" (click)="openConnectionProfile(connection)"
              [ngClass]="!(userProfile && userProfile.documentUser && userProfile.documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !userProfilePlan.profilePlanLimits.connectContacts || (userProfilePlan.profilePlanLimits.connectContactsView > 0 && i >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''">
              <div class="connection-calling-card" [style.background-image]="'url( ' + getBackground(connection) + ')'">
                <div class="profile-img">
                  <img
                    [src]="connection.connection.uriImageProfile ? connection.connection.uriImageProfile : defaultImageProfile"
                    alt="connection-profile-pic" class="connection-profile-pic" />
                </div>

                <div class="connection-actions">
                  <button (click)="generateVcardConnections($event, connection.connection)"
                    class="connection-action-button" matTooltip="Gerar v-card">
                    <i class="bi bi-person-vcard"></i>
                  </button>

                  <button (click)="checkConnectionInterests($event, connection.connection)"
                    class="connection-action-button" matTooltip="Ver interesses">
                    <i class="bi bi-person-lines-fill"></i>
                  </button>

                  <button (click)="checkConnectionPresentation($event, connection.connection)"
                    class="connection-action-button" matTooltip="Ver apresentação">
                    <i class="bi bi-person-workspace"></i>
                  </button>
                </div>
              </div>

              <div class="connection-headline">
                <div class="connection-infos">
                  <span class="connection-name">{{ connection.connection.name }}</span>
                  <span class="connection-bio" [innerHTML]="connection.connection.bio">{{ connection.connection.bio
                    }}</span>
                </div>
              </div>

              <div class="indication-connection-actions-container">
                <button class="highlight-button" (click)="acceptConnectionRequest($event, connection.connection)">
                  <i class="bi bi-person-fill-add"></i>
                  Aceitar
                </button>
                <button class="remove-button" (click)="rejectConnectionRequest($event, connection.connection)">
                  <i class="bi bi-person-fill-x"></i>
                  Recusar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="no-contacts-dispplayed" *ngIf="userConnections.length < 1">
          <span class="no-contacts-dispplayed-label">Nenhuma solicitação para aprovar!</span>
        </div>

        <div class="horizontal-line"></div>

        <header class="title-container">
          <h1 class="title" translate>contacts.sentConnectionsRequest</h1>
        </header>

        <div class="participants-list" *ngIf="!loadingConnections && sentConnectionRequests.length > 0">
          <div *ngFor="let connection of sentConnectionRequests; let i = index" class="card">
            <div class="connection-card" (click)="openConnectionProfile(connection)"
              [ngClass]="!(userProfile && userProfile.documentUser && userProfile.documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !userProfilePlan.profilePlanLimits.connectContacts || (userProfilePlan.profilePlanLimits.connectContactsView > 0 && i >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''">
              <div class="connection-calling-card" [style.background-image]="'url( ' + getBackground(connection) + ')'">
                <div class="profile-img">
                  <img
                    [src]="connection.connection.uriImageProfile ? connection.connection.uriImageProfile : defaultImageProfile"
                    alt="connection-profile-pic" class="connection-profile-pic" />
                </div>

                <div class="connection-actions">
                  <button (click)="generateVcardConnections($event, connection.connection)"
                    class="connection-action-button" matTooltip="Gerar v-card">
                    <i class="bi bi-person-vcard"></i>
                  </button>

                  <button (click)="checkConnectionInterests($event, connection.connection)"
                    class="connection-action-button" matTooltip="Ver interesses">
                    <i class="bi bi-person-lines-fill"></i>
                  </button>

                  <button (click)="checkConnectionPresentation($event, connection.connection)"
                    class="connection-action-button" matTooltip="Ver apresentação">
                    <i class="bi bi-person-workspace"></i>
                  </button>
                </div>
              </div>

              <div class="connection-headline">
                <div class="connection-infos">
                  <span class="connection-name">{{ connection.connection.name }}</span>
                  <span class="connection-bio" [innerHTML]="connection.connection.bio">{{ connection.connection.bio
                    }}</span>
                </div>
              </div>

              <div class="indication-connection-actions-container">
                <button class="remove-button" (click)="cancelConnectionRequest($event, connection.connection)">
                  <i class="bi bi-person-fill-x"></i>
                  Cancelar solicitação
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="no-contacts-dispplayed" *ngIf="userConnections.length < 1">
          <span class="no-contacts-dispplayed-label">Você não possui nenhuma solicitação de conexão pendente</span>
        </div>

        <div class="horizontal-line"></div>

        <header class="title-container">
          <h1 class="title" translate>contacts.connectionsTitle</h1>
        </header>

        <div class="participants-list" *ngIf="!loadingConnections && userConnections.length > 0">
          <div *ngFor="let connection of userConnections; let i = index" class="card">
            <div class="connection-card" (click)="openConnectionProfile(connection)"
              [ngClass]="!(userProfile && userProfile.documentUser && userProfile.documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !userProfilePlan.profilePlanLimits.connectContacts || (userProfilePlan.profilePlanLimits.connectContactsView > 0 && i >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''">
              <div class="connection-calling-card" [style.background-image]="'url( ' + getBackground(connection) + ')'">
                <div class="profile-img">
                  <img
                    [src]="connection.connection.uriImageProfile ? connection.connection.uriImageProfile : defaultImageProfile"
                    alt="connection-profile-pic" class="connection-profile-pic" />
                </div>

                <div class="connection-actions">
                  <button (click)="generateVcardConnections($event, connection.connection)"
                    class="connection-action-button" matTooltip="Gerar v-card">
                    <i class="bi bi-person-vcard"></i>
                  </button>

                  <button (click)="checkConnectionInterests($event, connection.connection)"
                    class="connection-action-button" matTooltip="Ver interesses">
                    <i class="bi bi-person-lines-fill"></i>
                  </button>

                  <button (click)="checkConnectionPresentation($event, connection.connection)"
                    class="connection-action-button" matTooltip="Ver apresentação">
                    <i class="bi bi-person-workspace"></i>
                  </button>
                </div>
              </div>

              <div class="connection-headline">
                <div class="connection-infos">
                  <span class="connection-name">{{ connection.connection.name }}</span>
                  <span class="connection-bio" [innerHTML]="connection.connection.bio">{{ connection.connection.bio
                    }}</span>
                </div>
              </div>

              <div class="indication-connection-actions-container">
                <button class="highlight-button" (click)="openIndicateModal($event, connection.connection)">
                  <i class="bi bi-person-fill-add"></i>
                  Indicar
                </button>
                <button class="remove-button" (click)="removeConnection($event, connection.connection)">
                  <i class="bi bi-person-fill-x"></i>
                  Desconectar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="no-contacts-dispplayed" *ngIf="userConnections.length < 1">
          <span class="no-contacts-dispplayed-label">Você ainda nāo possui nenhuma conexão</span>
        </div>

        <div class="horizontal-line"></div>

        <header class="title-container">
          <h1 class="title" translate>contacts.indicationsTitle</h1>
        </header>

        <div class="participants-list" *ngIf="!loadingConnections && userIndicationConnections.length > 0">
          <div class="card" *ngFor="let connection of userIndicationConnections">
            <div class="connection-card" (click)="openConnectionProfile(connection)">
              <div class="connection-calling-card" [style.background-image]="'url( ' + getBackground(connection) + ')'">
                <div class="profile-img">
                  <img
                    [src]="connection.connection.uriImageProfile ? connection.connection.uriImageProfile : defaultImageProfile"
                    alt="connection-profile-pic" class="connection-profile-pic" />
                </div>
                <div class="connection-actions">
                  <button (click)="generateVcardConnections($event, connection.connection)"
                    class="connection-action-button" matTooltip="Gerar v-card">
                    <i class="bi bi-person-vcard"></i>
                  </button>
                  <button (click)="checkConnectionInterests($event, connection.connection)"
                    class="connection-action-button" matTooltip="Ver interesses">
                    <i class="bi bi-person-lines-fill"></i>
                  </button>
                  <button (click)="checkConnectionPresentation($event, connection.connection)"
                    class="connection-action-button" matTooltip="Ver apresentação">
                    <i class="bi bi-person-workspace"></i>
                  </button>
                </div>
              </div>

              <div class="connection-headline">
                <div class="connection-infos">
                  <span class="connection-name">{{ connection.connection.name }}</span>
                  <span class="connection-bio" [innerHTML]="connection.connection.bio">{{ connection.connection.bio
                    }}</span>
                </div>
              </div>

              <div class="indication-connection-actions-container">
                <span class="connection-indicado">Indicado por: {{connection.indicatorName}}</span>
                <button class="highlight-button"
                  (click)="handleConnect($event, connection.connection.idUser, connection.connection)">
                  <i class="bi bi-person-fill-add"></i>
                  Conectar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="no-contacts-dispplayed" *ngIf="userIndicationConnections.length < 1">
          <span class="no-contacts-dispplayed-label">Você ainda nāo possui nenhuma indicação</span>
        </div>
      </div>
    </div>

    <div class="connections-list-container" *ngIf="selectedTab === 3">
      <app-loading-spinner *ngIf="loadingConnections"></app-loading-spinner>

      <div class="vertical-connections-list-container">
        <div class="filters" *ngIf="userPublicHistory.length > 0">
          <label class="title">Filtros:</label>
          <div class="filters-inputs">
            <input type="text" id="namePublic" name="name" class="name-input" placeholder="Nome"
              [(ngModel)]="connectionNamePublic" (input)="onSelectChangePublic()"
              [ngClass]="!(userProfile && userProfile.documentUser && userProfile.documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !userProfilePlan.profilePlanLimits.connectContacts || (userProfilePlan.profilePlanLimits.connectContactsView > 0 && userConnections.length >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''">

            <ng-select class="select-preferences" [items]="preferencesList" [multiple]="true" [closeOnSelect]="false"
              [searchable]="false" bindLabel="description" bindValue="id" placeholder="Interesses"
              [(ngModel)]="selectedPreferencesPublic" [hideSelected]="true" (change)="onSelectChangePublic()"
              [ngClass]="!(userProfile && userProfile.documentUser && userProfile.documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !userProfilePlan.profilePlanLimits.connectContacts || (userProfilePlan.profilePlanLimits.connectContactsView > 0 && userConnections.length >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''"></ng-select>
          </div>
          <div class="filters-inputs">
            <ng-select class="select-preferences" [items]="occupationsList" [multiple]="true" [closeOnSelect]="false"
              [searchable]="false" bindLabel="description" bindValue="id" placeholder="Áreas de Atuação"
              [(ngModel)]="selectedOccupationsPublic" [hideSelected]="true" (change)="onSelectChangePublic()"
              [ngClass]="!(userProfile && userProfile.documentUser && userProfile.documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !userProfilePlan.profilePlanLimits.connectContacts || (userProfilePlan.profilePlanLimits.connectContactsView > 0 && userConnections.length >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''"></ng-select>
          </div>
        </div>

        <div class="participants-list" *ngIf="!loadingConnections && userPublicConnections.length > 0">
          <div *ngFor="let connection of userPublicConnections; let i = index" class="card">
            <div class="connection-card" (click)="openConnectionProfile(connection)"
              [ngClass]="!(userProfile && userProfile.documentUser && userProfile.documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !userProfilePlan.profilePlanLimits.connectContacts || (userProfilePlan.profilePlanLimits.connectContactsView > 0 && i >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''">
              <div class="connection-calling-card" [style.background-image]="'url( ' + getBackground(connection) + ')'">
                <div class="profile-img">
                  <img
                    [src]="connection.connection.uriImageProfile ? connection.connection.uriImageProfile : defaultImageProfile"
                    alt="connection-profile-pic" class="connection-profile-pic" />
                </div>

                <div class="connection-actions">
                  <button (click)="generateVcardConnections($event, connection.connection)"
                    class="connection-action-button" matTooltip="Gerar v-card">
                    <i class="bi bi-person-vcard"></i>
                  </button>

                  <button (click)="checkConnectionInterests($event, connection.connection)"
                    class="connection-action-button" matTooltip="Ver interesses">
                    <i class="bi bi-person-lines-fill"></i>
                  </button>

                  <button (click)="checkConnectionPresentation($event, connection.connection)"
                    class="connection-action-button" matTooltip="Ver apresentação">
                    <i class="bi bi-person-workspace"></i>
                  </button>
                </div>
              </div>

              <div class="connection-headline">
                <div class="connection-infos">
                  <span class="connection-name">{{ connection.connection.name }}</span>
                  <span class="connection-bio" [innerHTML]="connection.connection.bio">{{ connection.connection.bio
                    }}</span>
                </div>
              </div>

              <div *ngIf="connection.connection.autoApprove " class="indication-connection-actions-container">
                <button class="highlight-button" (click)="handleConnectPublic($event, connection.connection)">
                  <i class="bi bi-person-fill-add"></i>
                  Conectar
                </button>
              </div>

              <div *ngIf="!connection.connection.autoApprove " class="indication-connection-actions-container">
                <button class="highlight-button" (click)="handleConnectPublic($event, connection.connection)">
                  <i class="bi bi-person-fill-add"></i>
                  Conectar
                </button>
              </div>

            </div>
          </div>
        </div>

        <div class="no-contacts-dispplayed" *ngIf="userPublicConnections.length < 1">
          <span class="no-contacts-dispplayed-label">Nenhum contato público encontrado. Nesta pesquisa você encontrará
            os perfis públicos que você não possui conexão.</span>
        </div>

        <div class="no-access-container"
          *ngIf="!(userProfile && userProfile.documentUser && userProfile.documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !userProfilePlan.profilePlanLimits.connectContacts || (userProfilePlan.profilePlanLimits.connectContactsView > 0 && userConnections.length >= userProfilePlan.profilePlanLimits.connectContactsView)">
          <div *ngIf="!loadingConnections" class="no-access-content">
            <span class="no-access-text" translate>Seu plano atual não permite acesso aos contatos públicos.</span>
            <img src="/assets/img/undraw_access_denied_re_awnf.svg" style="width: 200px" alt="No access"
              class="no-access-img" />

            <span class="cta-label">Para acessar,
              <a target="_blank"
                href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça
                upgrade do seu plano</a>.</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
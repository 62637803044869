<app-header-principal></app-header-principal>
<div class="container">
  <div class="d-flex justify-content-center align-items-center" style="margin: 25px 0">
    <div class="card card-profile position-relative">
      <div class="card-top">
        <!-- Botão para abrir o Toast -->
        <div class="position-absolute z-1 div-alterar-fundo-btn end-0 top-0">
          <button type="button" class="btn btn-light btn-escolher-fundo text-grey" (click)="openBackgroundModal()">
            <i class="bi bi-palette-fill"></i> Alterar cor de fundo
          </button>
        </div>

        <!-- Área de fundo do perfil -->
        <div class="profile-background-container position-relative"
          [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }">
          <app-profile-picture [profileImage]="profileImage" (imageChanged)="onProfileImageChanged($event)">
          </app-profile-picture>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-12 form-div">
          <span class="text-normal-16-700 text-light-grey">* Itens obrigatórios</span>
        </div>

        <!-- Ajuste colunas para serem responsivas -->
        <div class="col-md-6 col-12 form-div">
          <label for="nomeEmpresa" class="label-text-form" translate>*Nome de exibição</label>
          <input (blur)="autoCompleteBlur()" type="text" id="nomeEmpresa" class="form-input"
            [formControl]="nomeExibicao" />
        </div>

        <div class="col-md-6 col-12 form-div">
          <label class="label-text-form" translate>*Customize sua URL da bio:</label>
          <div class="input-group">
            <span class="input-group-text text-normal-16-400" id="basic-addon3">https://sluper.bio/</span>
            <input type="text" class="form-control form-input" id="basic-url" placeholder="ex: seu nome"
              (blur)="validateLink()" [formControl]="urlProfile" aria-describedby="site-addon3" />
          </div>
          <span class="error-message" *ngIf="urlProfile.touched && urlProfile.invalid">URL inválida</span>
        </div>

        <div class="col-md-6 col-12 form-div">
          <label for="nomeEmpresa" class="label-text-form" translate>*Título</label>
          <input type="text" id="nomeEmpresa" class="form-input" [formControl]="titulo"
            placeholder="ex: Desenvolvedor / CEO na Empresa..." />
        </div>

        <div class="col-12 form-div">
          <label for="bio" class="label-text-form">*Bio</label>
          <textarea class="form-input form-control" id="exampleFormControlTextarea1" rows="3" maxlength="3200"
            [formControl]="biografia" (input)="updateCharacterCount()"
            placeholder="Faça uma breve descrição sobre sua jornada como Profissional."></textarea>
          <div class="text-muted">{{ characterCount }}/3200 characters</div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button type="button" class="btn button-roxo-form" (click)="salvar()">
          <span class="text-normal-16-700 text-white" translate> Salvar </span>
        </button>
      </div>
    </div>
  </div>
</div>
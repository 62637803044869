import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-side-menu-options',
  templateUrl: './side-menu-options.component.html',
  styleUrls: ['./side-menu-options.component.css']
})
export class SideMenuOptionsComponent implements OnInit {
  public modalType: string = 'category';


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { selectedItem: any, allCategories: [any], modalType: 'category' | 'channel', },
    private dialogRef: MatDialogRef<SideMenuOptionsComponent>
  ) {
    this.modalType = this.data.modalType;
  }

  ngOnInit(): void {

  }

  closeModal(type: string): void {
    const returnData = { success: true, sidebarOption: type };
    this.dialogRef.close(returnData);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

interface Store {
  image: string;
  name: string;
  description: string;
  contact: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  isFeatured: boolean;
}

export interface IStore extends Store {
  id: number;
}

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private readonly urlBase = environment.eventsApiUrl;

  constructor(private readonly http: HttpClient) {}

  getStores(communityId: string): Observable<IStore[]> {
    return this.http.get<IStore[]>(`${this.urlBase}Store/${communityId}`);
  }

  getStoreInformation(communityId: string): Observable<IStore[]> {
    return this.http.get<IStore[]>(`${this.urlBase}Store/${communityId}`);
  }

  createStore(data: Store): Observable<IStore> {
    return this.http.post<IStore>(`${this.urlBase}Store`, data);
  }

  updateStore(data: Store, id: number): Observable<IStore> {
    return this.http.put<IStore>(`${this.urlBase}Store/${id}`, data);
  }

  deleteStore(id: number): Observable<void> {
    return this.http.delete<void>(`${this.urlBase}Store/${id}`);
  }
}

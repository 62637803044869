import { HttpResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, filter, firstValueFrom, interval, map, switchMap, takeUntil } from 'rxjs';
import { CustomCommunityPhotosService } from '../custom-community-photos/custom-community-photos.service';
import { EventsService } from '../events/events.service';
import { IConnection } from '../model/connection.model';
import { IDocumentUserProfile } from '../model/document-user-profile.model';
import { DOCUMENT_USER_CSS, DOCUMENT_USER_PROFILE } from '../model/documents';
import { SluperEvent } from '../model/event.model';
import { MenuState } from '../model/menu-screens.model';
import { Phone } from '../model/phone.model';
import { IDocumentPreferences } from '../model/preferences.model';
import { IUserProfilePlan } from '../model/user-plan.model';
import { ToastService } from '../new-sluper/core/service/toast.service';
import { OpenedCommunityService } from '../opened-community.service';
import { AuthService } from './../auth/auth.service';
import { IDocumentUser } from './../model/document-user.model';
import { Personal } from './../model/personal.model';
import { IUserProfileWithCSS } from './../model/user-profile-css.model';
import { UserAdminPlan, UserProfileService } from './user-profile.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  private unauthorizedSubscription: Subscription | undefined;
  private stopNotifier = new Subject<void>();
  urlImagemHeader = '';
  pendingConnectionRequests: IConnection[] = [];

  mobileMenuOpened = false;

  userAdminPlan!: UserAdminPlan;
  userProfile: IUserProfileWithCSS | null = null;
  response = '';
  menuState: MenuState = {
    active: 1,
    homeSelected: false,
    sentContactsSelected: false,
    aparenciaSelected: false,
    menuSelected: false,
    linksSelected: false,
    indicadoresSelected: false,
    opcoesSelected: false,
    dashboardSelected: false,
    salesDashboardSelected: false,
    sluperCoinsSelected: false,
    eventsSelected: false,
    communitySelected: false,
    businessSelected: false,
    corpTemplatesSelected: false,
  };
  userProfilePlan: IUserProfilePlan = {
    planName: 'default',
    profilePlanLimits: {
      contactsInclude: 0,
      socialLinksInclude: 0,
      linksInclude: 0,
      leadsViewContacts: 0,
      dashViews: false,
      dashAccessGraph: false,
      dashAccessedLinks: false,
      professionalView: false,
      eventsView: false,
      eventsIncludeQuantity: 0,
      connectContacts: false,
      includeEvents: false,
      connectContactsView: 0,
      participateInEvents: false,
      eventsParticipantsView: false
    }
  };
  preferences!: IDocumentPreferences[];
  userPreferences!: IDocumentPreferences[];
  occupations!: IDocumentPreferences[];

  idUser = '';

  loading = true;
  screenWidth: any;
  screenHeight: any;

  disableMenu = false;
  communityItem: SluperEvent | null = null;
  communityLogo: string | null = null;
  notifications: any = [];
  private intervalId: any;


  constructor(
    private userProfileService: UserProfileService,
    protected jwtHelper: JwtHelperService,
    private authService: AuthService,
    private eventsService: EventsService,
    private router: Router,
    private openedCommunityService: OpenedCommunityService,
    private toastrService: ToastService,
    private customCommunityPhotosService: CustomCommunityPhotosService,
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.customCommunityPhotosService.logo$.subscribe(logo => {
      this.communityLogo = logo && logo.length ? logo : null;
    });

    const storedCommunity = this.openedCommunityService.getCommunity();
    if (storedCommunity) {
      this.router.navigate([`/comunidade/${storedCommunity}`]);
    }

    // this.getHeaderImage();
    await this.getUserById();
    this.getUserProfilePlan();
    this.unauthorizedSubscription = this.authService.unauthorized$
      .pipe(
        switchMap(() =>
          interval(500).pipe(takeUntil(this.stopNotifier))
        )
      )
      .subscribe(() => {
        this.toastrService.show('Sua sessão expirou. Por favor, faça login novamente.', 'error');
      });
    await this.fetchData();
    this.getScreenSize();
    this.getUserAdminPlan();
    this.getPreferences();
    await this.fetchUserPendingContactsRequests();
    this.startFetchUserPendingContactsRequestsInterval();
  }

  ngOnDestroy(): void {
    if (this.unauthorizedSubscription) {
      this.unauthorizedSubscription.unsubscribe();
    }
    this.stopNotifier.next();
    this.stopNotifier.complete();
    this.stopFetchUserPendingContactsRequestsInterval();

  }
  async fetchData(onlyRefresh: boolean = false) {
    this.loading = true;

    try {
      const userData = await firstValueFrom(this.userProfileService
        .userById(null)
        .pipe(
          filter((mayBeOk: HttpResponse<IUserProfileWithCSS>) => mayBeOk.ok),
          map((response: HttpResponse<IUserProfileWithCSS>) => response.body),
        )
      )

      if (!onlyRefresh) {
        this.setUserProfile(userData);
        // this.getUserPreferences();
      } else {
        this.userProfile = userData;
      }

      const userPlan = await firstValueFrom(this.userProfileService
        .getUserPlan()
        .pipe(
          filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
          map((response: HttpResponse<any>) => response.body),
        )
      );

      this.userProfilePlan = userPlan;

      this.loading = false;
    } catch (e) {
      this.loading = false;


      this.toastrService.show('Não foi possível buscar as informações do perfil. Contacte o administrador.', 'error');
      setTimeout(() => {
        this.logout();
      }, 3000);
    }
  }

  private startFetchUserPendingContactsRequestsInterval(): void {
    this.intervalId = setInterval(async () => {
      await this.fetchUserPendingContactsRequests();
    }, 60000); // 10 segundos
  }

  private stopFetchUserPendingContactsRequestsInterval(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  async fetchUserPendingContactsRequests(): Promise<void> {
    try {

      if (!this.userProfile?.documentUser.idUser) {
        return;
      }
      const connectionsRequests = await firstValueFrom(this.eventsService.getPendingConnectionRequests(this.userProfile?.documentUser.idUser));
      if (!connectionsRequests.body) {
        return;
      }
      this.pendingConnectionRequests = connectionsRequests.body;
    } catch (error) {
      console.error(error);
    }
  }

  getPlanStampStyles() {
    const planStampStyles: { [k: string]: string } = {
      'Plano Básico': "background-color: transparent; color: #2F80ED;background: linear-gradient(to right, #56CCF2, #2F80ED); border-radius: 28px;",
      'free plan 2': "background-color: transparent; color: #2F80ED;background: linear-gradient(to right, #56CCF2, #2F80ED); border-radius: 28px;",
    }

    const defaultPlanStyle = "background-color: transparent; color: #9733EE; background: linear-gradient(to right, #DA22FF, #9733EE, #9B51E0); border-radius: 28px;"

    return planStampStyles[this.userProfilePlan.planName] || defaultPlanStyle;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  getUserById(): void {
    this.userProfileService
      .userById(null)
      .pipe(
        filter((mayBeOk: HttpResponse<IUserProfileWithCSS>) => mayBeOk.ok),
        map((response: HttpResponse<IUserProfileWithCSS>) => response.body),
      )
      .subscribe({
        next: (res) => this.setUserProfile(res),
        error: (res) => console.error(res),
      });
  }

  async refreshPendingRequests(event?: any): Promise<void> {
    await this.fetchUserPendingContactsRequests();
    const actualMenu = this.getCurrentMenu();

    let temporaryMenu: 'home' | 'aparencia' | 'links' | 'opcoes' | 'indicadores' | 'sentContacts' | 'dashboard' | 'salesDashboard' | 'sluperCoins' | 'events' | 'business' | 'businessTemplates' | 'community' = 'dashboard';

    if (temporaryMenu === actualMenu) {
      temporaryMenu = 'home';
    }

    // this.mudouMenu(temporaryMenu);
    // await this.delay(300);
    // this.mudouMenu(actualMenu);
  }

  // Método utilitário para pegar o menu atual
  private getCurrentMenu(): 'home' | 'aparencia' | 'links' | 'opcoes' | 'indicadores' | 'sentContacts' | 'dashboard' | 'salesDashboard' | 'sluperCoins' | 'events' | 'business' | 'businessTemplates' | 'community' {
    return Object.keys(this.menuState).find(
      (key) => this.menuState[key as keyof MenuState] === true
    ) as 'home' | 'aparencia' | 'links' | 'opcoes' | 'indicadores' | 'sentContacts' | 'dashboard' | 'salesDashboard' | 'sluperCoins' | 'events' | 'business' | 'businessTemplates' | 'community';
  }

  private delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }


  getUserProfilePlan(): void {
    this.userProfileService
      .getUserPlan()
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((response: HttpResponse<any>) => response.body),
      )
      .subscribe({
        next: (res) => {
          this.userProfilePlan = res;
        },
        error: (res) => console.error(res),
      });
  }

  setUserProfile(res: IUserProfileWithCSS | null) {
    this.userProfile = res;

    if (this.userProfile) {
      if (this.userProfile.documentUserProfile !== null && this.userProfile.documentUserCSS !== null) {
        this.menuState.eventsSelected = true;
        this.menuState.active = 10;

        return;
      }

      if (this.userProfile.documentUserProfile === null) {
        this.userProfile.documentUserProfile = DOCUMENT_USER_PROFILE;
      }

      if (this.userProfile.documentUserCSS === null) {
        this.userProfile.documentUserCSS = DOCUMENT_USER_CSS;
      }

      this.getIdUser();
      this.userProfile.documentUserProfile.idUser = this.idUser;
      this.userProfile.documentUserCSS.idUser = this.idUser;
      this.menuState.opcoesSelected = true;
      this.menuState.active = 4;
    }

    const primeiroAcesso = localStorage.getItem('primeiroAcesso');
    if (primeiroAcesso === 'true') {
      // Atualiza dados de usuário primeiro acesso
      const nome = localStorage.getItem('nome');

      if (
        this.userProfile &&
        this.userProfile.documentUserProfile &&
        nome &&
        this.userProfile.documentUserProfile.name == ''
      ) {
        this.userProfile.documentUserProfile.name = nome;
        this.atualizaDocumentUserProfile(this.userProfile.documentUserProfile);
      }

      if (
        this.userProfile &&
        this.userProfile.documentUser &&
        nome &&
        this.userProfile.documentUser.personal === undefined
      ) {
        this.userProfile.documentUser.personal = new Personal(
          '',
          '',
          '',
          new Phone('', ''),
          '',
        );
        this.userProfile.documentUser.personal.name = nome;
        this.atualizaDocumentUser(this.userProfile.documentUser);
      }

      // Remover a validação de primeiro acesso e variaveis
      localStorage.removeItem('primeiroAcesso');
      localStorage.removeItem('nome');
      localStorage.removeItem('email');
    }
  }

  atualizaDocumentUserProfile(documentUserProfile: IDocumentUserProfile): void {
    this.userProfileService
      .saveDocumentUserProfile(documentUserProfile)
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((response: HttpResponse<any>) => response.body),
      )
      .subscribe({
        next: (res) => console.error(res),
        error: (res) => console.error(res),
      });
  }

  atualizaDocumentUser(documentUser: IDocumentUser): void {
    this.userProfileService
      .saveDocumentUser(documentUser)
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((response: HttpResponse<any>) => response.body),
      )
      .subscribe({
        next: (res) => console.error(res),
        error: (res) => console.error(res),
      });
  }

  getIdUser() {
    const token: string | null = localStorage.getItem('authToken');
    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      const userId = decodedToken.idUser;
      this.idUser = userId;
    }
  }


  changeLogo(event: SluperEvent | null) {
    this.communityItem = event;
  }
  mudouMenu(selecionado: 'home' | 'aparencia' | 'links' | 'opcoes' | 'indicadores' | 'sentContacts' | 'dashboard' | 'salesDashboard' | 'sluperCoins' | 'events' | 'business' | 'businessTemplates' | 'community'): void {
    const menuStates: { [key: string]: Partial<MenuState> } = {
      home: { active: 1, homeSelected: true, menuSelected: true },
      aparencia: { active: 2, aparenciaSelected: true },
      links: { active: 3, linksSelected: true },
      opcoes: { active: 4, opcoesSelected: true },
      indicadores: { active: 5, indicadoresSelected: true },
      sentContacts: { active: 6, sentContactsSelected: true },
      dashboard: { active: 7, dashboardSelected: true },
      salesDashboard: { active: 8, salesDashboardSelected: true },
      sluperCoins: { active: 9, sluperCoinsSelected: true },
      events: { active: 10, eventsSelected: true },
      business: { active: 12, businessSelected: true },
      businessTemplates: { active: 13, corpTemplatesSelected: true },
      community: { active: 14, communitySelected: true },
    };

    // Estado padrão para resetar todas as variáveis
    const defaultState: MenuState = {
      active: 0,
      homeSelected: false,
      sentContactsSelected: false,
      aparenciaSelected: false,
      menuSelected: false,
      linksSelected: false,
      indicadoresSelected: false,
      opcoesSelected: false,
      dashboardSelected: false,
      salesDashboardSelected: false,
      sluperCoinsSelected: false,
      eventsSelected: false,
      communitySelected: false,
      businessSelected: false,
      corpTemplatesSelected: false,
    };

    // Obtém o estado selecionado com base no menuStates
    const selectedState = menuStates[selecionado] || {};

    // Atualiza o this.menuState combinando o estado padrão com o selecionado
    this.menuState = { ...defaultState, ...selectedState };

    if (this.mobileMenuOpened) {
      this.toggleMobileMenu();
    }
  }


  linkRequired(value: any) {
    this.disableMenu = value;
  }

  contaSaved() {
    this.mudouMenu('events');
  }

  toggleMobileMenu() {
    this.mobileMenuOpened = !this.mobileMenuOpened;
  }

  logout() {
    this.authService.logout();
  }

  getPreferences() {
    this.userProfileService
      .getPreferences()
      .pipe(
        filter((mayBeOk: HttpResponse<IDocumentPreferences[]>) => mayBeOk.ok),
        map((response: HttpResponse<IDocumentPreferences[]>) => response.body),
      )
      .subscribe({
        next: (res) => {
          if (res !== null) {
            this.preferences = res.filter(p => p.type === 'INTERESSE');
            this.occupations = res.filter(o => o.type === 'AREA_ATUACAO');
            this.occupations.sort((a, b) => a.description.localeCompare(b.description));
          }
        },
        error: (res) => console.error(res),
      });
  }

  handleHomeOutput(event: 'home' | 'aparencia' | 'links' | 'opcoes' | 'indicadores' | 'sentContacts' | 'dashboard' | 'salesDashboard' | 'sluperCoins' | 'events' | 'business' | 'businessTemplates' | 'community' | 'logout'): void {
    if (event != 'logout') {
      this.mudouMenu(event);
    } else {
      this.logout();
    }
  }

  getHeaderImage() {
    this.userProfileService.getHeaderImage(window.location.hostname).subscribe({
      next: (response: string) => {
        this.urlImagemHeader = response;
      },
      error: (error) => {
        if (error.status === 404) {
          console.warn('Imagem de cabeçalho não existe, exibindo cabeçalho padrão (404).');
        } else {
          console.error('Erro ao buscar a imagem de login:', error);
        }
      },
    });
  }

  getUserAdminPlan(): void {
    this.userProfileService
      .getAdmPlan()
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((response: HttpResponse<any>) => response.body),
      )
      .subscribe({
        next: (res) => {
          this.userAdminPlan = res;
          this.loading = false;
        },
        error: (res) => {
          console.error(res)
          this.loading = false;
        },
      });
  }

  refreshData(event: any) {
    this.fetchData(true);
  }
}

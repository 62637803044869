import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ngxCsv } from 'ngx-csv';
import { Subject, Subscription, debounceTime, filter, firstValueFrom, map } from 'rxjs';
import { NotificationService } from '../bell-notification/notification.service';
import { EventsService } from '../events/events.service';
import { ExceedPlanResourcesModalComponent } from '../links/exceed-plan-resources/exceeded-plan-resources.component';
import { IConnection } from '../model/connection.model';
import { IDocumentUserProfile, IDocumentUserProfileWithPreferences } from '../model/document-user-profile.model';
import { IImageResponse } from '../model/image-response.model';
import { IDocumentPreferences } from '../model/preferences.model';
import { IUserProfilePlan } from '../model/user-plan.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { UserProfileService } from '../user-profile/user-profile.service';
import { AcceptConnectionRequestComponent } from './accept-connection-request/accept-connection-request.component';
import { CancelConnectionRequestComponent } from './cancel-connection-request/cancel-connection-request.component';
import { CheckPreferencesModalComponent } from './check-preferences/check-preferences.component';
import { IndicateResourcesModalComponent } from './indicate-resources/indicate-resources.component';
import { RejectConnectionRequestComponent } from './reject-connection-request/reject-connection-request.component';
import { RemoveConnectionModalComponent } from './remove-connection/remove-connection.componente';
import { UserPresentationModalComponent } from './user-presentation/user-presentation.component';

export interface IContact {
  id: string;
  idUser: string;
  contactCompany: string;
  contactPosition: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  sharingDate: string;
  ip: string;
  hidden: boolean;
}

@Component({
  selector: 'app-contacts-and-connections',
  templateUrl: './contacts-sent.component.html',
  styleUrls: ['./contacts-sent.component.css'],
})
export class ContactsAndConnectionsComponent implements OnInit, OnDestroy {
  @Input() userProfile!: IUserProfileWithCSS | null;
  @Input() userProfilePlan!: IUserProfilePlan;
  @Output() linkRequired = new EventEmitter<boolean>();
  @Input() preferencesList!: IDocumentPreferences[];
  @Input() occupationsList!: IDocumentPreferences[];

  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  private _alert = new Subject<string>();
  alertMessage = '';
  alertType = '';

  loadingContacts = true;
  sentContacts: IContact[] = [];
  loadingConnections = true;
  userConnections: IConnection[] = [];
  connectionRequests: IConnection[] = [];
  sentConnectionRequests: IConnection[] = [];
  pendingConnectionRequests: IConnection[] = [];
  userIndicationConnections: IConnection[] = [];
  userPublicConnections: IConnection[] = [];
  selectedTab = 0;

  defaultImageProfile = '/assets/img/imagem_perfil.png';
  defaultBackgroundImage = '/assets/img/imagem_fundo.png';
  userConnectionsHistory: IConnection[] = [];
  userIndicationsHistory: IConnection[] = [];
  userPublicHistory: IConnection[] = [];

  selectedPreferences: number[] = [];
  connectionName = '';

  selectedPreferencesPublic: number[] = [];
  connectionNamePublic = '';

  selectedOccupations: number[] = [];
  selectedOccupationsPublic: number[] = [];

  csvContatosRecebidosOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    useBom: true,
    noDownload: false,
    headers: ["Name", "Empresa", "Email", "Telefone"]
  };

  csvConexoesOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    useBom: true,
    noDownload: false,
    headers: ["Name", "Email", "Telefone", "Redes"]
  };
  private subscriptionChangedNotification: Subscription | null = null;
  private refreshInterval: any;


  constructor(
    protected http: HttpClient,
    private userProfileService: UserProfileService,
    private modalService: NgbModal,
    private eventsService: EventsService,
    private notificationService: NotificationService

  ) { }

  ngOnInit(): void {
    this.initializeData();

    if (!this.subscriptionChangedNotification) {
      this.subscriptionChangedNotification = this.notificationService.notifications$.subscribe((data) => {
        if (data) {
          this.initializeData();
        }
      });
    }

    this.refreshInterval = setInterval(async () => {
      try {
        await this.fetchUserContacts({ page: 1, size: 100 }, true);
        await this.fetchUserSentContactsRequests(true);
        await this.fetchUserPendingContactsRequests(true);
        await this.fetchUserConnections(true);
      } catch (error) {
        if (this.refreshInterval) {
          clearInterval(this.refreshInterval);
        }
      }
    }, 60000);
  }

  initializeData(): void {
    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
    this.fetchUserContacts({ page: 1, size: 100 });
    this.fetchUserSentContactsRequests();
    this.fetchUserPendingContactsRequests();
    this.fetchUserConnections();
  }


  ngOnDestroy() {
    if (this.subscriptionChangedNotification) {
      this.subscriptionChangedNotification.unsubscribe();
      this.subscriptionChangedNotification = null;
    }
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }


  private async fetchUserSentContactsRequests(silenceRefresh: boolean = false): Promise<void> {
    if (!silenceRefresh) {
      this.loadingContacts = true;
    }
    if (!this.userProfile?.documentUserCSS.idUser) {
      return;
    }

    const connectionsRequests = await firstValueFrom(this.eventsService.getSentConnectionRequests(this.userProfile?.documentUserCSS.idUser));

    if (!connectionsRequests.body) {
      this.loadingConnections = false;
      return;
    }

    this.sentConnectionRequests = connectionsRequests.body;
    this.loadingContacts = false;
  }

  private async fetchUserPendingContactsRequests(silenceRefresh: boolean = false): Promise<void> {
    if (!silenceRefresh) {
      this.loadingContacts = true;
    }
    if (!this.userProfile?.documentUserCSS.idUser) {
      return;
    }

    const connectionsRequests = await firstValueFrom(this.eventsService.getPendingConnectionRequests(this.userProfile?.documentUserCSS.idUser));

    if (!connectionsRequests.body) {
      this.loadingConnections = false;
      return;
    }

    this.pendingConnectionRequests = connectionsRequests.body;
    this.loadingContacts = false;
  }

  private async fetchUserContacts({ page, size }: { page: number; size: number }, silenceRefresh: boolean = false): Promise<void> {
    if (!silenceRefresh) {
      this.loadingContacts = true;
    }

    const contactsResponse = await firstValueFrom(this.userProfileService.getSentContacts({
      idUser: this.userProfile?.documentUserProfile.idUser || '',
      page,
      size,
    }));

    this.sentContacts = contactsResponse.body.leads;
    this.loadingContacts = false;
  }

  private async fetchUserConnections(silenceRefresh: boolean = false) {

    if (!this.userProfile?.documentUserCSS.idUser) {
      return;
    }
    if (!silenceRefresh) {
      this.loadingContacts = true;
    }

    const connectionsResponse = await firstValueFrom(this.eventsService.getUserConnections(this.userProfile?.documentUserCSS.idUser));

    if (!connectionsResponse.body) {
      this.loadingConnections = false;
      return;
    }

    this.userConnections = connectionsResponse.body.filter(connection => !connection.indication);
    this.userIndicationConnections = connectionsResponse.body.filter(connection => connection.indication && connection.indication === true);

    this.loadingConnections = false;

    this.userConnectionsHistory = Object.assign([], this.userConnections);
    this.userIndicationsHistory = Object.assign([], this.userIndicationConnections);
  }

  private async fetchUserPublic(silenceRefresh: boolean = false) {
    if (!silenceRefresh) {
      this.loadingContacts = true;
    }
    try {
      const publicResponse = await firstValueFrom(this.eventsService.getUserPublic());
      if (!publicResponse.body) {
        this.loadingConnections = false;
        return;
      }

      const myId = this.userProfile?.documentUser.idUser;
      let filteredBody = publicResponse.body.filter(c => c.connection.idUser !== myId);
      if (this.userConnectionsHistory && this.userConnectionsHistory.length > 0) {
        const userConnectionIds = this.userConnectionsHistory.map(connection => connection.connection.idUser);
        filteredBody = filteredBody.filter(connection => !userConnectionIds.includes(connection.connection.idUser));
      }

      if (this.userIndicationsHistory && this.userIndicationsHistory.length > 0) {
        const indicationConnectionIds = this.userIndicationsHistory.map(connection => connection.connection.idUser);
        filteredBody = filteredBody.filter(connection => !indicationConnectionIds.includes(connection.connection.idUser));
      }

      if (this.sentConnectionRequests && this.sentConnectionRequests.length > 0) {
        const sentConnectionRequestIds = this.sentConnectionRequests.map(connection => connection.connection.idUser);
        filteredBody = filteredBody.filter(connection => !sentConnectionRequestIds.includes(connection.connection.idUser));
      }

      this.userPublicConnections = filteredBody;

      this.loadingConnections = false;

      this.userPublicHistory = Object.assign([], this.userPublicConnections);
    } catch (error) {
      console.error("Erro inesperado durante a consulta por contatos públicos.", error);
      this.loadingConnections = false;
      return;
    }
  }

  openPlanExceededModal() {
    const modalRef = this.modalService.open(ExceedPlanResourcesModalComponent, {
      fullscreen: false,
      centered: true,
    });

    modalRef.componentInstance.descriptionMessage = "Você atingiu o limite máximo permitido pelo seu plano atual em termos de leads, contatos ou conexões. Atualize agora seu plano para ter acesso a todos os seus leads."
    modalRef.componentInstance.callToAction = "Saiba quem viu seu perfil! Atualize agora e mantenha seu perfil completo."
  }

  changeSelectedTab(tab: number) {
    this.selectedTab = tab;
    if (tab === 1) {
      this.fetchUserSentContactsRequests();
    }
    if (tab === 3 && (!this.userPublicHistory || this.userPublicHistory.length < 1)) {
      this.fetchUserPublic();
    }
  }

  private async getImageBase64(imageUrl: string): Promise<string | null> {
    try {
      const response = await fetch(imageUrl);

      if (!response.ok) {
        return null;
      }

      const imageBlob = await response.blob();

      return await new Promise<string | null>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(imageBlob);
      });
    } catch (error) {
      return null;
    }
  }

  generateVcardConnections(event: Event, profile: IDocumentUserProfile) {
    event.stopPropagation();
    if (!profile.idUser) {
      return;
    }

    try {
      this.userProfileService.getImageProfile(profile.idUser).
        pipe(
          filter((mayBeOk: HttpResponse<IImageResponse>) => mayBeOk.ok),
          map((response: HttpResponse<IImageResponse>) => response.body))
        .subscribe({
          next: (res) => {
            this.montaVCard(profile, res?.base64Image);
          },
          error: (err) => {
            console.error("Erro ao buscar imagem profile", err)
            this.montaVCard(profile);
          }
        });
    } catch (error) {
      this.montaVCard(profile);
    }
  }

  montaVCard(profile: IDocumentUserProfile, profileImageBase64?: string) {
    let vcardContent = `BEGIN:VCARD
VERSION:3.0
FN:${profile.name}
URL;CHARSET=UTF-8:https://sluper.bio/${profile.idUser}`;

    // const profileImageBase64 = await this.getImageBase64(profile.uriImageProfile);

    if (profileImageBase64) {
      vcardContent += `\nPHOTO;TYPE=JPEG;ENCODING=b:${profileImageBase64}`;
    }

    const contactTypePrefixes = {
      'telefone': 'TEL;TYPE=WORK,VOICE:',
      'email': 'EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:',
      'localizacao': 'ADR;CHARSET=UTF-8;TYPE=HOME:'
    }

    if (profile.listContacts) {
      profile.listContacts.forEach((contact) => {
        vcardContent += `\n${contactTypePrefixes[contact.type as 'telefone' | 'email' | 'localizacao']}${contact.value}`;
      });
    }

    if (profile.listSocialNetwork) {
      profile.listSocialNetwork.forEach((social) => {
        if (social.link) {
          vcardContent += `\nURL;CHARSET=UTF-8:${social.link}`;
        }
      });
    }

    vcardContent += '\nEND:VCARD';

    const blob = new Blob([vcardContent], { type: 'text/vcard' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'contatoSluper.vcf');
    link.click();
    window.URL.revokeObjectURL(url);
  }

  async openConnectionProfile(connection: IConnection) {
    window.open(`https://sluper.bio/${connection.connection.idUser}`);
  }

  generateVcardContact(contact: IContact) {
    if (contact.hidden) {
      return;
    }

    let vcardContent = `BEGIN:VCARD
VERSION:3.0
FN:${contact.contactName}`;

    if (contact.contactEmail) {
      vcardContent += `\nORG;TYPE=WORK,VOICE:${contact.contactCompany}`;
    }

    if (contact.contactEmail) {
      vcardContent += `\nTEL;TYPE=WORK,VOICE:${contact.contactPhone}`;
    }

    if (contact.contactEmail) {
      vcardContent += `\nEMAIL;CHARSET=UTF-8;type=HOME,INTERNET:${contact.contactEmail}`;
    }

    vcardContent += '\nEND:VCARD';

    const blob = new Blob([vcardContent], { type: 'text/vcard' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'contatoSluper.vcf');
    link.click();
    window.URL.revokeObjectURL(url);
  }

  openIndicateModal(event: Event, connection: IDocumentUserProfile) {
    event.stopPropagation();
    const modalRef = this.modalService.open(IndicateResourcesModalComponent, {
      fullscreen: false,
      centered: true,
    });

    modalRef.componentInstance.idUser = this.userProfile?.documentUser.idUser;
    modalRef.componentInstance.idUserIndication = connection.idUser;
    modalRef.componentInstance.nameUserIndication = connection.name;
    modalRef.componentInstance.connections = this.userConnections
      .filter(c => c.connection.idUser !== connection.idUser && c.connection.name)
      .sort((a, b) => a.connection.name.localeCompare(b.connection.name));

    modalRef.result.then((result) => {
      if (result) {
        this._alert.next('Indicação realizada com sucesso!');
        this.alertType = 'success';
      }
    });
  }

  async handleConnect(event: Event, userId: string, connection: IDocumentUserProfile) {
    event.stopPropagation();

    if (!this.userProfile?.documentUserProfile.idUser) {
      return;
    }

    try {
      await firstValueFrom(this.eventsService.connectUser({
        participantId: userId,
        userId: this.userProfile.documentUserProfile.idUser,
        eventId: null
      }));

      this._alert.next('Você agora está conectado com este usuário!');
      this.alertType = 'success';

      this.userConnections.push({
        connectionDate: new Date().toISOString(),
        event: null,
        connection: connection,
      });
      this.userConnectionsHistory = Object.assign([], this.userConnections);
      this.userIndicationConnections = this.userIndicationConnections.filter(x => x.connection.idUser !== userId);
      this.userConnectionsHistory = Object.assign([], this.userIndicationConnections);
    } catch (error: any) {
      this._alert.next(error?.message || 'Ocorreu um erro ao tentar conectar com este usuário. Tente novamente mais tarde.');
      this.alertType = 'danger';
    }
  }

  async handleConnectPublic(event: Event, connection: IDocumentUserProfile) {
    event.stopPropagation();

    if (!this.userProfile?.documentUserProfile.idUser) {
      return;
    }


    try {

      if (connection.autoApprove) {
        await firstValueFrom(this.eventsService.connectUser({
          participantId: connection.idUser,
          userId: this.userProfile.documentUserProfile.idUser,
          eventId: null
        }));

        this._alert.next('Você agora está conectado com este usuário!');
        this.alertType = 'success';

        this.userConnectionsHistory.push({
          connectionDate: new Date().toISOString(),
          event: null,
          connection: connection,
        });
        this.userConnections = Object.assign([], this.userConnectionsHistory);
        this.userIndicationConnections = this.userIndicationConnections.filter(x => x.connection.idUser !== connection.idUser);
        this.userIndicationsHistory = Object.assign([], this.userIndicationConnections);
        this.userPublicConnections = this.userPublicConnections.filter(x => x.connection.idUser !== connection.idUser);
        this.userPublicHistory = Object.assign([], this.userPublicConnections);
      } else {
        await firstValueFrom(this.eventsService.requestConnection({
          participantId: connection.idUser,
          userId: this.userProfile.documentUserProfile.idUser,
          eventId: null
        }));

        this._alert.next('Solicitação de conexão enviada com sucesso!');
        this.alertType = 'success';

        this.userConnectionsHistory.push({
          connectionDate: new Date().toISOString(),
          event: null,
          connection: connection,
        });

        this.connectionRequests = Object.assign([], this.userConnectionsHistory);
        this.userPublicConnections = this.userPublicConnections.filter(x => x.connection.idUser !== connection.idUser);
      }


    } catch (error: any) {
      this._alert.next(error?.message || 'Ocorreu um erro ao tentar conectar com este usuário. Tente novamente mais tarde.');
      this.alertType = 'danger';
    }
  }

  async handleRequestConnect(event: Event, connection: IDocumentUserProfile) {
    event.stopPropagation();

    if (!this.userProfile?.documentUserProfile.idUser) {
      return;
    }

    try {
      await firstValueFrom(this.eventsService.connectUser({
        participantId: connection.idUser,
        userId: this.userProfile.documentUserProfile.idUser,
        eventId: null
      }));

      this._alert.next('Você agora está conectado com este usuário!');
      this.alertType = 'success';

      this.userConnectionsHistory.push({
        connectionDate: new Date().toISOString(),
        event: null,
        connection: connection,
      });
      this.userConnections = Object.assign([], this.userConnectionsHistory);
      this.userIndicationConnections = this.userIndicationConnections.filter(x => x.connection.idUser !== connection.idUser);
      this.userIndicationsHistory = Object.assign([], this.userIndicationConnections);
      this.userPublicConnections = this.userPublicConnections.filter(x => x.connection.idUser !== connection.idUser);
      this.userPublicHistory = Object.assign([], this.userPublicConnections);
    } catch (error: any) {
      this._alert.next(error?.message || 'Ocorreu um erro ao tentar conectar com este usuário. Tente novamente mais tarde.');
      this.alertType = 'danger';
    }
  }

  getBackground(connection: any) {
    return connection.connection.uriImageBackground ? connection.connection.uriImageBackground : this.defaultBackgroundImage;
  }

  onSelectChange() {
    if (this.selectedPreferences.length > 0 || this.connectionName != '' || this.selectedOccupations.length > 0) {
      this.filterUserConnections();
      this.filterUserIndications();
    } else {
      this.userConnections = this.userConnectionsHistory;
      this.userIndicationConnections = this.userIndicationsHistory;
    }
  }

  onSelectChangePublic() {
    if (this.selectedPreferencesPublic.length > 0 || this.connectionNamePublic != '' || this.selectedOccupationsPublic.length > 0) {
      this.filterUserPublic();
    } else {
      this.userPublicConnections = this.userPublicHistory;
    }
  }

  clearName() {
    this.connectionName = '';
    this.onSelectChange();
  }

  filterUserConnections() {
    if (this.selectedPreferences.length > 0 && this.connectionName != '' && this.selectedOccupations.length > 0) {
      // filtra por interesse && nome && area de atuacao
      this.userConnections = this.userConnectionsHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedPreferences.length > 0 && this.checkUserPreferences(c.connection, 'INTERESSE', this.selectedPreferences)) &&
          (this.selectedOccupations.length > 0 && this.checkUserPreferences(c.connection, 'AREA_ATUACAO', this.selectedOccupations)) &&
          (this.connectionName != '' && c.connection.name.toLowerCase().includes(this.connectionName.toLowerCase()))));
    } else if (this.selectedPreferences.length > 0 && this.connectionName != '' && this.selectedOccupations.length == 0) {
      // filtra por interesse && nome
      this.userConnections = this.userConnectionsHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedPreferences.length > 0 && this.checkUserPreferences(c.connection, 'INTERESSE', this.selectedPreferences)) &&
          (this.connectionName != '' && c.connection.name.toLowerCase().includes(this.connectionName.toLowerCase()))));
    } else if (this.selectedPreferences.length == 0 && this.connectionName != '' && this.selectedOccupations.length > 0) {
      // filtra por nome && area de atuacao
      this.userConnections = this.userConnectionsHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedOccupations.length > 0 && this.checkUserPreferences(c.connection, 'AREA_ATUACAO', this.selectedOccupations)) &&
          (this.connectionName != '' && c.connection.name.toLowerCase().includes(this.connectionName.toLowerCase()))));
    } else if (this.selectedPreferences.length > 0 && this.connectionName == '' && this.selectedOccupations.length > 0) {
      // filtra por interesse && area de atuacao
      this.userConnections = this.userConnectionsHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedPreferences.length > 0 && this.checkUserPreferences(c.connection, 'INTERESSE', this.selectedPreferences)) &&
          (this.selectedOccupations.length > 0 && this.checkUserPreferences(c.connection, 'AREA_ATUACAO', this.selectedOccupations))));
    } else {
      // filtra por qualquer que for preenchido
      this.userConnections = this.userConnectionsHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedPreferences.length > 0 && this.checkUserPreferences(c.connection, 'INTERESSE', this.selectedPreferences)) ||
          (this.selectedOccupations.length > 0 && this.checkUserPreferences(c.connection, 'AREA_ATUACAO', this.selectedOccupations)) ||
          (this.connectionName != '' && c.connection.name.toLowerCase().includes(this.connectionName.toLowerCase()))));
    }
  }

  filterUserIndications() {
    if (this.selectedPreferences.length > 0 && this.connectionName != '' && this.selectedOccupations.length > 0) {
      // filtra por interesse && nome && area de atuacao
      this.userIndicationConnections = this.userIndicationsHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedPreferences.length > 0 && this.checkUserPreferences(c.connection, 'INTERESSE', this.selectedPreferences)) &&
          (this.selectedOccupations.length > 0 && this.checkUserPreferences(c.connection, 'AREA_ATUACAO', this.selectedOccupations)) &&
          (this.connectionName != '' && c.connection.name.toLowerCase().includes(this.connectionName.toLowerCase()))));
    } else if (this.selectedPreferences.length > 0 && this.connectionName != '' && this.selectedOccupations.length == 0) {
      // filtra por interesse && nome
      this.userIndicationConnections = this.userIndicationsHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedPreferences.length > 0 && this.checkUserPreferences(c.connection, 'INTERESSE', this.selectedPreferences)) &&
          (this.connectionName != '' && c.connection.name.toLowerCase().includes(this.connectionName.toLowerCase()))));
    } else if (this.selectedPreferences.length == 0 && this.connectionName != '' && this.selectedOccupations.length > 0) {
      // filtra por nome && area de atuacao
      this.userIndicationConnections = this.userIndicationsHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedOccupations.length > 0 && this.checkUserPreferences(c.connection, 'AREA_ATUACAO', this.selectedOccupations)) &&
          (this.connectionName != '' && c.connection.name.toLowerCase().includes(this.connectionName.toLowerCase()))));
    } else if (this.selectedPreferences.length > 0 && this.connectionName == '' && this.selectedOccupations.length > 0) {
      // filtra por interesse && area de atuacao
      this.userIndicationConnections = this.userIndicationsHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedPreferences.length > 0 && this.checkUserPreferences(c.connection, 'INTERESSE', this.selectedPreferences)) &&
          (this.selectedOccupations.length > 0 && this.checkUserPreferences(c.connection, 'AREA_ATUACAO', this.selectedOccupations))));
    } else {
      // filtra por qualquer que for preenchido
      this.userIndicationConnections = this.userIndicationsHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedPreferences.length > 0 && this.checkUserPreferences(c.connection, 'INTERESSE', this.selectedPreferences)) ||
          (this.selectedOccupations.length > 0 && this.checkUserPreferences(c.connection, 'AREA_ATUACAO', this.selectedOccupations)) ||
          (this.connectionName != '' && c.connection.name.toLowerCase().includes(this.connectionName.toLowerCase()))));
    }
  }

  filterUserPublic() {
    if (this.selectedPreferencesPublic.length > 0 && this.connectionNamePublic != '' && this.selectedOccupationsPublic.length > 0) {
      // filtra por interesse && nome && area de atuacao
      this.userPublicConnections = this.userPublicHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedPreferencesPublic.length > 0 && this.checkUserPreferences(c.connection, 'INTERESSE', this.selectedPreferencesPublic)) &&
          (this.selectedOccupationsPublic.length > 0 && this.checkUserPreferences(c.connection, 'AREA_ATUACAO', this.selectedOccupationsPublic)) &&
          (this.connectionNamePublic != '' && c.connection.name.toLowerCase().includes(this.connectionNamePublic.toLowerCase()))));
    } else if (this.selectedPreferencesPublic.length > 0 && this.connectionNamePublic != '' && this.selectedOccupationsPublic.length == 0) {
      // filtra por interesse && nome
      this.userPublicConnections = this.userPublicHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedPreferencesPublic.length > 0 && this.checkUserPreferences(c.connection, 'INTERESSE', this.selectedPreferencesPublic)) &&
          (this.connectionNamePublic != '' && c.connection.name.toLowerCase().includes(this.connectionNamePublic.toLowerCase()))));
    } else if (this.selectedPreferencesPublic.length == 0 && this.connectionNamePublic != '' && this.selectedOccupationsPublic.length > 0) {
      // filtra por nome && area de atuacao
      this.userPublicConnections = this.userPublicHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedOccupationsPublic.length > 0 && this.checkUserPreferences(c.connection, 'AREA_ATUACAO', this.selectedOccupationsPublic)) &&
          (this.connectionNamePublic != '' && c.connection.name.toLowerCase().includes(this.connectionNamePublic.toLowerCase()))));
    } else if (this.selectedPreferencesPublic.length > 0 && this.connectionNamePublic == '' && this.selectedOccupationsPublic.length > 0) {
      // filtra por interesse && area de atuacao
      this.userPublicConnections = this.userPublicHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedPreferencesPublic.length > 0 && this.checkUserPreferences(c.connection, 'INTERESSE', this.selectedPreferencesPublic)) &&
          (this.selectedOccupationsPublic.length > 0 && this.checkUserPreferences(c.connection, 'AREA_ATUACAO', this.selectedOccupationsPublic))));
    } else {
      // filtra por qualquer que for preenchido
      this.userPublicConnections = this.userPublicHistory.filter(c =>
        c.connection.preferences && c.connection.name &&
        c.connection.idUser != this.userProfile?.documentUserProfile.idUser &&
        ((this.selectedPreferencesPublic.length > 0 && this.checkUserPreferences(c.connection, 'INTERESSE', this.selectedPreferencesPublic)) ||
          (this.selectedOccupationsPublic.length > 0 && this.checkUserPreferences(c.connection, 'AREA_ATUACAO', this.selectedOccupationsPublic)) ||
          (this.connectionNamePublic != '' && c.connection.name.toLowerCase().includes(this.connectionNamePublic.toLowerCase()))));
    }
  }

  checkUserPreferences(connection: IDocumentUserProfileWithPreferences, type: string, selectedList: number[]): boolean {
    let result = false;
    const filtredList = connection.preferences?.filter(pref => pref.type == type);
    if (filtredList) {
      filtredList.forEach(p => {
        if (selectedList.includes(p.id)) {
          result = true;
        }
      });
    }

    return result;
  }

  checkUserPreferencesPublic(connection: IDocumentUserProfileWithPreferences): boolean {
    let result = false;
    connection.preferences?.forEach(p => {
      if (this.selectedPreferencesPublic.includes(p.id)) {
        result = true;
      }
    });

    return result;
  }

  checkConnectionInterests(event: Event, connection: IDocumentUserProfileWithPreferences) {
    event.stopPropagation();
    const modalRef = this.modalService.open(CheckPreferencesModalComponent, {
      fullscreen: false,
      centered: true,
    });

    modalRef.componentInstance.connectionName = connection.name;
    modalRef.componentInstance.preferences = connection.preferences;
  }

  checkConnectionPresentation(event: Event, connection: IDocumentUserProfileWithPreferences) {
    event.stopPropagation();
    const modalRef = this.modalService.open(UserPresentationModalComponent, { fullscreen: false, centered: true });

    modalRef.componentInstance.userPresentation = connection.presentation && connection.presentation.length ?
      connection.presentation : 'O usuário ainda nao possui apresentação.';
  }

  downloadContatoCSV() {
    if (this.sentContacts) {
      const listContact = this.sentContacts.filter(c => !c.hidden).map(contact => {
        return {
          Name: contact.contactName,
          Empresa: contact.contactCompany,
          Email: contact.contactEmail,
          Telefone: contact.contactPhone
        };
      });
      new ngxCsv(listContact, 'ContatosRecebidos', this.csvContatosRecebidosOptions);
    }
  }

  downloadConexoeCSV() {
    if (this.userConnections) {
      const allContactTypes = new Set<string>();
      const allSocialTypes = new Set<string>();

      this.userConnections.forEach(connection => {
        const contactTypesCount: { [key: string]: number } = {};
        const socialTypesCount: { [key: string]: number } = {};

        connection.connection.listContacts.forEach(contact => {
          if (contact.type in contactTypesCount) {
            contactTypesCount[contact.type]++;
          } else {
            contactTypesCount[contact.type] = 1;
          }
        });

        connection.connection.listContacts.forEach(contact => {
          if (contactTypesCount[contact.type] > 1) {
            for (let i = 0; i <= contactTypesCount[contact.type]; i++) {
              if (i === 0) {
                allContactTypes.add(contact.type);
              } else {
                allContactTypes.add(contact.type + contactTypesCount[contact.type]);
              }
            }
          } else {
            allContactTypes.add(contact.type);
          }
        });

        // Contagem de tipos de contatos
        connection.connection.listSocialNetwork.forEach(social => {
          if (social.type in socialTypesCount) {
            socialTypesCount[social.type]++;
          } else {
            socialTypesCount[social.type] = 1;
          }
        });

        // Adicionando os tipos de contatos ao conjunto
        connection.connection.listSocialNetwork.forEach(social => {
          if (socialTypesCount[social.type] > 1) {
            for (let i = 0; i <= socialTypesCount[social.type]; i++) {
              if (i === 0) {
                allSocialTypes.add(social.type);
              } else {
                allSocialTypes.add(social.type + socialTypesCount[social.type]);
              }
            }
          } else {
            allSocialTypes.add(social.type);
          }
        });

      });

      // Converter os conjuntos para arrays e combinar os cabeçalhos
      const contactTypesArray = Array.from(allContactTypes).sort();
      const socialTypesArray = Array.from(allSocialTypes).sort();
      const dynamicHeaders = ['nome', ...contactTypesArray, ...socialTypesArray];

      const listConnections = this.userConnections.map(connection => {
        const { listContacts, listSocialNetwork } = connection.connection;

        // Criar um objeto para armazenar os dados de cada conexão
        const connectionData: { [key: string]: string } = {
          nome: connection.connection.name
        };

        // Preencher os dados de contatos
        contactTypesArray.forEach(type => {
          const contacts = listContacts.filter(contact => contact.type === type);
          let typeFilled = false; // Variável para controlar se o tipo sem sufixo já foi preenchido
          contacts.forEach((c, i) => {
            if (i > 0) {
              const typeMod = type.concat(String(i + 1));
              connectionData[typeMod] = c ? c.value : '';
              typeFilled = true;
            } else {
              connectionData[type] = c ? c.value : '';
              typeFilled = true; // Define que o tipo sem sufixo foi preenchido
            }
          });
          // Caso o tipo sem sufixo não tenha sido preenchido dentro do loop, defina como vazio
          if (!typeFilled && !connectionData[type]) {
            connectionData[type] = '';
          }
        });

        // Preencher os dados de contatos
        socialTypesArray.forEach(type => {
          const socials = listSocialNetwork.filter(social => social.type === type);
          let typeFilled = false; // Variável para controlar se o tipo sem sufixo já foi preenchido
          socials.forEach((s, i) => {
            if (i > 0) {
              const typeMod = type.concat(String(i + 1));
              connectionData[typeMod] = s ? s.link : '';
              typeFilled = true;
            } else {
              connectionData[type] = s ? s.link : '';
              typeFilled = true; // Define que o tipo sem sufixo foi preenchido
            }
          });
          // Caso o tipo sem sufixo não tenha sido preenchido dentro do loop, defina como vazio
          if (!typeFilled && !connectionData[type]) {
            connectionData[type] = '';
          }
        });

        return connectionData;
      });

      // Configurar os cabeçalhos dinâmicos no options
      const csvOptions = {
        ...this.csvConexoesOptions,
        headers: dynamicHeaders
      };

      new ngxCsv(listConnections, 'Conexões', csvOptions);
    }
  }

  removeConnection(event: Event, connection: IDocumentUserProfileWithPreferences) {
    event.stopPropagation();
    const modalRef = this.modalService.open(RemoveConnectionModalComponent, { fullscreen: false, centered: true });

    modalRef.componentInstance.idUser = this.userProfile?.documentUserProfile.idUser;
    modalRef.componentInstance.idConnection = connection.idUser;
    modalRef.componentInstance.nameConnection = connection.name;

    modalRef.result.then(async (result) => {
      if (result) {
        await this.fetchUserConnections();
        this._alert.next('Remoção realizada com sucesso.');
        this.alertType = 'success';
      }
    });
  }

  cancelConnectionRequest(event: Event, connection: IDocumentUserProfileWithPreferences) {
    event.stopPropagation();
    const modalRef = this.modalService.open(CancelConnectionRequestComponent, { fullscreen: false, centered: true });

    modalRef.componentInstance.idUser = this.userProfile?.documentUserProfile.idUser;
    modalRef.componentInstance.idConnection = connection.idUser;
    modalRef.componentInstance.nameConnection = connection.name;

    modalRef.result.then(async (result) => {
      if (result) {
        await this.fetchUserConnections();
        await this.fetchUserSentContactsRequests();
        await this.fetchUserPendingContactsRequests();
        this._alert.next('Remoção realizada com sucesso.');
        this.alertType = 'success';
      }
    });
  }

  rejectConnectionRequest(event: Event, connection: IDocumentUserProfileWithPreferences) {
    event.stopPropagation();
    const modalRef = this.modalService.open(RejectConnectionRequestComponent, { fullscreen: false, centered: true });

    modalRef.componentInstance.idUser = this.userProfile?.documentUserProfile.idUser;
    modalRef.componentInstance.idConnection = connection.idUser;
    modalRef.componentInstance.nameConnection = connection.name;

    modalRef.result.then(async (result) => {
      if (result) {
        await this.fetchUserConnections();
        await this.fetchUserSentContactsRequests();
        await this.fetchUserPendingContactsRequests();
        this._alert.next('Conexão rejeitada com sucesso.');
        this.alertType = 'success';
      }
    });
  }

  acceptConnectionRequest(event: Event, connection: IDocumentUserProfileWithPreferences) {
    event.stopPropagation();
    const modalRef = this.modalService.open(AcceptConnectionRequestComponent, { fullscreen: false, centered: true });

    modalRef.componentInstance.idUser = this.userProfile?.documentUserProfile.idUser;
    modalRef.componentInstance.idConnection = connection.idUser;
    modalRef.componentInstance.nameConnection = connection.name;

    modalRef.result.then(async (result) => {
      if (result) {
        await this.fetchUserConnections();
        await this.fetchUserSentContactsRequests();
        await this.fetchUserPendingContactsRequests();
        this._alert.next('Conexão aceita com sucesso.');
        this.alertType = 'success';
      }
    });
  }
}

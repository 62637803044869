import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, Subject } from 'rxjs';
import { AcceptConnectionRequestComponent } from '../contacts-and-connections/accept-connection-request/accept-connection-request.component';
import { RejectConnectionRequestComponent } from '../contacts-and-connections/reject-connection-request/reject-connection-request.component';
import { IConnection } from '../model/connection.model';
import { IDocumentUserProfileWithPreferences } from '../model/document-user-profile.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { ToastService } from '../new-sluper/core/service/toast.service';
import { NotificationService } from './notification.service';

@Component({
  selector: 'app-bell-notification',
  templateUrl: './bell-notification.component.html',
  styleUrls: ['./bell-notification.component.css']
})
export class BellNotificationComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  @Input() set receivedRequests(value: IConnection[]) {
    this._receivedRequests$.next(value);
  }

  @Input() notifications: { type: string, item: IConnection | any }[] = [];
  @Input() userProfile: IUserProfileWithCSS | null = null;
  @Output() refreshPendingRequests = new EventEmitter();
  private _receivedRequests$ = new ReplaySubject<IConnection[]>(1);

  public loading: boolean = false;
  private _alert = new Subject<string>();
  alertMessage = '';
  alertType = ''; constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private toastrService: ToastService
  ) { }
  ngOnInit(): void {
    this._receivedRequests$.subscribe((requests) => {
      this.notifications = [];
      requests.forEach((request) => {
        this.notifications.push({
          type: 'connection',
          item: request
        });

      });
    });


  }


  rejectConnectionRequest(event: Event, connection: IDocumentUserProfileWithPreferences) {
    event.stopPropagation();

    const modalRef = this.modalService.open(RejectConnectionRequestComponent, { fullscreen: false, centered: true });

    modalRef.componentInstance.idUser = this.userProfile?.documentUserProfile.idUser;
    modalRef.componentInstance.idConnection = connection.idUser;
    modalRef.componentInstance.nameConnection = connection.name;

    modalRef.result.then(async (result) => {
      if (result) {
        this.sendNotification();
        this.refreshPendingRequests.emit();
        this.toastrService.show(
          'Conexão rejeitada com sucesso.',
          'warning',
        )
      }
    });
  }

  acceptConnectionRequest(event: Event, connection: IDocumentUserProfileWithPreferences) {
    event.stopPropagation();
    const modalRef = this.modalService.open(AcceptConnectionRequestComponent, { fullscreen: false, centered: true });

    modalRef.componentInstance.idUser = this.userProfile?.documentUserProfile.idUser;
    modalRef.componentInstance.idConnection = connection.idUser;
    modalRef.componentInstance.nameConnection = connection.name;
    modalRef.componentInstance.idEvent = '';
    modalRef.componentInstance.type = 'connect';

    modalRef.result.then(async (result) => {
      if (result) {
        this.sendNotification();
        this.refreshPendingRequests.emit();
        this.toastrService.show(
          'Conexão aceita com sucesso.',
          'success',
        )
        this._alert.next('Conexão aceita com sucesso.');
        this.alertType = 'success';
      }
    });
  }

  sendNotification() {
    const data = { message: 'Dados atualizados!', timestamp: new Date() };
    this.notificationService.notify(data);
  }
}

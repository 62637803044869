<div class="other-events-card">
    <div class="image-container">
        <img *ngIf="isSluperEvent(event) ? event?.imageLink : event?.event?.imageLink"
            [src]="isSluperEvent(event) ? event?.imageLink : event?.event?.imageLink"
            [alt]="isSluperEvent(event) ? event?.name : event?.event?.name">

        <img *ngIf="isSluperEvent(event) ? !event?.imageLink : !event?.event?.imageLink" [src]="defaultCallingCardImage"
            [alt]="isSluperEvent(event) ? event?.name : event?.event?.name">
        <span class="event-category-title">{{ isSluperEvent(event) ? event?.type : event?.event?.type }}</span>
        <span class="event-category-date">{{ getFormattedDateRange() }}</span>
        <span class="event-category-time">{{ getFormattedTimeRange() }}</span>
    </div>

    <!-- Exibe o título do evento -->
    <h4 class="event-title multi-line">{{ isSluperEvent(event) ? event?.name : event?.event?.name }}</h4>
    <div class="line"></div>

    <!-- Exibe a descrição do evento -->
    <span class="event-description multi-line" [innerHTML]="sanitizedContentDescription        "></span>

    <!-- Botão de participação com as verificações para os dois tipos de evento -->
    <button (click)="joinEvent()" [ngClass]="{
          'event-button join': participationType === 1,
          'event-button joining': participationType === 2,
          'event-button waiting': participationType === 3
        }">
        {{
        participationType === 2 ? 'Participando' :
        participationType === 3 ? 'Aguardando Aprovação' :
        'Visualizar evento'
        }}
    </button>

    <button *ngIf="isIEventWithParticipationStatus(event)" (click)="joinEvent()" [ngClass]="{
          'event-button join': event?.participationStatus === 'NAO_PARTICIPANDO',
          'event-button joining': event?.participationStatus === 'PARTICIPANDO',
          'event-button waiting': event?.participationStatus === 'PENDENTE_APROVACAO'
        }">
        {{
        event?.participationStatus === 'PARTICIPANDO' ? 'Participando' :
        event?.participationStatus === 'PENDENTE_APROVACAO' ? 'Aguardando Aprovação' :
        'Participar'
        }}
    </button>
</div>
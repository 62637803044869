import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SluperEvent } from 'src/app/model/event.model';

@Component({
  selector: 'app-modal-show-rules',
  templateUrl: './modal-show-rules.component.html',
  styleUrls: ['./modal-show-rules.component.css']
})
export class ModalShowRulesComponent implements OnInit {
  @Input() communityItem!: SluperEvent;
  public sanitizedContentRules: SafeHtml = ''


  constructor(
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.sanitizedContentRules = this.sanitizer.bypassSecurityTrustHtml(this.communityItem.about ? this.communityItem.about : 'Não há políticas para esta comunidade.');

  }


  closeModal() {
    this.activeModal.close();
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/new-sluper/core/service/loading.service';
import { OpenedCommunityService } from '../opened-community.service';

@Component({
  selector: 'app-post-routing',
  templateUrl: './post-routing.component.html',
  styleUrls: ['./post-routing.component.css']
})
export class PostRoutingComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private openedCommunityService: OpenedCommunityService,
    private router: Router

  ) { }

  ngOnInit(): void {
    //get post
    //get community post
    // set community post
    // redirect to login/comunidade


    this.loadingService.show();
    this.route.params.subscribe(async (params) => {
      const postId = params['parametro'];
      const companyId = params['company'];

      if (companyId) {
        this.openedCommunityService.setCommunity(companyId);
      }
      localStorage.setItem('postId', postId);
      const communityId = this.openedCommunityService.getCommunity();
      if (communityId) {
        this.router.navigate([`/comunidade/${communityId}`]);
      } else {
        this.router.navigate([`/comunidade`]);
      }
      this.loadingService.hide();
    });
  }
}

import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, firstValueFrom, map } from 'rxjs';
import { AppNavigateService } from 'src/app/app-navigate.service';
import { DocumentUserCSS } from 'src/app/model/document-user-css.model';
import { AlterarCorFundoModalComponent } from '../../core/components/alterar-cor-fundo-modal/alterar-cor-fundo-modal.component';
import { UserProfileWithCSS } from '../../core/model/user-profile-css.model';
import { LoadingService } from '../../core/service/loading.service';
import { ToastService } from '../../core/service/toast.service';
import { UserProfileService } from '../../core/service/user-profile.service';
import { CadastroStorageService } from '../../public/cadastro/cadastro-storage.service';

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.css']
})
export class PerfilUsuarioComponent implements OnInit {

  profileImage: string = '../../../../assets/images/imagem_perfil.png';
  backgroundImage: string = '../../../../assets/images/imagem_fundo.png';
  backgroundFile!: File | undefined;
  profileImageFile!: File | undefined;

  isToastVisible: boolean = false;

  biografia = new FormControl('', [Validators.required]);
  nomeExibicao = new FormControl('', [Validators.required]);
  titulo = new FormControl('', [Validators.required]);
  urlProfile = new FormControl('', [Validators.required, Validators.maxLength(255), Validators.minLength(3), Validators.pattern(/^\S*$/), Validators.pattern(/^[a-z0-9_.]+$/)]);

  characterCount: number = 0;

  userProfile: UserProfileWithCSS = new UserProfileWithCSS();

  constructor(
    private appNavigateService: AppNavigateService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private userProfileService: UserProfileService,
    private cadastroStorage: CadastroStorageService,
    private modalService: NgbModal,

  ) { }

  ngOnInit(): void {
    this.autoCompleteUrl();
    this.getUserById();
  }

  autoCompleteUrl() {
    this.nomeExibicao.valueChanges.subscribe((nome: string | null) => {
      if (nome && nome.trim().length) {
        const url = nome
          .trim()
          .toLocaleLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[^a-z0-9]/g, '')
          .replace(/\s+/g, '');
        this.urlProfile.setValue(url, { emitEvent: false });
      } else {
        this.urlProfile.setValue('', { emitEvent: false });
      }
    });
  }


  autoCompleteBlur() {
    if (this.nomeExibicao.value === '') return
    this.validateLink();
  }

  getUserById() {
    const userId = this.cadastroStorage.license;

    this.userProfileService.userById(userId).subscribe({
      next: (response) => {
        const userProfile = response.body; // body contém a resposta JSON
        if (userProfile) {
          this.userProfile = new UserProfileWithCSS();
          Object.assign(this.userProfile, userProfile);
        }
      },
      error: (error) => {
        console.error('Erro ao obter perfil do usuário:', error);
      }
    });
  }

  updateCharacterCount(): void {
    this.characterCount = String(this.biografia.value).length;
  }

  onProfileImageChanged(data: { newImage: string, file?: File }) {
    this.profileImage = data.newImage;
    this.profileImageFile = data.file;
  }

  async salvar() {
    await this.validateLink()
    if (this.nomeExibicao.invalid || this.titulo.invalid || this.biografia.invalid || this.urlProfile.invalid || this.urlProfile.invalid) {
      this.toastService.showError("Insira todos os dados necessários.");
      return;
    }

    await this.salvarDados();
  }

  private async salvarDados() {
    this.loadingService.show();

    let newCallingCardBgUrl: string | undefined = undefined;
    let newProfileImage: string | undefined = undefined;

    try {
      if (this.backgroundFile) {
        newCallingCardBgUrl = await this.uploadProfileImage(this.backgroundFile, 'background_-1', 'Não foi possível salvar a imagem de fundo. Contacte o administrador.');
      }

      if (this.profileImageFile) {
        newProfileImage = await this.uploadProfileImage(this.profileImageFile, 'profile_-1', 'Não foi possível salvar a imagem de perfil. Contacte o administrador.');
      }
      const nome: string = String(this.nomeExibicao.value) ? String(this.nomeExibicao.value) : this.userProfile.documentUserProfile.name;
      this.userProfile.documentUserProfile.listURI = [{ link: String(this.urlProfile.value) }];
      this.userProfile.documentUserProfile.bio = String(this.biografia.value);
      this.userProfile.documentUserProfile.header = { text: String(this.titulo.value), visible: true };
      this.userProfile.documentUserProfile.name = nome;


      if (newProfileImage) {
        this.userProfile.documentUserProfile.uriImageProfile = newProfileImage;
      }

      if (newCallingCardBgUrl) {
        this.userProfile.documentUserProfile.uriImageBackground = newCallingCardBgUrl;
      }

      await firstValueFrom(this.userProfileService.saveDocumentUserProfile(this.userProfile.documentUserProfile));
      await this.saveDefaultUserCSS();
      this.toastService.showSuccess("Perfil atualizado com sucesso.");
      this.appNavigateService.navegarUserProfile();

    } catch (err) {
      this.toastService.showError("Erro ao atualizar perfil do usuário.");
      console.error(err);
    } finally {
      this.loadingService.hide();
    }
  }

  async saveDefaultUserCSS() {
    if (this.userProfile) {
      const newCss: DocumentUserCSS = {
        css: "{\"estiloPreDefinido\":{\"externo\":{\"corPrincipal\":\"#1176ae\",\"borderRadius\":\"8px\",\"borderStyle\":\"solid\",\"borderColor\":\"#e5e5e5\",\"borderWidth\":\"1px\"},\"interno\":{\"background\":\"#ffffff\",\"borderRadius\":\"2px\",\"borderStyle\":\"none\",\"borderColor\":\"#e5e5e5\",\"borderWidth\":\"0\"}},\"corPrimaria\":\"#1176ae\",\"espessura\":\"0\",\"preenchimento\":\"#ffffff\",\"contorno\":\"#e5e5e5\",\"curvaBorda\":\"2px\",\"fonte\":\"Wix Madefor Text\",\"negrito\":false,\"italico\":false,\"fontePerfil\":\"Roboto\",\"negritoPerfil\":false,\"italicoPerfil\":false,\"corSecundaria\":\"#ff0000\"}",
        idUser: this.userProfile.documentUserProfile.idUser
      };

      try {
        await firstValueFrom(
          this.userProfileService.saveUserCss(newCss).pipe(
            filter((mayBeOk: HttpResponse<object>) => mayBeOk.ok),
            map((response: HttpResponse<object>) => response.body),
          )
        );
      } catch (error) {
        this.toastService.showError('Não foi possível salvar a estilização do perfil. Contacte o administrador.');
        // throw error; // Rethrow to let the caller handle it if necessary
      }
    }
  }

  private async uploadProfileImage(image: File, prefix: string, errorMessage?: string): Promise<string | undefined> {
    try {
      const callingCardBgUrl = await firstValueFrom(this.userProfileService.saveProfileImage(
        this.userProfile.documentUserProfile.idUser,
        prefix,
        image,
      ));
      return callingCardBgUrl;
    } catch {
      this.toastService.showError(errorMessage || 'Não foi possível salvar a imagem. Contacte o administrador.');
      return undefined;
    }
  }

  async validateLink() {
    this.urlProfile.markAsTouched();
    const linkInput = this.urlProfile.value ? this.urlProfile.value : '';

    if (linkInput.length < 3) {
      this.urlProfile.setErrors({ ...this.urlProfile.errors, 'tooShort': true });
      this.toastService.showError("A URL deve conter pelo menos 3 caracteres.");
      return;
    }

    this.urlProfile.setErrors(null); // Limpa erros anteriores

    await this.isProfileLinkAvailable(linkInput).then((isAvailable) => {
      if (!isAvailable) {
        this.urlProfile.setErrors({ ...this.urlProfile.errors, 'notAvailable': true });
        // this.toastService.showError("Essa URL não está disponível!");
      }
    });
  }

  async isProfileLinkAvailable(link: string): Promise<boolean> {
    if (!link || link.length === 0) {
      this.urlProfile.setErrors({ ...this.urlProfile.errors, 'invalid': true });
      this.toastService.showError("A URL não pode estar vazia.");
      return false;
    }

    const regex = /^[a-zA-Z0-9]+$/;
    if (!regex.test(link)) {
      this.urlProfile.setErrors({ ...this.urlProfile.errors, 'invalidFormat': true });
      this.toastService.showError("A URL deve conter apenas letras e números, sem espaços ou caracteres especiais.");
      return false;
    }

    try {
      const isAvailable = await firstValueFrom(this.userProfileService.validaLinkDisponivel(link));
      if (!isAvailable) {
        this.urlProfile.setErrors({ ...this.urlProfile.errors, 'notAvailable': true });
        this.toastService.showError("Essa URL já está em uso. Por favor, insira outra.");
      } else {
        if (this.urlProfile.errors && this.urlProfile.errors['notAvailable']) {
          this.urlProfile.setErrors(null); // Remove erros, se válido
        }
      }
      return isAvailable;
    } catch (err) {
      this.urlProfile.setErrors({ ...this.urlProfile.errors, 'serverError': true });
      this.toastService.showError("Erro ao verificar a disponibilidade da URL. Por favor, tente novamente.");
      return false;
    }
  }

  openBackgroundModal() {
    const modalRef = this.modalService.open(AlterarCorFundoModalComponent, {
      centered: true,
    });

    modalRef.result.then((result) => {
      if (result) {
        this.backgroundImage = result.image;
        this.backgroundFile = result.file;
      }
    })
  }
}

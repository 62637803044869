import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { ApexChart, ChartType } from 'ng-apexcharts';
import { firstValueFrom } from 'rxjs';
import { DashboardUser, MetricsServiceService, UserEngagement, UserMetrics } from './metrics-service/metrics-service.service';

@Component({
  selector: 'app-community-manager-data',
  templateUrl: './community-manager-data.component.html',
  styleUrls: ['./community-manager-data.component.css']
})
export class CommunityManagerDataComponent implements OnInit {
  @Output() redirect = new EventEmitter<'home' | 'aparencia' | 'links' | 'opcoes' | 'indicadores' | 'sentContacts' | 'dashboard' | 'salesDashboard' | 'sluperCoins' | 'events' | 'business' | 'businessTemplates' | 'community'>();
  messageExchangeSeries = [
    { name: 'Recebidas', data: [10, 20, 15, 30, 25, 20, 22] },
    { name: 'Enviadas', data: [25, 15, 20, 10, 30, 18, 28] }
  ];
  messageExchangeChart: ApexChart = {
    type: 'bar' as ChartType,
    height: 350,
    redrawOnParentResize: true
  };
  messageExchangeXAxis = {
    categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul']
  };
  messageExchangeColors = ['#2B55E6', '#A078E6'];

  communityEngagementSeries = [180, 17, 3];
  communityEngagementChart: ApexChart = {
    type: 'radialBar' as ChartType,
    height: 250,
    redrawOnParentResize: true

  };
  communityEngagementLabels = ['Curtidas', 'Comentários', 'Postagens'];
  communityEngagementColors = ['#2B55E6', '#A078E6', '#E64A3F'];

  userFeedbackSeries = [200, 100, 50];
  userFeedbackChart: ApexChart = {
    type: 'donut' as ChartType,
    height: 300,
    redrawOnParentResize: true
  };
  userFeedbackLabels = ['Gostaram', 'Neutro', 'Não gostaram'];
  userFeedbackColors = ['#1ACF6C', '#F4D21A', '#EF4848'];
  userFeedbackLegend = {
    show: false,
  };

  public loading: boolean = false;
  public userMetrics: UserMetrics = {} as UserMetrics;
  public userEngagement: UserEngagement = {} as UserEngagement;
  public dashboardUser: DashboardUser = {} as DashboardUser;

  constructor(private metricsServiceService: MetricsServiceService) { }

  async ngOnInit() {
    this.loading = true;
    await this.getDashboardV2();
    await this.getDashboardAdmin();
    await this.getMetricsDashboardUser();
    this.loading = false;
  }

  async getDashboardAdmin() {
    try {
      this.userEngagement = await firstValueFrom(this.metricsServiceService.getDashboardAdmin());
    } catch (error) {
      console.error(error);
    }
  }

  async getDashboardV2() {
    try {
      this.userMetrics = await firstValueFrom(this.metricsServiceService.getMetricsDashboardV2());
    } catch (error) {
      console.error(error);
    }
  }
  async getMetricsDashboardUser() {
    try {
      this.dashboardUser = await firstValueFrom(this.metricsServiceService.getMetricsDashboardUser());
    } catch (error) {
      console.error(error);
    }
  }

  // 🔹 Atualiza os gráficos quando a tela for redimensionada
  @HostListener('window:resize', ['$event'])
  onResize() {
    setTimeout(() => {
      this.updateCharts();
    }, 300);
  }

  updateCharts() {
    this.messageExchangeChart = { ...this.messageExchangeChart }; // 🔹 Força atualização do gráfico
    this.communityEngagementChart = { ...this.communityEngagementChart };
    this.userFeedbackChart = { ...this.userFeedbackChart };
  }

  redirectToPage(page: 'home' | 'aparencia' | 'links' | 'opcoes' | 'indicadores' | 'sentContacts' | 'dashboard' | 'salesDashboard' | 'sluperCoins' | 'events' | 'business' | 'businessTemplates' | 'community') {
    this.redirect.emit(page);
  }
}

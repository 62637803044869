import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ModalDeletePostComponent } from 'src/app/communities/post/modal-delete-post/modal-delete-post.component';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { EventParticipant, SluperEvent } from 'src/app/model/event.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { AddMembersModalComponent } from '../../add-members-modal/add-members-modal.component';
import {
  IMembersCategories,
  MembersCategoriesService,
} from '../members-categories/members-categories-service/members-categories.service';

@Component({
  selector: 'app-edit-member-category',
  templateUrl: './edit-member-category.component.html',
  styleUrls: ['./edit-member-category.component.css'],
})
export class EditMemberCategoryComponent implements OnInit {
  // **Inputs**
  @Input() editCategoryItem: IMembersCategories = {} as IMembersCategories;
  @Input() communityItem!: SluperEvent;
  @Input() userProfile: DocumentUserProfile | undefined;
  @Input() userProfilePlan!: IUserProfilePlan;
  @Input() allCommunityMembers: EventParticipant[] = [];

  // **Outputs**
  @Output() back = new EventEmitter<void>();
  @Output() refreshCategories = new EventEmitter<void>();
  @Output() removeCategory = new EventEmitter<number>();
  // **Variáveis de Estado**
  public loading: boolean = false;
  public newMembers: EventParticipant[] = [];
  public originalMembers: EventParticipant[] = [];
  public membersDetails: EventParticipant[] = [];
  public categoryName = new FormControl('', Validators.required);
  public membersQuantity = new FormControl('10', [
    Validators.required,
    Validators.min(1),
  ]);

  constructor(
    private readonly dialog: MatDialog,
    private readonly membersCategoriesService: MembersCategoriesService,
    private readonly toastrService: ToastService
  ) { }

  // **Ciclo de Vida**
  ngOnInit(): void {
    this.categoryName.setValue(this.editCategoryItem.name);
    this.membersQuantity.setValue(this.editCategoryItem.quantity.toString());
    this.originalMembers = [...this.editCategoryItem.membersDetails];
    this.membersDetails = [...this.editCategoryItem.membersDetails];
  }

  async updateCategory() {
    if (+this.membersQuantity.value! < this.membersDetails.length) {
      this.toastrService.show('Quantidade de membros excedida', 'warning');
      return;
    }
    const newCategoryItem = {
      id: this.editCategoryItem.id,
      name: this.categoryName.value ?? '',
      quantity: this.membersQuantity.value ?? '1',
      members: this.membersDetails.map(member => member.idUser),
      description: '',
      communityId: this.communityItem.id,
    };

    try {
      this.loading = true;
      const resp = await firstValueFrom(
        this.membersCategoriesService.updateCategoryMembers(newCategoryItem)
      );
      this.toastrService.show('Perfil de membros atualizada com sucesso', 'success');
      this.refreshCategories.emit();
      setTimeout(() => {
        this.loading = false;
        this.back.emit();
      }, 2000);
    } catch (error) {
      const err = error as HttpErrorResponse;
      this.loading = false;
      if (
        err.error.message === 'Já existe outra categoria ativa com esse nome.'
      ) {
        this.toastrService.show('Já existe outro perfil de membro ativo com esse nome.', 'error');
      } else {
        this.toastrService.show('Erro ao atualizar perfil de membro', 'danger');
      }
    }
  }

  // **Métodos de Navegação**
  handleBack(): void {
    this.back.emit();
  }

  // **Métodos de Modais**
  openAddMembersModal(): void {
    const dialogRef = this.dialog.open(AddMembersModalComponent, {
      width: '80vw',
      data: {
        allMembers: this.allCommunityMembers.filter(
          member =>
            !this.membersDetails.some(
              categoryMember => categoryMember.idUser === member.idUser
            ) && member.idUser !== this.userProfile?.idUser
        ),
        categoryParticipants: this.editCategoryItem.members,
      },
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.newMembers = result;
          this.membersDetails = [...this.membersDetails, ...this.newMembers];

          if (+result.length > +this.membersQuantity.value!) {
            this.toastrService.show(
              'Quantidade de membros excedida',
              'warning'
            );
            this.membersQuantity.setValue(
              this.membersDetails.length.toString()
            );
          }
        }
      },
      error => {
        console.error('Erro ao fechar modal ', error);
      }
    );
  }

  openRemoveCategory(): void {
    const dialogRef = this.dialog.open(ModalDeletePostComponent, {
      data: {
        id: this.editCategoryItem.id,
        type: '',
        title: 'Remover perfil de membro',
        description:
          'Atenção! Você está prestes a remover um perfil de membro. Você tem certeza que deseja remover este perfil de membro?',
      },
      maxWidth: '500px',
    });

    dialogRef.afterClosed().subscribe(
      async result => {
        if (result?.success) {
          await this.handleRemoveCategory();
        }
      },
      error => {
        console.error('Erro ao fechar o modal:', error);
      }
    );
  }

  handleRemoveMember(id: string): void {
    this.membersDetails = this.membersDetails.filter(
      member => member.idUser !== id
    );
  }

  async handleRemoveCategory(): Promise<void> {
    this.removeCategory.emit(this.editCategoryItem.id);
  }

  filterMembersByName(event: Event) {
    const input = event.target as HTMLInputElement;
    const filterValue = input.value.toLowerCase();

    if (filterValue.length === 0) {
      this.membersDetails = [...this.originalMembers]; // Restaura os originais
    } else {
      this.membersDetails = this.originalMembers.filter(
        (member: EventParticipant) =>
          member.name.toLowerCase().includes(filterValue)
      );
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SluperEvent } from './model/event.model';

@Injectable({
    providedIn: 'root',
})
export class OpenedCommunityService {
    // Estado da comunidade ativa
    private communityIdSubject = new BehaviorSubject<string | null>(null);
    communityId$ = this.communityIdSubject.asObservable();

    private myCommunity: SluperEvent | null = null;

    constructor() {
        // Tenta resgatar o communityId e os dados da comunidade completa ao iniciar o serviço
        const storedCommunityId = localStorage.getItem('onlyCommunity');
        if (storedCommunityId) {
            this.communityIdSubject.next(storedCommunityId);
        }

        const storedCommunity = localStorage.getItem('myCommunity');
        if (storedCommunity) {
            this.myCommunity = JSON.parse(storedCommunity);
        }
    }

    /**
     * Define a comunidade ativa.
     * @param communityId ID da comunidade.
     */
    setCommunity(communityId: string | null): void {
        this.communityIdSubject.next(communityId);
        if (communityId) {
            localStorage.setItem('onlyCommunity', communityId);
        } else {
            localStorage.removeItem('onlyCommunity');
        }
    }

    /**
     * Obtém o ID da comunidade ativa.
     * @returns ID da comunidade ou `null` se nenhuma estiver ativa.
     */
    getCommunity(): string | null {
        const storedCommunity = this.communityIdSubject.value || localStorage.getItem('onlyCommunity');
        if (storedCommunity) {
            return storedCommunity;
        }
        return null;
    }


    /**
     * Verifica se há uma comunidade ativa no momento.
     * @returns `true` se houver uma comunidade ativa, caso contrário, `false`.
     */
    hasActiveCommunity(): boolean {
        return !!this.getCommunity();
    }

    /**
     * Define a comunidade completa.
     * @param community Dados da comunidade.
     */
    setMyCommunity(community: SluperEvent): void {
        this.myCommunity = community;
        localStorage.setItem('myCommunity', JSON.stringify(community));
    }

    /**
     * Obtém os dados da comunidade completa.
     * @returns Dados da comunidade ou `null` se não houver.
     */
    getMyCommunity(): SluperEvent | null {
        return this.myCommunity || (localStorage.getItem('myCommunity') ? JSON.parse(localStorage.getItem('myCommunity')!) : null);
    }

    /**
     * Apaga todos os dados relacionados à comunidade do localStorage e do serviço.
     */
    clearAllData(): void {
        // Apaga o ID da comunidade do localStorage
        localStorage.removeItem('onlyCommunity');
        localStorage.removeItem('myCommunity');

        // Limpa o BehaviorSubject (opcional, mas pode ser útil para resetar o estado do serviço)
        this.communityIdSubject.next(null);

        // Limpa os dados da comunidade completa
        this.myCommunity = null;
    }

    isPrivateCommunity(): boolean {
        return !!this.getCommunity(); // É privado se houver ID de comunidade salvo
    }

}

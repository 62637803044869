import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { firstValueFrom } from "rxjs";
import { SluperEvent } from "src/app/model/event.model";
import { EventsService } from "../events.service";

@Component({
    selector: 'app-delete-event-modal',
    templateUrl: './delete-event-modal.component.html',
    styleUrls: ['./delete-event-modal.component.css'],
})
export class DeleteEventModalComponent {
    @Input() event!: SluperEvent;
    @Output() triggerfeedbackMessage = new EventEmitter<{
        message: string;
        type: 'success' | 'danger';
    }>();
    loading = false;

    closeModal() {
        this.activeModal.close();
    }

    constructor(private activeModal: NgbActiveModal, private eventsService: EventsService) { }

    async handleDeleteEvent() {
        this.loading = true;

        try {
            await firstValueFrom(this.eventsService.deleteEvent(this.event.id));

            this.triggerfeedbackMessage.emit({
                message: this.event.type === 'COMUNIDADE' ? 'Comunidade apagada com sucesso' : 'Evento apagado com sucesso',
                type: 'success'
            });

            this.loading = false;

            this.closeModal();
        } catch (error: any) {
            this.triggerfeedbackMessage.emit({
                message: error?.message || 'Erro ao remover ' + this.event.type === 'COMUNIDADE' ? 'comunidade' : 'evento',
                type: 'danger'
            });

            this.loading = false;
        }

    }
}
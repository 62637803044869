<div class="container-fluid" id="community-data-finance">
    <div class="page-title">
        <i class="bi bi-chevron-left"></i>
        <h4 id="mention-title">Informações da Comunidade</h4>
    </div>


    <section class="tab-selector">
        <div id="event-community-tab-selector">
            <div *ngIf="isUserCommunityOwner || true"
                [class]="selectedTab === 'community-owner-community-data' ? 'tab selected-tab' : 'tab'"
                (click)="changeSelectedTab('community-owner-community-data')">
                <h2 class="tab-title">Dados da Comunidade</h2>
            </div>
            <div *ngIf="isUserCommunityOwner || true"
                [class]="selectedTab === 'community-owner-community-finance' ? 'tab selected-tab' : 'tab'"
                (click)="changeSelectedTab('community-owner-community-finance')">
                <h2 class="tab-title">Financeiro</h2>
            </div>
            <div *ngIf="!isUserCommunityOwner || true"
                [class]="selectedTab === 'community-user-community-finance' ? 'tab selected-tab' : 'tab'"
                (click)="changeSelectedTab('community-user-community-finance')">
                <h2 class="tab-title">Financeiro</h2>
            </div>
        </div>
    </section>


    <app-community-manager-data *ngIf="selectedTab === 'community-owner-community-data'"
        (redirect)="redirectToPage($event)"></app-community-manager-data>
    <app-community-manager-finance
        *ngIf="selectedTab === 'community-owner-community-finance'"></app-community-manager-finance>
    <app-community-user-finance *ngIf="selectedTab === 'community-user-community-finance'"></app-community-user-finance>

</div>
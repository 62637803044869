import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { BOLD_BUTTON, EditorConfig, FONT_SIZE_SELECT, FORE_COLOR, ITALIC_BUTTON, JUSTIFY_CENTER_BUTTON, JUSTIFY_FULL_BUTTON, JUSTIFY_LEFT_BUTTON, JUSTIFY_RIGHT_BUTTON, ORDERED_LIST_BUTTON, SEPARATOR, UNDO_BUTTON, UNORDERED_LIST_BUTTON } from 'ngx-simple-text-editor';
import { firstValueFrom } from 'rxjs';
import { ChannelInterface } from 'src/app/categoryAndChannel/category-channel.service';
import { IDocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { EventParticipant, SluperEvent } from 'src/app/model/event.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { CreatePostPayload, PostsService } from '../posts.service';

@Component({
  selector: 'app-modal-post-input',
  templateUrl: './modal-post-input.component.html',
  styleUrls: ['./modal-post-input.component.css']
})
export class ModalPostInputComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  @ViewChildren('mentionItem') mentionItems!: QueryList<ElementRef>;

  @Input() userProfile!: IDocumentUserProfile | null;
  @Input() communityItem!: SluperEvent | null;
  @Input() selectedChannel!: ChannelInterface | null;
  @Input() inputType: 'post' | 'comment' = 'post'
  @Input() postId: number = 0
  @Input() commentId: number | null = null
  @Input() participants?: EventParticipant[] = [];

  public loading = false
  public filteredParticipants: EventParticipant[] = [];
  public showMentionDropdown = false;
  public mentionPosition = 0;
  public activeMentionIndex: number = 0;
  public mentions: { name: string; id: string }[] = [];

  config: EditorConfig = {
    placeholder: this.inputType === 'post' ? 'Sobre o que você quer falar? ...' : 'Digite uma resposta ...',
    buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR,
      FONT_SIZE_SELECT, SEPARATOR, JUSTIFY_LEFT_BUTTON, JUSTIFY_CENTER_BUTTON,
      JUSTIFY_RIGHT_BUTTON, JUSTIFY_FULL_BUTTON, ORDERED_LIST_BUTTON, UNORDERED_LIST_BUTTON],
  };

  inputsData: { title: string, description: string, image?: string, imageFile?: File, movie?: string, file?: string, fileFile?: File } = {
    title: '',
    description: ''
  }

  commentData: { eventId: number, text: string, userId: string, replyTo: string | null, mentionedId: string[] } = {
    eventId: 0,
    text: '',
    userId: '',
    replyTo: null,
    mentionedId: []
  }
  changeImageEvent: File | null = null

  public title = new FormControl('', Validators.required)
  public description = new FormControl('', Validators.required)
  public showInputMovie = false
  constructor(
    private activeModal: NgbActiveModal,
    private postsService: PostsService,
    private toastrService: ToastService

  ) { }

  ngOnInit(): void {
    this.config.placeholder = this.inputType === 'post' ? 'Sobre o que você quer falar? ...' : 'Digite uma resposta ...';
    this.participants = this.participants?.sort((a, b) => a.name.localeCompare(b.name));
  }

  detectMention(value: string): void {
    if (this.inputType === 'post') return
    const cursorIndex = value.lastIndexOf('@');
    if (cursorIndex !== -1 && value.slice(cursorIndex).length <= 20) {
      const mentionQuery = value.slice(cursorIndex + 1).toLowerCase();
      this.filteredParticipants = this.participants?.filter(participant =>
        participant.name.toLowerCase().includes(mentionQuery)
      ) || [];
      this.showMentionDropdown = this.filteredParticipants.length > 0;
      this.mentionPosition = cursorIndex;
      this.activeMentionIndex = 0; // Resetar o índice ativo
    } else {
      this.showMentionDropdown = false;
    }
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (this.showMentionDropdown) {
      if (event.key === 'ArrowDown') {
        // Move para o próximo item
        this.activeMentionIndex = (this.activeMentionIndex + 1) % this.filteredParticipants.length;
        event.preventDefault();
        this.scrollToActiveItem();
      } else if (event.key === 'ArrowUp') {
        // Move para o item anterior
        this.activeMentionIndex = (this.activeMentionIndex - 1 + this.filteredParticipants.length) % this.filteredParticipants.length;
        event.preventDefault();
        this.scrollToActiveItem();
      } else if (event.key === 'Enter') {
        // Seleciona o item ativo
        this.selectParticipant(this.filteredParticipants[this.activeMentionIndex]);
        event.preventDefault();
      }
    }
  }

  scrollToActiveItem(): void {
    const activeItem = this.mentionItems.toArray()[this.activeMentionIndex];
    if (activeItem) {
      activeItem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }

  selectParticipant(participant: EventParticipant): void {
    // Parte do texto antes do '@'
    const textBefore = this.inputsData.description.slice(0, this.mentionPosition);

    // Remove o '@' que já estava digitado e o que vier depois até encontrar um espaço ou final
    const textAfter = this.inputsData.description
      .slice(this.mentionPosition)
      .replace(/@\w*/, '');

    // Aqui criamos a menção como um HTML com contenteditable="false"
    const mentionHtml = `<span class="mention" style="color: #7C29D9 !important; font-weight: 600;" data-mention-id="${participant.idUser}" contenteditable="false">@${participant.name}</span>&nbsp;`;

    // Atualizamos o conteúdo substituindo a parte digitada pela menção em si
    this.inputsData.description = `${textBefore}${mentionHtml}${textAfter}`;
    this.description.setValue(this.inputsData.description);

    // Armazenar a menção no array de menções (caso ainda não exista)
    if (!this.mentions.find((mention) => mention.id === participant.idUser)) {
      this.mentions.push({ name: participant.name, id: participant.idUser });
    }

    this.showMentionDropdown = false;
    setTimeout(() => {
      this.placeCaretAtEndOfEditor();
    }, 0);
  }


  async saveCommunityPost() {

    if (this.verifyForm()) {
      this.inputsData.title = this.title.value!;
      this.inputsData.description = this.description.value!;
    } else {
      return
    }

    this.loading = true;
    const result: {
      title?: string;
      description: string;
      image?: string;
      imageFile?: File;
      movie?: string;
      file?: string;
      fileFile?: File;
    } = this.inputsData

    this.loading = true;

    try {
      // Criação do payload do novo post
      const newPost: CreatePostPayload = {
        request: {
          title: result.title!.length > 0 ? result.title : '',
          content: this.prepareContentForBackend(),
          idCommunity: this.communityItem!.id,
          userId: this.userProfile!.idUser,
          channel: this.selectedChannel!.id,
          movie: result.movie || null,
          likes: 0,
        },
        photo: result.imageFile || null,
        file: result.fileFile || null,
      };

      const data = await firstValueFrom(this.postsService.createPost(newPost));

      if (data) {
        this.toastrService.show('Post criado com sucesso.', 'success');
      } else {
        throw new Error('Ocorreu um problema ao salvar o post.');
      }

      setTimeout(() => {
        this.activeModal.close(
          {
            result: true,
            post: data,
            comment: null
          }
        );
        this.loading = false;
      }, 2000);
    } catch (error) {
      this.toastrService.show('Ocorreu um problema ao salvar o post.', 'error');
      console.error('Erro ao salvar o post:', error);
      this.loading = false
    }
  }

  async createCommentPost() {

    if (!this.verifyForm()) {
      this.toastrService.show('Preencha os campos obrigatórios.', 'warning');
      return
    }
    this.loading = true;

    try {
      this.commentData = {
        eventId: this.postId,
        text: this.inputsData.description = this.prepareContentForBackend(),
        userId: this.userProfile!.idUser,
        replyTo: this.commentId && this.commentId > 0 ? this.commentId?.toString() : null,
        mentionedId: this.mentions
          .filter(mention => mention.id)
          .map(mention => mention.id) ?? []
      }

      const data = await firstValueFrom(this.postsService.createComment(this.commentData));
      // const dataTeste = await firstValueFrom(this.postsService.createCommentTest(this.commentData));

      if (data) {
        this.toastrService.show('Comentário criado com sucesso.', 'success');
      } else {
        throw new Error('Ocorreu um problema ao salvar o comentário.');
      }


      setTimeout(() => {
        this.activeModal.close(
          {
            result: true,
            post: null,
            comment: data.body
          }
        );
        this.loading = false;
      }, 2000);
    } catch (error) {
      this.toastrService.show('Ocorreu um problema ao salvar o comentário.', 'error');
      console.error('Erro ao salvar o comentário:', error);
      this.loading = false;
    }
  }

  verifyForm(): boolean {
    this.description.markAllAsTouched();

    if (this.description.valid) {
      return true;
    } else {
      return false;
    }
  }

  prepareContentForBackend(): string {
    let content = this.inputsData.description;
    return content;

    // Substituir nomes por IDs
    this.mentions.forEach((mention) => {
      const mentionRegex = new RegExp(`@${mention.name}`, 'g');
      content = content.replace(mentionRegex, `@${mention.id}`);
    });

    return content;
  }


  onEditorChange(value: string): void {
    this.description.setValue(value);
    this.inputsData.description = value;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = value;
    const mentionSpans = tempDiv.querySelectorAll('span.mention');
    mentionSpans.forEach(span => {
      (span as HTMLElement).style.color = '#7C29D9';
      (span as HTMLElement).style.fontWeight = '600';
      (span as HTMLElement).style.textDecoration = 'none'; // se precisar
    });
    this.detectMention(tempDiv.innerHTML);
    this.mentions = this.mentions.filter((mention) =>
      tempDiv.innerHTML.includes(`@${mention.name}`)
    );
    const correctedContent = tempDiv.innerHTML;
    this.inputsData.description = correctedContent;
    this.description.setValue(correctedContent);
  }

  private placeCaretAtEndOfEditor(): void {
    const editorElement = document.querySelector('.st-area');
    if (!editorElement) return;

    (editorElement as HTMLElement).focus();

    const selection = window.getSelection();
    if (!selection) return;

    const range = document.createRange();
    range.selectNodeContents(editorElement);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }


  closeModal() {
    this.activeModal.close(
      {
        result: false,
        post: null,
        comment: null
      }
    );
  }

  handleImageChange(event: any) {
    const file = event.target.files[0];
    this.changeImageEvent = null;

    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      if (!validImageTypes.includes(file.type)) {
        this.toastrService.show('Por favor, selecione um arquivo de imagem válido.', 'warning');
        return;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          this.deleteItem();
          this.showInputMovie = false;
          this.inputsData.imageFile = file;
          this.inputsData.image = e.target.result;
          this.changeImageEvent = event;
        };
      };

      reader.readAsDataURL(file);
    }
  }


  openPdfInput() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/pdf';
    input.onchange = (event: any) => {
      const file = event.target.files[0];
      if (file) {

        const maxSize = 1 * 1024 * 1024;
        if (file.size > maxSize) {
          this.toastrService.show('O tamanho máximo do arquivo é 1 MB.', 'warning');
          return;
        }

        this.deleteItem();
        this.showInputMovie = false;
        this.inputsData.fileFile = file;

        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.inputsData.file = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  }

  handleVideoInput() {
    this.showInputMovie = !this.showInputMovie

    if (!this.showInputMovie) {
      this.inputsData.movie = ''
    } else {
      this.deleteItem()
    }
  }

  deleteItem() {
    this.inputsData.image = ''
    this.inputsData.imageFile = undefined
    this.inputsData.movie = ''
    this.inputsData.file = ''
    this.inputsData.fileFile = undefined
    this.changeImageEvent = null
  }


  templateImageCropped(event: any): void {
    const blob = event.blob;

    if (blob && blob.size) {
      // Converte o blob em um File
      const croppedFile = new File([blob], this.inputsData.imageFile?.name || 'cropped_image.png', {
        type: blob.type,
      });

      const reader = new FileReader();
      reader.onload = (e) => {
        if (!e.target || !e.target.result) return;
        if (typeof e.target.result === 'string') {
          this.showInputMovie = false;
          this.inputsData.imageFile = croppedFile;
          this.inputsData.image = e.target.result;
        }
      };

      reader.readAsDataURL(croppedFile);
    }
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/events/events.service';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { SluperEvent } from 'src/app/model/event.model';
import { OpenEventService } from 'src/app/open-event.service';

@Component({
  selector: 'app-modal-select-community',
  templateUrl: './modal-select-community.component.html',
  styleUrls: ['./modal-select-community.component.css'],
})
export class ModalSelectCommunityComponent implements OnInit {
  public myCommunities: SluperEvent[] = [];
  public userProfile: DocumentUserProfile | null = null;

  isCommunityOwner: boolean = false;
  isUserEventInvited: boolean = false;
  isUserLogged: boolean = false;
  isFromLogin: boolean = false;

  selectedCommunityHtml: HTMLElement | null = null;
  selectedCommunity: SluperEvent | null = null;
  showHideCreateCommunity: boolean | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      communities: SluperEvent[];
      isCommunityOwner: boolean;
      isFromLogin?: boolean;
    },
    private readonly matDialog: MatDialogRef<ModalSelectCommunityComponent>,
    private readonly openEventService: OpenEventService,
    private readonly router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.isUserLogged = this.router.url.includes('comunidade');
    this.isCommunityOwner = this.data.isCommunityOwner;
    this.myCommunities = this.data.communities ?? [];
    this.isFromLogin = this.data.isFromLogin ?? true;
    this.isUserEventInvited = !!(
      this.openEventService.getId() &&
      this.openEventService.getType() === 'COMUNIDADE'
    );
  }

  handleSelectCommunity(event: Event, community?: SluperEvent): void {
    const element = event.target as HTMLElement;
    const closestElement = element.closest('.community-item') as HTMLElement;
    if (this.selectedCommunityHtml === closestElement) {
      this.selectedCommunityHtml?.classList.remove('selected');
      this.selectedCommunityHtml = null;
      this.selectedCommunity = null;
      return;
    }
    if (this.selectedCommunityHtml) {
      this.selectedCommunityHtml.classList.remove('selected');
    }
    this.selectedCommunityHtml = closestElement;
    if (this.selectedCommunityHtml) {
      this.selectedCommunityHtml.classList.add('selected');
    }
    this.selectedCommunity = community || null;
  }

  confirmSelection(): void {
    if (this.selectedCommunity) {
      this.matDialog.close(this.selectedCommunity);
    } else if (this.isFromLogin) {
      this.matDialog.close('openAll');
    }
  }

  cancelSelection(): void {
    this.matDialog.close(null);
  }

  hideShowCreateCommunity(): void {
    this.showHideCreateCommunity = true;
  }

  showCreateCommunity(): void {
    this.showHideCreateCommunity = false;
  }
}

export class NewRegister {
    companyName: string;
    companyUri: string;
    description: string;
    coverPhoto: File;
    profilePhoto: File;
    coverPhotoString: string;
    profilePhotoString: string;
    constructor(

    ) {
        this.companyName = '';
        this.companyUri = '';
        this.description = '';
        this.coverPhotoString = '';
        this.profilePhotoString = '';
        this.coverPhoto = new File([], '');
        this.profilePhoto = new File([], '');
    }
}

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IContact } from '../model/contact.model';
import { DocumentUserProfile } from '../model/document-user-profile.model';
import { DocumentUser } from '../model/document-user.model';
import { IImageResponse } from '../model/image-response.model';
import { DocumentPreferences, IDocumentPreferences } from '../model/preferences.model';
import { UserProfilePlan } from '../model/user-plan.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { DocumentUserCSS } from './../model/document-user-css.model';

interface IGetContactsParams {
  page: number;
  size: number;
  idUser: string;
}

export interface UserAdminPlan {
  planName: string;
  adminPlanLimits: {
    dashCard: boolean;
    dashViews: boolean;
    dashTopProfileAccess: boolean;
    templateQuantity: number;
    dashLinkedlicenses: boolean;
    dashGeneratedLicenses: boolean;
    dashAccessGraph: boolean;
    dashLinks: boolean;
  }
}

export interface ISluperCoinsHistory {
  id: string;
  sluperCreditId: string;
  operationType: string;
  operationAmount: number,
  operatorAdminId: string;
  operatorAdminEmail: string;
  createDate: string;
}

export interface ISluperPromotion {
  promoId: string;
  startDate: Date;
  endDate: Date;
  promoName: string;
  activeForUser: boolean;
  promoDescription: string;
  creditsHistory: ISluperCoinsHistory[];
  quantityByFrequence: number;
  quantityCredit: number;
}

export interface ISalesDashboardData {
  totalSalesCommission: number;
  salesCount: number;
  salesTotalValue: number;
  revenuesByDay: { day: string; salesCount: number; salesTotalValue: number; valueCommission: number }[];
}

export interface IDashboardData {
  acessedLinksCount: number;
  profileAccessCount: number;
  socialNetworkAcessedCount: number;
  socialNetworkMetrics: {
    value: string;
    id: string;
    infoGroup: string;
    datetime: string;
  }[];
  generatedCardsCount: number;
  enabledLicensesCount: number;
  stripeLicensesCount: number;
  unavailableLicenses: number;
  availableLicenses: number;
  generatedLicenses: number;
  profileAccessByWeeks: number[];
  linksClickedByWeeks: number[];
  socialNetworkByWeeks: number[];
  linkedLicenses: number;
  topAccessedSocialNetworks: {
    url: string;
    count: number;
  }[];
  topAccessedLinks: {
    url: string;
    count: number;
  }[];
  profileAccessCountList: {
    count: number;
    userProfile: {
      bio: string;
      name: string;
      uriImageBackground: string;
      uriImageProfile: string;
    };
    userProfileCSS: {
      createDate: string;
      css: string;
    };
  }[];
  updateDate?: Date;
}

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  baseUrl = environment.profileUrl;
  baseAdmUrl = environment.administradorUrl;
  publicUrl = environment.publicUrl;
  eventsUrl = environment.eventsApiUrl;

  constructor(
    protected http: HttpClient,
    protected jwtHelper: JwtHelperService,
    protected router: Router,
  ) { }

  userById(
    idUser: string | null,
  ): Observable<HttpResponse<IUserProfileWithCSS>> {
    if (idUser == null) {
      const token: string | null = localStorage.getItem('authToken');
      if (token) {
        const decodedToken = this.jwtHelper.decodeToken(token);
        const userId = decodedToken.idUser;
        idUser = userId;
      } else {
        return throwError('Usuário não autenticado');
      }
    }
    return this.http.get<IUserProfileWithCSS>(
      `${this.baseUrl}/userById/${idUser}`,
      { observe: 'response' },
    );
  }

  getUserPlan(): Observable<HttpResponse<UserProfilePlan>> {
    return this.http.get<UserProfilePlan>(
      `${this.baseUrl}/plan`,
      { observe: 'response' },
    );
  }

  saveUserCss(userCss: DocumentUserCSS): Observable<HttpResponse<any>> {
    return this.http.put<DocumentUserCSS>(this.baseUrl + '/userCSS', userCss, {
      observe: 'response',
    });
  }

  saveDocumentUserProfile(
    userProfile: DocumentUserProfile,
  ): Observable<HttpResponse<any>> {
    return this.http.put<DocumentUserProfile>(
      this.baseUrl + '/user',
      userProfile,
      { observe: 'response' },
    );
  }

  saveDocumentUser(userProfile: DocumentUser): Observable<HttpResponse<any>> {
    return this.http.put<DocumentUser>(this.baseAdmUrl + '/user', userProfile, {
      observe: 'response',
    });
  }

  saveProfileImage(idUser: string, imageType: string, img: File): Observable<string> {
    const formData = new FormData();
    formData.append('img', img);

    return this.http
      .put<string>(
        `${this.baseUrl}/imageProfile?idUser=${idUser}&imageType=${imageType}`,
        formData,
        { responseType: 'text' as 'json' },
      )
      .pipe(map((response) => response.trim()));
  }

  saveImage(img: File): Observable<string> {
    const formData = new FormData();
    formData.append('img', img);

    return this.http
      .put<string>(
        `${this.baseUrl}/image`,
        formData,
        { responseType: 'text' as 'json' },
      )
      .pipe(map((response) => response.trim()));
  }

  uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('img', file);

    return this.http.put<string>(`${this.baseUrl}/image`, formData, {
      responseType: 'text' as 'json'
    });
  }


  validaLinkDisponivel(link: string, company: string): Observable<boolean> {
    return this.http
      .get<{ status: string }>(
        `${this.baseUrl}/uriProfile?uriProfile=${link}&company=${company}`,
        { observe: 'response' },
      )
      .pipe(map((response) => response.body?.status === 'SUCCESS'));
  }

  getSentContacts({
    page,
    size,
    idUser,
  }: IGetContactsParams): Observable<HttpResponse<any>> {
    return this.http.get<IContact[]>(`${this.baseUrl}/leads`, {
      observe: 'response',
      params: {
        page: page.toString(),
        size: size.toString(),
        idUser: idUser,
      },
    });
  }

  getDashboardData(): Observable<HttpResponse<IDashboardData>> {
    return this.http.get<IDashboardData>(`${this.baseAdmUrl}/dashboard/v2`, {
      observe: 'response',
    });
  }

  getSalesDashboardData(monthFilter: string): Observable<HttpResponse<ISalesDashboardData>> {
    return this.http.get<ISalesDashboardData>(`${this.baseAdmUrl}/sales-dashboard`, {
      observe: 'response',
      params: {
        monthFilter
      }
    });
  }

  getSluperCoinsByUser(): Observable<HttpResponse<ISluperPromotion[]>> {
    return this.http.get<ISluperPromotion[]>(`${this.baseUrl}/sluper-coins`, {
      observe: 'response',
    });
  }

  getImageProfile(idUser: string): Observable<HttpResponse<IImageResponse>> {
    return this.http.get<IImageResponse>(this.publicUrl + "/imageProfile/" + idUser, { observe: 'response' });
  }

  getPreferences(): Observable<HttpResponse<IDocumentPreferences[]>> {
    return this.http.get<IDocumentPreferences[]>(this.eventsUrl + "/preferences", { observe: 'response' });
  }

  getUserPreferences(idUser: string): Observable<HttpResponse<IDocumentPreferences[]>> {
    return this.http.get<IDocumentPreferences[]>(this.eventsUrl + "/userPreferences/" + idUser, { observe: 'response' });
  }

  saveUserPreferences(idUser: string, preferences: DocumentPreferences[]): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.eventsUrl + "/userPreferences", {
      idUser: idUser,
      preferences: preferences
    }, { observe: 'response' });
  }

  saveUserPublicSearch(publicSearch: boolean): Observable<HttpResponse<any>> {
    const params = new HttpParams()
      .set('publicSearch', publicSearch.toString());

    return this.http.put<any>(`${this.eventsUrl}/userPublicSearch`, null, {
      params: params,
      observe: 'response'
    });
  }

  getHeaderImage(domain: string): Observable<string> {
    return this.http.get(this.publicUrl + "/headerImage/" + domain, { responseType: 'text' });
  }

  getAdmPlan(): Observable<HttpResponse<UserAdminPlan>> {
    return this.http.get<UserAdminPlan>(
      `${this.baseAdmUrl}/plan`,
      { observe: 'response' },
    );
  }
}

import { Injectable } from '@angular/core';
import { NewRegister } from 'src/app/new-sluper/core/model/new-register.model';
import { Personal } from 'src/app/new-sluper/core/model/personal.model';
import { Profissional } from 'src/app/new-sluper/core/model/professional.model';
import { SocialNetwork } from 'src/app/new-sluper/core/model/social-network.model';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoCadastroStorageService {

  private personalData: Personal = new Personal();
  private professionalData: Profissional = new Profissional();
  private socialNetworkData: SocialNetwork[] = [];
  private newRegister: NewRegister = new NewRegister();

  setPersonalData(data: Personal) {
    this.personalData = data;
  }

  getPersonalData(): Personal {
    return this.personalData;
  }

  setProfessionalData(data: Profissional) {
    this.professionalData = data;
  }

  getProfessionalData(): Profissional {
    return this.professionalData;
  }

  setSocialNetworkData(data: SocialNetwork[]) {
    this.socialNetworkData = data;
  }

  getSocialNetworkData(): SocialNetwork[] {
    return this.socialNetworkData;
  }

  setNewRegister(data: NewRegister) {
    this.newRegister = data;
  }

  getNewRegister(): NewRegister {
    return this.newRegister;
  }
  resetNewRegister() {
    this.newRegister = new NewRegister();
  }


}

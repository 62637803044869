<div class="side-menu d-flex flex-column bg-light p-2">
  <section class="side-menu-screens" [ngStyle]="isAdmin ? { 'margin-bottom': '0px' } : { 'margin-bottom': '20px' }">
    <div class="side-menu-screen-item" (click)="handleSelectScreen('initial')">
      <img src="../../assets/img/sidebar-sluper-icon.png" alt="Página inicial ícone">
      <span>Página inicial</span>
    </div>
    <div class="side-menu-screen-item" (click)="handleSelectScreen('mentions')">
      <img src="../../assets/img/sidebar-arroba-icon.png" alt="Menções ícone">
      <span>Menções</span>
    </div>
    <div class="side-menu-screen-item" (click)="handleSelectScreen('saved')">
      <img src="../../assets/img/sidebar-savemark-icon.png" alt="Favoritos ícone">
      <span>Favoritos</span>
    </div>
    <!-- <div class="side-menu-screen-item" (click)="handleSelectScreen('alerts')">
      <img src="../../assets/img/sidebar-aviso-icon.png" alt="Avisos ícone">
      <span>Avisos</span>
    </div> -->
    <!-- <div class="side-menu-screen-item" (click)="handleSelectScreen('store')">
      <img src="../../assets/img/sidebar-store-icon.png" alt="Vitrine ícone">
      <span>Vitrine</span>
    </div> -->
  </section>

  <section class="side-menu-screens" *ngIf="isAdmin"
    [ngStyle]="isAdmin ? { 'margin-bottom': '20px' } : { 'margin-bottom': '0px' }">
    <div class="side-menu-screen-item" (click)="handleSelectScreen('settings')">
      <img src="../../assets/img/sidebar-settings-icon.png" alt="Configurar Comunidade ícone">
      <span>Configurar Comunidade</span>
    </div>
    <!-- <div class="side-menu-screen-item" (click)="handleSelectScreen('finance')">
      <img src="../../assets/img/sidebar-finance-icon.png" alt="Informações da Comunidade ícone">
      <span>Informações da Comunidade</span>
    </div> -->
  </section>

  <h6 class="text">Canais</h6>
  <div class="side-menu-section" *ngFor="let community of communityCategories; let i = index">
    <div class="side-menu-header" (click)="toggleSection(community.id)">
      <span class="icon" style="margin: 0;">
        <img src="../../assets/img/icone-canais-sluper.png" alt="icone-canais-sluper.png">
      </span>
      <span class="text">{{ community.name }}</span>
      <div class="d-flex align-items-center">
        <span *ngIf="isSectionOpen(community.id)" class="bi bi-chevron-down"></span>
        <span *ngIf="!isSectionOpen(community.id)" class="bi bi-chevron-right"></span>
        <span (click)="openSideMenuOptionsModal(community, 'category')" class="menu-dots-header"
          *ngIf="isAdmin">⋮</span>
      </div>
    </div>
    <ul class="side-menu-items list-unstyled" *ngIf="isSectionOpen(community.id)">
      <li *ngFor="let item of community.channels" (click)="onSelectChannel(item)"
        [ngClass]="{ 'active': item.id === selectedChannel?.id }" class="side-menu-item">
        <div>
          <span class="icon">{{ item.icon }}</span>
          <span class="text">{{ item.name }}</span>
          <span class="d-flex align-items-center justify-content-end">
            <span class="number-circle" *ngIf="isAdmin">{{ item.postQty || 0 }} </span>
            <span class="menu-dots" *ngIf="isAdmin"
              (click)="openSideMenuOptionsModal(item, 'channel'); $event.stopPropagation()"> ⋮ </span>
            <span class="menu-dots" *ngIf="!isAdmin"> </span>
          </span>
        </div>
      </li>
    </ul>

  </div>
  <div class="side-menu-section" *ngIf="channelsWithoutCategory.length > 0">
    <div class="side-menu-header">
      <span class="icon">{{communityCategories.length + 1}}</span>
      <span class="text">Canais sem Categoria</span>
      <div class="d-flex align-items-center">
        <span *ngIf="openChannelsWithoutCategory" class="bi bi-chevron-down"
          (click)="openChannelsWithoutCategory = false"></span>
        <span *ngIf="!openChannelsWithoutCategory" class="bi bi-chevron-right"
          (click)="openChannelsWithoutCategory = true"></span>
      </div>
    </div>
    <ul class="side-menu-items list-unstyled" *ngIf="openChannelsWithoutCategory">
      <li *ngFor="let item of channelsWithoutCategory" (click)="onSelectChannel(item)"
        [ngClass]="{ 'active': item.id === selectedChannel?.id }" class="side-menu-item">
        <div>
          <span class="icon">{{ item.icon }}</span>
          <span class="text">{{ item.name }}</span>
          <div class="number-circle" *ngIf="isAdmin">{{ item.count || 0 }}</div>
          <span class="menu-dots" *ngIf="isAdmin"
            (click)="openSideMenuOptionsModal(item, 'channel'); $event.stopPropagation()"> ⋮ </span>
        </div>
      </li>
    </ul>
  </div>


  <button (click)="onChannelClick('channel')" class="create-channel my-1"
    *ngIf="isAdmin && communityCategories.length > 0">
    <span class="icon">+</span>
    <span class="text multi-line">Canal</span>
  </button>
  <button (click)="onChannelClick('category')" class="create-channel my-1" *ngIf="isAdmin">
    <span class="icon">+</span>
    <span class="text multi-line">Categoria</span>
  </button>

  <div class="no-items" *ngIf="communityCategories.length === 0 && channelsWithoutCategory.length === 0 && !isAdmin">
    <span class="icon">😥</span>
    <span class="text multi-line">Nenhum item criado</span>
  </div>
</div>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginPreviewComponent } from './login-preview/login-preview.component';
import { PerfilUsuarioComponent } from './new-sluper/private/perfil-usuario/perfil-usuario.component';
import { CadastroComponent } from './new-sluper/public/cadastro/cadastro.component';
import { ChaveAtivacaoComponent } from './new-sluper/public/cadastro/chave-ativacao/chave-ativacao.component';
import { ConfiguracaoCadastroComponent } from './new-sluper/public/cadastro/configuracao-cadastro/configuracao-cadastro.component';
import { ConfirmacaoCriacaoContaComponent } from './new-sluper/public/cadastro/confirmacao-criacao-conta/confirmacao-criacao-conta.component';
import { LoginComponent } from './new-sluper/public/login/login.component';
import { PostRoutingComponent } from './post-routing/post-routing.component';
import { PublicEventComponent } from './public-event/public-event.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: 'login/:parametro', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/:parametro', component: LoginComponent },
  { path: 'comunidade', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'comunidade/:parametro', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'signup', component: LoginComponent },
  { path: 'cadastro', component: CadastroComponent },
  { path: 'post/:parametro/:company', component: PostRoutingComponent },
  { path: 'chave-ativacao', component: ChaveAtivacaoComponent },
  { path: 'chave-ativacao/:parametro', component: ChaveAtivacaoComponent },
  { path: 'confirmacao-criacao-conta', component: ConfirmacaoCriacaoContaComponent },
  { path: 'configuracao-cadastro', component: ConfiguracaoCadastroComponent },
  { path: 'cadastro-perfil-usuario', component: PerfilUsuarioComponent },
  { path: 'public-event/:eventId', component: PublicEventComponent },
  { path: 'login-preview', component: LoginPreviewComponent },
  { path: ':parametro', component: LoginComponent },
  {
    path: '**',
    canActivate: [AuthGuard],
    component: LoginComponent,
  }];


@NgModule({
  imports: [RouterModule.forRoot(routes),],
  exports: [RouterModule],
})
export class AppRoutingModule { }
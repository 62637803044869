<app-loading-spinner [customBg]="'transparent'" *ngIf="loading"></app-loading-spinner>


<div class="members-settings-container"
     [ngStyle]="screen === 'members-screen' ? { 'display': 'block' } : { 'display': 'none' }">
  <!-- Seção: Membros (com abas) -->
  <div class="members-wrapper">
    <!-- ABA NO ESTILO DO ITEM PADRÃO -->
    <section class="tab-selector">
      <div id="event-community-tab-selector">
        <div [class]="selectedTab === 'active' ? 'tab selected-tab' : 'tab'" (keydown)="selectedTab = 'active'"
             (click)="selectedTab = 'active'">
          <h2 class="tab-title">Membros Ativos</h2>
        </div>
        <div [class]="selectedTab === 'inactive' ? 'tab selected-tab' : 'tab'" (keydown)="selectedTab = 'inactive'"
             (click)="selectedTab = 'inactive'">
          <h2 class="tab-title">Membros Inativos</h2>
        </div>
      </div>
    </section>
    <!-- FIM DA ABA NO ESTILO DO ITEM PADRÃO -->

    <!-- Conteúdo da aba Membros Ativos -->
    <div class="tab-content" *ngIf="selectedTab === 'active'">
      <div class="members-header">
        <h2>Membros da comunidade</h2>
        <div class="members-actions">
          <input type="text" placeholder="Procurar membros" (input)="filterMembersByName($event, 'active')" />
          <button class="add-members-btn" (click)="openModalCommunityMembers('add')">
            <i class="bi bi-plus"></i>
            Adicionar Membros
          </button>
        </div>
      </div>

      <div class="people-container">
        <div class="connection-request-card" *ngFor="let member of activeMembers">
          <img *ngIf="member.uriImageProfile && member.uriImageProfile !== ''" [src]="member.uriImageProfile"
               alt="Foto do usuário" />
          <img *ngIf="!member.uriImageProfile || !member.uriImageProfile.length" src="/assets/img/imagem_perfil.png"
               alt="Foto padrão" />
          <div class="request-info">
            <div class="info">
              <span class="request-name">{{ member.name }}</span>
              <span class="request-profession multi-line" [innerHtml]="member.presentation"></span>
            </div>
          </div>
          <i class="btn bi bi-dash-square-fill quantity-people-button"
             (keydown)="openInactiveMember(member.idUser)"
             (click)="openInactiveMember(member.idUser)"
             [ngStyle]="userProfile?.idUser === member.idUser ? { 'color': 'lightgray', 'cursor': 'default' } : {}">
          </i>
        </div>
        <h3 class="no-items" *ngIf="activeMembers.length === 0 && !loading">Nenhum membro ativo</h3>
        <h3 class="no-items" *ngIf="loading && activeMembers.length === 0">Carregando...</h3>
      </div>
    </div>

    <!-- Conteúdo da aba Membros Inativos -->
    <div class="tab-content" *ngIf="selectedTab === 'inactive'">
      <div class="members-header">
        <h2>Membros da comunidade inativos</h2>
        <div class="members-actions">
          <input type="text" placeholder="Procurar membros" (input)="filterMembersByName($event, 'inactive')" />
          <!--          <button class="add-members-btn">-->
          <!--            <i class="bi bi-plus"></i>-->
          <!--            Reativar Membros-->
          <!--          </button>-->
        </div>
      </div>

      <div class="people-container">
        <div class="connection-request-card" *ngFor="let member of inactiveMembers">
          <img *ngIf="member.uriImageProfile && member.uriImageProfile !== ''" [src]="member.uriImageProfile"
               alt="Foto do usuário" />
          <img *ngIf="!member.uriImageProfile || !member.uriImageProfile.length" src="/assets/img/imagem_perfil.png"
               alt="Foto padrão" />
          <div class="request-info">
            <div class="info">
              <span class="request-name">{{ member.name }}</span>
              <span class="request-profession multi-line" [innerHtml]="member.presentation"></span>
            </div>
          </div>
          <i class="btn bi bi-plus-square-fill quantity-people-button"
             (keydown)="openActiveMember(member.idUser)"
             (click)="openActiveMember(member.idUser)"></i>
        </div>
        <h3 class="no-items" *ngIf="inactiveMembers.length === 0 && !loading">Nenhum membro inativo</h3>
        <h3 class="no-items" *ngIf="loading && inactiveMembers.length === 0">Carregando...</h3>
      </div>
    </div>
  </div>

  <!-- Seção: Categorias de Membros (fora das abas) -->
  <div>
    <app-members-categories [allActiveMembers]="activeMembers" (openEditCategory)="handleOpenEditCategory($event)"
                            [communityItem]="communityItem"
                            [userProfile]="userProfile"
    ></app-members-categories>
  </div>
</div>
<div *ngIf="screen === 'edit-member-category'">
  <app-edit-member-category [editCategoryItem]="editCategoryItem" [userProfile]="userProfile"
                            [allCommunityMembers]="allCommunityMembers" [communityItem]="communityItem"
                            (refreshCategories)="handleRefreshCategories()"
                            (back)="handleBackMembersScreen()"
                            (removeCategory)="handleRemoveCategory($event)"
  ></app-edit-member-category>
</div>

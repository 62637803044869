<div class="modal-container">
  <div class="modal-header">
    <h2 class="modal-title">Adicionar membros a Perfil de membros:</h2>
    <input class="modal-input-search" type="text" placeholder="Procurar conexões"
      (input)="searchMembersByName($event)" />
  </div>
  <div class="people-container">
    <div class="connection-request-card" *ngFor="let member of membersFiltered">
      <img *ngIf="member.uriImageProfile && member.uriImageProfile !== ''" [src]="member.uriImageProfile"
        alt="Foto do usuário" />
      <img *ngIf="!member.uriImageProfile || !member.uriImageProfile.length" src="/assets/img/imagem_perfil.png"
        alt="Foto padrão" />
      <div class="request-info">
        <div class="info">
          <span class="request-name">
            {{ member.name }}
          </span>
          <span class="request-profession multi-line" [innerHtml]="member.presentation"> </span>
        </div>
      </div>
      <i *ngIf="member.selected" class="btn bi bi-dash-square-fill quantity-people-button"
        (click)="toggleMemberSelection(member)"></i>

      <i *ngIf="!member.selected" class="btn bi bi-plus-square-fill quantity-people-button"
        (click)="toggleMemberSelection(member)"></i>
    </div>
    <h3 *ngIf="membersFiltered.length === 0" class="no-items">Nenhum membro encontrado</h3>
  </div>
  <div class="modal-actions">
    <button class="add-all-button" (click)="handleSelectEverybody()">
      <span class="span-add-all"> Adicionar Todos </span>
    </button>

    <div class="d-flex justify-content-end align-items-center w-100">
      <button class="cancel-button" (click)="this.closeModal()">
        <span class="span-cancel"> Cancelar </span>
      </button>
      <button class="finish-button" (click)="closeModal(true)">
        <span class="span-finish"> Finalizar </span>
      </button>
    </div>
  </div>
</div>

<app-manager-company-advertisement *ngIf="screens === 'company-advertisement-screen'"
                                   (closeCompanyAdvertisement)="handleCloseScreens()"
                                   (submittedCompanyAdvertisement)="handleSubmittedCompanyAdvertisement()"
></app-manager-company-advertisement>

<app-contact-announcement *ngIf="screens === 'contact-screen'"
                          (closeScreen)="handleCloseScreens()"
></app-contact-announcement>
<div class="showcase" *ngIf="screens === 'store-screen'">
  <div class="page-title">
    <div class="d-flex align-items-center">
      <i class="bi bi-chevron-left"></i>
      <h4 id="mention-title">Vitrines</h4>
    </div>
    <button class="highlight-button" (click)="handleOpenCompanyAdvertisement()">Divulgar Empresa</button>
  </div>

  <div class="stats">
    <div class="stat-item">
      <div class="stat-item-left">
        <img src="assets/img/store-card-people-icon.png" alt="Ícone de pessoas">
      </div>
      <div class="stat-item-right">
        <span>Qtd. de Anúncios</span>
        <h4>20</h4>
      </div>
    </div>
    <div class="stat-item">
      <div class="stat-item-left">
        <img src="assets/img/store-card-people-icon.png" alt="Ícone de pessoas">
      </div>
      <div class="stat-item-right">
        <span>Qtd. de Anúncios Premium</span>
        <h4>2</h4>
      </div>
    </div>
    <div class="stat-item">
      <div class="stat-item-left">
        <img src="assets/img/store-card-people-icon.png" alt="Ícone de pessoas">
      </div>
      <div class="stat-item-right">
        <span>Nº de Contatos</span>
        <h4>10</h4>
      </div>
    </div>
    <div class="stat-item">
      <div class="stat-item-left">
        <img src="assets/img/store-card-people-icon.png" alt="Ícone de pessoas">
      </div>
      <div class="stat-item-right">
        <span>Qtd. Negócios Realizados</span>
        <h4>10</h4>
      </div>
    </div>
  </div>

  <div class="search-bar">
    <select class="category-select">
      <option>Categoria</option>
    </select>

    <div class="search-bar-filter">
      <input type="text" placeholder="O que você procura?" />
      <select class="region-select">
        <option>Região</option>
      </select>
      <button class="search-button">
        <span>Buscar</span>
        <i class="bi bi-search search-icon"></i>
      </button>
    </div>
  </div>


  <div class="featured">
    <h3>Em destaque</h3>
    <div class="featured-grid">
      <div class="featured-item" (click)="openModalSeeAnnouncement('teste')"
           style="background-image: url('assets/images/background-logo.png');">
        <div class="featured-content">
          <div class="d-flex justify-content-between">
            <h4>Nome da Empresa</h4>
            <i class="edit-image"></i>
          </div>
          <p>Descrição sobre a empresa e seus serviços com breves informações relevantes para a divulgação.
          </p>
          <div class="featured-actions">
            <button (click)="openModalContactAnnouncement( $event,'teste')">Ver mais detalhes</button>
            <div class="featured-stats">
              <div class="d-flex featured-stats-row">
                <i class="bi bi-star"></i>
                <div class="d-flex flex-column featured-stats-column">
                  <span>Aprovações:</span>
                  <h3>30</h3>
                </div>
              </div>
              <div class="d-flex featured-stats-row">
                <i class="bi bi-heart"></i>
                <div class="d-flex flex-column featured-stats-column">
                  <span>Favorito:</span>
                  <h3>3</h3>
                </div>
              </div>
              <div class="d-flex featured-stats-row">
                <div class="price-svg"></div>
                <div class="d-flex flex-column featured-stats-column">
                  <span>A partir de:</span>
                  <h3> R$ 00,00</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="featured-item" (click)="openModalSeeAnnouncement('teste')"
           style="background-image: url('assets/images/background-logo.png');">
        <div class="featured-content">
          <div class="d-flex justify-content-between">
            <h4>Nome da Empresa</h4>
            <i class="edit-image"></i>
          </div>
          <p>Descrição sobre a empresa e seus serviços com breves informações relevantes para a divulgação.
          </p>
          <div class="featured-actions">
            <button (click)="openModalContactAnnouncement( $event,'teste')">Ver mais detalhes</button>
            <div class="featured-stats">
              <div class="d-flex featured-stats-row">
                <i class="bi bi-star"></i>
                <div class="d-flex flex-column featured-stats-column">
                  <span>Aprovações:</span>
                  <h3>30</h3>
                </div>
              </div>
              <div class="d-flex featured-stats-row">
                <i class="bi bi-heart"></i>
                <div class="d-flex flex-column featured-stats-column">
                  <span>Favorito:</span>
                  <h3>3</h3>
                </div>
              </div>
              <div class="d-flex featured-stats-row">
                <div class="price-svg"></div>
                <div class="d-flex flex-column featured-stats-column">
                  <span>A partir de:</span>
                  <h3> R$ 00,00</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="others">
    <h3>Outros anunciantes</h3>
    <div class="others-grid">
      <div class="other-item" (click)="openModalSeeAnnouncement('teste')">
        <div class="other-item-image">
          <img class="logo-image" src="assets/images/background-logo.png" alt="Empresa">
          <i class="edit-image"></i>
        </div>
        <h4>Nome da Empresa</h4>
        <p>Descrição sobre a empresa e seus serviços com breves informações relevantes para a divulgação.</p>
        <div class="featured-actions">
          <div class="featured-stats">
            <div class="d-flex featured-stats-row">
              <i class="bi bi-star"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Aprovações:</span>
                <h3>30</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <i class="bi bi-heart"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Favorito:</span>
                <h3>3</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <div class="price-svg"></div>
              <div class="d-flex flex-column featured-stats-column">
                <span>A partir de:</span>
                <h3> R$ 00,00</h3>
              </div>
            </div>
          </div>
          <button (click)="openModalContactAnnouncement( $event,'teste')">Ver mais detalhes</button>
        </div>
      </div>
      <div class="other-item" (click)="openModalSeeAnnouncement('teste')">
        <div class="other-item-image">
          <img class="logo-image" src="assets/images/background-logo.png" alt="Empresa">
          <i class="edit-image"></i>
        </div>
        <h4>Nome da Empresa</h4>
        <p>Descrição sobre a empresa e seus serviços com breves informações relevantes para a divulgação.</p>
        <div class="featured-actions">
          <div class="featured-stats">
            <div class="d-flex featured-stats-row">
              <i class="bi bi-star"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Aprovações:</span>
                <h3>30</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <i class="bi bi-heart"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Favorito:</span>
                <h3>3</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <div class="price-svg"></div>
              <div class="d-flex flex-column featured-stats-column">
                <span>A partir de:</span>
                <h3> R$ 00,00</h3>
              </div>
            </div>
          </div>
          <button (click)="openModalContactAnnouncement( $event,'teste')">Ver mais detalhes</button>
        </div>
      </div>
      <div class="other-item" (click)="openModalSeeAnnouncement('teste')">
        <div class="other-item-image">
          <img class="logo-image" src="assets/images/background-logo.png" alt="Empresa">
          <i class="edit-image"></i>
        </div>
        <h4>Nome da Empresa</h4>
        <p>Descrição sobre a empresa e seus serviços com breves informações relevantes para a divulgação.</p>
        <div class="featured-actions">
          <div class="featured-stats">
            <div class="d-flex featured-stats-row">
              <i class="bi bi-star"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Aprovações:</span>
                <h3>30</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <i class="bi bi-heart"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Favorito:</span>
                <h3>3</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <div class="price-svg"></div>
              <div class="d-flex flex-column featured-stats-column">
                <span>A partir de:</span>
                <h3> R$ 00,00</h3>
              </div>
            </div>
          </div>
          <button (click)="openModalContactAnnouncement( $event,'teste')">Ver mais detalhes</button>
        </div>
      </div>
      <div class="other-item" (click)="openModalSeeAnnouncement('teste')">
        <div class="other-item-image">
          <img class="logo-image" src="assets/images/background-logo.png" alt="Empresa">
          <i class="edit-image"></i>
        </div>
        <h4>Nome da Empresa</h4>
        <p>Descrição sobre a empresa e seus serviços com breves informações relevantes para a divulgação.</p>
        <div class="featured-actions">
          <div class="featured-stats">
            <div class="d-flex featured-stats-row">
              <i class="bi bi-star"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Aprovações:</span>
                <h3>30</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <i class="bi bi-heart"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Favorito:</span>
                <h3>3</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <div class="price-svg"></div>
              <div class="d-flex flex-column featured-stats-column">
                <span>A partir de:</span>
                <h3> R$ 00,00</h3>
              </div>
            </div>
          </div>
          <button (click)="openModalContactAnnouncement( $event,'teste')">Ver mais detalhes</button>
        </div>
      </div>
    </div>
  </div>

  <div class="also-here">
    <h3>Também estão aqui</h3>
    <div class="others-grid">
      <div class="other-item" (click)="openModalSeeAnnouncement('teste')">
        <div class="other-item-image">
          <!-- <img class="logo-image" src="assets/images/background-logo.png" alt="Empresa"> -->
          <i class="edit-image"></i>
        </div>
        <h4>Nome da Empresa</h4>
        <p>Descrição sobre a empresa e seus serviços com breves informações relevantes para a divulgação.</p>
        <div class="featured-actions">
          <div class="featured-stats">
            <div class="d-flex featured-stats-row">
              <i class="bi bi-star"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Aprovações:</span>
                <h3>30</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <i class="bi bi-heart"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Favorito:</span>
                <h3>3</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <div class="price-svg"></div>
              <div class="d-flex flex-column featured-stats-column">
                <span>A partir de:</span>
                <h3> R$ 00,00</h3>
              </div>
            </div>
          </div>
          <button (click)="openModalContactAnnouncement( $event,'teste')">Ver mais detalhes</button>
        </div>
      </div>
      <div class="other-item" (click)="openModalSeeAnnouncement('teste')">
        <div class="other-item-image">
          <!-- <img class="logo-image" src="assets/images/background-logo.png" alt="Empresa"> -->
          <i class="edit-image"></i>
        </div>
        <h4>Nome da Empresa</h4>
        <p>Descrição sobre a empresa e seus serviços com breves informações relevantes para a divulgação.</p>
        <div class="featured-actions">
          <div class="featured-stats">
            <div class="d-flex featured-stats-row">
              <i class="bi bi-star"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Aprovações:</span>
                <h3>30</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <i class="bi bi-heart"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Favorito:</span>
                <h3>3</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <div class="price-svg"></div>
              <div class="d-flex flex-column featured-stats-column">
                <span>A partir de:</span>
                <h3> R$ 00,00</h3>
              </div>
            </div>
          </div>
          <button (click)="openModalContactAnnouncement( $event,'teste')">Ver mais detalhes</button>
        </div>
      </div>
      <div class="other-item" (click)="openModalSeeAnnouncement('teste')">
        <div class="other-item-image">
          <!-- <img class="logo-image" src="assets/images/background-logo.png" alt="Empresa"> -->
          <i class="edit-image"></i>
        </div>
        <h4>Nome da Empresa</h4>
        <p>Descrição sobre a empresa e seus serviços com breves informações relevantes para a divulgação.</p>
        <div class="featured-actions">
          <div class="featured-stats">
            <div class="d-flex featured-stats-row">
              <i class="bi bi-star"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Aprovações:</span>
                <h3>30</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <i class="bi bi-heart"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Favorito:</span>
                <h3>3</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <div class="price-svg"></div>
              <div class="d-flex flex-column featured-stats-column">
                <span>A partir de:</span>
                <h3> R$ 00,00</h3>
              </div>
            </div>
          </div>
          <button (click)="openModalContactAnnouncement( $event,'teste')">Ver mais detalhes</button>
        </div>
      </div>
      <div class="other-item" (click)="openModalSeeAnnouncement('teste')">
        <div class="other-item-image">
          <!-- <img class="logo-image" src="assets/images/background-logo.png" alt="Empresa"> -->
          <i class="edit-image"></i>
        </div>
        <h4>Nome da Empresa</h4>
        <p>Descrição sobre a empresa e seus serviços com breves informações relevantes para a divulgação.</p>
        <div class="featured-actions">
          <div class="featured-stats">
            <div class="d-flex featured-stats-row">
              <i class="bi bi-star"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Aprovações:</span>
                <h3>30</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <i class="bi bi-heart"></i>
              <div class="d-flex flex-column featured-stats-column">
                <span>Favorito:</span>
                <h3>3</h3>
              </div>
            </div>
            <div class="d-flex featured-stats-row">
              <div class="price-svg"></div>
              <div class="d-flex flex-column featured-stats-column">
                <span>A partir de:</span>
                <h3> R$ 00,00</h3>
              </div>
            </div>
          </div>
          <button (click)="openModalContactAnnouncement( $event,'teste')">Ver mais detalhes</button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, HostListener, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IDocumentPreferences } from "src/app/model/preferences.model";


@Component({
    selector: 'app-check-preferences',
    templateUrl: './check-preferences.component.html',
    styleUrls: ['./check-preferences.component.css'],
})
export class CheckPreferencesModalComponent implements OnInit {
    @Input() preferences!: IDocumentPreferences[];
    @Input() connectionName!: string;

    interests!: IDocumentPreferences[];
    occupations!: IDocumentPreferences[];

    screenHeight: any;
    screenWidth: any;

    constructor(private activeModal: NgbActiveModal) { }

    ngOnInit(): void {
        this.interests = this.preferences?.filter(p => p.type == 'INTERESSE');
        this.occupations = this.preferences?.filter(p => p.type == 'AREA_ATUACAO');
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: any) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
    }

    closeModal(status: boolean) {
        this.activeModal.close(status);
    }
}
<app-loading-spinner [bgTransparent]="true" *ngIf="loading"></app-loading-spinner>


<div class="answer-comment-screen">
    <div class="comment-information">
        <div class="comment-information-left">
            <div class="comment-information-img">
                <img *ngIf="answersComment?.user?.profilePicture && answersComment.user.profilePicture.length > 0"
                    [src]="answersComment.user.profilePicture" alt="profile-pic">
                <img *ngIf="!answersComment.user?.profilePicture" src="/assets/img/default_avatar_1.png"
                    alt="profile-pic">
            </div>
        </div>
        <div class="comment-information-center">
            <div class="comment-information-info">
                <span class="comment-information-name pointer"
                    (click)="openParticipantProfile($event, answersComment.userId)">{{ answersComment.user.name
                    }}</span>
                <span class="comment-information-date">{{ transformDate(answersComment.createdAt) }}</span>

                <p class="comment-text" [innerHTML]="sanitizedComment"></p>

                <div class="comment-options">
                    <div>
                        <span class="comment-likes">{{ answersComment.likes }}</span>
                        <i *ngIf="!answersComment.like" (click)="likePost(answersComment.id)"
                            class="bi bi-hand-thumbs-up "></i>
                        <i *ngIf="answersComment.like" (click)="unlikePost(answersComment.like.id)"
                            class="bi bi-hand-thumbs-up-fill "></i>
                    </div>
                    <!--   <div>
                        <span class="comment-likes">Falta</span>
                        <i class="bi bi-hand-thumbs-down "></i>
                    </div> -->
                    <!-- <span class="answer-comment ">Responder</span> -->
                </div>
            </div>
        </div>
        <div class="comment-information-right ">
            <!-- <img *ngIf="verifyRemoveMention()" class="remove-mention" (click)="removeMention(answersComment)"
                src="../../../../assets/img/remove-mention.png" alt="Remover menção"> -->
            <span class="comment-information-delete-comment"
                *ngIf="showDeleteButton &&(isUserCommunityOwner || answersComment.userId === userProfile?.idUser)"
                (click)="handleDeleteComment(answersComment.id)">
                <i class="bi bi-trash-fill"></i>
            </span>
        </div>
    </div>
</div>
<app-loading-spinner *ngIf="loading" [customBg]="'#fff'" ]></app-loading-spinner>

<div class="modal-container">
  <header class="modal-header">
    <div class="d-flex w-100">
      <i (click)="selectedTab > 0 && backStep()" [class.disabled]="selectedTab === 0"
        class="bi bi-arrow-left pointer"></i>
      <h4 class="modal-title" *ngIf="!updateMode" translate>communitys.createCommunity.title</h4>
      <h4 class="modal-title" *ngIf="updateMode" translate>communitys.updateCommunity.title</h4>
    </div>
    <section class="tab-selector">
      <div id="community-tab-selector">
        <div [class]="selectedTab === 0 ? 'tab selected-tab pointer' : 'tab pointer'"
          (click)="changeSelectedTabStep(0)">
          <h2 class="tab-title">Informações da Comunidade</h2>
        </div>

        <div [class]="selectedTab === 1 ? 'tab selected-tab pointer' : 'tab pointer'"
          (click)="changeSelectedTabStep(1)">
          <h2 class="tab-title">Regras da Comunidade</h2>
        </div>

        <div [class]="selectedTab === 2 ? 'tab selected-tab pointer' : 'tab pointer'"
          (click)="changeSelectedTabStep(2)">
          <h2 class="tab-title">Adicionar Participantes</h2>
        </div>
      </div>
      <div class="modal-header-options">
        <button (click)="closeModal()" class="cancel-community-button">Cancelar</button>
        <button (click)="nextStep()" class="save-community">
          {{ selectedTab === 2 ? 'Publicar' : 'Próximo' }}
        </button>
      </div>
    </section>
  </header>

  <section class="modal-body">
    <section class="community-fields-section" *ngIf="screens === 'information'">
      <div class="file-input-container" *ngIf="screens === 'information'">
        <label for="perfil-container" class="input-label">Logo da Comunidade*</label>
        <div class="perfil-container" id="perfil-container"
          [style.border]="!formErrors.imageLogo.valid && formErrors.imageLogo.message ? '1px solid red' : ''">
          <div class="image-container">
            <div class="image-selector-container">
              <div (click)="openImageSelectionModal('profile')" class="profile-image-background" [ngStyle]="{
                  'background-image':
                    modalData.imageProfile && modalData.imageProfile.length
                      ? 'url(' + modalData.imageProfile + ')'
                      : 'url(' + defaultUnknownImage + ')'
                }">
                <button type="button" class="select-image-button">
                  <img src="/assets/img/photo_camera.svg" alt="Selecionar imagem" />
                </button>
                <div class="info-image-profile"
                  matTooltip="Dimensões ideias da foto: 1200x675&#13;Os arquivos de imagens devem ser até de 2MB"
                  matTooltipClass="mat-tooltip" (click)="toggleTooltipProfile($event)">
                  <i class="bi bi-info"></i>
                </div>
                <div class="tooltip-content" *ngIf="
                    showTooltipProfile &&
                    (screenWidth < desktopLimitWidth || screenHeight < desktopLimitHeight) &&
                    !previewModalOpen &&
                    !imageSelectionModalOpen
                  " #tooltip>
                  <p>Dimensões ideais da foto: 1200x675</p>
                  <p>Os arquivos de imagens devem ser até de 2MB</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span class="error-message" *ngIf="!formErrors.imageLogo.valid && formErrors.imageLogo.message">
          {{ formErrors.imageLogo.message }}
        </span>
      </div>
      <div class="file-input-container" *ngIf="screens === 'information'">
        <label for="perfil-container" class="input-label">Capa da Comunidade*</label>
        <image-cropper *ngIf="cropMode" [imageChangedEvent]="changedImageEvent" [maintainAspectRatio]="true"
          [aspectRatio]="16 / 9" format="png" (imageCropped)="handleImageCropped($event)" [cropperMaxWidth]="720">
        </image-cropper>
        <label class="image-upload-label" for="image-upload" *ngIf="!cropMode"
          [style.border-color]="!formErrors.image.valid && formErrors.image.message ? 'red' : ''">
          <input id="image-upload" class="image-upload" accept="image/jpeg, image/png" type="file"
            (change)="handleFileChange($event)" />
          <i class="bi bi-card-image"></i>

          <span> Adicionar Banner </span>
          <p>A imagem deve ter no máximo 720x405</p>
          <span *ngIf="updateMode"> * ou deixe em branco caso não queira alterar </span>
        </label>
        <div style="display: flex; flex-direction: row; justify-content: center; margin-top: 1%">
          <button class="close" (click)="removeSelectedImage()" *ngIf="cropMode">
            <i class="bi bi-x-circle"></i>
          </button>
        </div>

        <span class="error-message" *ngIf="!formErrors.image.valid && formErrors.image.message">
          {{ formErrors.image.message }}
        </span>
      </div>

      <div class="community-fields-container w-100" *ngIf="screens === 'information'">
        <div class="row row-input-container">
          <!-- Campo "Nome da Comunidade" -->
          <div class="col-12 col-lg-6 input-container">
            <label for="community-name" class="input-label">Nome da Comunidade*</label>
            <div class="input-group community-input-container">
              <input maxlength="50"
                [style.border-color]="!formErrors.name.valid && formErrors.name.message ? 'red' : ''"
                [(ngModel)]="modalData.name" id="community-name" placeholder="ex. Comunidade sluper" type="text"
                (input)="createCommunityUrl($event)" class="form-control community-input" />
            </div>
            <span class="error-message" *ngIf="!formErrors.name.valid && formErrors.name.message">
              {{ formErrors.name.message }}
            </span>
          </div>

          <!-- Campo "Customize sua URL da comunidade" -->
          <div class="col-12 col-lg-6 input-container">
            <label for="community-site" class="input-label">* Customize sua URL da comunidade</label>
            <div class="input-group community-input-container">
              <div class="input-group-prepend">
                <span class="input-group-text" id="site-addon3"> https://sluper.bio/ </span>
              </div>
              <input maxlength="50"
                [style.border-color]="!formErrors.site.valid && formErrors.site.message ? 'red' : ''"
                [(ngModel)]="modalData.site" id="community-site" placeholder="Nome da sua empresa" type="text"
                class="form-control community-input" aria-describedby="site-addon3" (blur)="isProfileLinkAvailable()" />
            </div>
            <span class="error-message" *ngIf="!formErrors.site.valid && formErrors.site.message">
              {{ formErrors.site.message }}
            </span>
          </div>
        </div>

        <div class="description-textarea-container grid-full">
          <label for="community-description" class="input-label"> Descrição da Comunidade </label>
          <st-editor style="width: 100%"
            [style.border]="!formErrors.description.valid || countTextChars(modalData.description) > 1500 ? '1px solid red' : ''"
            [style.border-radius]="'6px'" [(ngModel)]="modalData.description" [config]="config"></st-editor>
          <div class="count-items">
            <span [ngClass]="countTextChars(modalData.description) > 1500 ? 'error-message' : ''"> {{
              countTextChars(modalData.description) }} / 1500 </span>
          </div>
          <span class="error-message" *ngIf="!formErrors.description.valid && formErrors.description.message">
            {{ formErrors.description.message }}
          </span>
        </div>
      </div>
    </section>
    <section class="community-fields-section" *ngIf="screens === 'about'">
      <div class="community-fields-container-1 w-100">
        <div class="input-container">
          <div class="description-textarea-container grid-full">
            <label for="community-description" class="input-label"> Regras da comunidade </label>
            <st-editor style="width: 100%"
              [style.border]="!formErrors.about.valid || countTextChars(modalData.about) > 1500 ? '1px solid red' : ''"
              [style.border-radius]="'6px'" [(ngModel)]="modalData.about" [config]="configRules"></st-editor>
            <div class="count-items">
              <span [ngClass]="countTextChars(modalData.about) > 1500 ? 'error-message' : ''"> {{
                countTextChars(modalData.about) }} / 1500 </span>
            </div>
            <span class="error-message" *ngIf="!formErrors.about.valid && formErrors.about.message">
              {{ formErrors.about.message }}
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="community-fields-section" *ngIf="screens === 'add'">
      <div class="input-container">
        <div class="search-person-container">
          <label class="input-label"> Adicione suas conexões a comunidade: </label>
          <div class="d-flex">
            <span>
              Importe um arquivo CSV
            </span>
            <button *ngIf="!csvFile" (click)="triggerFileInput()" class="highlight-button">Selecionar</button>
          </div>
          <div class="d-flex" *ngIf="csvFile">
            <i class="bi bi-file-earmark-arrow-down" style="font-size: 1.5rem; color: black;">
            </i>
            <span>
              {{ csvFile!.name }}
            </span>

            <button (click)="removeFile()" class="highlight-button-cancel">
              Cancelar
            </button>
            <!-- <button (click)="sendInviteByCsv()" class="highlight-button">
              Enviar
            </button> -->

          </div>


          <div class="d-flex">
            <input type="email" [(ngModel)]="formModel.email" (ngModelChange)="validateEmail()" maxlength="50"
              placeholder="exemplo@gmail.com" class="form-control w-50" />
            <!-- <button [disabled]="!formModel.isValid" (click)="addManualParticipant()" class="highlight-button">
              Convidar
            </button> -->
            <i style="font-size: 30px;" (click)="!formModel.isValid  ? '' : addManualParticipant()"
              class="bi bi-plus-square-fill pointer"></i>
          </div>
          <span class="error-message" *ngIf="formModel.errorMessage">
            {{ formModel.errorMessage }}
          </span>
          <section class="manual-invite-container">
            <div class="manual-invite-item px-3 mb-3" *ngFor="let participant of manualParticipants">
              <span class="request-profession">{{participant}}</span>
              <i class="btn bi bi-dash-square-fill quantity-people-button"
                (click)="rmManualParticipant(participant)"></i>
            </div>
          </section>

        </div>
        <div class="search-person-container" *ngIf="communityParticipantsFiltered.length">
          <input maxlength="50" (input)="filterCommunityParticipants($event)" placeholder="Procurar conexões"
            class="form-control" />
        </div>
        <div class="people-container">
          <div class="connection-request-card" *ngFor="let person of communityParticipantsFiltered">
            <div class="request-info">
              <div class="info">
                <span class="request-name">
                  {{ person.connection.name }}
                </span>
                <span class="request-profession multi-line" [innerHTML]="person.connection.presentation"></span>
              </div>
            </div>
            <i *ngIf="verifyCommunityParticipants(person)" class="btn bi bi-dash-square-fill quantity-people-button"
              (click)="rmCommunityParticipants(person)"></i>
            <i *ngIf="!verifyCommunityParticipants(person)" class="btn bi bi-plus-square-fill quantity-people-button"
              (click)="addCommunityParticipants(person)"></i>
          </div>
        </div>
      </div>
    </section>
  </section>
</div>

<ng-template #imageSelectionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Selecione a imagem</h4>
    <button type="button" class="close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label for="image-upload-profile" class="image-upload-label" *ngIf="!profileCropMode">
      <input id="image-upload-profile" class="image-upload" accept="image/jpeg, image/png, image/jpg" type="file"
        (change)="fileChangeEvent($event, 1, 1)" />
      <i class="bi bi-card-image"></i>
      <span> Clique aqui para selecionar e enviar a foto da logomarca </span>
      <small style="font-size: 15px; font-weight: bold;"> A imagem deve ter no máximo 200 x 99 </small>
      <small style="font-size: 11px; font-weight: 200;">O arquivo de imagem deve ter no máximo 2MB</small>
    </label>
    <ng-container>
      <image-cropper *ngIf="profileCropMode" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
        [aspectRatio]="200 / 99" format="png" [cropperMaxWidth]="200" [cropperMaxHeight]="99"
        (imageCropped)="imageCropped($event)">
      </image-cropper>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" *ngIf="profileCropMode" (click)="removeSelectedProfileImage()"
      translate>
      meuSluper.modalImagem.remove
    </button>
    <button type="button" class="btn btn-primary" (click)="confirmImageSelection(modal)"
      translate>meuSluper.modalImagem.confirmar</button>
  </div>
</ng-template>
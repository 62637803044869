<div class="modal-container">
  <header class="modal-header">
    <h4 class="modal-title" *ngIf="event.type !== 'COMUNIDADE'" translate>events.deleteEvent.title</h4>
    <h4 class="modal-title" *ngIf="event.type === 'COMUNIDADE'" translate>communitys.deleteCommunity.title</h4>
    <button type="button" class="close" aria-label="Fechar" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </header>

  <section class="modal-body">
    <p *ngIf="event.type !== 'COMUNIDADE'">Tem certeza que deseja remover o evento <b>{{ event.name }}</b>?</p>
    <p *ngIf="event.type === 'COMUNIDADE'">Tem certeza que deseja remover a comunidade <b>{{ event.name }}</b>?</p>
  </section>

  <section class="modal-footer">
    <button type="button" class="btn btn-secondary" translate (click)="closeModal()">events.deleteEvent.cancel</button>

    <button type="button" class="btn save-event-button" (click)="handleDeleteEvent()" translate>
      <div class="button-load-spinner" *ngIf="loading"></div>
      {{loading ? '' : 'events.deleteEvent.confirm'}}
    </button>
  </section>
</div>
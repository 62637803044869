import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TenantInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let tenantId = window.location.hostname;

    if (tenantId === 'localhost' || tenantId === "sluper-front-user.vercel.app") {
      tenantId = 'perfil.sluper.bio';
    }

    const cepUrlPattern = /^https:\/\/viacep\.com\.br\/ws\/\d{8}\/json\/$/;

    if (!cepUrlPattern.test(req.url)) {
      const modifiedReq = req.clone({
        setHeaders: {
          'X-Tenant-Id': tenantId,
        }
      });

      return next.handle(modifiedReq);
    }

    return next.handle(req);
  }
}

<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<div class="background-custom-section-body">
    <!-- <div class="alert-div">
        <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
            {{ alertMessage }}
        </ngb-alert>
    </div> -->
    <div class="background-container">
        <h4 class="modal-community-info-title">Editar imagens da comunidade</h4>

        <div class="background-option-container p-0" [style]="'background-color: #fff;'">
            <div class="background-option-title">
                <label for="image-company-logo" class="image-background-enabled-label"> Logo da empresa
                </label>
            </div>

            <div class="file-input-container" id="image-company-logo">
                <div class="perfil-container">
                    <div class="image-container">
                        <div class="image-selector-container">
                            <div (click)="openImageSelectionModal('profile')" class="profile-image-background"
                                [ngStyle]="{'background-image': imageItems.logo && imageItems.logo.length ?
                                'url(' + imageItems.logo + ')': 'url(' + defaultUnknownImage + ')'}">
                                <button type="button" class="select-image-button">
                                    <img src="/assets/img/photo_camera.svg" alt="Selecionar imagem" />
                                </button>
                                <div class="info-image-profile"
                                    matTooltip="Dimensões ideias da foto: 200x99&#13;Os arquivos de imagens devem ser inferior a 2 MB"
                                    matTooltipClass="mat-tooltip" (click)="toggleTooltipProfile($event)">
                                    <i class="bi bi-info"></i>
                                </div>
                                <div class="tooltip-content" *ngIf="
                          logoConfig.showTooltip                         " #tooltip>
                                    <p>
                                        Dimensões ideais da foto: 200x99
                                    </p>
                                    <p>
                                        Os arquivos de imagens devem ser inferior a 2 MB
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span class="error-message" *ngIf="!formErrors.imageLogo.valid && formErrors.imageLogo.message">
                        {{ formErrors.imageLogo.message }}
                    </span>
                </div>
            </div>
            <div class="background-option-title">
                <label for="image-company-cover" class="image-background-enabled-label"> Capa da empresa
                </label>
            </div>
            <div class="background-options-content background-image-option-container p-0" id="image-company-cover">
                <button class="background-image-selector" (click)="openImageSelectionModal('calling-card-background')"
                    [style]="imageItems.cover && imageItems.cover.length ? 'background-image: url(' + imageItems.cover + ')' : ''">
                    <span class="background-image-selector-label" *ngIf="!imageItems.cover || !imageItems.cover.length">
                        <i class="bi bi-file-image"></i>

                        Clique aqui para selecionar uma imagem para a capa
                    </span>
                    <p *ngIf="!imageItems.cover || !imageItems.cover.length">A imagem deve ter no máximo 720x405</p>
                </button>
                <span class="error-message" *ngIf="!formErrors.image.valid && formErrors.image.message">
                    {{ formErrors.image.message }}
                </span>

            </div>
        </div>
    </div>
    <div class="modal-community-info-buttons">
        <button type="button" class="cancel-button" (click)="closeModalBackground(false)">Cancelar</button>
        <button type="button" class="save-button" (click)="updateImages()">Atualizar</button>
    </div>
</div>

<ng-template #imageSelectionModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" translate>meuSluper.modalImagem.titulo</h4>
        <button type="button" class="close" aria-label="Fechar" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <label class="image-upload-label" for="image-upload" *ngIf="!imageConfig.cropMode">
            <input id="image-upload" class="image-upload" accept="image/jpeg, image/png, image/jpg" type="file"
                (change)="imageConfig.currentImageType === 'profile' ? fileChangeEventLogo($event, 1, 1) : fileChangeEvent($event, 1, 1)" />

            <i class="bi bi-card-image"></i>

            <span>Clique aqui para selecionar e enviar a sua foto</span>

            <span *ngIf="imageConfig.currentImageType === 'calling-card-background' && !imageConfig.cropMode"
                class="image-scale-orientations">
                Tamanho mínimo da imagem: 600x200
            </span>
            <span *ngIf="imageConfig.currentImageType === 'background' && !imageConfig.cropMode"
                class="image-scale-orientations">
                <strong>A imagem deve ter no máximo 200 x 99</strong>
            </span>
            <span *ngIf="imageConfig.currentImageType === 'profile' && !imageConfig.cropMode"
                class="image-scale-orientations">
                <strong>A imagem deve ter no máximo 200 x 99</strong>
            </span>
        </label>

        <ng-container *ngIf="imageConfig.currentImageType === 'profile' && imageConfig.cropMode">
            <image-cropper [imageChangedEvent]="imageItems.temporaryEvent" [maintainAspectRatio]="true"
                [aspectRatio]="200 / 99" format="png" [cropperMaxWidth]="200" [cropperMaxHeight]="99"
                (imageCropped)="imageCropped($event)"></image-cropper>
        </ng-container>

        <ng-container *ngIf="imageConfig.currentImageType === 'background' && imageConfig.cropMode">
            <image-cropper [imageChangedEvent]="imageItems.temporaryEvent" [maintainAspectRatio]="true"
                [aspectRatio]="16 / 9" format="png" (imageCropped)="imageCropped($event)" [cropperMaxWidth]="1200"
                [cropperMinWidth]="1200" [cropperMaxHeight]="720" [cropperMinHeight]="720"></image-cropper>
        </ng-container>

        <ng-container *ngIf="imageConfig.currentImageType === 'calling-card-background' && imageConfig.cropMode">
            <image-cropper [imageChangedEvent]="imageItems.temporaryEvent" [maintainAspectRatio]="true"
                [aspectRatio]="600 / 200" format="png" (imageCropped)="imageCropped($event)" [cropperMaxWidth]="600"
                [cropperMaxHeight]="200"></image-cropper>
        </ng-container>
    </div>

    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" *ngIf="imageConfig.cropMode" (click)=" imageConfig.currentImageType === 'calling-card-background'?
         removeSelectedImage() : removeSelectedLogoImage()" translate>
            meuSluper.modalImagem.remove
        </button> -->
        <button type="button" class="btn btn-primary" (click)="confirmImageSelection()" translate>
            meuSluper.modalImagem.confirmar
        </button>
    </div>
</ng-template>
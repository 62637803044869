import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ModalCommunityMembersComponent } from 'src/app/communities/modal-community-members/modal-community-members.component';
import { ModalDeletePostComponent } from 'src/app/communities/post/modal-delete-post/modal-delete-post.component';
import { EventsService } from 'src/app/events/events.service';
import { Connection } from 'src/app/model/connection.model';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { EventParticipant, SluperEvent } from 'src/app/model/event.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { AddMembersModalComponent } from '../add-members-modal/add-members-modal.component';
import {
  IMembersCategories,
  MembersCategoriesService,
} from './members-categories/members-categories-service/members-categories.service';
import { MembersCategoriesComponent } from './members-categories/members-categories.component';
import { MemberService } from './member-service/member.service';

@Component({
  selector: 'app-members-settings',
  templateUrl: './members-settings.component.html',
  styleUrls: ['./members-settings.component.css'],
})
export class MembersSettingsComponent implements OnInit {
  @ViewChild(MembersCategoriesComponent)
  childComponent!: MembersCategoriesComponent;

  // **Inputs**
  @Input() communityItem!: SluperEvent;
  @Input() userProfile: DocumentUserProfile | undefined;
  @Input() userProfilePlan!: IUserProfilePlan;

  // **Variáveis de Estado**
  public userConnections: Connection[] = [];
  public selectedTab: 'active' | 'inactive' = 'active';
  public screen: 'members-screen' | 'edit-member-category' = 'members-screen';
  public loading: boolean = false;

  public activeMembers: EventParticipant[] = [];
  public allActiveMembers: EventParticipant[] = [];
  public inactiveMembers: EventParticipant[] = [];
  public allInactiveMembers: EventParticipant[] = [];
  public allCommunityMembers: EventParticipant[] = [];

  public editCategoryItem: IMembersCategories = {} as IMembersCategories;

  constructor(
    private readonly dialog: MatDialog,
    private readonly toastrService: ToastService,
    private readonly eventsService: EventsService,
    private readonly membersSettingsService: MemberService
  ) {}

  // **Ciclo de Vida**
  async ngOnInit() {
    await this.getData();
  }

  // **Metodo de Carregamento de Dados**
  async getData(): Promise<void> {
    try {
      this.loading = true;
      await Promise.all([
        this.getCommunityParticipants(),
        this.getUserConnections(),
      ]);
    } catch (error) {
      console.error('Erro ao carregar dados:', error);
    } finally {
      this.loading = false;
    }
  }

  async getCommunityParticipants() {
    try {
      const active = await firstValueFrom(
        this.membersSettingsService.getCommunityMembers(
          this.communityItem.id,
          'active'
        )
      );
      const inactive = await firstValueFrom(
        this.membersSettingsService.getCommunityMembers(
          this.communityItem.id,
          'inactive'
        )
      );
      if (active && inactive) {
        this.allCommunityMembers = [...active, ...inactive];
        this.allActiveMembers = active;
        this.activeMembers = active;
        this.allInactiveMembers = inactive;
        this.inactiveMembers = inactive;
      }
    } catch (error) {
      this.toastrService.show('Erro ao obter membros da comunidade.', 'error');
    }
  }

  async getUserConnections() {
    try {
      if (!this.userProfile?.idUser) {
        return;
      }
      const resp = await firstValueFrom(
        this.eventsService.getUserConnections(this.userProfile.idUser)
      );
      if (resp?.body) {
        this.userConnections = resp.body;
      }
    } catch (error) {
      this.toastrService.show('Erro ao obter conexões do usuário.', 'error');
    }
  }

  async handleRefreshCategories() {
    if (this.childComponent) {
      await this.childComponent.getMembersCategories();
    }
  }

  handleOpenEditCategory(event: IMembersCategories) {
    this.screen = 'edit-member-category';
    this.editCategoryItem = event;
  }

  handleBackMembersScreen() {
    this.screen = 'members-screen';
  }

  async handleRemoveCategory(id: number) {
    this.handleBackMembersScreen();
    await this.childComponent.deleteMembersCategory(id);
  }

  // **Filtragem**
  filterMembersByName(event: Event, type: 'active' | 'inactive') {
    const target = event.target as HTMLInputElement;
    const filter = target.value.toLowerCase();

    if (!filter && type === 'active') {
      this.activeMembers = this.allActiveMembers;
      return;
    } else if (!filter && type === 'inactive') {
      this.inactiveMembers = this.allInactiveMembers;
      return;
    }

    if (type === 'active') {
      this.activeMembers = this.allActiveMembers.filter(member =>
        member.name.toLowerCase().includes(filter)
      );
    } else {
      this.inactiveMembers = this.allInactiveMembers.filter(member =>
        member.name.toLowerCase().includes(filter)
      );
    }
  }

  // **Modais**
  openAddMembersModal() {
    const dialogRef = this.dialog.open(AddMembersModalComponent, {
      width: '80vw',
      data: { allMembers: this.allActiveMembers, categoryParticipants: [] },
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          // Implementação futura após fechar modal
        }
      },
      error => {
        console.error('Erro ao fechar modal', error);
      }
    );
  }

  openModalCommunityMembers(type: 'add' | 'connect' | 'delete') {
    const dialogRef = this.dialog.open(ModalCommunityMembersComponent, {
      data: {
        participants: this.allActiveMembers.filter(
          p => p.idUser !== this.userProfile?.idUser
        ),
        userProfile: this.userProfile,
        userProfilePlan: this.userProfilePlan,
        userConnections: this.userConnections,
        type,
        communityItem: this.communityItem,
      },
      height: '80vh',
      width: '80vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Implementação futura após fechar modal
      }
    });
  }

  openInactiveMember(id: string) {
    if (this.userProfile?.idUser === id) return;
    const title = 'Inativar membro';
    const description =
      'Atenção! Você está prestes a inativar um membro. Você tem certeza que deseja inativar este membro?';

    const actionButton = 'Inativar';
    const dialogRef = this.dialog.open(ModalDeletePostComponent, {
      data: { id, type: '', title, description, actionButton: actionButton },
      maxWidth: '500px',
    });

    dialogRef.afterClosed().subscribe(
      async result => {
        if (result?.success) {
          this.loading = true;
          try {
            await firstValueFrom(
              this.membersSettingsService.handleInactiveMember(
                this.communityItem.id,
                id
              )
            );

            const user = this.allActiveMembers.find(p => p.idUser === id);
            if (user) {
              this.allActiveMembers = this.allActiveMembers.filter(
                p => p.idUser !== id
              );
              this.activeMembers = this.activeMembers.filter(
                p => p.idUser !== id
              );
              this.allInactiveMembers.push(user);
              this.inactiveMembers.push(user);
              this.allInactiveMembers = [
                ...new Map(
                  this.allInactiveMembers.map(u => [u.idUser, u])
                ).values(),
              ];
              this.inactiveMembers = [
                ...new Map(
                  this.inactiveMembers.map(u => [u.idUser, u])
                ).values(),
              ];
            }
            await this.childComponent.getMembersCategories();
          } catch (error) {
            this.toastrService.show('Erro ao inativar membro', 'error');
          } finally {
            this.loading = false;
          }
        }
      },
      error => {
        console.error('Erro ao fechar o modal:', error);
      }
    );
  }

  openActiveMember(id: string) {
    const title = 'Reativar membro';
    const description =
      'Atenção! Você está prestes a reativar um membro. Você tem certeza que deseja reativar este membro?';

    const actionButton = 'Reativar';
    const dialogRef = this.dialog.open(ModalDeletePostComponent, {
      data: { id, type: '', title, description, actionButton: actionButton },
      maxWidth: '500px',
    });

    dialogRef.afterClosed().subscribe(
      async result => {
        if (result?.success) {
          this.loading = true;
          try {
            await firstValueFrom(
              this.membersSettingsService.handleActiveMember(
                this.communityItem.id,
                id
              )
            );
            const user = this.allInactiveMembers.find(p => p.idUser === id);
            if (user) {
              this.allInactiveMembers = this.allInactiveMembers.filter(
                p => p.idUser !== id
              );
              this.inactiveMembers = this.inactiveMembers.filter(
                p => p.idUser !== id
              );
              this.allActiveMembers.push(user);
              this.activeMembers.push(user);
              this.allActiveMembers = [
                ...new Map(
                  this.allActiveMembers.map(u => [u.idUser, u])
                ).values(),
              ];
              this.activeMembers = [
                ...new Map(this.activeMembers.map(u => [u.idUser, u])).values(),
              ];
            }
            await this.childComponent.getMembersCategories();
          } catch (error) {
            this.toastrService.show('Erro ao reativar membro', 'error');
          } finally {
            this.loading = false;
          }
        }
      },
      error => {
        console.error('Erro ao fechar o modal:', error);
      }
    );
  }
}

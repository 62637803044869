import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-contact-announcement',
  templateUrl: './contact-announcement.component.html',
  styleUrls: ['./contact-announcement.component.css'],
})
export class ContactAnnouncementComponent implements OnInit, OnDestroy {
  @Output() closeScreen = new EventEmitter<void>();
  images: string[] = [
    'https://images.pexels.com/photos/3183150/pexels-photo-3183150.jpeg?auto=compress&cs=tinysrgb&w=800&h=600',
    'https://images.pexels.com/photos/5673505/pexels-photo-5673505.jpeg?auto=compress&cs=tinysrgb&w=800&h=600',
    'https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&cs=tinysrgb&w=800&h=600',
    'https://images.pexels.com/photos/669615/pexels-photo-669615.jpeg?auto=compress&cs=tinysrgb&w=800&h=600',
  ];
  formData = {
    name: '',
    email: '',
    phone: '',
    purpose: '',
    message: '',
  };

  selectedRating: number = 0;

  selectedImage: number = 0;
  intervalId: any;

  ngOnInit() {
    const element = document.getElementById('user-profile-screen-scroll');
    if (element) {
      element.scrollTop = 0;
    }
    this.startAutoSlide();
  }

  onSubmit() {
    alert('Formulário enviado com sucesso!');
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startAutoSlide() {
    this.intervalId = setInterval(() => {
      this.nextImage();
    }, 5000); // Troca a imagem a cada 5 segundos
  }

  selectImage(index: number) {
    this.selectedImage = index;
  }

  prevImage() {
    this.selectedImage =
      this.selectedImage === 0
        ? this.images.length - 1
        : this.selectedImage - 1;
  }

  nextImage() {
    this.selectedImage =
      this.selectedImage === this.images.length - 1
        ? 0
        : this.selectedImage + 1;
  }

  rate(stars: number) {
    this.selectedRating = stars;
  }

  handleCloseScreen() {
    this.closeScreen.emit();
  }
}

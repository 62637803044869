<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="body-div">
  <div *ngIf="visualization === 'creation'">
    <app-create-template (templateCreationError)="templateCreationError($event)" (templateSaved)="templateSaved()"
      (backToManageView)="backToManageView()"></app-create-template>
  </div>

  <div *ngIf="visualization === 'manage'">
    <header class="heading">
      <div class="title-container">
        <h1 class="title" translate>menu.businessTemplates</h1>
        <span class="sub-title" translate>businessTemplates.subtitle</span>
      </div>

      <button class="new-template-button"
        [disabled]="adminPlan.adminPlanLimits.templateQuantity !== -1 && adminPlan.adminPlanLimits.templateQuantity <= templates.length"
        (click)="changeVisualization()">
        Novo template
      </button>
    </header>

    <section class="templates-section">
      <div class="template-card" *ngFor="let template of templates"
        [style]="template.id === selectedTemplate ? 'border: 2px solid #8c65e7;' : ''"
        [style.cursor]="template.suspended ? 'not-allowed' : 'pointer'" (click)="handleSelectTemplate(template.id)">
        <div class="template-card-header">
          <div class="template-card-title">
            <input type="checkbox" name="select-template" [checked]="selectedTemplate  === template.id"
              class="select-template-input">
            <h2 class="template-name">{{template.name}}</h2>
          </div>

          <div class="template-actions">
            <div class="default-template-stamp" *ngIf="template.isDefault">
              <i style="font-size: 10px;" class="bi bi-award"></i>

              Template Padrāo
            </div>

            <button class="template-action-button" (click)="setDefaultTemplate($event, template.id)"
              matTooltip="Configurar como template padrāo" *ngIf="!template.isDefault">
              <i style="font-size: 12px;" class="bi bi-award"></i>
            </button>

            <button class="template-action-button" (click)="deleteTemplate($event, template.id)"
              matTooltip="Remover template">
              <i style="font-size: 12px;" class="bi bi-trash"></i>
            </button>
          </div>
        </div>

        <div class="template"
          [style]="template.formattedCss.cardBackgroundImageUrl ? 'background-image: url(' + template.formattedCss.cardBackgroundImageUrl + ')' : 'background:' + getBackground(template.formattedCss)">
          <div class="calling-card"
            [style]="template.formattedCss.callingCardImageUrl ? 'background-image: url(' + template.formattedCss.callingCardImageUrl + ')'  : ''">
            <img src="/assets/img/default_avatar_1.png" alt="expemplo avatar" class="avatar-pic">

            <div class="profile-informations"><span class="card-user-name" [style.color]="template.formattedCss"
                [style.font-family]="template.formattedCss.fontePerfil"
                [style.font-weight]="template.formattedCss.negritoPerfil ? 'bold' : undefined"
                [style.font-style]="template.formattedCss.italicoPerfil ? 'italic' : undefined">john Doe</span>
              <span class="user-role" [style.color]="template.formattedCss"
                [style.font-family]="template.formattedCss.fontePerfil"
                [style.font-weight]="template.formattedCss.negritoPerfil ? 'bold' : undefined"
                [style.font-style]="template.formattedCss.italicoPerfil ? 'italic' : undefined">CEO</span>
            </div>
          </div>

          <div class="template-links">
            <div class="default-link" [style.background-color]="template.formattedCss.preenchimento"
              style="border-style: solid" [style.border-width]="template.formattedCss.espessura + 'px'"
              [style.border-color]="template.formattedCss.contorno"
              [style.border-radius]="template.formattedCss.curvaBorda + 'px '"
              [style.box-shadow]="template.formattedCss.sombra">
              <span class="link-description" [style.color]="template.formattedCss.corFonte"
                [style.font-family]="template.formattedCss.fonte" style="word-break: break-all"
                [style.font-weight]="template.formattedCss.negrito ? 'bold' : undefined"
                [style.font-style]="template.formattedCss.italico ? 'italic' : undefined">Botāo tradicional</span>
            </div>

            <div class="classic-link" [style.background-color]="template.formattedCss.preenchimento"
              style="border-style: solid" [style.border-width]="template.formattedCss.espessura + 'px'"
              [style.border-color]="template.formattedCss.contorno"
              [style.border-radius]="template.formattedCss.curvaBorda + 'px'"
              [style.box-shadow]="template.formattedCss.sombra">
              <img
                src="https://images.unsplash.com/photo-1707197066378-36583db5e892?q=80&w=2340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="example-image" class="classic-link-image">
              <span class="link-description" [style.color]="template.formattedCss.corFonte"
                [style.font-family]="template.formattedCss.fonte" style="word-break: break-all"
                [style.font-weight]="template.formattedCss.negrito ? 'bold' : undefined"
                [style.font-style]="template.formattedCss.italico ? 'italic' : undefined">Botāo com imagem</span>
            </div>
          </div>

          <div class="suspended-template" *ngIf="template.suspended">
            <div class="cta-container">
              <i class="bi bi-lock-fill lock-ico"></i>

              <span class="suspended-template-text">Template bloqueado: limite de uso do plano ultrapassado</span>

              <a href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal" target="_blank"
                class="update-plan-button">
                Atualizar Plano
              </a>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="profile-settings-section">
      <div class="profile-configuration-container">
        <div class="title-container">
          <span class="subtitle-text" translate>businessTemplates.permitirApenasNfc.titulo</span>
        </div>

        <div class="input-div">
          <label class="labels-text" translate>businessTemplates.permitirApenasNfc.ativadoDesativado</label>

          <div class="external-image-toggle-container">
            <div class="form-check form-switch" style="margin-bottom: 0;">
              <input [(ngModel)]="allowAccessOnlyByNFC" id="allow-access" type="checkbox" role="switch"
                class="form-check-input" name="active" />
            </div>

            <label for="allow-access" class="labels-text" *ngIf="allowAccessOnlyByNFC"
              translate>businessTemplates.permitirApenasNfc.ativado</label>

            <label for="allow-access" class="labels-text" *ngIf="!allowAccessOnlyByNFC"
              translate>businessTemplates.permitirApenasNfc.desativado</label>
          </div>
        </div>
      </div>

      <div class="profile-configuration-container">
        <div class="title-container">
          <span class="subtitle-text" translate>businessTemplates.permitirPesquisaPublica.titulo</span>
        </div>

        <div class="input-div">
          <label class="labels-text" translate>businessTemplates.permitirPesquisaPublica.ativadoDesativado</label>

          <div class="external-image-toggle-container">
            <div class="form-check form-switch" style="margin-bottom: 0;">
              <input [(ngModel)]="publicSearch" id="allow-access" type="checkbox" role="switch" class="form-check-input"
                name="active" />
            </div>

            <label for="allow-access" class="labels-text" *ngIf="publicSearch"
              translate>businessTemplates.permitirPesquisaPublica.ativado</label>

            <label for="allow-access" class="labels-text" *ngIf="!publicSearch"
              translate>businessTemplates.permitirPesquisaPublica.desativado</label>
          </div>
        </div>
      </div>
    </section>

    <section class="users-section">
      <div class="title-container">
        <h2 class="title" translate>businessTemplates.usersSectionTitle</h2>
      </div>

      <div class="users-list">
        <div class="user-card" *ngFor="let user of users" (click)="handleSelectUsers(user.id)">
          <div class=" user-card-title">
            <input type="checkbox" name="select-template" class="select-template-input"
              [checked]="selectedUserIds.includes(user.id)">

            <div class="user-profile-container">
              <img src="/assets/img/default_avatar_2.png" alt="user-avatar" class="user-avatar">

              <div class="user-infos">
                <span class="user-name" *ngIf="user.name">{{user.name}}</span>
                <span class="user-email">{{user.email}}</span>
              </div>
            </div>
          </div>

          <div class="user-actions">
            <button *ngIf="!!user.linkedTemplate" class="user-action-button"
              [matTooltip]="'desvincular usuário do template [' +  user.linkedTemplate.name + ']'"
              (click)="unlinkTemplate(user.id)">
              <i class="user-action-button-icon bi bi-lock-fill"></i>
            </button>

            <a [href]="'http://sluper.bio/' + user.uri" target="_blank" rel="noopener noreferrer" *ngIf="user.uri">
              <button class="user-action-button">
                <i class="user-action-button-icon bi bi-arrow-up-right"></i>
              </button>
            </a>
          </div>
        </div>
      </div>

      <div class="buttons-container">
        <button class="apply-tamplate-button" (click)="applyCorpTemplate('COPY')">
          <i class="bi bi-clipboard-check"></i>
          Cópia independente de template</button>
        <button class="apply-tamplate-button" (click)="applyCorpTemplate('SYNC')">
          <i class="bi bi-people-fill"></i>
          Vinculo consistente do template</button>
      </div>
    </section>
  </div>
</div>
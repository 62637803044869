import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventParticipant } from '../../../../../model/event.model';
import { Observable } from 'rxjs';

export interface IAllCommunityMembers {
  active: EventParticipant[];
  inactive: EventParticipant[];
}

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  // baseUrl = 'http://localhost:3001/api/bizConnect/events';
  baseUrl = environment.eventsApiUrl;
  baseAdmUrl = environment.administradorUrl;

  constructor(protected http: HttpClient) {}

  getCommunityMembers(
    communityId: string,
    type: 'active' | 'inactive'
  ): Observable<EventParticipant[]> {
    return this.http.get<EventParticipant[]>(
      `${this.baseUrl}/participantsByEventId/${communityId}?status=${type}`
    );
  }

  handleInactiveMember(communityId: string, memberId: string): Observable<void> {
    return this.http.delete<void>(
      `${this.baseUrl}/participantsByEventId/${communityId}/${memberId}`
    );
  }

  handleActiveMember(communityId: string, memberId: string): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl}/participantsByEventId/${communityId}/${memberId}/activate`,
      null
    );
  }
}

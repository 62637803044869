import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Post } from 'src/app/communities/post/posts.service';

export interface FavoritesResponse {
  currentPage: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
  items: Post[];
}

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  private baseUrl: string = 'https://backend-sluper-net.fly.dev/';

  constructor(private http: HttpClient) { }

  getFavoritesPosts(data: {
    userId: string;
    communityId?: string;
    startDate?: string;
    endDate?: string;
    pageNumber: number;
    pageSize: number;
  }): Observable<FavoritesResponse> {
    const params = new URLSearchParams();

    params.append('UserId', data.userId);
    if (data.communityId) params.append('CommunityId', data.communityId);
    if (data.startDate) params.append('StartDate', data.startDate);
    if (data.endDate) params.append('EndDate', data.endDate);
    params.append('pageNumber', data.pageNumber.toString());
    params.append('pageSize', data.pageSize.toString());

    const url = `${this.baseUrl}Favorites?${params.toString()}`;

    return this.http.get<FavoritesResponse>(url);
  }


  unfavoritePost(data: { postId: number; userId: string }): Observable<any> {
    const url = `${this.baseUrl}Favorites/${data.postId}?userId=${data.userId}`;
    return this.http.delete(url);
  }
  favoritePost(data: { postId: number; userId: string }): Observable<boolean> {
    const url = `${this.baseUrl}Favorites`;
    return this.http.post<boolean>(url, data);
  }
}  
<div class="payment-info">
    <div class="payment-header">Informação de pagamento</div>
    <div class="payment-due">Vencimento todo dia 10</div>
    <div class="card-section">
        <div class="card-header">Seu cartão</div>
        <div class="card">
            <div class="card-details">
                <div class="card-name">Master Card</div>
                <div class="card-number">Cartão de crédito terminando em •••• 7604</div>
            </div>
            <div class="card-actions">
                <div class="action edit">
                    <div class="edit-image"></div>
                    <span>Editar</span>
                </div>
                <div class="action delete">
                    <div class="edit-delete"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="add-payment-method">
        <div class="add-header">Adicionar método de pagamento</div>
        <div class="add-card">
            <i class="bi bi-plus"></i>
        </div>
    </div>
    <div class="cancel-account">
        <button>Cancelar Conta</button>
    </div>
</div>
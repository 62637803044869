<app-loading-spinner [customBg]="'transparent'" *ngIf="loadingState.global"></app-loading-spinner>

<div class="communities-screen">
  <!-- SIDEBAR -->
  <app-side-menu *ngIf="showSidebar" [isAdmin]="isUserCommunityOwner"
                 (openSideMenuOption)="openSideMenuOptionsModal($event)" [communityCategories]="communityCategories"
                 [channelsWithoutCategory]="channelsWithoutCategory" (createChannel)="openCreateChannel($event)"
                 (selectChannel)="onSelectChannel($event)" (selectScreen)="onSelectScreen($event)">
  </app-side-menu>

  <!-- TELAS ESPECÍFICAS -->
  <app-community-mentions *ngIf="screenCommunity === 'mentions'" [userProfile]="userProfile"
                          [communityItem]="communityItem" [selectedChannel]="selectedChannel"
                          [userProfilePlan]="userProfilePlan"
                          [userConnections]="userConnections" [participants]="participants"
                          [isUserCommunityOwner]="isUserCommunityOwner">
  </app-community-mentions>

  <app-community-favorites *ngIf="screenCommunity === 'saved'" [userProfile]="userProfile"
                           [communityItem]="communityItem" [selectedChannel]="selectedChannel"
                           [userProfilePlan]="userProfilePlan"
                           [userConnections]="userConnections" [participants]="participants"
                           [isUserCommunityOwner]="isUserCommunityOwner"></app-community-favorites>

  <app-community-warnings *ngIf="screenCommunity === 'alerts'" [isUserCommunityOwner]="isUserCommunityOwner"
                          [userProfile]="userProfile" [communityItem]="communityItem"
                          [selectedChannel]="selectedChannel"
                          [userProfilePlan]="userProfilePlan" [userConnections]="userConnections"
                          [participants]="participants">
  </app-community-warnings>

  <app-community-store *ngIf="screenCommunity === 'store'" [userProfile]="userProfile" [communityItem]="communityItem"
                       [userProfilePlan]="userProfilePlan"
                       [isUserCommunityOwner]="isUserCommunityOwner"></app-community-store>

  <app-community-settings *ngIf="screenCommunity === 'settings'" [userProfile]="userProfile"
                          [userConnections]="userConnections" [communityItem]="communityItem"
                          [userProfilePlan]="userProfilePlan"
                          [isUserCommunityOwner]="isUserCommunityOwner"></app-community-settings>

  <app-community-finance *ngIf="screenCommunity === 'finance'" [userProfile]="userProfile"
                         [communityItem]="communityItem" [userProfilePlan]="userProfilePlan"
                         (redirect)="redirectToPage($event)"
                         [isUserCommunityOwner]="isUserCommunityOwner"></app-community-finance>

  <!-- TELA PRINCIPAL (INICIAL) -->
  <div class="communities-screen-content" *ngIf="screenCommunity === 'initial'">
    <!-- CABEÇALHO / HEADER DA COMUNIDADE -->
    <section class="section-header">
      <div class="back" *ngIf="showBack">
        <span class="pointer" (click)="goBack()"><i class="bi bi-arrow-left mr-1"></i> Voltar</span>
      </div>

      <div class="communities-header" [ngStyle]="{ 'background-image': 'url(' + communityItem.imageLink + ')' }">
        <div class="community-informations">
          <div class="community-informations-top row">
            <div class="community-informations-items">
              <div class="title-items">
                <h4>{{ communityItem.name }}</h4>
              </div>

              <div class="description-items">
                <span [ngClass]="{ limited: !readMoreDescription }" [innerHTML]="sanitizedBio"> </span>
                <div class="line"></div>
                <div class="read-more d-flex justify-content-end">
                  <span class="pointer" (click)="openModalSeeCommunityBio()">
                    {{ readMoreDescription ? 'Leia menos' : 'Leia mais' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- CORPO / PRINCIPAL -->
    <section class="section-body-content row">
      <section class="scroll-community-screen col-sm-12 col-md-8 col-lg-8 mb-3">
        <!-- CAMPO PARA CRIAR POST -->
        <div class="user-post-container pointer" (click)="openModalInputPost()" *ngIf="selectedChannel">
          <div class="user-post">
            <img *ngIf="userProfile?.uriImageProfile && userProfile!.uriImageProfile!.length > 0; else defaultImage"
                 [src]="userProfile!.uriImageProfile" alt="Imagem de Perfil" />
            <ng-template #defaultImage>
              <img src="/assets/img/imagem_perfil.png" alt="Default Profile" />
            </ng-template>
            <textarea class="pointer" name="user-post-text" id="user-post-input"
                      placeholder="Compartilhe com seu grupo"></textarea>
          </div>
          <div class="user-post-attachment">
            <div class="user-attachment">
              <i class="bi bi-images"></i>
              <i class="bi bi-camera-video"></i>
              <i class="bi bi-paperclip"></i>
            </div>
          </div>
        </div>

        <!-- LISTA DE POSTS -->
        <div class="post-list">
          <app-post *ngFor="let post of posts; let i = index; trackBy: trackByPostId" [attr.id]="'post-' + i"
                    [post]="post" [userProfile]="userProfile" [communityItem]="communityItem"
                    [selectedChannel]="selectedChannel" [userProfilePlan]="userProfilePlan"
                    [userConnections]="userConnections"
                    [participants]="participants" [isUserCommunityOwner]="isUserCommunityOwner"
                    (deletePost)="deletePost($event)" (refreshPosts)="onRefreshPosts($event)"
                    (refreshFavorites)="getUserFavoritesPosts()" (refreshInteractions)="onRefreshLikes($event)">
          </app-post>

          <span class="no-items" *ngIf="selectedChannel && posts.length === 0 && !postsPagination.isLoading">Ainda não
            há posts aqui. Que tal ser o
            primeiro a compartilhar algo?</span>
          <span class="no-items" *ngIf="!selectedChannel">Selecione um canal para acessar os posts</span>
          <span class="no-items" *ngIf="postsPagination.isLoading">Carregando posts...</span>
        </div>
      </section>

      <!-- SIDEBAR DIREITA -->
      <aside class="register-event col-sm-12 col-md-4 col-lg-4">
        <!-- GESTÃO DA COMUNIDADE (BOTÕES) -->
        <div class="container-fluid container-management" *ngIf="isUserCommunityOwner && verifyUserIsPremium()">
          <div class="create-event-button mt-2" *ngIf="!isDeploy()">
            <button class="custom-button-border-red col-12" (click)="openDeleteEventModal()">
              <i class="bi bi-trash danger"></i> Remover Comunidade
            </button>
          </div>

          <div class="card my-4">
            <div class="card-header">
              <h4 class="personalized-title">Gestão da Comunidade</h4>
            </div>

            <!-- VERSÃO BOTOES ICONE OU BOTOES TEXTO (escolha 1 estilo e remova o outro) -->
            <div class="card-body card-body-buttons-icons d-flex gap-3">
              <button class="sluper-icon-button" matTooltip="Criar Eventos" matTooltipPosition="above"
                      (click)="openEventTypeModal()">
                <i class="bi bi-calendar-plus"></i>
              </button>

              <button class="sluper-icon-button" matTooltip="Adicionar Membros" matTooltipPosition="above"
                      (click)="openModalCommunityMembers('add')">
                <i class="bi bi-person-plus"></i>
              </button>

              <button class="sluper-icon-button" matTooltip="Remover Membros" matTooltipPosition="above"
                      (click)="openModalCommunityMembers('delete')">
                <i class="bi bi-person-dash"></i>
              </button>

              <button class="sluper-icon-button" matTooltip="Editar Nome e Bio" matTooltipPosition="above"
                      (click)="openModalEditCommunity()">
                <i class="bi bi-pencil-square"></i>
              </button>

              <button
                *ngIf="(documentUser && documentUser.role === 'adm') || userProfilePlan.profilePlanLimits.includeEvents"
                class="sluper-icon-button" matTooltip="Editar Imagens" matTooltipPosition="above"
                (click)="openModalBackgroundColor()">
                <i class="bi bi-image"></i>
              </button>
            </div>
            <div class="card-body card-body-buttons d-flex gap-3">
              <div class="create-event-button full-width" *ngIf="isUserCommunityOwner && verifyUserIsPremium()">
                <button (click)="openEventTypeModal()" id="create-event"
                        class="slupper-button-default equal-size-buttons">
                  Criar Eventos
                </button>
              </div>

              <div class="create-event-button" *ngIf="isUserCommunityOwner && verifyUserIsPremium()">
                <button (click)="openModalCommunityMembers('add')" id="add-members"
                        class="slupper-button-default equal-size-buttons">
                  Adicionar Membros
                </button>
              </div>

              <div class="create-event-button" *ngIf="isUserCommunityOwner && verifyUserIsPremium()">
                <button (click)="openModalCommunityMembers('delete')" id="delete-members"
                        class="slupper-button-default equal-size-buttons">
                  Remover Membros
                </button>
              </div>

              <div class="create-event-button" *ngIf="isUserCommunityOwner && verifyUserIsPremium()">
                <button (click)="openModalEditCommunity()" id="edit-name-bio"
                        class="slupper-button-default equal-size-buttons">
                  Editar nome e bio
                </button>
              </div>

              <div class="create-event-button" *ngIf="isUserCommunityOwner && verifyUserIsPremium()">
                <button id="edit-image" class="slupper-button-default equal-size-buttons"
                        (click)="openModalBackgroundColor()" *ngIf="
                    (documentUser && documentUser.role === 'adm' && verifyUserIsPremium()) ||
                    (userProfilePlan.profilePlanLimits.includeEvents && verifyUserIsPremium())
                  ">
                  Editar imagens
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- REGRAS DA COMUNIDADE -->
        <div class="community-rules">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="personalized-title">Regras da comunidade</h4>
            <i *ngIf="isUserCommunityOwner && verifyUserIsPremium()" (click)="openModalCommunityRules()"
               class="bi bi-pencil-square pointer">
            </i>
          </div>
          <span class="community-rules-refreshed-at"> Atualizado: {{ getFormattedDate(communityItem.startDate) }}
          </span>
          <span class="community-rules-title"> Antes de publicar, leia as regras do grupo. </span>
          <div class="read-rules">
            <span class="span-button pointer" (click)="openModalSeeCommunityRules()">
              {{ !readMoreRules ? 'Ver Regras' : 'Ver Menos' }}
            </span>
          </div>
        </div>

        <!-- PRÓXIMOS EVENTOS -->
        <div class="next-events">
          <div class="d-flex align-items-center">
            <h4 class="personalized-title">Próximos eventos</h4>
            <span class="quantity-items ml-2">{{ nextEvents.length }}</span>
          </div>

          <div class="next-events-cards" *ngFor="let event of nextEvents | slice: 0 : 5; let i = index">
            <div class="next-events-card">
              <div class="next-events-card-information">
                <h6 class="event-name">{{ event.name }}</h6>
                <span class="event-date">{{ getFormattedDate(event.startDate) }}</span>
              </div>
              <span class="span-button pointer" (click)="joinEvent(event)">Ver evento</span>
            </div>
            <div class="separator" *ngIf="i < 4"></div>
          </div>

          <div class="read-rules mt-3">
            <span class="span-button pointer" (click)="showAllEvents.emit()"> Ver todos </span>
          </div>
        </div>

        <!-- CALENDÁRIO (Exemplo com Angular Material DateRange) -->
        <div class="calendar">
          <h4 class="personalized-title w-100">Calendário</h4>
          <mat-form-field appearance="fill" class="demo-inline-calendar-card mt-3">
            <mat-label>Escolha as datas</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Data inicial" />
              <input matEndDate placeholder="Data final" />
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <!-- MEMBROS DA COMUNIDADE -->
        <div class="community-members">
          <div class="d-flex align-items-center">
            <h4 class="personalized-title">Membros da Comunidade</h4>
          </div>

          <div class="community-members-cards">
            <div class="community-members-card pointer"
                 *ngFor="let member of participants | slice: 0 : (seeMoreCommunityMembers ? participants.length : 8); let i = index">
              <div class="comment-information">
                <div class="comment-information-left">
                  <div class="comment-information-img">
                    <img [src]="
                        member.uriImageProfile && member.uriImageProfile.length > 0
                          ? member.uriImageProfile
                          : '/assets/img/imagem_perfil.png'
                      " alt="profile-pic" />
                  </div>
                </div>
                <div class="comment-information-center">
                  <div class="comment-information-info" (click)="openParticipantProfile(member)">
                    <div class="comment-information-text">
                      <span class="comment-information-name">
                        {{ member.name && member.name.length > 0 ? member.name : 'Sem nome' }}
                      </span>
                      <span class="comment-information-date"
                            [innerHTML]="member.bio || member.presentation || 'Sem bio/apresentação'">
                      </span>
                    </div>
                  </div>
                </div>

                <!-- BOTÕES DE CONEXÃO -->
                <div class="connection-button-option" *ngIf="isConnectParticipant(member.idUser!) === 'disconnected'">
                  <button class="btn btn-primary btn-sm icon-button"
                          (click)="handleConnect($event, member.idUser, member, i)">
                    <i class="bi bi-person-plus"></i>
                  </button>
                </div>
                <div class="connection-button-option" *ngIf="isConnectParticipant(member.idUser!) === 'connected'">
                  <button class="btn btn-primary btn-sm icon-button"
                          (click)="handleRemoveConnection($event, member.idUser)">
                    <i class="bi bi-person-dash"></i>
                  </button>
                </div>
                <div class="connection-button-option" *ngIf="isConnectParticipant(member.idUser!) === 'pending'">
                  <button class="btn btn-secondary btn-sm icon-button" disabled>
                    <i class="bi bi-hourglass-split"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="read-rules mt-3">
            <span class="span-button pointer" (click)="openModalCommunityMembers('connect')">
              {{ !seeMoreCommunityMembers ? 'Ver todos' : 'Ver menos' }}
            </span>
          </div>
        </div>

        <!-- ÚLTIMOS POSTS -->
        <div class="community-members">
          <div class="d-flex align-items-center">
            <h4 class="personalized-title">Últimos posts</h4>
          </div>
          <div class="just-posted-cards mt-3">
            <div class="just-posted-card pointer mb-3" *ngFor="let post of posts | slice: 0 : 10; let i = index"
                 (click)="scrollToPost(i)">
              <div class="comment-information-center">
                <div class="comment-information-info">
                  <!-- <span class="comment-information-name">{{ toCapitalCase(post.title) || 'Post Sem Título' }}</span> -->
                  <!-- <span class="comment-information-name" [innerHTML]="post.content"></span> -->
                  <span class="comment-information-name">{{ truncateTextSemHtml(post.content) }}</span>
                  <span class="comment-information-date">{{ toCapitalCase(post.user.name) }}</span>
                </div>
              </div>
              <div class="separator" *ngIf="i < posts.length - 1"></div>
            </div>
          </div>
        </div>
      </aside>
    </section>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ModalDeletePostComponent } from 'src/app/communities/post/modal-delete-post/modal-delete-post.component';
import { Connection } from 'src/app/model/connection.model';
import { EventParticipant, SluperEvent } from 'src/app/model/event.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { CreateCategoryMembersModalComponent } from '../../create-category-members-modal/create-category-members-modal.component';
import {
  IMembersCategories,
  MembersCategoriesService,
} from './members-categories-service/members-categories.service';
import { DocumentUserProfile } from '../../../../../model/document-user-profile.model';

@Component({
  selector: 'app-members-categories',
  templateUrl: './members-categories.component.html',
  styleUrls: ['./members-categories.component.css'],
})
export class MembersCategoriesComponent implements OnInit {
  @Input() allActiveMembers: EventParticipant[] = [];
  @Input() communityItem!: SluperEvent;
  @Input() userConnections!: Connection[];
  @Input() userProfile: DocumentUserProfile | undefined;

  @Output() openEditCategory = new EventEmitter<IMembersCategories>();
  public loading: boolean = false;
  public categories: IMembersCategories[] = [];

  constructor(
    private readonly dialog: MatDialog,
    private readonly toastrService: ToastService,
    private readonly membersCategoriesService: MembersCategoriesService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getMembersCategories();
  }

  async getMembersCategories() {
    try {
      this.loading = true;
      this.categories = await firstValueFrom(
        this.membersCategoriesService.getCategoryMembersByStatus(
          this.communityItem.id,
          'active',
          'active'
        )
      );
    } catch (error) {
      console.error(error);
      this.toastrService.show('Erro ao obter categorias', 'error');
    } finally {
      this.loading = false;
    }
  }

  async deleteMembersCategory(id: number) {
    try {
      this.loading = true;
      await firstValueFrom(
        this.membersCategoriesService.deleteCategoryMembers(id)
      );
      this.categories = this.categories.filter(item => item.id != Number(id));
      this.toastrService.show('Categoria removida com sucesso', 'success');
    } catch (error) {
      this.toastrService.show('Erro ao deletar categoria', 'error');
    } finally {
      this.loading = false;
    }
  }

  openCreateCategoryMembersModal() {
    const dialogRef = this.dialog.open(CreateCategoryMembersModalComponent, {
      width: '600px',
      data: {
        members: this.allActiveMembers,
        communityItem: this.communityItem,
        userProfile: this.userProfile,
      },
      autoFocus: true,
      restoreFocus: true,
    });

    dialogRef
      .afterClosed()
      .subscribe(
        async (result: { success: boolean; data?: IMembersCategories }) => {
          if (result && result.success && result.data) {
            await this.getMembersCategories();
          }
        }
      );
  }

  openRemoveCategory(id: number) {
    const title = 'Remover categoria';
    const description =
      'Atenção! Você está prestes a remover uma categoria de membro. Você tem certeza que deseja remover esta categoria?';

    const dialogRef = this.dialog.open(ModalDeletePostComponent, {
      data: { id: id, type: '', title: title, description: description },
      maxWidth: '500px',
    });

    dialogRef.afterClosed().subscribe(
      async (result?: { success?: boolean }) => {
        if (result?.success) {
          await this.deleteMembersCategory(id);
        }
      },
      error => {
        console.error('Erro ao fechar o modal:', error);
      }
    );
  }

  handleOpenEditCategory(category: IMembersCategories) {
    this.openEditCategory.emit(category);
  }
}

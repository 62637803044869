<app-loading-spinner [bgTransparent]="true" *ngIf="loading"></app-loading-spinner>



<div class="comment-screen">
    <div class="comment-information">
        <div class="comment-information-left">
            <div class="comment-information-img">
                <img *ngIf="comment?.user?.profilePicture && comment.user.profilePicture.length > 0"
                    [src]="comment.user.profilePicture" alt="profile-pic">
                <img *ngIf="!comment.user?.profilePicture" src="/assets/img/default_avatar_1.png" alt="profile-pic">
            </div>
        </div>
        <div class="comment-information-center">
            <div class="comment-information-info">
                <span class="comment-information-name pointer"
                    (click)="openParticipantProfile($event,comment.userId)">{{ comment.user.name }}</span>
                <span class="comment-information-date">{{ transformDate(comment.createdAt) }}</span>

                <p class="comment-text" [innerHTML]="sanitizedComment"></p>

                <div class="comment-options">
                    <div>
                        <span class="comment-likes">{{ comment.likes }}</span>
                        <i *ngIf="!comment.like" (click)="likePost(comment.id)" class="bi bi-hand-thumbs-up "></i>
                        <i *ngIf="comment.like" (click)="unlikePost(comment.like.id)"
                            class="bi bi-hand-thumbs-up-fill "></i>
                    </div>
                    <!-- <div>
                        <span class="comment-likes">0</span>
                        <i class="bi bi-hand-thumbs-down "></i>
                    </div> -->
                    <span class="answer-comment " (click)="handleAnswerComment(comment.id)">Responder</span>
                </div>
            </div>
            <div class="comment-divider">
            </div>
        </div>
        <div class="comment-information-right ">
            <!-- <img *ngIf="verifyRemoveMention()" class="remove-mention" (click)="removeMention(comment)"
                src="../../../../assets/img/remove-mention.png" alt="Remover menção"> -->
            <span class="comment-information-delete-comment"
                *ngIf="showDeleteButton &&(isUserCommunityOwner || comment.userId === userProfile?.idUser)"
                (click)="handleDeleteComment(comment.id)">
                <i class="bi bi-trash-fill"></i>
            </span>
        </div>
    </div>
    <div class="answers-comment" *ngIf="comment.answers && comment.answers.length">
        <app-answers-comment *ngFor="let answersComment of  comment.answers" (likeComment)="likePost($event)"
            (unlikeComment)="unlikePost($event)" (deleteComment)="handleDeleteComment($event)"
            (removeComment)="handleRemoveComment($event)" [answersComment]="answersComment" [userProfile]="userProfile"
            [showDeleteButton]="showDeleteButton" [isUserCommunityOwner]="isUserCommunityOwner"></app-answers-comment>
    </div>
</div>
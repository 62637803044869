<div class="community-container">
  <h4 class="community-title">Selecione uma comunidade</h4>
  <div class="community-list">
    <div *ngFor="let community of myCommunities" class="community-item"
         (keydown.enter)="handleSelectCommunity($event, community)"
         (click)="handleSelectCommunity($event, community)">
      <img
        [src]="community.imageLink && community.imageLink.length > 0 ? community.imageLink : '/assets/img/Marca Sluper Colorida.svg'"
        [alt]="'Community' + community.name" class="community-photo" />
      <span class="community-name">{{ community.name }}</span>
    </div>
  </div>
  <div class="d-flex justify-content-end gap-2">
    <button (click)="cancelSelection()" style="border: 1px solid red" *ngIf="!isFromLogin"
            class="select-button-cancel">
      Cancelar
    </button>
    <button class="select-button" (click)="confirmSelection()">Entrar</button>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { SluperEvent } from 'src/app/model/event.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';
import { MatDialog } from '@angular/material/dialog';
import { SeeAnnouncementComponent } from './see-announcement/see-announcement.component';
import { ContactAnnouncementComponent } from './contact-announcement/contact-announcement.component';
import { StoreService } from './store-service/store.service';
import { firstValueFrom } from 'rxjs';
import { ToastService } from '../../new-sluper/core/service/toast.service';

@Component({
  selector: 'app-community-store',
  templateUrl: './community-store.component.html',
  styleUrls: ['./community-store.component.css'],
})
export class CommunityStoreComponent implements OnInit {
  @Input() isUserCommunityOwner: boolean = false;
  @Input() userProfile: DocumentUserProfile | undefined;
  @Input() communityItem!: SluperEvent;
  @Input() userProfilePlan!: IUserProfilePlan;
  public loading: boolean = false;
  public screens:
    | 'store-screen'
    | 'contact-screen'
    | 'company-advertisement-screen' = 'store-screen';

  constructor(
    private readonly dialog: MatDialog,
    private storeService: StoreService,
    private toastrService: ToastService
  ) {}

  async ngOnInit() {
    await this.getStores();
  }

  async getStores() {
    try {
      this.loading = true;
      const resp = await firstValueFrom(
        this.storeService.getStores(this.communityItem.id)
      );
    } catch (error) {
      this.toastrService.show(
        'Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
        'error'
      );
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  handleOpenCompanyAdvertisement() {
    this.screens = 'company-advertisement-screen';
  }

  handleCloseScreens() {
    this.screens = 'store-screen';
  }

  handleSubmittedCompanyAdvertisement() {
    this.handleOpenCompanyAdvertisement();
  }

  openModalSeeAnnouncement(data: any) {
    this.dialog
      .open(SeeAnnouncementComponent, {
        data: {
          announcement: data,
        },
        width: '600px',
      })
      .afterClosed()
      .subscribe(response => {
      });
  }

  openModalContactAnnouncement(event: Event, data: any) {
    event.stopPropagation();
    this.screens = 'contact-screen';
  }
}

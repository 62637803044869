<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<app-loading-spinner [bgTransparent]="true" *ngIf="loading"></app-loading-spinner>

<div class="dropdown">
  <button class="btn btn-light position-relative" type="button" id="notificationDropdown" data-bs-toggle="dropdown"
    aria-expanded="false">
    <i class="bi bi-bell"></i>
    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
      {{ notifications.length }}
    </span>
  </button>

  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="notificationDropdown">
    <ng-container *ngIf="notifications.length === 0">
      <li class="text-center text-muted px-3 py-2">
        Não há solicitações de conexão.
      </li>
    </ng-container>

    <h6 *ngIf="notifications.length > 0" class="dropdown-header text-muted">Solicitação de conexão</h6>
    <li *ngFor="let notification of notifications; let i = index">
      <ng-container [ngSwitch]="notification.type">

        <ng-container *ngSwitchCase="'connection'">
          <div class="d-flex align-items-center px-3 py-2">
            <img
              *ngIf="notification.item.connection.uriImageProfile && notification.item.connection.uriImageProfile.length"
              [src]="notification.item.connection.uriImageProfile" alt="User Avatar" class="rounded-circle me-3"
              style="width: 58px; height: 58px;" />
            <img
              *ngIf="!notification.item.connection.uriImageProfile || !notification.item.connection.uriImageProfile.length"
              src="../../assets/img/default_avatar_1.png" alt="User Avatar" class="rounded-circle me-3"
              style="width: 58px; height: 58px;" />
            <div>
              <p class="fw-bold mb-1">{{ notification.item.connection.name }}</p>
              <div>
                <a href="https://sluper.bio/{{ notification.item.connection.idUser }}" class="text-primary me-3 pointer"
                  target="_blank" rel="noopener noreferrer">Ver perfil</a>
                <a (click)="rejectConnectionRequest($event, notification.item.connection)"
                  class="text-danger me-3 pointer">Recusar</a>
                <a (click)="acceptConnectionRequest($event, notification.item.connection)"
                  class="text-success fw-bold pointer">Aceitar</a>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- <ng-container *ngSwitchCase="'update'">
          <h6 class="dropdown-header text-muted">Atualizações da Sluper</h6>
          <div id="notification-content-1" class="px-3 py-2 rounded">
            <h6 id="notification-title" class="fw-bold">{{ notification.title }}</h6>
            <p id="notification-description">{{ notification.description }}</p>
            <a id="notification-link" href="#" class="text-primary fw-bold">Conferir</a>
          </div>
          <hr class="dropdown-divider">
        </ng-container> -->

        <!-- <ng-container *ngSwitchCase="'recommendation'">
          <h6 class="dropdown-header text-muted">Recomendações do sistema</h6>
          <div id="notification-content-2" class="px-3 py-2 rounded">
            <h6 id="notification-title" class="fw-bold">{{ notification.title }}</h6>
            <p id="notification-description">{{ notification.description }}</p>
            <a id="notification-link" href="#" class="text-primary fw-bold">Ir para configurações</a>
          </div>
          <hr class="dropdown-divider">
        </ng-container> -->
      </ng-container>
    </li>
  </ul>
</div>
<div class="app-event-card" [ngStyle]="{ 'background-image': 'url(' + event?.event?.imageLink && event?.event?.imageLink?.length
 ? event?.event?.imageLink : defaultCallingCardImage + ')' }">
  <h4 class="app-event-card-title multi-line">{{ event?.event?.name }}</h4>
  <span class="app-event-card-description multi-line" [innerHTML]="event?.event?.description"></span>
  <span class="app-event-card-description">{{ getFormattedDate(event?.event?.startDate) }}</span>
  <button (click)="joinEvent()" [ngClass]="{
        'app-event-card-button join': event?.participationStatus === 'NAO_PARTICIPANDO',
        'app-event-card-button joining': event?.participationStatus === 'PARTICIPANDO',
        'app-event-card-button waiting': event?.participationStatus === 'PENDENTE_APROVACAO'
      }">
    {{
    event?.participationStatus === 'PARTICIPANDO' ? 'Participando' :
    event?.participationStatus === 'PENDENTE_APROVACAO' ? 'Aguardando Aprovação' :
    'Participar'
    }}
  </button>
</div>
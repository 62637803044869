<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<div class="container-fluid" id="community-mentions">

    <div class="page-title">
        <i class="bi bi-chevron-left"></i>
        <h4 id="mention-title">Menções</h4>
    </div>

    <div class="mentioned-posts">
        <section class="post-left">
            <div class="posts-group" *ngFor="let post of posts; let i = index">
                <div class="post-month" *ngIf="isFirstPostOfMonth(post, i)">
                    <span>{{ post.createdAt | date: 'MMMM' | titlecase }}</span>
                    <div class="line"></div>
                </div>
                <div class="card-item">
                    <app-post-component [post]="post" [fromScreen]="'community-mentions'" [userProfile]="userProfile"
                        [communityItem]="communityItem" [selectedChannel]="selectedChannel"
                        [userProfilePlan]="userProfilePlan" [userConnections]="userConnections"
                        [participants]="participants" [isUserCommunityOwner]="isUserCommunityOwner"
                        [attr.id]="'post-' + i" (deletePost)="deletePost($event)"
                        (refreshPosts)="onRefreshPosts($event)" (unfavoritePost)="unfavoritePost($event)"
                        (favoritePost)="favoritePost($event)" (refreshInteractions)="onRefreshLikes($event)"
                        (removeComment)="handleRemoveComment($event)">
                        ></app-post-component>
                </div>
            </div>

            <div class="no-posts" *ngIf="posts.length === 0 && !loading">
                <h3>Nenhuma menção por aqui ainda. Assim que alguém marcar você, ela aparecerá aqui.</h3>
            </div>
            <div class="no-posts" *ngIf="loading && posts.length === 0">
                <h3>Carregando menções</h3>
            </div>
        </section>

        <section class="post-right">
            <div class="post-month">
                <span>Filtrar por</span>
            </div>

            <div class="personalized-card mb-3">
                <h4 id="mention-title" class="mt-0 mb-2">Data de Menção</h4>
                <div class="line mb-3"></div>
                <ngx-daterangepicker-material [locale]="calendarLocale" [showCancel]="true"
                    (choosedDate)="onDateChosen($event)">
                </ngx-daterangepicker-material>
            </div>

            <div class="personalized-card">
                <h4 id="mention-title" class="mt-0 mb-2">Tags</h4>
                <div class="line mb-3"></div>
                <div class="card-item-categories">
                    <div class="tag">Categoria 1</div>
                    <div class="tag">Categoria 2</div>
                    <div class="tag">Categoria 3</div>
                    <div class="tag">Categoria 4</div>
                    <div class="tag">Categoria 5</div>
                    <div class="tag">Categoria 6</div>
                </div>
            </div>
        </section>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-community-user-finance',
  templateUrl: './community-user-finance.component.html',
  styleUrls: ['./community-user-finance.component.css']
})
export class CommunityUserFinanceComponent implements OnInit {



  constructor() { }

  ngOnInit(): void {
  }

}

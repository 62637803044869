import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { firstValueFrom } from "rxjs";
import { EventsService } from "src/app/events/events.service";

@Component({
    selector: 'app-cancel-connection-request',
    templateUrl: './cancel-connection-request.component.html',
    styleUrls: ['./cancel-connection-request.component.css'],
})
export class CancelConnectionRequestComponent {
    @Input() idUser = '';
    @Input() idConnection = '';
    @Input() nameConnection = '';

    execute = false;

    constructor(private activeModal: NgbActiveModal, private service: EventsService) { }

    async cancelConnectionRequest() {
        if (this.idConnection !== '' && this.idUser !== '') {
            this.execute = true;
            try {
                await firstValueFrom(this.service.cancelConnectionRequest(this.idUser, this.idConnection));
            } catch (error) {
                console.error(error);
                this.execute = false;
                return;
            }
            this.execute = false;
            this.closeModal(true);
        }
    }

    closeModal(status: boolean) {
        this.activeModal.close(status);
    }
}

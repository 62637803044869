<div class="header-inicio">
    <div class="logo">
        <img class="logo-sluper" [src]=" customLogo ? customLogo : urlImagemLogin" alt="logo Sluper" />

    </div>
    <div class="buttons">
        <span class="button-header" [hidden]="true" [ngClass]="[classColorido, borderRight]"
            (click)="navegarCadastro()">Sua Bio
            gratuita</span>
        <span class="button-header" [hidden]="true" [ngClass]="[classColorido, borderRight]"
            (click)="navegarSluperCard()">Sluper
            Card</span>
        <span class="button-header" [hidden]="true" (click)="navegarLogin()" [ngClass]="classColorido">Login</span>
    </div>
</div>
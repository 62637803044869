import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription, firstValueFrom, forkJoin } from 'rxjs';

import { ChannelInterface } from 'src/app/categoryAndChannel/category-channel.service';
import { Connection } from 'src/app/model/connection.model';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { EventParticipant, SluperEvent } from 'src/app/model/event.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';

import { Like, Post, PostsService } from '../../post/posts.service';
import { FavoritesService } from './favorites-service/favorites-service.service';

@Component({
  selector: 'app-community-favorites',
  templateUrl: './community-favorites.component.html',
  styleUrls: ['./community-favorites.component.css'],
})
export class CommunityFavoritesComponent implements OnInit, OnDestroy, AfterViewInit {
  /** =================================================================
   *  @Inputs
   *  ================================================================ */
  @Input() isUserCommunityOwner: boolean = false;
  @Input() userProfile: DocumentUserProfile | undefined;
  @Input() communityItem!: SluperEvent;
  @Input() selectedChannel!: ChannelInterface | null;
  @Input() userProfilePlan!: IUserProfilePlan;
  @Input() userConnections!: Connection[];
  @Input() participants: EventParticipant[] = [];

  /** =================================================================
   *  VARIÁVEIS
   *  ================================================================ */
  public posts: Post[] = [];
  public originalPosts: Post[] = [];
  public likes: Like[] = [];

  // Paginação dos posts (exatamente como no communities.component)
  postsPagination = {
    page: 1,
    pageSize: 100,
    totalPages: 1,
    totalElements: 0, // <--- adicionado
    isLoading: false
  };


  // Controla exibição do spinner
  public loading: boolean = false;

  // Filtro de datas (ngx-daterangepicker)
  selectedRange: any = null;  // aqui guardamos as datas selecionadas do range picker

  calendarLocale = {
    applyLabel: 'Aplicar',
    cancelLabel: 'Limpar',
    clearLabel: 'Limpar',
    customRangeLabel: 'Intervalo Personalizado',
  };

  // Scroll do container (para infinite scroll ou rolagem)
  private scrollListenerFn: (() => void) | null = null;
  private parentScrollElement!: HTMLElement;
  private buttonClickListener: (() => void) | null = null;

  // Subscriptions, se você precisar de notificações
  private subscriptionNotification: Subscription | null = null;

  constructor(
    private favoritesService: FavoritesService,
    private postsService: PostsService, // para buscar likes do usuário
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private el: ElementRef,
    private dialog: MatDialog,
    private modalService: NgbModal
  ) { }

  /** =================================================================
   *  Lifecycle Hooks
   *  ================================================================ */
  ngOnInit(): void {
    // Inicializa a lista de posts favoritos
    this.fetchPosts(true);

    // Configura o listener do scroll no container principal (id="user-profile-screen-scroll")
    this.setupParentScrollListener();
  }

  ngOnDestroy(): void {
    if (this.buttonClickListener) {
      this.buttonClickListener();
    }
    if (this.scrollListenerFn) {
      this.scrollListenerFn();
      this.scrollListenerFn = null;
    }
    if (this.subscriptionNotification) {
      this.subscriptionNotification.unsubscribe();
      this.subscriptionNotification = null;
    }
  }

  ngAfterViewInit(): void {
    this.addButtonClickListener();
  }

  /** =================================================================
   *  MÉTODOS DE BUSCA / PAGINAÇÃO DE POSTS
   *  ================================================================ */
  async fetchPosts(refresh = false) {
    if (!this.userProfile?.idUser) return;

    // Verifica se já está carregando ou se já chegou no final da paginação
    if (this.postsPagination.isLoading || (!refresh && this.postsPagination.page >= this.postsPagination.totalPages)) {
      return;
    }

    // Se for refresh, reinicia os parâmetros
    if (refresh) {
      this.postsPagination.page = 1;
      this.postsPagination.totalPages = 1;
      this.postsPagination.totalElements = 0;
      this.posts = [];
    }

    // Define a flag de carregamento
    this.loading = true;
    this.postsPagination.isLoading = true;

    try {
      const startDate = this.selectedRange?.startDate
        ? this.convertToCSharpDateTime(this.selectedRange.startDate)
        : undefined;
      const endDate = this.selectedRange?.endDate
        ? this.convertToCSharpDateTime(this.selectedRange.endDate)
        : undefined;

      const dataFetch$ = this.favoritesService.getFavoritesPosts({
        userId: this.userProfile.idUser,
        communityId: this.communityItem.id,
        startDate: startDate,
        endDate: endDate,
        pageNumber: this.postsPagination.page,
        pageSize: this.postsPagination.pageSize,
      });

      const likesFetch$ = this.postsService.getUserLikes(this.userProfile.idUser);

      const [postsResp, likesResp] = await firstValueFrom(
        forkJoin([dataFetch$, likesFetch$])
      );

      const newPosts: Post[] = postsResp?.items || [];
      const sortedNewPosts = newPosts.sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      // Atualiza paginação
      this.postsPagination.page = postsResp?.currentPage ?? this.postsPagination.page;
      this.postsPagination.totalPages = postsResp?.totalPages ?? this.postsPagination.totalPages;

      this.posts = [...sortedNewPosts];

      // Atualiza likes
      this.likes = likesResp?.body || [];
      this.mergePostsLikes();

    } catch (error) {
      console.error('Erro ao carregar posts favoritos:', error);
      this.toastr.error('Erro ao carregar posts favoritos.', 'Erro');
    } finally {
      this.postsPagination.isLoading = false;
      this.loading = false;
    }
  }


  async unfavoritePost(post: Post) {
    if (!this.userProfile?.idUser) {
      this.toastr.error('Usuário não encontrado.', 'Erro');
      return;
    }

    try {
      this.loading = true;
      await firstValueFrom(
        this.favoritesService.unfavoritePost({
          postId: post.id,
          userId: this.userProfile.idUser,
        })
      );

      this.posts = this.posts.filter((p) => p.id !== post.id);

      this.toastr.success('Post desfavoritado com sucesso.', 'Sucesso');
    } catch (error: any) {
      console.error('Erro ao desfavoritar post:', error);
      this.toastr.error('Erro ao desfavoritar post.', 'Erro');
    } finally {
      this.loading = false;
    }
  }



  // Une os likes com os posts, de modo parecido ao communities.component
  private mergePostsLikes() {
    this.posts.forEach((post) => {
      const postLikes = this.likes.filter(
        (like) => like.post?.id === post.id && !like.comment
      );
      post.like = postLikes.length > 0 ? postLikes[0] : undefined;

      // Ajusta likes em comentários
      post.comments.forEach((comment) => {
        const commentLikes = this.likes.filter(
          (like) => like.comment?.id === comment.id
        );
        comment.like = commentLikes.length > 0 ? commentLikes[0] : undefined;

        if (comment.answers) {
          comment.answers.forEach((answer) => {
            const answerLikes = this.likes.filter(
              (like) => like.comment?.id === answer.id
            );
            answer.like = answerLikes.length > 0 ? answerLikes[0] : undefined;
          });
        }
      });
    });
  }

  /** =================================================================
   *  INFINITE SCROLL
   *  ================================================================ */
  private setupParentScrollListener() {
    this.parentScrollElement = document.getElementById(
      'user-profile-screen-scroll'
    ) as HTMLElement;
    if (this.parentScrollElement) {
      this.scrollListenerFn = this.renderer.listen(
        this.parentScrollElement,
        'scroll',
        (event) => {
          this.onParentScroll(event);
        }
      );
    }
  }

  onParentScroll(event: any) {
    const element = event.target;
    const isAtBottom =
      element.scrollHeight - element.scrollTop <= element.clientHeight + 100;
    if (isAtBottom) {
      this.fetchPosts(false);
    }
  }

  /** =================================================================
   *  EVENTOS DE POST (Excluir, Refresh)
   *  ================================================================ */
  deletePost(postId: number) {
    this.posts = this.posts.filter((post) => post.id !== postId);
  }

  onRefreshPosts(_: any) {
    this.fetchPosts(true);
  }

  onRefreshLikes(_: any) {
    if (!this.userProfile?.idUser) return;
    // Recarrega somente likes (igual communities.component)
    this.postsService.getUserLikes(this.userProfile.idUser).subscribe({
      next: (resp) => {
        this.likes = resp.body || [];
        this.mergePostsLikes();
      },
      error: (err) => {
        console.error('Erro ao recarregar likes:', err);
      },
    });
  }

  // Exemplo de método para rolar até o post
  scrollToPost(index: number): void {
    const postElement = document.getElementById(`post-${index}`);
    const screenElement = document.getElementById('user-profile-screen-scroll');
    if (postElement && screenElement) {
      const elementOffset = postElement.offsetTop;
      const containerOffset = screenElement.offsetTop;
      const scrollOffset = elementOffset - containerOffset;
      screenElement.scrollTo({
        top: scrollOffset + 700,
        behavior: 'smooth',
      });
    }
  }

  trackByPostId(index: number, post: Post): number {
    return post.id;
  }

  /** =================================================================
   *  FILTRO DE DATAS (ngx-daterangepicker)
   *  ================================================================ */
  onDateChosen(event: any): void {
    if (!event.startDate || !event.endDate) {
      this.handleClearDate();
    } else {
      this.selectedRange = {
        startDate: event.startDate,
        endDate: event.endDate,
      };
      this.fetchPosts(true);
    }
  }

  handleClearDate(): void {
    this.selectedRange = null;
    this.fetchPosts(true);
  }


  convertToCSharpDateTime(date: Date): string {
    const jsDate = new Date(date);
    if (!(jsDate instanceof Date) || isNaN(jsDate.getTime())) {
      throw new Error("Invalid JavaScript Date");
    }

    const year = jsDate.getFullYear();
    const month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
    const day = jsDate.getDate().toString().padStart(2, '0');
    const hours = jsDate.getHours().toString().padStart(2, '0');
    const minutes = jsDate.getMinutes().toString().padStart(2, '0');
    const seconds = jsDate.getSeconds().toString().padStart(2, '0');
    const newDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`

    return newDate;
  }


  /** =================================================================
   *  Botão "Limpar" do daterangepicker
   *  ================================================================ */
  addButtonClickListener(): void {
    const button = this.el.nativeElement.querySelector('.btn.btn-default');
    if (button) {
      this.buttonClickListener = this.renderer.listen(button, 'click', () => {
        this.handleClearDate();
      });
    } else {
      setTimeout(() => {
        this.addButtonClickListener();
      }, 1000);
    }
  }

  isFirstPostOfMonth(post: Post, index: number): boolean {
    if (index === 0) {
      return true;
    }
    const currentPostDate = new Date(post.createdAt);
    const previousPostDate = new Date(this.posts[index - 1].createdAt);
    return (
      currentPostDate.getMonth() !== previousPostDate.getMonth() ||
      currentPostDate.getFullYear() !== previousPostDate.getFullYear()
    );
  }


}

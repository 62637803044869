import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BOLD_BUTTON, EditorConfig, FONT_SIZE_SELECT, FORE_COLOR, ITALIC_BUTTON, JUSTIFY_CENTER_BUTTON, JUSTIFY_FULL_BUTTON, JUSTIFY_LEFT_BUTTON, JUSTIFY_RIGHT_BUTTON, ORDERED_LIST_BUTTON, SEPARATOR, UNDO_BUTTON, UNORDERED_LIST_BUTTON } from 'ngx-simple-text-editor';
import { filter, firstValueFrom, map, Subject } from 'rxjs';
import { CategoryChannelService, CreateChannelData } from 'src/app/categoryAndChannel/category-channel.service';
import { Connection } from 'src/app/model/connection.model';
import { IDocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { EventParticipant, SluperEvent } from 'src/app/model/event.model';
import { IUserProfileWithCSS } from 'src/app/model/user-profile-css.model';
import { OpenedCommunityService } from 'src/app/opened-community.service';
import { UserProfileService } from 'src/app/user-profile/user-profile.service';
import { EventsService } from '../events.service';

type ProfileImagesTypes = 'profile' | 'calling-card-background' | 'background';

@Component({
  selector: 'app-create-community-modal',
  templateUrl: './create-community-modal.component.html',
  styleUrls: ['./create-community-modal.component.css'],
})
export class CreateCommunityModalComponent implements OnInit {
  @Input() userProfile!: IDocumentUserProfile | null;
  @Input() event!: SluperEvent | null;
  @Input() userConnections: Connection[] = [];
  @ViewChild('imageSelectionModal') imageSelectionModal: any;
  public loadingConnections = false;
  private _alert = new Subject<string>();
  @Output() triggerfeedbackMessage = new EventEmitter<{
    message: string;
    type: 'success' | 'error';
  }>();

  updateMode = false;
  updateProfileMode = false;
  selectedTab = 0;
  screens: 'information' | 'about' | 'add' = 'information';
  isUpdate = false;
  imageChanged = false;
  addressChanged = false;
  defaultImageTest = '/assets/img/imagem_fundo_preview.png';
  imageSelectionModalOpen = false;
  currentImageType: ProfileImagesTypes = 'background';
  communityParticipantsFiltered: Connection[] = [];
  communityParticipantsAdded: Connection[] = [];
  public defaultUnknownImage = '/assets/img/imagem_perfil.png';
  mapsOptions: any = {
    componentRestrictions: { country: 'BR' }
  };
  formModel = {
    email: '',
    isValid: false,
    errorMessage: '',
  };
  allEventParticipants: EventParticipant[] = [];

  manualParticipants: string[] = [];
  modalData: {
    image?: Blob | null,
    imageProfile: string,
    imageProfileBlob: File | null,
    showImageProfile: boolean,
    name: string,
    site: string,
    state: string,
    type: string,
    startDate: string,
    endDate: string,
    maxAttendees: number,
    public: boolean,
    description: string,
    mainDescription: string,
    requiresConfirmation: boolean,
    guests: Connection[],
    about: string,
  } = {
      image: null,
      imageProfile: '',
      showImageProfile: false,
      name: '',
      site: '',
      state: '',
      type: 'COMUNIDADE',
      startDate: '',
      endDate: '9999-12-31T23:59:59.499Z',
      maxAttendees: 9999,
      public: false,
      description: '',
      mainDescription: '',
      requiresConfirmation: false,
      guests: [] as Connection[],
      about: '',
      imageProfileBlob: null
    };
  guestEmailToInvite = "";
  guestsFormErrors = {
    guestEmail: {
      valid: true,
      message: ''
    },
  };
  formErrors = {
    image: {
      valid: true,
      message: ''
    },
    imageLogo: {
      valid: true,
      message: ''
    },
    name: {
      valid: true,
      message: ''
    },
    site: {
      valid: true,
      message: ''
    },
    description: {
      valid: true,
      message: ''
    },
    about: {
      valid: true,
      message: ''
    },
  }
  changedImageEvent: any = null;
  imageChangedEvent: any = null;
  croppedImage: any = '';
  imageToCrop = "";
  cropMode = false;
  screenWidth: any;
  screenHeight: any;
  desktopLimitWidth = 840;
  desktopLimitHeight = 550;
  previewModalOpen = false;
  alertType = '';
  // imageSelectionModalOpen = false;
  profileCropMode = false;
  showTooltipProfile = false;
  defaultProfileImage = '/assets/img/imagem_perfil.png';
  imageProfileChanged = false;
  defaultImageSelected = false;
  minDate = format(new Date(), "yyyy-MM-dd'T'hh:mm");
  loading = false;
  csvFile: File | null = null;
  communityId!: any;

  config: EditorConfig = {
    placeholder: '',
    buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR, FONT_SIZE_SELECT]
  };

  configRules: EditorConfig = {
    placeholder: 'Faça uma descrição de regras detalhadas',
    buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR,
      FONT_SIZE_SELECT, SEPARATOR, JUSTIFY_LEFT_BUTTON, JUSTIFY_CENTER_BUTTON,
      JUSTIFY_RIGHT_BUTTON, JUSTIFY_FULL_BUTTON, ORDERED_LIST_BUTTON, UNORDERED_LIST_BUTTON],
  };

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private eventsService: EventsService,
    private userProfileService: UserProfileService,
    private categoryChannelService: CategoryChannelService,
    private openedCommunityService: OpenedCommunityService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.communityParticipantsFiltered = this.userConnections;
    if (this.event != null) {
      this.isUpdate = true;
      this.modalData.name = this.event.name;
      this.modalData.site = this.event.website;
      this.modalData.type = this.event.type;
      this.modalData.description = this.event.description;
      this.modalData.mainDescription = this.event.description;
      this.modalData.startDate = this.event.startDate;
      this.modalData.endDate = this.event.endDate;
      this.modalData.maxAttendees = this.event.numberOfParticipants;
      this.modalData.public = this.event.isPublic;
      this.modalData.requiresConfirmation = this.event.requiresConfirmation;
    }
    return;
  }

  closeModal(type?: 'none' | 'openModal', id?: string) {
    this.activeModal.close(
      {
        type: type,
        id: id
      }
    );
  }

  formatDate(event: any) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '');
    if (value.length > 8) {
      value = value.slice(0, 8);
    }

    if (value.length >= 2) {
      value = value.substring(0, 2) + '/' + value.substring(2);
    }
    if (value.length >= 5) {
      value = value.substring(0, 5) + '/' + value.substring(5);
    }

    input.value = value;
  }
  handleFileChange(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      this.cropMode = true;
      this.imageToCrop = e.target?.result as string;
      this.changedImageEvent = event;
    }
    reader.readAsDataURL(file);
    this.imageChanged = true;
  }

  removeSelectedImage() {
    this.cropMode = false;
  }

  private resetFormErrors() {
    this.formErrors = {
      image: {
        valid: true,
        message: ''
      },
      imageLogo: {
        valid: true,
        message: ''
      },
      name: {
        valid: true,
        message: ''
      },
      site: {
        valid: true,
        message: ''
      },
      description: {
        valid: true,
        message: ''
      },
      about: {
        valid: true,
        message: ''
      },
    }
  }

  handleImageCropped(event: ImageCroppedEvent) {
    this.modalData.image = event.blob;
  }

  private validateForm() {
    this.resetFormErrors();
    if (!this.modalData.site) {
      this.formErrors.site = {
        valid: false,
        message: 'Site obrigatório'
      };
    }
    if (!this.modalData.name) {
      this.formErrors.name = {
        valid: false,
        message: 'Nome obrigatório'
      };
    }
  }

  async handleSaveEvent() {
    if (!this.userProfile) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao criar comunidade, o usuário não foi encontrado.',
        type: 'error'
      });

      return;
    }

    this.loading = true;

    this.validateForm();

    if (Object.values(this.formErrors).some((error) => error.valid === false)) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao criar comunidade, verifique os campos obrigatórios.',
        type: 'error'
      });
      this.loading = false;

      return;
    }

    const event = {
      id: '',
      name: this.modalData.name,
      website: this.modalData.site,
      type: 'COMUNIDADE',
      startDate: new Date().toISOString(),
      endDate: new Date('9999-12-31T23:59:59.499Z').toISOString(),
      numberOfParticipants: this.modalData.maxAttendees,
      isPublic: false,
      description: this.modalData.description,
      mainDescription: this.modalData.mainDescription,
      imageLink: '',
      organizer: {
        idUser: this.userProfile.idUser,
        name: this.userProfile.name,
        bio: this.userProfile.bio,
        uriImageProfile: this.userProfile.uriImageProfile,
        uriImageBackground: this.userProfile.uriImageBackground,
        header: this.userProfile.header,
      },
      about: this.modalData.about,
      guests: await this.processGuests(),
      requiresConfirmation: this.modalData.requiresConfirmation,
      active: true,
      eventUrl: this.modalData.site ? this.modalData.site.length ? this.modalData.site : null : null
    }

    try {
      if (!this.isUpdate) {
        await firstValueFrom(this.eventsService.createCommunity(event)).then(e => {
          this.communityId = event.id;
          event.id = e.body.message;
        });
      } else {
        event.id = this.event ? this.event.id : '';
        await firstValueFrom(this.eventsService.updateCommunity(event));
      }

      try {
        let imageUrl;
        if (!this.isUpdate || this.imageChanged) {
          imageUrl = await this.uploadEventImage(event.id);
        }
        if (this.modalData.imageProfile && this.modalData.imageProfile.length) {
          await this.uploadEventImageLogo(event.id);
        }
        await this.joinEvent(event.id, this.userProfile.idUser)

        await this.saveCategory(event.id);

        if (this.csvFile) {
          await this.sendInviteByCsv(event.id);
        }
      } catch (error) {
        console.error('Erro ao salvar categoria:', error);
      }


      this.triggerfeedbackMessage.emit({
        message: 'Comunidade criada com sucesso',
        type: 'success'
      });
      const myCommunity = await firstValueFrom(this.eventsService.eventById(event.id));
      if (myCommunity && myCommunity.body) {
        this.openedCommunityService.setMyCommunity(myCommunity.body?.event)
      }
      this.openedCommunityService.setCommunity(event.eventUrl)

      setTimeout(async () => {

        this.router.navigate([`/communities/${event.eventUrl}`])
        this.closeModal('openModal', event.id)
        this.loading = false;
      }, 2000);

    } catch (error: any) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao criar comunidade',
        type: 'error'
      });

      this.loading = false;
    }
  }

  async updateUserProfile() {
    if (!this.userProfile) return
    try {
      const response = await firstValueFrom(
        this.userProfileService.saveProfileImage(
          this.userProfile!.idUser,
          `profile_${'-1'}`,
          this.modalData.imageProfileBlob!
        )
      );
      this.userProfile!.uriImageProfile = response
      await firstValueFrom(
        this.userProfileService
          .saveDocumentUserProfile(this.userProfile!)
          .pipe(filter((res: HttpResponse<object>) => res.ok))
      );
    } catch (error) {
      this.triggerfeedbackMessage.emit({
        message: 'Atualização de perfil não foi detectada.',
        type: 'error'
      });
    }
  }

  async joinEvent(eventId: string, userId: string) {
    try {
      this.eventsService.joinEvent(eventId, userId)
        .pipe(
          filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
          map((response: HttpResponse<any>) => response.body)
        )
        .subscribe({
          next: () => {
          },
          error: (err) => {
            console.error("Erro ao tentar entrar no evento: ", err);
          }
        });
    } catch (error: any) {
      console.error("Erro inesperado: ", error);
    }
  }


  private createEventImage(blob: Blob) {
    const currentTime = Date.now();
    const fileName = `event_${currentTime}.png`;

    const blobFile = new File([blob], fileName, {
      type: 'image/png',
      lastModified: currentTime,
    });

    return blobFile;
  }

  private async uploadEventImage(eventId: string) {
    if (!this.modalData.image) return null; // Retorne null se não houver imagem
    if (!this.userProfile) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao criar comunidade, o usuário não foi encontrado.',
        type: 'error',
      });
      return null;
    }

    try {
      const eventFile = this.createEventImage(this.modalData.image as Blob);
      const uploadImageResponse = await firstValueFrom(
        this.eventsService.saveEventImage(eventId, `event_${eventId}`, eventFile)
      );
      return uploadImageResponse;
    } catch (error: any) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao salvar imagem',
        type: 'error'
      });
      console.error('Erro ao salvar imagem do evento:', error);
      return null; // Retorna null para sinalizar que o upload falhou
    }
  }

  private async uploadEventImageLogo(eventId: string) {
    if (!this.modalData.imageProfile || !this.modalData.imageProfile.length || !this.modalData.imageProfileBlob) {
      return null; // Retorne null se não houver imagem de perfil
    }
    if (!this.userProfile) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao criar comunidade, o usuário não foi encontrado.',
        type: 'error',
      });
      return null;
    }

    try {
      const uploadImageResponse = await firstValueFrom(
        this.eventsService.saveCommunityImageLogo(eventId, this.modalData.imageProfileBlob!)
      );
      return uploadImageResponse;
    } catch (error: any) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao salvar imagem',
        type: 'error'
      });
      console.error('Erro ao salvar imagem de logo:', error);
      return null; // Retorna null para sinalizar que o upload falhou
    }
  }



  private changeSelectedTab(tab: number) {
    this.selectedTab = tab;

    switch (tab) {
      case 0:
        this.screens = 'information'
        break;
      case 1:
        this.screens = 'about'
        break;
      case 2:
        this.screens = 'add'
        break;
    }
  }

  private async validateFormFirstStep() {
    this.resetFormErrors();

    if (!this.modalData.image && !this.updateMode) {
      this.formErrors.image = {
        valid: false,
        message: 'Imagem obrigatória'
      };
    }

    if (!this.modalData.imageProfile && !this.updateProfileMode) {
      this.formErrors.imageLogo = {
        valid: false,
        message: 'Imagem obrigatória'
      };
    }

    if (!this.modalData.name) {
      this.formErrors.name = {
        valid: false,
        message: 'Nome obrigatório'
      };
    }

    const isAvailable = await this.isProfileLinkAvailable();

    if (!this.modalData.site || !isAvailable) {
      this.formErrors.site = {
        valid: false,
        message: !isAvailable && this.modalData.site.length ? 'Essa URL já está em uso. Por favor, insira outra.' : 'Site obrigatório'
      };
    }

    if (!this.modalData.description) {
      this.formErrors.description = {
        valid: false,
        message: 'Descrição obrigatória'
      };
    }
    if (this.countTextChars(this.modalData.description) > 1500) {
      this.formErrors.description = {
        valid: false,
        message: 'A descrição deve ter menos de 1500 caracteres'
      };
    }
  }


  countTextChars(text: string): number {
    const textWithoutHtml = text.replace(/<[^>]*>/g, '');
    return textWithoutHtml.length;
  }

  private validateSecondStep() {
    this.resetFormErrors();

    if (!this.modalData.about) {
      this.formErrors.about = {
        valid: false,
        message: 'Regra obrigatória'
      };
    }

    if (this.countTextChars(this.modalData.about) > 1500) {
      this.formErrors.about = {
        valid: false,
        message: 'A regra deve ter menos de 1500 caracteres'
      };
    }
  }

  async nextStep() {
    switch (this.selectedTab) {
      case 0:
        await this.validateFormFirstStep();
        if (Object.values(this.formErrors).some((error) => error.valid === false)) {
          this.triggerfeedbackMessage.emit({
            message: 'Erro ao criar comunidade, verifique os campos obrigatórios.',
            type: 'error'
          });
          return;
        }
        this.changeSelectedTab(1)
        break;
      case 1:
        this.validateSecondStep()
        if (Object.values(this.formErrors).some((error) => error.valid === false)) {
          this.triggerfeedbackMessage.emit({
            message: 'Erro ao criar comunidade, verifique os campos obrigatórios.',
            type: 'error'
          });
          return;
        }
        this.changeSelectedTab(2)
        break;
      case 2:
        this.handleSaveEvent()
        break;
    }
  }

  backStep() {
    if (this.selectedTab == 0) return
    this.changeSelectedTab(this.selectedTab - 1)
  }

  async changeSelectedTabStep(nextTab: number) {
    if (nextTab > this.selectedTab) {
      while (this.selectedTab < nextTab) {
        switch (this.selectedTab) {
          case 0:
            await this.validateFormFirstStep();
            if (Object.values(this.formErrors).some((error) => error.valid === false)) {
              this.triggerfeedbackMessage.emit({
                message: 'Erro ao criar comunidade, verifique os campos obrigatórios.',
                type: 'error'
              });
              this.changeSelectedTab(this.selectedTab);
              return;
            }
            break;
          case 1:
            this.validateSecondStep();
            if (Object.values(this.formErrors).some((error) => error.valid === false)) {
              this.triggerfeedbackMessage.emit({
                message: 'Erro ao criar comunidade, verifique os campos obrigatórios.',
                type: 'error'
              });
              this.changeSelectedTab(this.selectedTab);
              return;
            }
            break;
        }
        this.selectedTab++;
        this.changeSelectedTab(this.selectedTab);
      }
    } else if (nextTab < this.selectedTab) {
      while (this.selectedTab > nextTab) {
        this.selectedTab--;
        this.changeSelectedTab(this.selectedTab);
      }
    }
  }

  addCommunityParticipants(person: Connection) {
    this.modalData.guests.push(person)
    if (this.manualParticipants.includes(person.connection.email!)) {
      this.manualParticipants.splice(this.manualParticipants.indexOf(person.connection.email!), 1)
    }
  }

  rmCommunityParticipants(person: Connection) {
    this.modalData.guests.splice(this.modalData.guests.indexOf(person), 1)
  }

  verifyCommunityParticipants(person: Connection) {
    return this.modalData.guests.includes(person)
  }

  filterCommunityParticipants(event: any) {
    if (event.target.value.length === 0) {
      this.communityParticipantsFiltered = this.userConnections
      return
    }
    this.communityParticipantsFiltered = this.userConnections.filter((person: Connection) => person.connection.name.toLowerCase().includes(event.target.value.toLowerCase()))
  }

  async isProfileLinkAvailable(): Promise<boolean> {
    if (this.modalData.site.length === 0) return false;

    // Validação para caracteres especiais ou espaços
    const regex = /^[a-zA-Z0-9]+$/; // Somente letras e números
    if (!regex.test(this.modalData.site)) {
      this.formErrors.site = {
        valid: false,
        message: 'A URL deve conter apenas letras e números, sem espaços ou caracteres especiais.'
      };
      return false;
    }

    try {
      const resp = await firstValueFrom(this.eventsService.validaComunidadeDisponivel(this.modalData.site));

      if (resp === null || resp === undefined) {
        // Quando a resposta está vazia
        this.formErrors.site = {
          valid: true,
          message: '' // Disponível para uso
        };
        return true;
      } else {
        // Quando a resposta indica que a URL já existe
        this.formErrors.site = {
          valid: false,
          message: 'Essa URL já está em uso. Por favor, insira outra.'
        };
        return false;
      }
    } catch (err) {
      // Tratamento de erro durante a validação
      this.formErrors.site = {
        valid: false,
        message: 'Erro ao verificar a disponibilidade da URL. Por favor, tente novamente.'
      };
      return false;
    }
  }

  async createCommunityUrl(event: Event) {
    // Obtem o valor do input
    let urlName = (event.target as HTMLInputElement).value.trim();

    // Formatar usando o regex: remover caracteres especiais
    const regex = /[^a-zA-Z0-9]/g; // Qualquer coisa que não seja letra ou número
    urlName = urlName.replace(regex, '').toLowerCase();

    // Caso tenha espaços no nome, unir as palavras
    urlName = urlName.replace(/\s+/g, '');

    // Atribuir à propriedade modalData.site
    this.modalData.site = urlName;

    // Realizar a validação da URL formatada
    await this.isProfileLinkAvailable();
  }



  async processGuests(): Promise<string[]> {
    try {

      let allEmails: string[] = this.modalData.guests
        .filter((item: Connection) => {
          return !!item.connection?.email && item.connection.email.length > 5;
        })
        .map((item: Connection) => item.connection.email!);

      allEmails = [...allEmails, ...this.manualParticipants];

      return allEmails;
    } catch (error) {
      console.error('Erro ao buscar perfis de convidados:', error);
      return [];
    }
  }

  async getUserById(id: string): Promise<IUserProfileWithCSS | ''> {
    try {
      const response = await this.userProfileService
        .userById(id)
        .pipe(
          filter((mayBeOk: HttpResponse<IUserProfileWithCSS>) => mayBeOk.ok),
          map((response: HttpResponse<IUserProfileWithCSS>) => response.body)
        )
        .toPromise();

      return response || '';
    } catch (error) {
      console.error(`Erro ao buscar usuário com ID ${id}:`, error);
      return '';
    }
  }

  openImageSelectionModal(imageType: ProfileImagesTypes): void {
    this.imageSelectionModalOpen = true;
    this.currentImageType = imageType;
    this.profileCropMode = false;

    const modalRef = this.modalService.open(this.imageSelectionModal, {
      size: 'lg',
      centered: true,
    });

    modalRef.result
      .then(() => {
        this.imageSelectionModalOpen = false;
      })
      .catch(() => {
        this.imageSelectionModalOpen = false;
      });

  }

  toggleProfileImageVisible(event: any) {
    event.stopPropagation();
    this.modalData.showImageProfile = !this.modalData.showImageProfile;
  }

  toggleTooltipProfile(event: Event) {
    event.stopPropagation();
    this.showTooltipProfile = !this.showTooltipProfile;
  }

  cancelProfileImageChange(event: Event) {
    event.stopPropagation();
    this.imageProfileChanged = false;
    this.defaultImageSelected = true;
    this.modalData.imageProfile = this.defaultProfileImage;
    this.modalData.imageProfileBlob = null;
  }

  fileChangeEvent(event: any, minWidth: number, minHeight: number): void {
    const maxFileSize = 5 * 1024 * 1024;
    const file: File = event.target.files[0];

    if (file.size > maxFileSize) {
      this.triggerfeedbackMessage.emit({
        message: 'A imagem selecionada excede o limite de 2MB.',
        type: 'error'
      });
      this.profileCropMode = false;
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.profileCropMode = true;
      this.imageToCrop = e.target.result as string;
      this.imageChangedEvent = event;
    };

    reader.readAsDataURL(file);
    this.imageChanged = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    const blob = event.blob;
    if (blob && blob.size) {
      const reader = new FileReader();
      reader.onload = () => {
        this.croppedImage = reader.result as string;
      };
      reader.readAsDataURL(blob);
    }
  }

  removeSelectedProfileImage() {
    this.profileCropMode = false;
    // this.imageProfileChanged = false;
    // this.defaultImageSelected = true;
    // this.modalData.imageProfile = this.defaultProfileImage;
    // this.modalData.imageProfileBlob = null;
    this.formErrors.imageLogo = { valid: false, message: '' }
  }

  confirmImageSelection(modal: any): void {
    if (!this.imageToCrop) {
      this.triggerfeedbackMessage.emit({
        message: 'Por favor, selecione uma imagem antes de confirmar.',
        type: 'error'
      });
      return;
    }
    if (this.croppedImage) {
      const imageTypeToFileNamePrefix: { [key in ProfileImagesTypes]: string } = {
        'profile': `profile_image_`,
        'calling-card-background': 'background_image_',
        'background': 'external_background_image_'
      };
      const fileNamePrefix = imageTypeToFileNamePrefix['profile'];
      if (!fileNamePrefix) {
        console.warn(`Unknown image type: profile`);
        return;
      }
      const currentTime = Date.now();
      const fileName = `${fileNamePrefix}${currentTime}.png`;
      const blob = this.dataURItoBlob(this.croppedImage);
      const blobFile = new File([blob], fileName, {
        type: 'image/png',
        lastModified: currentTime,
      });
      this.modalData.imageProfile = this.croppedImage;
      this.modalData.imageProfileBlob = blobFile;
      modal.close();
    } else {
      alert('Por favor, selecione e corte uma imagem antes de salvar.');
    }
  }

  async saveCategory(communityId: string) {

    try {
      const dataCategory = {
        name: 'Boas vindas',
        communityId: communityId
      }

      const resp = await firstValueFrom(
        this.categoryChannelService.createCategory(dataCategory)
      );
      if (!resp.body) return

      const dataChannel: CreateChannelData = {
        name: 'Apresente-se',
        community: communityId,
        category: resp.body!.id,
        icon: '😀',
      };

      await firstValueFrom(
        this.categoryChannelService.createChannel(dataChannel)
      );

    } catch (error) {

      this._alert.next('Erro ao criar categoria.');
      this.alertType = 'error';
    }
  }

  validateEmail(): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (this.formModel.email.trim() === '') {
      this.formModel.isValid = false;
      this.formModel.errorMessage = 'O campo de email não pode estar vazio.';
      return false;
    }

    if (!emailRegex.test(this.formModel.email)) {
      this.formModel.isValid = false;
      this.formModel.errorMessage = 'Por favor, insira um email válido.';
      return false;
    }

    this.formModel.isValid = true;
    this.formModel.errorMessage = '';
    return true;
  }


  rmManualParticipant(personToRemove: string) {
    this.manualParticipants = this.manualParticipants.filter((person: string) => person !== personToRemove)
  }

  addManualParticipant() {
    if (this.updateMode) {
      if (this.modalData.guests.some(participant => participant.connection.email === this.formModel.email)
        || this.allEventParticipants.some(participant => participant.email === this.formModel.email)
      ) {
        this.triggerfeedbackMessage.emit({
          message: 'Este usuário ja foi convidado.',
          type: 'error'
        });
        this.formModel = {
          email: '',
          isValid: false,
          errorMessage: '',
        }
        return
      }
    } else {
      if (this.modalData.guests.some(participant => participant.connection.email === this.formModel.email)) {
        this.triggerfeedbackMessage.emit({
          message: 'Este usuário ja foi convidado.',
          type: 'error'
        });
        this.formModel = {
          email: '',
          isValid: false,
          errorMessage: '',
        }
        return
      }
    }




    this.manualParticipants.push(this.formModel.email)
    this.formModel = {
      email: '',
      isValid: false,
      errorMessage: '',
    }
  }
  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  }

  triggerFileInput() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (event: any) => {
      const file = event.target.files[0];
      if (file && file.name.endsWith('.csv')) {
        this.processCsvFile(file);
      } else {
        this._alert.next('Por favor, selecione um arquivo CSV válido.');
        this.alertType = 'warning';
      }
    };
    input.click();
  }

  processCsvFile(file: File) {
    this.csvFile = file;
  }

  removeFile() {
    this.csvFile = null;
  }

  async sendInviteByCsv(eventId: string) {
    if (!this.csvFile) {
      this._alert.next('Por favor, selecione um arquivo CSV antes de enviar.');
      this.alertType = 'warning';
      return;
    }
    this._alert.next('Enviando convites, por favor aguarde...');
    this.alertType = 'info';
    try {
      await firstValueFrom(this.eventsService.inviteToEventByCSV(eventId, this.csvFile));
      this.csvFile = null;
      this._alert.next('Usuários convidados com sucesso!');
      this.alertType = 'success';
    } catch (error: any) {
      console.error('Erro ao enviar convites: ', error);
      const errorMessage = 'Ocorreu um erro inesperado ao tentar enviar os convites. Tente novamente mais tarde';
      this._alert.next(errorMessage);
      this.alertType = 'error';
    }
  }

}
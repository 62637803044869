import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { SluperEvent } from 'src/app/model/event.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';

@Component({
  selector: 'app-community-finance',
  templateUrl: './community-finance.component.html',
  styleUrls: ['./community-finance.component.css']
})
export class CommunityFinanceComponent implements OnInit {
  @Input() isUserCommunityOwner: boolean = false
  @Input() userProfile: DocumentUserProfile | undefined
  @Input() communityItem!: SluperEvent
  @Input() userProfilePlan!: IUserProfilePlan;
  @Output() redirect = new EventEmitter<'home' | 'aparencia' | 'links' | 'opcoes' | 'indicadores' | 'sentContacts' | 'dashboard' | 'salesDashboard' | 'sluperCoins' | 'events' | 'business' | 'businessTemplates' | 'community'>();

  public selectedTab: 'community-owner-community-data' | 'community-owner-community-finance' | 'community-user-community-finance' = 'community-owner-community-data';
  constructor() { }

  ngOnInit(): void {

    if (this.isUserCommunityOwner) {

    }
  }


  public changeSelectedTab(tab: 'community-owner-community-data' | 'community-owner-community-finance' | 'community-user-community-finance') {
    this.selectedTab = tab;
  }


  redirectToPage(page: 'home' | 'aparencia' | 'links' | 'opcoes' | 'indicadores' | 'sentContacts' | 'dashboard' | 'salesDashboard' | 'sluperCoins' | 'events' | 'business' | 'businessTemplates' | 'community') {
    this.redirect.emit(page);
  }
}

<div id="modal-people-liked-post">
    <div class="close">
        <span class="close-icon" (click)="closeModal()">
            <i class="bi bi-x"></i>
        </span>
    </div>
    <div class="modal-content" (click)="$event.stopPropagation()">
        <div class="modal-header">
            <span class="like-icon">
                <i class="bi bi-hand-thumbs-up"></i>
            </span>
            <span class="like-count">
                {{ (post.likes || 0) }} {{ (post.likes || 0) > 1 ? 'Curtidas' : 'Curtida' }}
            </span>
        </div>

        <h2 class="modal-subtitle">Membros que curtiram</h2>

        <div class="user-list" *ngIf="post.likesData && post.likesData.length > 0">
            <div class="user-item" *ngFor="let person of post.likesData">
                <img *ngIf="person?.user?.profilePicture && person.user.profilePicture.length > 0"
                    [src]="person.user.profilePicture" [alt]="person.user.name" class="user-avatar">
                <img *ngIf="!person.user?.profilePicture" src="/assets/img/default_avatar_1.png"
                    [alt]="person.user.name" class="user-avatar">


                <div class="user-info">
                    <span class="user-name">{{ person.user.name }}</span>
                    <span class="user-status multi-line">{{ truncateTextSemHtml(person.user.presentation)}}</span>
                    <a (click)="openParticipantProfile(person.userId)" class="user-link">Ver perfil</a>
                </div>
            </div>
        </div>

        <h3 class="no-items" *ngIf="!post.likesData || post.likesData.length === 0">Nenhuma curtida</h3>
    </div>
</div>
import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SluperEvent } from 'src/app/model/event.model';

@Component({
  selector: 'app-other-community-card',
  templateUrl: './other-community-card.component.html',
  styleUrls: ['./other-community-card.component.css']
})
export class OtherCommunityCardComponent {
  @Input() event: SluperEvent | undefined;
  @Output() join = new EventEmitter<SluperEvent>();
  defaultCallingCardImage = '/assets/img/imagem_fundo_preview.png';

  getFormattedDateRange(): string {
    if (!this.event?.startDate || !this.event?.endDate) return '';
    const start = new Date(this.event?.startDate);
    const end = new Date(this.event?.endDate);
    const startDay = formatDate(start, 'dd', 'pt-BR');
    const endDay = formatDate(end, 'dd', 'pt-BR');
    const month = formatDate(end, 'MMMM', 'pt-BR').toLowerCase();
    const year = formatDate(end, 'yyyy', 'pt-BR');
    return `${startDay} a ${endDay} de ${month}, ${year}`;
  }

  getFormattedTimeRange(): string {
    if (!this.event?.startDate || !this.event?.endDate) return '';
    const start = new Date(this.event?.startDate);
    const end = new Date(this.event?.endDate);
    const formattedStartTime = formatDate(start, 'HH:mm', 'pt-BR');
    const formattedEndTime = formatDate(end, 'HH:mm', 'pt-BR');
    return `${formattedStartTime}hr às ${formattedEndTime}hr`;
  }



  joinEvent() {
    if (!this.event) return;
    this.join.emit(this.event);
  }

  async openParticipantProfile(event: Event, id: string) {
    // event.preventDefault();
    // event.stopPropagation();
    window.open(`https://sluper.bio/${id}`);
  }

}
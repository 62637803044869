<div id="create-channel-category-screen">
  <div class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title">
        Escolha entre Categoria e Canais para separar seu conteúdo
      </h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div>
        <button class="category-button me-3" (click)="openCategoryModal()">
          <span>
            Categoria
          </span>
        </button>
        <button class="channel-button" (click)="openChannelModal()">
          <span>
            Canal
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid" id="community-warnings">
  <div class="page-title">
    <div class="d-flex align-items-center">
      <i class="bi bi-chevron-left"></i>
      <h4 id="warning-title">Avisos</h4>
    </div>
    <div id="publish-warning__btn-container" *ngIf="isUserCommunityOwner">
      <button id="publish-warning__btn" (click)="openWarningPost()">Publicar Aviso</button>
    </div>
  </div>
  <div id="community-warning-about">
    <h4 *ngIf="titlePage === '' && isUserCommunityOwner">Descrição da página</h4>
    <p *ngIf="descriptionPage !== ''">
      {{ descriptionPage }}
    </p>
    <p *ngIf="descriptionPage === '' && isUserCommunityOwner">
      Você ainda não criou uma descrição para este quadro de avisos. É importante escrever uma descrição para poder
      orientar melhor sua
      comunidade sobre o que eles podem encontrar nessa página.
    </p>
    <div *ngIf="isUserCommunityOwner">
      <button type="button" *ngIf="titlePage === '' && descriptionPage === ''" class="description-button">
        Criar uma descrição agora
      </button>
    </div>
    <!-- <p>
      Esta é uma página dedicada a avisos e informações pertinentes referentes aos grupos. Aqui será postada todas e
      quaisquer informações
      que sejam relevantes para a manutenção da nossa comunidade.
    </p> -->
  </div>
  <div *ngIf="warnings.length === 0 && isUserCommunityOwner" class="not-warnings">
    <span>
      Você ainda não criou avisos
    </span>
    <button *ngIf="isUserCommunityOwner">
      Publicar aviso
    </button>
  </div>
  <div class="warning-posts" *ngIf="warnings.length > 0">
    <section class="post-left">
      <div class="post-month">
        <span>Julho</span>
        <div class="line"></div>
      </div>
      <div class="card-item">
        <div *ngFor="let warning of warnings; let i = index">
          <div class="warning-item mb-3">
            <div class="warning-header">
              <div class="warning-header-left">
                <div class="warning-header-info">
                  <span class="warning-header-date">
                    {{ warning.dateHour }}
                  </span>
                  <span class="warning-header-title pointer">
                    {{ warning.title }}
                  </span>
                </div>
              </div>
              <div class="warning-header-right">
                <button class="warning-delete-post" *ngIf="isUserCommunityOwner" (click)="openDeleteWarning()">
                  <i class="bi bi-trash-fill"></i>
                </button>
                <button class="warning-edit-post" *ngIf="isUserCommunityOwner" (click)="openWarningPost()">
                  <i class="bi bi-pencil-square"></i>
                </button>
              </div>
            </div>
            <div class="warning-body">
              <p class="warning-text">
                {{ warning.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="post-month">
        <span>Junho</span>
        <div class="line"></div>
      </div>
      <div class="card-item">
        <div *ngFor="let warning of warnings; let i = index">
          <div class="warning-item mb-3">
            <div class="warning-header">
              <div class="warning-header-left">
                <div class="warning-header-info">
                  <span class="warning-header-date">
                    {{ warning.dateHour }}
                  </span>
                  <span class="warning-header-title pointer">
                    {{ warning.title }}
                  </span>
                </div>
              </div>
              <div class="warning-header-right">
                <button class="warning-delete-post" *ngIf="isUserCommunityOwner" (click)="openDeleteWarning()">
                  <i class="bi bi-trash-fill"></i>
                </button>
                <button class="warning-edit-post" *ngIf="isUserCommunityOwner" (click)="openWarningPost()">
                  <i class="bi bi-pencil-square"></i>
                </button>
              </div>
            </div>
            <div class="warning-body">
              <p class="warning-text">
                {{ warning.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="post-right">
      <div class="post-month">
        <span>Filtrar por:</span>
      </div>
      <div class="personalized-card mb-3">
        <h4 id="warning-title" class="mb-2 mt-0">Data de Avisos</h4>
        <div class="line mb-3"></div>
        <ngx-daterangepicker-material [locale]="calendarLocale" [showCancel]="true"
          (choosedDate)="onDateChosen($event)"></ngx-daterangepicker-material>
      </div>
    </section>
  </div>
</div>
import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IEventWithParticipationStatus, SluperEvent } from 'src/app/model/event.model';

@Component({
  selector: 'app-other-events-card',
  templateUrl: './other-events-card.component.html',
  styleUrls: ['./other-events-card.component.css']
})
export class OtherEventsCardComponent implements OnInit {
  @Input() event: SluperEvent | IEventWithParticipationStatus | undefined;
  @Output() join = new EventEmitter<SluperEvent | IEventWithParticipationStatus>();
  @Input() participationType?: number | undefined;
  @Input() participationMessage?: string | undefined;
  defaultCallingCardImage = '/assets/img/imagem_fundo_preview.png';
  public sanitizedContentDescription: SafeHtml = ''


  constructor(private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.sanitizedContentDescription = this.sanitizer.bypassSecurityTrustHtml(
      this.isSluperEvent(this.event)
        ? (this.event?.description || 'Não há descrição para este evento.')
        : (this.event?.event?.description || 'Não há descrição para este evento.')
    );

  }
  getFormattedDateRange(): string {
    if (this.isSluperEvent(this.event)) {
      if (!this.event?.startDate || !this.event?.endDate) return '';

      const start = new Date(this.event?.startDate);
      const end = new Date(this.event?.endDate);

      const startDay = formatDate(start, 'dd', 'pt-BR');
      const endDay = formatDate(end, 'dd', 'pt-BR');
      const month = formatDate(end, 'MMMM', 'pt-BR').toLowerCase();
      const year = formatDate(end, 'yyyy', 'pt-BR');

      return `${startDay} a ${endDay} de ${month}, ${year}`;
    } else if (this.isIEventWithParticipationStatus(this.event)) {
      if (!this.event?.event?.startDate || !this.event?.event?.endDate) return '';
      const start = new Date(this.event?.event?.startDate);
      const end = new Date(this.event?.event?.endDate);
      const startDay = formatDate(start, 'dd', 'pt-BR');
      const endDay = formatDate(end, 'dd', 'pt-BR');
      const month = formatDate(end, 'MMMM', 'pt-BR').toLowerCase();
      const year = formatDate(end, 'yyyy', 'pt-BR');
      return `${startDay} a ${endDay} de ${month}, ${year}`;
    } else {
      return '';
    }
  }

  // Funções de verificação de tipo
  isSluperEvent(event: any): event is SluperEvent {
    return event && 'startDate' in event && 'endDate' in event;
  }

  isIEventWithParticipationStatus(event: any): event is IEventWithParticipationStatus {
    return event && 'participationStatus' in event;
  }


  getFormattedTimeRange(): string {
    if (this.isSluperEvent(this.event)) {

      if (!this.event?.startDate || !this.event?.endDate) return '';
      const start = new Date(this.event?.startDate);
      const end = new Date(this.event?.endDate);
      const formattedStartTime = formatDate(start, 'HH:mm', 'pt-BR');
      const formattedEndTime = formatDate(end, 'HH:mm', 'pt-BR');
      return `${formattedStartTime}hr às ${formattedEndTime}hr`;
    } else if (this.isIEventWithParticipationStatus(this.event)) {
      if (!this.event?.event?.startDate || !this.event?.event?.endDate) return '';
      const start = new Date(this.event?.event?.startDate);
      const end = new Date(this.event?.event?.endDate);
      const formattedStartTime = formatDate(start, 'HH:mm', 'pt-BR');
      const formattedEndTime = formatDate(end, 'HH:mm', 'pt-BR');
      return `${formattedStartTime}hr às ${formattedEndTime}hr`;
    } else {
      return '';
    }
  }
  joinEvent() {
    if (!this.event) return;
    this.join.emit(this.event);
  }
}
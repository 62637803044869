import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-event-type',
  templateUrl: './event-type.component.html',
  styleUrls: ['./event-type.component.css']
})
export class EventTypeComponent {

  constructor(
    private activeModal: NgbActiveModal,
  ) { }
  closeModal(status: boolean, response?: 'PRESENCIAL' | 'REMOTO') {
    this.activeModal.close({
      status: status,
      response: response
    });
  }

  selectEventType(event: 'PRESENCIAL' | 'REMOTO') {
    this.closeModal(true, event);
  }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { EventParticipant } from 'src/app/model/event.model';
import { environment } from 'src/environments/environment';

interface MembersCategories {
  id: number;
  name: string;
  description: string;
  quantity: number;
  membersDetails: {
    id: string;
    name: string;
    presentation: string;
    uriImageProfile: string;
  }[];
  members: string[];
  createdAt: Date;
  updatedAt: Date;
}

export interface IMembersCategories {
  id: number;
  name: string;
  description: string;
  quantity: number;
  membersDetails: EventParticipant[];
  members: string[];
  createdAt: Date;
  updatedAt: Date;
}

@Injectable({
  providedIn: 'root',
})
export class MembersCategoriesService {
  baseUrl = environment.eventsApiUrl;
  baseAdmUrl = environment.administradorUrl;
  baseProfileUrl = environment.profileUrl;

  constructor(protected http: HttpClient) {}

  private mapToIMembersCategories(
    category: MembersCategories
  ): IMembersCategories {
    return {
      ...category,
      membersDetails: category.membersDetails.map(member => ({
        idUser: member.id,
        name: member.name,
        bio: member.presentation,
        uriImageProfile: member.uriImageProfile,
        uriImageBackground: '',
        header: {
          text: '',
          visible: false,
        },
        checkIn: false,
      })),
    };
  }

  getCategoriesMembers(communityId: string): Observable<IMembersCategories[]> {
    return this.http
      .get<MembersCategories[]>(
        `${this.baseUrl}/categoryMember?communityId=${communityId}`
      )
      .pipe(map(categories => categories.map(this.mapToIMembersCategories)));
  }

  getCategoryMembersByStatus(
    communityId: string,
    statusCategory: 'active' | 'inactive',
    statusMember: 'active' | 'inactive'
  ): Observable<IMembersCategories[]> {
    return this.http
      .get<MembersCategories[]>(
        `${this.baseUrl}/categoryMember?communityId=${communityId}&categoryStatus=${statusCategory}&memberStatus=${statusMember}`
      )
      .pipe(map(categories => categories.map(this.mapToIMembersCategories)));
  }

  getCategoryMembersById(categoryId: string): Observable<IMembersCategories> {
    return this.http
      .get<MembersCategories>(`${this.baseUrl}/categoryMember/${categoryId}`)
      .pipe(map(this.mapToIMembersCategories));
  }

  createCategoryMembers(data: {
    name: string;
    description: string;
    quantity: string;
    members: string[];
    communityId: string;
  }): Observable<MembersCategories> {
    return this.http.post<MembersCategories>(
      `${this.baseUrl}/categoryMember`,
      data
    );
  }

  updateCategoryMembers(data: {
    id: number;
    name: string;
    description: string;
    quantity: string;
    members: string[];
    communityId: string;
  }): Observable<MembersCategories> {
    return this.http.put<MembersCategories>(
      `${this.baseUrl}/categoryMember/${data.id}`,
      data
    );
  }

  deleteCategoryMembers(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/categoryMember/${id}`);
  }
}

<div class="template-creation-container">
  <section class="form-section">
    <div ngbAccordion>
      <div ngbAccordionItem [collapsed]="false" class="collapse-container">
        <!-- [collapsed]="openedSection !== 'templateInfo'" (click)="toggleSection('templateInfo')" -->
        <h2 ngbAccordionHeader class="collapse-container">
          <button ngbAccordionButton class="collapse-container-button" translate>
            <div class="collapse-header-content">
              <h2 class="form-box-title">Informaçōes do template</h2>
              <span class="form-box-subtitle">Informaçōes básicas do template</span>
            </div>
          </button>
        </h2>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="basic-template-infos">
                <div class="template-name-container">
                  <label for="template-name" class="input-label" translate>Nome</label>

                  <input class="form-control" id="template-name" name="template-name" [(ngModel)]="templateName" />
                </div>

                <div class="template-name-container">
                  <label for="template-name" class="input-label" translate>Domínio</label>

                  <input class="form-control" id="template-name" name="template-name" [(ngModel)]="domain" />
                </div>

                <div class="default-template-field-container">
                  <label for="template-name" class="input-label" translate>Template padrāo</label>
                  <div class="form-check form-switch">

                    <input type="checkbox" role="default-template" class="form-check-input" name="default"
                      [checked]="defaultTemplate" [(ngModel)]="defaultTemplate">
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div ngbAccordion>
      <div ngbAccordionItem [collapsed]="false" class="collapse-container">
        <!-- [collapsed]="openedSection !== 'profileFont'" (click)="toggleSection('profileFont')" -->
        <h2 ngbAccordionHeader class="collapse-container">
          <button ngbAccordionButton class="collapse-container-button" translate>
            <div class="collapse-header-content">
              <h2 class="form-box-title">Estilo de fonte do perfil</h2>
              <span class="form-box-subtitle">Estilize a fonte dos textos presente no card principal e nos botōes</span>
            </div>
          </button>
        </h2>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="template-style-options-container">
                <div class="profile-font-family-container">
                  <label for="font-family" class="input-label" translate>meuSluper.estiloFontePerfil.fonte</label>

                  <select id="font-family" name="font-family" class="form-select font-family-input"
                    [(ngModel)]="templateCss.fontePerfil">
                    <option *ngFor="let font of fonts" [value]="font">
                      {{ font }}
                    </option>
                  </select>
                </div>

                <div class="profile-font-style-container">
                  <label class="input-label" translate>meuSluper.estiloFontePerfil.opcoesCaractere</label>

                  <div class="text-styles-options">
                    <button class="text-style-option" (click)="changeTextStyle('bold')"
                      [ngClass]="templateCss.negritoPerfil ? 'selected-text-style-option' : 'text-style-option'"
                      style="font-weight: bold;">
                      N
                    </button>

                    <button class="text-style-option" (click)="changeTextStyle('italic')"
                      [ngClass]="templateCss.italicoPerfil ? 'selected-text-style-option' : 'text-style-option'"
                      style="font-style: italic;">
                      I
                    </button>
                  </div>
                </div>

                <div class="profile-color-container">
                  <label for="cor-fonte" class="input-label" translate>meuSluper.estiloFontePerfil.corPrincipal</label>

                  <div class="font-color-input-container">
                    <input type="color" class="form-control form-control-color color-input" id="cor-fonte"
                      name="cor-fonte" [(ngModel)]="templateCss.corFontePerfil" />

                    <span class="font-color-text">{{templateCss.corFontePerfil}}</span>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div ngbAccordion>
      <div ngbAccordionItem [collapsed]="false" class="collapse-container">
        <!-- [collapsed]="openedSection !== 'linksStyle'" (click)="toggleSection('linksStyle')" -->
        <h2 ngbAccordionHeader class="collapse-container">
          <button ngbAccordionButton class="collapse-container-button" translate>
            <div class="collapse-header-content">
              <h2 class="form-box-title">Estilo dos links</h2>
              <span class="form-box-subtitle">Estilize os elementos dos links</span>
            </div>
          </button>
        </h2>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="template-style-options-container">
                <div class="template-styles-field">
                  <div class="border-selection-top-container">
                    <label class="input-label" translate>Borda</label>

                    <div class="border-radius-input-container">
                      <img src="/assets/img/Vector 8.svg" alt="Curva" style="width: 12px; height: 12px;" />
                      <input type="number" class="border-radius-input" [(ngModel)]="templateCss.curvaBorda">
                    </div>
                  </div>

                  <div class="borders-container">
                    <div (click)="changeLinkButtonRadius('0')"
                      [ngClass]="templateCss.curvaBorda === '0' ? 'link-container-style-option square-border radius-selected' : 'link-container-style-option square-border'">
                    </div>
                    <div (click)="changeLinkButtonRadius('8')"
                      [ngClass]="templateCss.curvaBorda === '8' ? 'link-container-style-option medium-radius radius-selected' : 'link-container-style-option medium-radius'">
                    </div>
                    <div (click)="changeLinkButtonRadius('16')"
                      [ngClass]="templateCss.curvaBorda === '16' ? 'link-container-style-option large-radius radius-selected' : 'link-container-style-option large-radius'">
                    </div>
                  </div>
                </div>

                <div class="border-width-container template-styles-field">
                  <label for="width" class="input-label" translate>Expessura</label>

                  <div class="range-indicators">
                    <span class="range-value range-min">-</span>
                    <span class="range-value range-max">+</span>
                  </div>

                  <input [(ngModel)]="templateCss.espessura" type="range" class="range-input" min="0" max="5" id="width"
                    #range />
                </div>

                <div class="template-styles-field">
                  <label class="input-label" translate>Sombra</label>

                  <div class="borders-container">
                    <div [style.border-radius]="templateCss.curvaBorda + 'px'"
                      [ngClass]="templateCss.sombra === '' ? 'link-container-style-option no-shadow shadow-selected' : 'link-container-style-option no-shadow'"
                      (click)="changeLinkButtonShadow('')">
                    </div>
                    <div [style.border-radius]=" templateCss.curvaBorda + 'px'"
                      [ngClass]="templateCss.sombra === '0px 6px 6px 0px rgba(0, 0, 0, 0.1)' ? 'link-container-style-option medium-shadow shadow-selected' : 'link-container-style-option medium-shadow'"
                      (click)="changeLinkButtonShadow('0px 6px 6px 0px rgba(0, 0, 0, 0.1)')"></div>
                    <div [style.border-radius]=" templateCss.curvaBorda + 'px'"
                      [ngClass]="templateCss.sombra === '0px 8px 8px 0px rgba(0, 0, 0, 0.25)' ? 'link-container-style-option large-shadow shadow-selected' : 'link-container-style-option large-shadow'"
                      (click)="changeLinkButtonShadow('0px 8px 8px 0px rgba(0, 0, 0, 0.25)')"></div>
                  </div>
                </div>

                <div class="link-colors-container">
                  <label for="button-link-fill" class="input-label" translate>Preenchimento</label>

                  <div class="font-color-input-container">
                    <input type="color" class="form-control form-control-color color-input" id="button-link-fill"
                      name="button-link-fill" [(ngModel)]="templateCss.preenchimento" />

                    <span class="font-color-text">{{templateCss.preenchimento}}</span>
                  </div>
                </div>

                <div class="link-colors-container">
                  <label for="cor-fonte" class="input-label" translate>Contorno</label>

                  <div class="font-color-input-container">
                    <input type="color" class="form-control form-control-color color-input"
                      [(ngModel)]="templateCss.contorno" [value]="templateCss.contorno" />

                    <span class="font-color-text">{{templateCss.contorno}}</span>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div ngbAccordion>
      <div ngbAccordionItem [collapsed]="false" class="collapse-container">
        <!-- [collapsed]="openedSection !== 'linksFont'" (click)="toggleSection('linksFont')" -->
        <h2 ngbAccordionHeader class="collapse-container">
          <button ngbAccordionButton class="collapse-container-button" translate>
            <div class="collapse-header-content">
              <h2 class="form-box-title">Estilo da fonte dos links</h2>
              <span class="form-box-subtitle">Estilize a fonte do texto dos links</span>
            </div>
          </button>
        </h2>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="template-style-options-container">
                <div class="template-styles-field">
                  <div class="profile-font-family-container">
                    <label for="font-family" class="input-label" translate>meuSluper.estiloFontePerfil.font</label>

                    <select id="font-family" name="font-family" class="form-select font-family-input"
                      [(ngModel)]="templateCss.fonte">
                      <option *ngFor="let font of fonts" [value]="font">
                        {{ font }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="profile-font-style-container">
                  <label class="input-label" translate>meuSluper.estiloFontePerfil.opcoesCaractere</label>

                  <div class="text-styles-options">
                    <button class="text-style-option" (click)="changeTextLinkStyle('bold')"
                      [ngClass]="templateCss.negrito ? 'selected-text-style-option' : 'text-style-option'"
                      style="font-weight: bold;">
                      N
                    </button>

                    <button class="text-style-option" (click)="changeTextLinkStyle('italic')"
                      [ngClass]="templateCss.italico ? 'selected-text-style-option' : 'text-style-option'"
                      style="font-style: italic;">
                      I
                    </button>
                  </div>
                </div>

                <div class="profile-color-container">
                  <label for="cor-fonte" class="input-label" translate>Cor da fonte</label>

                  <div class="font-color-input-container">
                    <input type="color" class="form-control form-control-color color-input" id="cor-fonte"
                      name="cor-fonte" [(ngModel)]="templateCss.corFonte" />

                    <span class="font-color-text">{{templateCss.corFonte}}</span>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div ngbAccordion>
      <div ngbAccordionItem [collapsed]="false" class="collapse-container">
        <!-- (click)="toggleSection('preDefinedStyles') [collapsed]="openedSection !== 'preDefinedStyles'" -->
        <h2 ngbAccordionHeader class="collapse-container">
          <button ngbAccordionButton class="collapse-container-button" translate>
            <div class="collapse-header-content">
              <h2 class="form-box-title">Estilos Pré definidos</h2>
              <span class="form-box-subtitle">Selecione estilos pré definidos para o template</span>
            </div>
          </button>
        </h2>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody class="background-accordition-body">
            <ng-template>
              <div class="default-styles-container">
                <div class="default-style-background" *ngFor="let style of defaultBackgroundStyles; let i = index"
                  (click)="applyDefaultBackgroundStyle(i)" [style.background]="getBackground(style)"
                  [style.border-radius]="style.externo.borderRadius" [style.border-style]="style.externo.borderStyle"
                  [style.border-color]="style.externo.borderColor" [style.border-width]="style.externo.borderWidth">
                  <div class="default-style-button" [style.background]="style.interno.background"
                    [style.border-radius]="style.interno.borderRadius" [style.border-style]="style.interno.borderStyle"
                    [style.border-color]="style.interno.borderColor" [style.border-width]="style.interno.borderWidth">
                  </div>
                  <div class="default-style-button" [style.background]="style.interno.background"
                    [style.border-radius]="style.interno.borderRadius" [style.border-style]="style.interno.borderStyle"
                    [style.border-color]="style.interno.borderColor" [style.border-width]="style.interno.borderWidth">
                  </div>
                  <div class="default-style-button" [style.background]="style.interno.background"
                    [style.border-radius]="style.interno.borderRadius" [style.border-style]="style.interno.borderStyle"
                    [style.border-color]="style.interno.borderColor" [style.border-width]="style.interno.borderWidth">
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div ngbAccordion>
      <div ngbAccordionItem [collapsed]="false" class="collapse-container">
        <!-- [collapsed]="openedSection !== 'background'" (click)="toggleSection('background')" -->
        <h2 ngbAccordionHeader class="collapse-container">
          <button ngbAccordionButton class="collapse-container-button" translate>
            <div class="collapse-header-content">
              <h2 class="form-box-title">Estilo do background</h2>
              <span class="form-box-subtitle">Customize o fundo com as cores da sua prefêrencia</span>
            </div>
          </button>
        </h2>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody class="background-accordition-body">
            <ng-template>
              <div class="background-custom-container">
                <div (click)="changeBackgroundType('color')" class="background-option-container"
                  [style]="templateBgType === 'color' ? 'background-color: #fff;' : 'background: transparent'">
                  <div class="background-option-title">
                    <input (click)="changeBackgroundType('color')" [(ngModel)]="isColorBgSelected"
                      [checked]="templateBgType === 'color'" type="checkbox" name="colors-background-enabled"
                      id="colors-background-enabled">

                    <label for="colors-background-enabled" id="colors-background-enabled-label"
                      class="colors-background-enabled-label">Fundo colorido

                    </label>
                  </div>

                  <div class="background-options-content">
                    <div class="profile-color-container background-colors-container">
                      <label for="primary-background-color" class="input-label"
                        translate>meuSluper.estiloFontePerfil.corPrincipal</label>

                      <div class="font-color-input-container">
                        <input type="color" class="form-control form-control-color color-input"
                          id="primary-background-color" name="primary-background-color"
                          [(ngModel)]="templateCss.corPrimaria" />

                        <span class="font-color-text">{{templateCss.corPrimaria}}</span>
                      </div>
                    </div>

                    <div class="profile-color-container background-colors-container">
                      <label for="secondary-background-color" class="input-label"
                        translate>meuSluper.estiloFontePerfil.corPrincipal</label>

                      <div class="font-color-input-container">
                        <input type="color" class="form-control form-control-color color-input"
                          id="secondary-background-color" name="secondary-background-color"
                          [(ngModel)]="templateCss.corSecundaria" />

                        <span class="font-color-text">{{templateCss.corSecundaria}}</span>
                      </div>
                    </div>

                    <div class="fade-direction-container">
                      <label class="input-label" translate>Direçāo do degradê</label>

                      <div class="fade-direction-input-container">
                        <img src="/assets/img/gradient.svg" alt="Curva" style="width: 12px; height: 12px;" />
                        <input type="number" class="fade-direction-input" [(ngModel)]="templateCss.direcaoDegrade">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="background-option-container" (click)="changeBackgroundType('image')"
                  [style]="templateBgType === 'image' ? 'background-color: #fff;' : 'background: transparent'">
                  <div class="background-option-title">
                    <input [(ngModel)]="isImageBgSelected" [checked]="templateBgType === 'image'" type="checkbox"
                      name="image-background-enabled" id="image-background-enabled"
                      (click)="changeBackgroundType('image')">

                    <label for="image-background-enabled" class="image-background-enabled-label">
                      Fundo com imagem
                    </label>
                  </div>

                  <div class="background-options-content background-image-option-container">
                    <button class="background-image-selector" [disabled]="templateBgType === 'color'"
                      (click)="openUploadTemplateImages('background')"
                      [style]="templateCss.cardBackgroundImageUrl ? 'background-image: url(' + templateCss.cardBackgroundImageUrl + ')' : ''">
                      <span class="background-image-selector-label" *ngIf="!templateCss.cardBackgroundImageUrl">
                        <i class="bi bi-file-image"></i>

                        Clique aqui para selecionar uma imagem para o fundo
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div ngbAccordion>
      <div ngbAccordionItem [collapsed]="false" class="collapse-container">
        <!-- [collapsed]="openedSection !== 'background'" (click)="toggleSection('background')" -->
        <h2 ngbAccordionHeader class="collapse-container">
          <button ngbAccordionButton class="collapse-container-button" translate>
            <div class="collapse-header-content">
              <h2 class="form-box-title">Estilo da sua aplicação!</h2>
              <span class="form-box-subtitle">Customize as imagens de acordo com a sua preferência</span>
            </div>
          </button>
        </h2>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody class="background-accordition-body">
            <ng-template>
              <div class="background-custom-container">
                <div class="background-option-container" (click)="changeBackgroundType('image')"
                  [style]="templateBgType === 'image' ? 'background-color: #fff;' : 'background: transparent'">
                  <div class="background-option-title">
                    <label for="image-background-enabled" class="image-background-enabled-label">
                      Imagem do login
                    </label>
                  </div>

                  <div class="background-options-content background-image-option-container">
                    <button class="background-image-selector" (click)="openUploadTemplateImages('login')"
                      [style]="templateCss.loginImageUrl ? 'background-image: url(' + templateCss.loginImageUrl + ')' : ''">
                      <span class="background-image-selector-label" *ngIf="!templateCss.loginImageUrl">
                        <i class="bi bi-file-image"></i>

                        Clique aqui para selecionar uma imagem para o login
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody class="background-accordition-body">
            <ng-template>
              <div class="background-custom-container">
                <div class="background-option-container" (click)="changeBackgroundType('image')"
                  [style]="templateBgType === 'image' ? 'background-color: #fff;' : 'background: transparent'">
                  <div class="background-option-title">
                    <label for="image-background-enabled" class="image-background-enabled-label">
                      Imagem background de login
                    </label>
                  </div>

                  <div class="background-options-content background-image-option-container">
                    <button class="background-image-selector" (click)="openUploadTemplateImages('background-login')"
                      [style]="templateCss.backgroundLoginImageUrl ? 'background-image: url(' + templateCss.backgroundLoginImageUrl + ')' : ''">
                      <span class="background-image-selector-label" *ngIf="!templateCss.backgroundLoginImageUrl">
                        <i class="bi bi-file-image"></i>

                        Clique aqui para selecionar uma imagem para o background do login
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody class="background-accordition-body">
            <ng-template>
              <div class="background-custom-container">
                <div class="background-option-container" (click)="changeBackgroundType('image')"
                  [style]="templateBgType === 'image' ? 'background-color: #fff;' : 'background: transparent'">
                  <div class="background-option-title">
                    <label for="image-background-enabled" class="image-background-enabled-label">
                      Logo do cabeçalho
                    </label>
                  </div>

                  <div class="background-options-content background-image-option-container">
                    <button class="background-image-selector" (click)="openUploadTemplateImages('header')"
                      [style]="templateCss.headerImageUrl ? 'background-image: url(' + templateCss.headerImageUrl + ')' : ''">
                      <span class="background-image-selector-label" *ngIf="!templateCss.headerImageUrl">
                        <i class="bi bi-file-image"></i>

                        Clique aqui para selecionar uma imagem para o cabeçalho
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="template-result-section">
    <div class="template" [style.background]="getBackground()"
      [style]="templateCss.cardBackgroundImageUrl ? 'background-image: url(' + templateCss.cardBackgroundImageUrl + ')' : 'background:' + getBackground()">
      <div class="template-content">
        <div class="calling-card"
          [style]="templateCss.callingCardImageUrl ? 'background-image: url(' + templateCss.callingCardImageUrl + ')' : ''">
          <button class="open-calling-card-selection-button" (click)="openUploadTemplateImages('card')">
            <i class="bi bi-upload upload-calling-card-img-ico"></i>
          </button>

          <button class="remove-calling-card-image-button" (click)="removeCallingCardImage()"
            *ngIf="templateCss.callingCardImageUrl">
            <i class="bi bi-x remove-calling-card-image-ico"></i>
          </button>

          <img src="/assets/img/default_avatar_1.png" alt="expemplo avatar" class="avatar-pic">

          <div class="profile-informations">
            <span class="card-user-name" [style.color]="templateCss.corFontePerfil"
              [style.font-family]="templateCss.fontePerfil"
              [style.font-weight]="templateCss.negritoPerfil ? 'bold' : undefined"
              [style.font-style]="templateCss.italicoPerfil ? 'italic' : undefined">john Doe</span>
            <span class="user-role" [style.color]="templateCss.corFontePerfil"
              [style.font-family]="templateCss.fontePerfil"
              [style.font-weight]="templateCss.negritoPerfil ? 'bold' : undefined"
              [style.font-style]="templateCss.italicoPerfil ? 'italic' : undefined">CEO</span>
          </div>
        </div>

        <div class="template-links">
          <div class="default-link" [style.background-color]="templateCss.preenchimento" style="border-style: solid"
            [style.border-width]="templateCss.espessura + 'px'" [style.border-color]="templateCss.contorno"
            [style.border-radius]="templateCss.curvaBorda + 'px '" [style.box-shadow]="templateCss.sombra">
            <span class="link-description" [style.color]="templateCss.corFonte" [style.font-family]="templateCss.fonte"
              style="word-break: break-all" [style.font-weight]="templateCss.negrito ? 'bold' : undefined"
              [style.font-style]="templateCss.italico ? 'italic' : undefined">Botāo tradicional</span>
          </div>

          <div class="classic-link" [style.background-color]="templateCss.preenchimento" style="border-style: solid"
            [style.border-width]="templateCss.espessura + 'px'" [style.border-color]="templateCss.contorno"
            [style.border-radius]="templateCss.curvaBorda + 'px'" [style.box-shadow]="templateCss.sombra">
            <img
              src="https://images.unsplash.com/photo-1707197066378-36583db5e892?q=80&w=2340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="example-image" class="classic-link-image">
            <span class="link-description" [style.color]="templateCss.corFonte" [style.font-family]="templateCss.fonte"
              style="word-break: break-all" [style.font-weight]="templateCss.negrito ? 'bold' : undefined"
              [style.font-style]="templateCss.italico ? 'italic' : undefined">Botāo clássico com imagem</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="buttons-container">
  <button class="back-button" (click)="backToManageTemplates()">
    Voltar
  </button>
  <button class="save-button" (click)="saveTemplate()">
    Salvar
  </button>
  <button class="preview-button" (click)="openPreview()">
    Preview da tela de login
  </button>
</div>

<ng-template #templateBgImgSelctionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" translate>meuSluper.modalImagem.titulo</h4>
    <button type="button" class="close" aria-label="Fechar" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body file-selection-container">
    <input type="file" (change)="templateBgImageChanged($event)" accept="image/*" />

    <ng-container *ngIf="imageTypeToBeUploaded === 'background'">
      <span class="image-scale-orientations">
        Tamanho ideal da imagem: 1280x832 (1:5)
      </span>

      <image-cropper [imageChangedEvent]="changeImageEvent" [maintainAspectRatio]="true" [aspectRatio]="1280 / 832"
        format="png" (imageCropped)="templateImageCropped($event)" [cropperMaxWidth]="1920"
        [cropperMaxHeight]="1080"></image-cropper>
    </ng-container>

    <ng-container *ngIf="imageTypeToBeUploaded === 'card'">
      <span class="image-scale-orientations">
        Tamanho ideal da imagem: 617x149 (4:1)
      </span>

      <image-cropper [imageChangedEvent]="changeImageEvent" [maintainAspectRatio]="true" [aspectRatio]="617 / 149"
        format="png" (imageCropped)="templateImageCropped($event)" [cropperMaxWidth]="1920" [cropperMaxHeight]="1080"
        [onlyScaleDown]="true"></image-cropper>
    </ng-container>

    <ng-container *ngIf="imageTypeToBeUploaded === 'login'">
      <span class="image-scale-orientations">
        Tamanho ideal da imagem: 175x63 (2:1)
      </span>

      <image-cropper [imageChangedEvent]="changeImageEvent" [maintainAspectRatio]="true" [aspectRatio]="175 / 63"
        format="png" (imageCropped)="templateImageCropped($event)" [cropperMaxWidth]="1920" [cropperMaxHeight]="1080"
        [onlyScaleDown]="true"></image-cropper>
    </ng-container>

    <ng-container *ngIf="imageTypeToBeUploaded === 'header'">
      <span class="image-scale-orientations">
        Tamanho ideal da imagem: 125x45 (6:1)
      </span>

      <image-cropper [imageChangedEvent]="changeImageEvent" [maintainAspectRatio]="true" [aspectRatio]="125 / 45"
        format="png" (imageCropped)="templateImageCropped($event)" [cropperMaxWidth]="1920" [cropperMaxHeight]="1080"
        [onlyScaleDown]="true"></image-cropper>
    </ng-container>

    <ng-container *ngIf="imageTypeToBeUploaded === 'background-login'">
      <span class="image-scale-orientations">
        Tamanho ideal da imagem: 614x707 (0.8x1)
      </span>

      <image-cropper [imageChangedEvent]="changeImageEvent" [maintainAspectRatio]="true" [aspectRatio]="614 / 707"
        format="png" (imageCropped)="templateImageCropped($event)" [cropperMaxWidth]="1920" [cropperMaxHeight]="1080"
        [onlyScaleDown]="true"></image-cropper>
    </ng-container>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="confirmTemplateImageCropped(croppedBgImage)" translate>
      meuSluper.modalImagem.confirmar
    </button>
  </div>
</ng-template>
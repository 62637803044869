import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  // Subject que mantém o estado das notificações
  private notificationSubject = new BehaviorSubject<any>(null);

  // Observable que os componentes podem se inscrever para receber atualizações
  notifications$ = this.notificationSubject.asObservable();

  constructor() { }

  // Método para emitir uma nova notificação
  notify(data: any) {
    this.notificationSubject.next(data);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { CustomCommunityPhotosService } from 'src/app/custom-community-photos/custom-community-photos.service';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'app-sluper-background',
  templateUrl: './sluper-background.component.html',
  styleUrls: ['./sluper-background.component.css']
})
export class SluperBackgroundComponent implements OnInit {
  @Input() backgroundType?: number = 0;
  customBg?: string | null = null
  @Input() removeBackground?: boolean = false
  urlBackgroundImageLogin = '';

  constructor(
    private loginService: LoginService,
    private customCommunityPhotosService: CustomCommunityPhotosService
  ) {
    this.getBackgroundLoginImage();
  }

  ngOnInit(): void {
    this.customCommunityPhotosService.background$.subscribe(background => {
      this.customBg = background && background.length ? background : null;
    });

  }

  getBackgroundLoginImage() {
    this.loginService.getBackgroundLoginImage(window.location.hostname).subscribe({
      next: (response: string) => {
        this.urlBackgroundImageLogin = response;
      },
      error: (error) => {
        this.urlBackgroundImageLogin = '../../../../assets/img-sluper/simbolo-brand.png';
        if (error.status === 404) {
          console.warn('Imagem de cabeçalho não existe, exibindo cabeçalho padrão (404).');
        } else {
          console.error('Erro ao buscar a imagem de login:', error);
        }
      },
    });
  }

  getBackgroundStyle() {
    if (this.backgroundType === 0) {
      return {
        background: 'linear-gradient(242deg, #01B3D5 -1.95%, #2F69CD 47.39%, #793BE7 90.77%)'
      };
    } else if (this.backgroundType === 1) {
      return {
        background: 'linear-gradient(135deg, #01B3D5 0%, #793BE7 100%)'
      };
    } else {
      return {};
    }
  }

}

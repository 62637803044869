<!-- <div class="alert-div">
    <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
        {{ alertMessage }}
    </ngb-alert>
</div> -->
<app-loading-spinner [bgTransparent]="true" *ngIf=" loading"></app-loading-spinner>


<section id="delete-side-bar-item">
  <h4>{{ data.title }}</h4>

  <span>{{ data.description }}</span>

  <div class="button-container">
    <button type="button" class="item-cancel-button" (click)="closeModal()">Cancelar</button>
    <button type="button" class="item-delete-button" (click)="deleteOption()">
      {{ data.actionButton ? data.actionButton : 'Remover' }}
    </button>
  </div>
</section>

<div class="modal-container">
    <header class="modal-header">
        <button type="button" class="close" aria-label="Fechar" (click)="closeModal(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </header>
    <div class="event-type-component">
        <h4>Qual tipo de evento você deseja criar?</h4>
        <div class="event-type-container">
            <div class="event-type" (click)="selectEventType('PRESENCIAL')">
                <span>
                    Evento Presencial
                </span>
            </div>
            <div class="event-type" (click)="selectEventType('REMOTO')">
                <span>
                    Evento Online
                </span>
            </div>
        </div>
    </div>


</div>
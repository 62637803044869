import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BOLD_BUTTON, EditorConfig, FONT_SIZE_SELECT, FORE_COLOR, ITALIC_BUTTON, JUSTIFY_CENTER_BUTTON, JUSTIFY_FULL_BUTTON, JUSTIFY_LEFT_BUTTON, JUSTIFY_RIGHT_BUTTON, ORDERED_LIST_BUTTON, SEPARATOR, UNDO_BUTTON, UNORDERED_LIST_BUTTON } from 'ngx-simple-text-editor';
import { filter, firstValueFrom, map } from 'rxjs';
import { Connection } from 'src/app/model/connection.model';
import { IDocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { EventParticipant, EventWithParticipantsQuantity, SluperEvent } from 'src/app/model/event.model';
import { Header } from 'src/app/model/header.model';
import { INetworkingControl } from 'src/app/model/networking-control.model';
import { LISTA_PAIS, Pais } from 'src/app/model/pais';
import { IUserProfileWithCSS } from 'src/app/model/user-profile-css.model';
import { UserProfileService } from 'src/app/user-profile/user-profile.service';
import { EventsService } from '../events.service';



@Component({
  selector: 'app-create-event-modal',
  templateUrl: './create-event-modal.component.html',
  styleUrls: ['./create-event-modal.component.css'],
})
export class CreateEventModalComponent implements OnInit {
  @Input() userProfile!: IDocumentUserProfile | null;
  @Input() event!: SluperEvent | null;
  @Input() guests!: string[] | null;
  @Input() userConnections!: Connection[]
  @Input() type: 'PRESENCIAL' | 'REMOTO' = 'PRESENCIAL'
  @Input() eventType: 'COMMUNITY-EVENT' | undefined = undefined
  @Input() communityItem?: SluperEvent
  @Output() triggerfeedbackMessage = new EventEmitter<{
    message: string;
    type: 'success' | 'error';
  }>();

  selectedTab = 0;
  screens: 'information' | 'description' | 'ticket' = 'information';
  communities: EventWithParticipantsQuantity[] = [];
  exceedsMaxAttendees: boolean = false;
  selectedCommunities: string[] = [];
  manualParticipants: string[] = [];
  updateMode = false;
  imageChanged = false;
  defaultImageTest = '/assets/img/imagem_fundo_preview.png';
  sentContacts: Connection[] = [];
  communityParticipantsFiltered: Connection[] = [];
  communityParticipantsAdded: Connection[] = [];
  allEventParticipants: EventParticipant[] = [];
  minStartTime = '00:00';
  minEndTime = '00:00';
  mapsOptions: any = {
    componentRestrictions: { country: 'BR' }
  };
  csvFile: File | null = null;

  formModel = {
    email: '',
    isValid: false,
    errorMessage: '',
  };
  modalData: {
    image?: Blob | null,
    name: string,
    site: string,
    type: 'PRESENCIAL' | 'REMOTO',
    neighborhood: string,
    number: string,
    country: string,
    city: string,
    state: string,
    latitude: number,
    longitude: number,
    address: string,
    startDate: string,
    endDate: string,
    startTime: string,
    endTime: string,
    maxAttendees: number,
    public: boolean,
    description: string,
    about: string,
    requiresConfirmation: boolean,
    participantDetailsAfterCheckin: boolean,
    guests: Connection[],
    communities: string[],
    zipCode: string
  } = {
      image: null,
      name: '',
      site: '',
      type: 'PRESENCIAL',
      country: '',
      city: '',
      state: '',
      latitude: 0,
      longitude: 0,
      address: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      maxAttendees: 30,
      public: false,
      description: '',
      about: '',
      requiresConfirmation: false,
      participantDetailsAfterCheckin: false,
      guests: [] as Connection[],
      communities: [] as string[],
      neighborhood: '',
      number: '',
      zipCode: ''
    };
  minEndDate: string = '';
  guestEmailToInvite = "";
  guestsFormErrors = {
    guestEmail: {
      valid: true,
      message: ''
    },
  };
  formErrors = {
    image: {
      valid: true,
      message: ''
    },
    name: {
      valid: true,
      message: ''
    },
    site: {
      valid: true,
      message: ''
    },
    type: {
      valid: true,
      message: ''
    },
    neighborhood: {
      valid: true,
      message: ''
    },
    number: {
      valid: true,
      message: ''
    },
    country: {
      valid: true,
      message: ''
    },
    city: {
      valid: true,
      message: ''
    },
    state: {
      valid: true,
      message: ''
    },
    latitude: {
      valid: true,
      message: ''
    },
    longitude: {
      valid: true,
      message: ''
    },
    address: {
      valid: true,
      message: ''
    },
    startDate: {
      valid: true,
      message: ''
    },
    endDate: {
      valid: true,
      message: ''
    },
    startTime: {
      valid: true,
      message: ''
    },
    endTime: {
      valid: true,
      message: ''
    },
    maxAttendees: {
      valid: true,
      message: ''
    },
    public: {
      valid: true,
      message: ''
    },
    description: {
      valid: true,
      message: ''
    },
    about: {
      valid: true,
      message: ''
    },
    requiresConfirmation: {
      valid: true,
      message: ''
    },
    participantDetailsAfterCheckin: {
      valid: true,
      message: ''
    },
    guests: {
      valid: true,
      message: ''
    },
    communities: {
      valid: true,
      message: ''
    },
    zipCode: {
      valid: true,
      message: ''
    }
  };
  changedImageEvent: any = null;
  imageToCrop = "";
  cropMode = false;
  minDate = new Date().toISOString().split('T')[0];;
  loading = false;
  paises: Pais[] = LISTA_PAIS;

  config: EditorConfig = {
    placeholder: 'Faça uma descrição de regras detalhadas',
    buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR,
      FONT_SIZE_SELECT, SEPARATOR, JUSTIFY_LEFT_BUTTON, JUSTIFY_CENTER_BUTTON,
      JUSTIFY_RIGHT_BUTTON, JUSTIFY_FULL_BUTTON, ORDERED_LIST_BUTTON, UNORDERED_LIST_BUTTON],
  };
  configRules: EditorConfig = {
    placeholder: 'Faça uma descrição de regras detalhadas',
    buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR,
      FONT_SIZE_SELECT, SEPARATOR, JUSTIFY_LEFT_BUTTON, JUSTIFY_CENTER_BUTTON,
      JUSTIFY_RIGHT_BUTTON, JUSTIFY_FULL_BUTTON, ORDERED_LIST_BUTTON, UNORDERED_LIST_BUTTON],
  };

  constructor(
    private activeModal: NgbActiveModal,
    private eventsService: EventsService,
    private userProfileService: UserProfileService,
    private http: HttpClient
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    if (this.type.length > 0) this.modalData.type = this.type;

    this.sentContacts = this.userConnections
    if (!this.event) {
      this.communityParticipantsFiltered = this.sentContacts
      this.loading = false;
      return;
    }

    try {
      const eventParticipant = await firstValueFrom(
        this.eventsService.getEventParticipants(this.event.id)
      );
      if (eventParticipant.body) {
        this.allEventParticipants = eventParticipant.body;
        this.communityParticipantsFiltered = this.sentContacts.filter((person: Connection) =>
          !eventParticipant.body!.some((participant: EventParticipant) =>
            participant.idUser === person.connection.idUser
          )
        );
      }
    } catch (error) {
      console.error("Erro ao filtrar participantes:", error);
    }


    this.updateMode = true;
    this.modalData.name = this.event.name;
    this.modalData.site = this.event.website;
    if (this.event.type === 'PRESENCIAL') {
      this.modalData.maxAttendees = this.event.numberOfParticipants;
      this.modalData.address = this.event.address || '';
      this.modalData.number = this.event.number || '';
      this.modalData.neighborhood = this.event.neighborhood || '';
      this.modalData.zipCode = this.event.zipCode || '';
      this.modalData.city = this.event.city;
      this.modalData.state = this.event.state;
      this.modalData.country = this.event.country;
    }
    this.modalData.description = this.event.description;
    this.modalData.about = this.event.about;
    this.modalData.public = this.event.isPublic;
    this.modalData.requiresConfirmation = this.event.requiresConfirmation;
    this.modalData.participantDetailsAfterCheckin = !!this.event?.participantDetailsAfterCheckin;
    this.modalData.type = this.event.type === 'REMOTO' ? 'REMOTO' : this.event.type === "PRESENCIAL" ? 'PRESENCIAL' : this.modalData.type;
    const [date, time] = this.event.startDate.split('T');
    this.modalData.startDate = date;
    this.modalData.startTime = time ? time.split(':').slice(0, 2).join(':') : "00:00";
    const [endDate, endTime] = this.event.endDate.split('T');
    this.modalData.endDate = endDate;
    this.modalData.endTime = endTime ? endTime.split(':').slice(0, 2).join(':') : "00:00";

    if (this.event.guests !== null) {
      const users = await this.buscaParticipantes();

      if (users) {
        users.forEach((user) => {
          if (user) {
            const newUser: Connection = {
              connectionDate: new Date().toISOString().split('T')[0],
              event: {
                id: this.event!.id,
                name: this.event!.name
              },
              connection: {
                idUser: '',
                name: '',
                bio: '',
                showImageProfile: false,
                publish: false,
                parent: '',
                listURI: [],
                uriImageProfile: '',
                uriImageBackground: '',
                listSocialNetwork: [],
                listButtons: [],
                listUriImages: [],
                header: {} as Header,
                listContacts: [],
                networkingControl: {} as INetworkingControl,
                listProfile: []
              }
            }
            this.addCommunityParticipants(newUser);
          }
        });
      }
    }

    this.eventsService.getImageEvent(this.event.id).subscribe(res => {
      if (res.body) {
        const file = new File([this.eventsService.base64ToBlob(res.body.base64Image, 'image/jpeg')], 'event_image.jpeg', { type: 'image/jpeg' });

        this.handleFileChange({ target: { files: [file] } });

        this.imageChanged = false;
      } else {
        this.imageChanged = false;
      }
    });
  }
  private async buscaParticipantes() {
    if (!this.event || !this.event.guests || this.event.guests.length === 0) return [];

    try {


      const eventParticipant = await firstValueFrom(this.eventsService.getEventParticipants(this.event.id));


      if (!eventParticipant.body) {
        return [];
      }

      this.allEventParticipants = this.allEventParticipants.filter((participant) => {
        return this.userConnections.some((connection) => connection.connection.idUser !== participant.idUser);
      })

      return this.allEventParticipants;
    } catch (error) {
      console.error('Erro ao buscar participantes:', error);
      throw error;
    }
  }



  closeModal() {
    this.activeModal.close();
  }

  private changeSelectedTab(tab: number) {
    this.selectedTab = tab;

    switch (tab) {
      case 0:
        this.screens = 'information'
        break;
      case 1:
        this.screens = 'description'
        break;
      case 2:
        this.screens = 'ticket'
        break;
    }
  }

  addQuantityPerson() {
    this.modalData.maxAttendees = this.modalData.maxAttendees + 1
    this.checkMaxAttendees()
  }

  rmQuantityPerson() {
    if (this.modalData.maxAttendees > 0) {
      this.modalData.maxAttendees = this.modalData.maxAttendees - 1
      this.checkMaxAttendees()
    }
  }

  addCommunityParticipants(person: Connection) {
    if (!this.verifyCommunityParticipants(person)) {
      this.modalData.guests = [...this.modalData.guests, person];
      this.communityParticipantsAdded = [...this.communityParticipantsAdded, person];
    }
  }


  rmCommunityParticipants(person: Connection) {
    this.modalData.guests.splice(this.modalData.guests.indexOf(person), 1)
  }

  rmManualParticipant(personToRemove: string) {
    this.manualParticipants = this.manualParticipants.filter((person: string) => person !== personToRemove)
  }

  addManualParticipant() {
    if (this.updateMode) {
      if (this.modalData.guests.some(participant => participant.connection.email === this.formModel.email)
        || this.allEventParticipants.some(participant => participant.email === this.formModel.email)
      ) {
        this.triggerfeedbackMessage.emit({
          message: 'Este usuário ja foi convidado.',
          type: 'error'
        });
        this.formModel = {
          email: '',
          isValid: false,
          errorMessage: '',
        }
        return
      }
    } else {
      if (this.modalData.guests.some(participant => participant.connection.email === this.formModel.email)) {
        this.triggerfeedbackMessage.emit({
          message: 'Este usuário ja foi convidado.',
          type: 'error'
        });
        this.formModel = {
          email: '',
          isValid: false,
          errorMessage: '',
        }
        return
      }
    }




    this.manualParticipants.push(this.formModel.email)
    this.formModel = {
      email: '',
      isValid: false,
      errorMessage: '',
    }
  }

  verifyCommunityParticipants(person: Connection) {
    return this.modalData.guests.some((guest: Connection) => guest.connection.idUser === person.connection.idUser);
  }


  filterCommunityParticipants(event: any) {
    const searchValue = event.target.value.toLowerCase();
    this.communityParticipantsFiltered = this.sentContacts.filter((person: Connection) =>
      person.connection.name.toLowerCase().includes(searchValue)
    );
  }


  async processGuests(): Promise<string[]> {
    try {

      let allEmails: string[] = this.modalData.guests
        .filter((item: Connection) => {
          return !!item.connection?.email && item.connection.email.length > 5;
        })
        .map((item: Connection) => item.connection.email!);

      allEmails = [...allEmails, ...this.manualParticipants];

      return allEmails;
    } catch (error) {
      console.error('Erro ao buscar perfis de convidados:', error);
      return [];
    }
  }


  async getUserById(id: string): Promise<IUserProfileWithCSS | ''> {
    try {
      const response = await this.userProfileService
        .userById(id)
        .pipe(
          filter((mayBeOk: HttpResponse<IUserProfileWithCSS>) => mayBeOk.ok),
          map((response: HttpResponse<IUserProfileWithCSS>) => response.body)
        )
        .toPromise();

      return response || '';
    } catch (error) {
      console.error(`Erro ao buscar usuário com ID ${id}:`, error);
      return '';
    }
  }
  formatDate(event: { target: HTMLInputElement }) {
    const input = event.target;
    let value = input.value.replace(/\D/g, '');
    if (value.length > 8) {
      value = value.slice(0, 8);
    }

    if (value.length >= 2) {
      value = value.substring(0, 2) + '/' + value.substring(2);
    }
    if (value.length >= 5) {
      value = value.substring(0, 5) + '/' + value.substring(5);
    }

    input.value = value;
  }

  private resetGuestsFormErrors() {
    this.guestsFormErrors = {
      guestEmail: {
        valid: true,
        message: ''
      },
    }
  }

  handleFileChange(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      this.cropMode = true;
      this.imageToCrop = e.target?.result as string;
      this.changedImageEvent = event;
    }

    reader.readAsDataURL(file);

    this.imageChanged = true;
    this.loading = false;
  }

  removeSelectedImage() {
    this.cropMode = false;
  }

  private resetFormErrors() {
    this.formErrors = {
      image: {
        valid: true,
        message: ''
      },
      name: {
        valid: true,
        message: ''
      },
      site: {
        valid: true,
        message: ''
      },
      type: {
        valid: true,
        message: ''
      },
      neighborhood: {
        valid: true,
        message: ''
      },
      number: {
        valid: true,
        message: ''
      },
      country: {
        valid: true,
        message: ''
      },
      city: {
        valid: true,
        message: ''
      },
      state: {
        valid: true,
        message: ''
      },
      latitude: {
        valid: true,
        message: ''
      },
      longitude: {
        valid: true,
        message: ''
      },
      address: {
        valid: true,
        message: ''
      },
      startDate: {
        valid: true,
        message: ''
      },
      endDate: {
        valid: true,
        message: ''
      },
      startTime: {
        valid: true,
        message: ''
      },
      endTime: {
        valid: true,
        message: ''
      },
      maxAttendees: {
        valid: true,
        message: ''
      },
      public: {
        valid: true,
        message: ''
      },
      description: {
        valid: true,
        message: ''
      },
      about: {
        valid: true,
        message: ''
      },
      requiresConfirmation: {
        valid: true,
        message: ''
      },
      participantDetailsAfterCheckin: {
        valid: true,
        message: ''
      },
      guests: {
        valid: true,
        message: ''
      },
      communities: {
        valid: true,
        message: ''
      },
      zipCode: {
        valid: true,
        message: ''
      }
    };
  }

  handleImageCropped(event: ImageCroppedEvent) {
    this.modalData.image = event.blob;
  }

  nextStep() {
    // Verificar se as datas são válidas
    switch (this.selectedTab) {
      case 0:
        this.validateFormFirstStep();
        if (Object.keys(this.formErrors)
          .filter((key) => key !== 'image')
          .some((key) => (this.formErrors as any)[key].valid === false)) {
          this.triggerfeedbackMessage.emit({
            message: 'Erro ao criar evento, verifique os campos obrigatórios.',
            type: 'error'
          });
          return;
        }
        this.changeSelectedTab(1);
        break;
      case 1:
        this.validateSecondStep();
        if (Object.keys(this.formErrors)
          .some((key) => (this.formErrors as any)[key].valid === false)) {
          this.triggerfeedbackMessage.emit({
            message: 'Erro ao criar evento, verifique os campos obrigatórios.',
            type: 'error'
          });
          return;
        }
        this.changeSelectedTab(2);
        break;
      case 2:
        this.handleSaveEvent();
        break;
    }
  }


  backStep() {
    if (this.selectedTab == 0) return
    this.changeSelectedTab(this.selectedTab - 1)
  }

  async changeSelectedTabStep(nextTab: number) {
    if (nextTab > this.selectedTab) {
      while (this.selectedTab < nextTab) {
        switch (this.selectedTab) {
          case 0:
            this.validateFormFirstStep();
            if (Object.keys(this.formErrors)
              .filter((key) => key !== 'image')
              .some((key) => (this.formErrors as any)[key].valid === false)) {
              this.triggerfeedbackMessage.emit({
                message: 'Erro ao criar evento, verifique os campos obrigatórios.',
                type: 'error'
              });
              this.changeSelectedTab(this.selectedTab);
              return;
            }
            break;

          case 1:
            this.validateSecondStep();
            if (Object.keys(this.formErrors)
              .some((key) => (this.formErrors as any)[key].valid === false)) {
              this.triggerfeedbackMessage.emit({
                message: 'Erro ao criar evento, verifique os campos obrigatórios.',
                type: 'error'
              });
              this.changeSelectedTab(this.selectedTab);
              return;
            }
            break;
        }
        this.selectedTab++;
        this.changeSelectedTab(this.selectedTab);
      }
    } else if (nextTab < this.selectedTab) {
      while (this.selectedTab > nextTab) {
        this.selectedTab--;
        this.changeSelectedTab(this.selectedTab);
      }
    }
  }



  validateEmail(): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.formModel.isValid = emailRegex.test(this.formModel.email.trim());
    this.formModel.errorMessage = this.formModel.isValid ? '' : 'Insira um e-mail válido.';
    return this.formModel.isValid;
  }


  private validateFormFirstStep() {
    this.resetFormErrors();

    // Validação de campos obrigatórios
    if (!this.modalData.name) {
      this.formErrors.name = {
        valid: false,
        message: 'Nome obrigatório'
      };
    }

    if (!this.modalData.startDate) {
      this.formErrors.startDate = {
        valid: false,
        message: 'Data de início obrigatória'
      };
    }

    if (!this.modalData.endDate) {
      this.modalData.endDate = this.modalData.startDate;
    }

    if (!this.modalData.startTime) {
      this.formErrors.startTime = {
        valid: false,
        message: 'Hora de início obrigatória'
      };
    }

    if (!this.modalData.endTime) {
      this.formErrors.endTime = {
        valid: false,
        message: 'Hora de término obrigatória'
      };
    }

    // Validação de data e hora
    const isDateValid = new Date(`${this.modalData.startDate}T${this.modalData.startTime}`) <=
      new Date(`${this.modalData.endDate}T${this.modalData.endTime}`);

    if (!isDateValid) {
      this.formErrors.endTime = {
        valid: false,
        message: 'A data de fim deve ser posterior à data de início.'
      };
      return; // Impede o avanço para o próximo passo
    }

    // Validação adicional para tipos de evento
    if (this.modalData.type === 'REMOTO' && (!this.modalData.site)) {
      this.formErrors.site = {
        valid: false,
        message: 'Link obrigatório'
      };
    }

    if (this.modalData.type === 'PRESENCIAL' && (!this.isCepValid() || !this.modalData.zipCode)) {
      this.formErrors.zipCode = {
        valid: false,
        message: 'CEP obrigatório'
      };
    }

    if (this.modalData.type === 'PRESENCIAL' && (!this.modalData.address)) {
      this.formErrors.address = {
        valid: false,
        message: 'Rua obrigatória'
      };
    }

    if (this.modalData.type === 'PRESENCIAL' && (!this.modalData.neighborhood)) {
      this.formErrors.neighborhood = {
        valid: false,
        message: 'Bairro obrigatório'
      };
    }

    if (this.modalData.type === 'PRESENCIAL' && (!this.modalData.number)) {
      this.formErrors.number = {
        valid: false,
        message: 'Número obrigatório'
      };
    }

    if (this.modalData.type === 'PRESENCIAL' && (!this.modalData.city)) {
      this.formErrors.city = {
        valid: false,
        message: 'Cidade obrigatória'
      };
    }

    if (this.modalData.type === 'PRESENCIAL' && (!this.modalData.state)) {
      this.formErrors.state = {
        valid: false,
        message: 'UF obrigatório'
      };
    }

    if (this.modalData.type === 'PRESENCIAL' && (!this.modalData.country)) {
      this.formErrors.country = {
        valid: false,
        message: 'País obrigatório'
      };
    }
  }


  private validateSecondStep() {
    this.resetFormErrors();

    if (this.countTextChars(this.modalData.description) > 1500) {
      this.formErrors.description = {
        valid: false,
        message: 'A descrição deve ter menos de 1500 caracteres'
      };
    }

    if (this.countTextChars(this.modalData.about) > 1500) {
      this.formErrors.about = {
        valid: false,
        message: 'A regra deve ter menos de 1500 caracteres'
      };
    }

  }

  private validateForm() {
    this.resetFormErrors();

    // if (!this.modalData.image && !this.updateMode) {
    //   this.formErrors.image = {
    //     valid: false,
    //     message: 'Imagem obrigatória'
    //   };
    // }

    if (!this.modalData.name) {
      this.formErrors.name = {
        valid: false,
        message: 'Nome obrigatório'
      };
    }

    if (!this.modalData.startDate) {
      this.formErrors.startDate = {
        valid: false,
        message: 'Data de início obrigatória'
      };
    }

    if (!this.modalData.endDate) {
      this.formErrors.endDate = {
        valid: false,
        message: 'Data de término obrigatória'
      };
    }
    if (!this.modalData.startTime) {
      this.formErrors.startTime = {
        valid: false,
        message: 'Hora de início obrigatória'
      };
    }

    if (!this.modalData.endTime) {
      this.formErrors.endTime = {
        valid: false,
        message: 'Hora de término obrigatória'
      };
    }

    if (this.modalData.type === 'REMOTO' && (!this.modalData.site)) {
      this.formErrors.site = {
        valid: false,
        message: 'Link obrigatório'
      }
    }

    if (this.modalData.type === 'PRESENCIAL' && (!this.isCepValid() || !this.modalData.zipCode)) {
      this.formErrors.zipCode = {
        valid: false,
        message: 'CEP inválido ou obrigatório'
      };
    }


    if (this.modalData.type === 'PRESENCIAL' && (!this.modalData.address)) {
      this.formErrors.address = {
        valid: false,
        message: 'Rua obrigatória'
      };
    }

    if (this.modalData.type === 'PRESENCIAL' && (!this.modalData.neighborhood)) {
      this.formErrors.neighborhood = {
        valid: false,
        message: 'Bairro obrigatório'
      };
    }
    if (this.modalData.type === 'PRESENCIAL' && (!this.modalData.number)) {
      this.formErrors.number = {
        valid: false,
        message: 'Número obrigatório'
      };
    }
    if (this.modalData.type === 'PRESENCIAL' && (!this.modalData.city)) {
      this.formErrors.city = {
        valid: false,
        message: 'Cidade obrigatória'
      };
    }
    if (this.modalData.type === 'PRESENCIAL' && (!this.modalData.state)) {
      this.formErrors.state = {
        valid: false,
        message: 'UF obrigatório'
      };
    }
    if (this.modalData.type === 'PRESENCIAL' && (!this.modalData.country)) {
      this.formErrors.country = {
        valid: false,
        message: 'País obrigatório'
      };
    }

    // if (!this.modalData.description) {
    //   this.formErrors.description = {
    //     valid: false,
    //     message: 'Descrição obrigatória'
    //   };
    // }
    // if (!this.modalData.about) {
    //   this.formErrors.about = {
    //     valid: false,
    //     message: 'Regras obrigatórias'
    //   };
    // }
  }

  async handleSaveEvent() {
    if (!this.userProfile) {
      this.triggerfeedbackMessage.emit({
        message: this.updateMode
          ? 'Erro ao atualizar evento, o usuário não foi encontrado.'
          : 'Erro ao criar evento, o usuário não foi encontrado.',
        type: 'error'
      });
      return;
    }

    this.loading = true;
    this.validateForm();

    if (Object.values(this.formErrors).some((error) => error.valid === false)) {
      this.triggerfeedbackMessage.emit({
        message: this.updateMode
          ? 'Erro ao atualizar evento, verifique os campos obrigatórios.'
          : 'Erro ao criar evento, verifique os campos obrigatórios.',
        type: 'error'
      });
      this.loading = false;
      return;
    }

    const startTime = this.modalData.startTime || "00:00";
    const formattedStartDate = `${this.modalData.startDate}T${startTime}:00.000Z`;

    const endTime = this.modalData.endTime || "23:59";
    const formattedEndDate = `${this.modalData.endDate}T${endTime}:00.000Z`;

    const event = {
      id: '',
      name: this.modalData.name,
      website: this.modalData.site,
      type: this.modalData.type,
      country: this.modalData.country,
      state: this.modalData.state,
      city: this.modalData.city,
      address: this.modalData.type !== 'REMOTO' ? this.modalData.address : '',
      number: this.modalData.type !== 'REMOTO' ? this.modalData.number : '',
      neighborhood: this.modalData.type !== 'REMOTO' ? this.modalData.neighborhood : '',
      zipCode: this.modalData.type !== 'REMOTO' ? this.modalData.zipCode : '',
      startDate: new Date(formattedStartDate).toISOString(),
      endDate: new Date(formattedEndDate).toISOString(),
      numberOfParticipants: this.modalData.maxAttendees,
      isPublic: this.modalData.public,
      description: this.modalData.description,
      latitude: String(this.modalData.latitude),
      longitude: String(this.modalData.longitude),
      parentEvent: this.eventType === 'COMMUNITY-EVENT' ? this.communityItem!.id : null,
      imageLink: '',
      organizer: {
        idUser: this.userProfile.idUser,
        name: this.userProfile.name,
        bio: this.userProfile.bio,
        uriImageProfile: this.userProfile.uriImageProfile,
        uriImageBackground: this.userProfile.uriImageBackground,
        header: this.userProfile.header,
      },
      guests: await this.processGuests(),
      requiresConfirmation: this.modalData.requiresConfirmation,
      participantDetailsAfterCheckin: this.modalData.participantDetailsAfterCheckin,
      active: true,
      about: this.modalData.about,
      communityLogo: null,
      useCommunityLogo: false
    };

    try {
      if (!this.updateMode) {
        const response = await firstValueFrom(this.eventsService.createEvent(event));
        event.id = response.body.message;
        await this.joinEvent(event.id);
      } else {
        event.id = this.event ? this.event.id : '';
        await firstValueFrom(this.eventsService.updateEvent(event));
      }

      // Tenta fazer o upload da imagem, mas se falhar, não interrompe a execução
      try {
        if (!this.updateMode || this.imageChanged) {
          await this.uploadEventImage(event.id);
        }
      } catch (error) {
        console.error('Erro ao salvar imagem: ', error);
      }

      // Tenta enviar os convites via CSV, mas se falhar, não interrompe a execução
      try {
        if (this.csvFile) {
          await this.sendInviteByCsv(event.id);
        }
      } catch (error) {
        console.error('Erro ao enviar convites: ', error);
      }

      this.triggerfeedbackMessage.emit({
        message: this.updateMode ? 'Evento atualizado com sucesso' : 'Evento criado com sucesso',
        type: 'success'
      });

      this.loading = false;
      this.closeModal();
    } catch (error: any) {
      this.triggerfeedbackMessage.emit({
        message: error?.message || this.updateMode ? 'Erro ao atualizar evento' : 'Erro ao criar evento',
        type: 'error'
      });
      this.loading = false;
    }
  }


  private createEventImage(blob: Blob) {
    const currentTime = Date.now();
    const fileName = `event_${currentTime}.jpeg`;

    const blobFile = new File([blob], fileName, {
      type: 'image/jpeg',
      lastModified: currentTime,
    });

    return blobFile;
  }

  private async uploadEventImage(eventId: string) {
    if (!this.modalData.image) return
    if (!this.userProfile) {
      this.triggerfeedbackMessage.emit({
        message: 'Erro ao criar evento, o usuário não foi encontrado.',
        type: 'error'
      });

      return;
    }

    try {
      const eventFile = this.createEventImage(this.modalData.image as Blob);
      const uploadImageResponse = await firstValueFrom(this.eventsService.saveEventImage(
        eventId,
        `event_${eventId}`,
        eventFile,
      ));

      return uploadImageResponse;
    } catch (error: any) {
      this.triggerfeedbackMessage.emit({
        message: error?.message || 'Erro ao salvar imagem',
        type: 'error'
      });

      return;
    }
  }

  removeGuest(i: number) {
    this.guests?.splice(i, 1);
  }

  onSelectionChange(selectedItems: EventWithParticipantsQuantity[]) {
    this.checkMaxAttendees();
  }

  checkMaxAttendees() {
    let totalParticipants = 0;

    this.selectedCommunities.forEach(selectedId => {
      const community = this.communities.find(c => c.event.id === selectedId);
      if (community) {
        totalParticipants += community.participantsQuantity;
      }
    });

    this.exceedsMaxAttendees = totalParticipants > this.modalData.maxAttendees;
  }

  validateDate(type: string, event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    const year = value.slice(0, 4);
    if (year.length > 4) {
      value = year.slice(0, 4) + value.slice(4);
    }
    input.value = value;

    if (type === 'start') {
      this.modalData.startDate = value;
      this.minEndDate = this.modalData.startDate;
      this.modalData.endDate && this.modalData.endDate < this.modalData.startDate && (this.modalData.endDate = this.modalData.startDate);
    } else if (type === 'end') {
      this.modalData.endDate = value;
    }

    const currentDate = new Date();
    const todayDate = currentDate.toISOString().split('T')[0];
    const currentTime = `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}`;
    if (type === 'start') {
      if (value === todayDate) {
        this.minStartTime = currentTime;
      } else {
        this.minStartTime = '00:00';
      }
    }

    if (type === 'end') {
      if (value === this.modalData.startDate) {
        this.minEndTime = this.modalData.startTime || this.minStartTime;
      } else {
        this.minEndTime = '00:00';
      }
    }

    // Validação de data para mostrar erros
    if (type === 'start' && value < todayDate) {
      this.formErrors.startDate = { valid: false, message: 'A data de início não pode ser anterior a hoje.' };
    } else if (type === 'end' && value < this.modalData.startDate) {
      this.formErrors.endDate = { valid: false, message: 'A data de fim não pode ser anterior à data de início.' };
    } else {
      if (type === 'start') {
        this.formErrors.startDate = { valid: true, message: '' };
      } else if (type === 'end') {
        this.formErrors.endDate = { valid: true, message: '' };
      }
    }
  }

  validateTime(type: string, event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    if (value.length > 5) {
      value = value.slice(0, 5);
    }

    input.value = value;
    if (type === 'start') {
      this.modalData.startTime = value;
    } else if (type === 'end') {
      this.modalData.endTime = value;
    }

    const currentDate = new Date();
    const currentTime = `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}`;
    const today = currentDate.toISOString().split('T')[0];

    if (type === 'start') {
      if (this.modalData.startDate === today && value < currentTime) {
        this.formErrors.startTime = { valid: false, message: 'O horário de inicio não pode ser anterior ao horário atual' };
      } else {
        this.formErrors.startTime = { valid: true, message: '' };
      }
    }

    if (type === 'end') {
      const isSameDay = this.modalData.endDate === this.modalData.startDate;

      if (isSameDay && value <= this.modalData.startTime) {
        this.formErrors.endTime = { valid: false, message: 'O horário de fim deve ser posterior ao horário de inicio' };
      } else {
        this.formErrors.endTime = { valid: true, message: '' };
      }
    }
  }

  countTextChars(text: string): number {
    const textWithoutHtml = text.replace(/<[^>]*>/g, '');
    return textWithoutHtml.length;
  }


  validateUrl() {
    const urlPattern = /^(https?:\/\/)?((([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,})|localhost)(:\d+)?(\/.*)?$/;
    if (!this.modalData.site || !urlPattern.test(this.modalData.site)) {
      this.formErrors.site.valid = false;
      this.formErrors.site.message = 'Por favor, insira uma URL válida.';
    } else {
      this.formErrors.site.valid = true;
      this.formErrors.site.message = '';
    }
  }

  async joinEvent(eventId: string) {
    try {

      this.eventsService.joinEvent(eventId, this.userProfile!.idUser)
        .pipe(
          filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
          map((response: HttpResponse<any>) => response.body)
        ).subscribe({
          next: () => {

          },
          error: (err) => {

          }
        });
    } catch (error: any) {
      console.error("Erro inesperado: ", error);
    }
  }

  async validateZipCode(): Promise<void> {
    const zipCode = this.modalData.zipCode;
    const zipCodePattern = /^\d{8}$/;

    if (zipCodePattern.test(zipCode)) {
      this.formErrors.zipCode.valid = true;
      this.formErrors.zipCode.message = '';

      const sanitizedZipCode = zipCode.replace('-', '');

      try {
        const response = await firstValueFrom(this.eventsService.getAddressByCEP(sanitizedZipCode));

        if (response.body) {
          if (response.body.erro) {
            // Caso o CEP seja inválido, exiba a mensagem de erro
            this.formErrors.zipCode.valid = false;
            this.formErrors.zipCode.message = 'CEP inválido. Por favor, tente novamente.';

            // Limpar ou resetar os campos de endereço
            this.modalData.address = '';
            this.modalData.neighborhood = '';
            this.modalData.city = '';
            this.modalData.state = '';
            this.modalData.country = '';

            this.triggerfeedbackMessage.emit({
              message: 'CEP inválido. Por favor, tente novamente.',
              type: 'error',
            });

          } else {
            // Se o CEP for válido, preenche os campos de endereço
            this.modalData.address = response.body.logradouro.length ? response.body.logradouro : this.modalData.address;
            this.modalData.neighborhood = response.body.bairro.length ? response.body.bairro : this.modalData.neighborhood;
            this.modalData.city = response.body.localidade.length ? response.body.localidade : this.modalData.city;
            this.modalData.state = response.body.uf.length ? response.body.uf : this.modalData.state;
            this.modalData.country = 'Brasil';
          }
        }

      } catch (error) {
        console.error('Erro ao buscar endereço:', error);
        this.formErrors.zipCode.valid = false;
        this.formErrors.zipCode.message = 'Não foi possível buscar o endereço.';
        this.triggerfeedbackMessage.emit({
          message: 'Erro ao buscar endereço. Por favor, tente novamente.',
          type: 'error',
        });
      }
    } else {
      this.formErrors.zipCode.valid = false;
      this.formErrors.zipCode.message = 'Formato de CEP inválido.';
    }
  }

  isCepValid(): boolean {
    const zipCode = this.modalData.zipCode;
    const zipCodePattern = /^\d{8}$/;

    // Chama a função de validação do CEP, que já está tratando a lógica completa
    // this.validateZipCode();

    return zipCodePattern.test(zipCode);
  }


  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  }

  triggerFileInput() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (event: any) => {
      const file = event.target.files[0];
      if (file && file.name.endsWith('.csv')) {
        this.processCsvFile(file);
      } else {

        this.triggerfeedbackMessage.emit({
          message: 'Por favor, selecione um arquivo CSV válido.',
          type: 'error',
        });
      }
    };
    input.click();
  }

  processCsvFile(file: File) {
    this.csvFile = file;
  }

  removeFile() {
    this.csvFile = null;
  }

  async sendInviteByCsv(eventId: string) {
    if (!this.csvFile) {

      this.triggerfeedbackMessage.emit({
        message: 'Por favor, selecione um arquivo CSV antes de enviar.',
        type: 'error',
      });
      return;
    }

    try {
      await firstValueFrom(this.eventsService.inviteToEventByCSV(eventId, this.csvFile));
      this.csvFile = null;

    } catch (error: any) {
      console.error('Erro ao enviar convites: ', error);
      const errorMessage = 'Ocorreu um erro inesperado ao tentar enviar os convites. Tente novamente mais tarde';

      this.triggerfeedbackMessage.emit({
        message: errorMessage,
        type: 'error',
      });
    }
  }

}
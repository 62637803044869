import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeText',
  pure: true, // Pipe puro para melhor desempenho
})
@Injectable({ providedIn: 'root' })
export class SanitizeTextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value: string): string {
    if (!value) return '';
    const tempElement = document.createElement('div');
    tempElement.innerHTML = value;
    return tempElement.textContent?.trim() || '';
  }
}

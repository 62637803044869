<app-loading-spinner [customBg]="'transparent'" *ngIf="loading"></app-loading-spinner>
<!-- <div class="alert-div">
    <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
        {{ alertMessage }}
    </ngb-alert>
</div> -->

<div class="modal-community-info-screen">
    <h4 class="modal-community-info-title">Editar Regras da Comunidade</h4>

    <st-editor
        [style.border]="rules.invalid && rules.touched || countTextChars(rulesModel) > 1500 ? '1px solid red' : ''"
        [style.border-radius]="'6px'" [(ngModel)]="rulesModel" (ngModelChange)="onEditorChange($event)"
        [config]="config"></st-editor>
    <div class="count-items">
        <span [ngClass]="countTextChars(rulesModel) > 1500 ? 'error-message' : ''"> {{
            countTextChars(rulesModel) }} / 1500 </span>
    </div>
    <span class="error-message" *ngIf="rules.invalid && rules.touched">
        {{countTextChars(rulesModel) > 1500 ? 'A regra deve ter no máximo 1500 caracteres' : 'A regra é obrigatória.'}}
    </span>

    <!-- <div class="community-attachment">
        <i class="bi bi-images"></i>
        <i class="bi bi-camera-video"></i>
        <i class="bi bi-paperclip"></i>
    </div> -->

    <div class="modal-community-info-buttons">
        <button type="button" class="cancel-button" (click)="closeModal(false)">Cancelar</button>
        <button type="button" class="save-button" (click)="handleSaveEvent()">Publicar</button>
    </div>
</div>
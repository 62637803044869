import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getHours } from 'date-fns';
import { ngxCsv } from 'ngx-csv';
import { filter, firstValueFrom, map } from 'rxjs';
import { CheckPreferencesModalComponent } from 'src/app/contacts-and-connections/check-preferences/check-preferences.component';
import { UserPresentationModalComponent } from 'src/app/contacts-and-connections/user-presentation/user-presentation.component';
import parseDateToString from 'src/app/helpers/parseDateToString';
import { Connection } from 'src/app/model/connection.model';
import { IDashboardEvent } from 'src/app/model/dashboard.model';
import { DocumentUserProfile, IDocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { DocumentUser } from 'src/app/model/document-user.model';
import { EventParticipant, EventReview, SluperEvent } from 'src/app/model/event.model';
import { IDocumentPreferences } from 'src/app/model/preferences.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';
import { IUserProfileWithCSS } from 'src/app/model/user-profile-css.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { OpenEventService } from 'src/app/open-event.service';
import { UserProfileService } from 'src/app/user-profile/user-profile.service';
import { CreateCommunityModalComponent } from '../create-community-modal/create-community-modal.component';
import { CreateEventModalComponent } from '../create-event-modal/create-event-modal.component';
import { DeleteEventModalComponent } from '../delete-event-modal/delete-event-modal.component';
import { EventNpsComponent } from '../event-nps/event-nps.component';
import { EventsService } from '../events.service';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css'],
})
export class EventDetailsComponent implements OnInit, OnDestroy {
  @Input() userProfile?: DocumentUserProfile | undefined;
  @Input() userProfilePlan?: IUserProfilePlan | undefined;
  @Input() documentUser?: DocumentUser | undefined;
  @Input() currentUserConnections?: Connection[] = [];
  @Input() eventId?: string;
  @Input() authorizedPage? = false;
  @Output() backToList = new EventEmitter<boolean>();
  @Input() preferencesList?: IDocumentPreferences[];
  @Input() occupationsList?: IDocumentPreferences[];
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  userProfileComplete: IUserProfileWithCSS | null = null;
  event!: SluperEvent;
  disableReviewButton = false;
  guests!: string[];
  organizerId!: string;
  participants: EventParticipant[] = [];
  eventReviews: EventReview[] = [];
  loading = true;
  joiningLoading = false;
  keepParticipantsUpdatedInterval: NodeJS.Timeout | null = null;

  missingParticipants = 0;

  showTooltipProfile = false;
  showTooltipBackground = false;
  @ViewChild('tooltip') tooltipElement!: ElementRef;

  screenWidth: any;
  screenHeight: any;
  desktopLimitWidth = 840;
  desktopLimitHeight = 550;

  defaultImageProfile = '/assets/img/imagem_perfil.png';
  defaultBackgroundImage = '/assets/img/imagem_fundo.png';
  private userCanCreate = false //Verificar se é premium
  selectedPreferences: number[] = [];
  selectedOccupations: number[] = [];
  participantsHistory: EventParticipant[] = [];
  participantName = '';

  csvParticipantsOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    useoBm: true,
    noDownload: false,
    headers: ["Name", "Email", "Telefone", "Redes"]
  };

  dashboardEvent: IDashboardEvent | null = null;
  registeredCount = 0;
  participantsCount = 0;
  averageRating = 0;
  public seeMoreCommunityMembers: boolean = false;
  public sanitizedContentDescription: SafeHtml = ''
  public sanitizedContentRules: SafeHtml = ''


  // authorizedPage: true,
  // userProfile: this.userProfile,
  // currentUserConnections: this.userConnections,
  // preferencesList: this.preferencesList,
  // occupationsList: this.occupationsList,
  // userProfilePlan: this.userProfilePlan,
  // eventId: this.openedEvent!.id,
  // documentUser: this.documentUser
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    authorizedPage: boolean,
    userProfile: IDocumentUserProfile,
    currentUserConnections: Connection[],
    preferencesList: IDocumentPreferences[],
    occupationsList: IDocumentPreferences[],
    userProfilePlan: IUserProfilePlan,
    eventId: string | undefined,
    documentUser: DocumentUser,
    userProfileComplete: IUserProfileWithCSS | null
  },
    private eventsService: EventsService,
    private modalService: NgbModal,
    private userProfileService: UserProfileService,
    private matDialog: MatDialogRef<EventDetailsComponent>,
    private sanitizer: DomSanitizer,
    private openEventService: OpenEventService,
    private toastrService: ToastService


  ) {
    this.getScreenSize();
  }

  ngOnDestroy(): void {
    if (this.keepParticipantsUpdatedInterval) {
      clearInterval(this.keepParticipantsUpdatedInterval);
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.data && this.data.eventId) {
      this.authorizedPage = this.data.authorizedPage;
      this.userProfile = this.data.userProfile;
      this.currentUserConnections = this.data.currentUserConnections;
      this.preferencesList = this.data.preferencesList;
      this.occupationsList = this.data.occupationsList;
      this.userProfilePlan = this.data.userProfilePlan;
      this.eventId = this.data.eventId;
      this.documentUser = this.data.documentUser;
      this.userProfileComplete = this.data.userProfileComplete;
      this.userCanCreate = this.userProfilePlan?.planName === "Plano Corporativo"
    }



    if (!this.authorizedPage) {
      const userData = await firstValueFrom(this.eventsService.userById())

      if (!userData?.body) {
        this.triggerUnauthorizedAlert(false);
      } else {
        this.userProfile = userData.body.documentUserProfile;
        this.documentUser = userData.body.documentUser;

        const { body: connections } = await firstValueFrom(this.eventsService.getUserConnections(this.userProfile.idUser))

        this.currentUserConnections = connections ? connections.filter(connection => !connection.indication) : [];

        this.getUserProfilePlan();
      }
      this.openEventService.removeId();
    }

    if (this.authorizedPage && this.eventId) {
      this.eventsService.getEventDashboad(this.eventId).subscribe(({ body }) => {
        if (body) {
          this.dashboardEvent = body;
        }
      });
    }

    await this.fetchEventDetails();
    this.keepParticipantsUpdated();
    // this.buscaParticipantes();

    return;
  }


  verifyUserCanCreate(): boolean {
    return this.userCanCreate
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  toggleTooltipProfile(event: Event) {
    event.stopPropagation();
    this.showTooltipProfile = !this.showTooltipProfile;
  }

  keepParticipantsUpdated() {
    this.buscaParticipantes();
    this.getEventReviews();
    const interval = setInterval(async () => {
      await this.buscaParticipantes();
      await this.getEventReviews();
    }, 5000);

    this.keepParticipantsUpdatedInterval = interval;
  }

  private async buscaParticipantes() {
    const response = await firstValueFrom(this.eventsService.getEventParticipants(this.event.id));

    this.participants = response.body || this.participants;
    const approvedParticipants = this.participants.filter(p => p.participationStatus == 'PARTICIPANDO');

    const participantes = this.participants.filter(p => p.idUser === this.userProfile?.idUser)
    if (participantes && participantes.length === 1 && participantes[0].participationStatus === 'PENDENTE_APROVACAO' && !this.event.isPublic) {
      this.participants = this.participants.filter(p => p.idUser === this.userProfile?.idUser);
    } else if (this.organizerId != this.userProfile?.idUser) {
      this.participants = this.participants.filter(p => p.participationStatus == 'PARTICIPANDO' || p.idUser === this.userProfile?.idUser);
    }

    this.missingParticipants = this.event.numberOfParticipants - approvedParticipants.length;
    this.registeredCount = approvedParticipants.length;
    this.participantsCount = approvedParticipants.filter(p => p.checkIn).length;

    this.participantsHistory = Object.assign([], this.participants);
    this.onSelectChange();
  }

  private async getEventReviews() {
    const response = await firstValueFrom(this.eventsService.getEventReviews(this.event.id));
    this.eventReviews = response.body || this.eventReviews;
    if (response && response.body && response.body.length > 0) {
      this.disableReviewButton = this.eventReviews.some(review => review.id === this.userProfile?.idUser);

      this.averageRating = response.body.reduce((acc, review) => acc + review.review, 0) / response.body.length;
    }
    return response.body || [];
  }

  async fetchEventDetails() {
    try {
      this.loading = true;

      const eventDetails = await firstValueFrom(this.eventsService.eventById(this.eventId!));

      if (!eventDetails.body?.event) {
        this.toastrService.show('Evento não encontrado.', 'error');
        this.loading = false;

        return;
      }

      this.event = eventDetails.body.event;
      this.sanitizedContentRules = this.sanitizer.bypassSecurityTrustHtml(this.event.about ? this.event.about : 'Não há políticas para este evento.');
      this.sanitizedContentDescription = this.sanitizer.bypassSecurityTrustHtml(this.event.description ?
        this.event.description : 'Não há descrição para este evento.');

      this.guests = eventDetails.body.event.guests;
      this.organizerId = this.event.organizer.idUser;
      this.missingParticipants = this.event.numberOfParticipants - eventDetails.body.participants.length;
      this.participants = eventDetails.body.participants;
      this.loading = false;
    } catch (error: any) {
      this.toastrService.show('Ocorreu um erro ao tentar carregar os detalhes do evento. Tente novamente mais tarde.', 'error');
      this.loading = false;
    }
  }

  handleBackToList() {
    this.backToList.emit(true);
    this.matDialog.close()
  }

  shareEvent() {
    const shareData = {
      title: this.event.name,
      text: `Venha participar do ${this.event.name} em ${new Date(this.event.startDate).toLocaleString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })} às ${getHours(new Date(this.event.startDate))}! Será em ${this.event.address}. Confira mais detalhes e confirme sua presença. Te esperamos lá!`,
      url: `https://perfil.sluper.bio/public-event/${this.event.id}`
    };

    if (!navigator.share) {
      return;
    }

    navigator.share(shareData);
  }

  saveEvent() {

  }

  addToGoogleCalendar() {
    const eventStartDateTime = parseDateToString(this.event.startDate);
    const eventEndDateTime = parseDateToString(this.event.endDate);

    window.open(`https://calendar.google.com/calendar/u/0/r/eventedit?location=${this.event.address}&sprop=name:${this.event.name}&sprop=website:${this.event.website}&text=${this.event.name}&dates=${eventStartDateTime}%2F${eventEndDateTime}`);
  }

  private triggerUnauthorizedAlert(shouldRedirect = true) {
    this.toastrService.show('Por favor, faça login para interagir com o evento.', 'error');
    if (shouldRedirect) {
      setTimeout(() => {
        // window.location.href = '/login';
      }, 3000);
    }
  }

  async joinEvent() {
    try {
      this.joiningLoading = true;

      if (!this.userProfile?.idUser) {
        this.triggerUnauthorizedAlert(true);

        setTimeout(() => {
          this.joiningLoading = false;
        }, 1000);

        return;
      }

      this.eventsService.joinEvent(this.event.id, this.userProfile.idUser)
        .pipe(
          filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
          map((response: HttpResponse<any>) => response.body)
        ).subscribe({
          next: () => {
            if (this.event.requiresConfirmation) {
              this.toastrService.show('Sua presença foi confirmada com sucesso!', 'success');
            } else {
              this.toastrService.show('Sua presença foi registrada com sucesso!', 'success');
            }
            this.joiningLoading = false;

            this.buscaParticipantes();
          },
          error: (err) => {
            console.error("Erro: ", err);
            this.toastrService.show('Ocorreu um erro ao tentar participar do evento. Contate o organizador do evento.', 'error');
            this.joiningLoading = false;
          }
        });
    } catch (error: any) {
      this.toastrService.show('Ocorreu um erro ao tentar participar do evento. Tente novamente mais tarde.', 'error');
      this.joiningLoading = false;
    }
  }

  async handleConnect(userId: string, event: Event) {
    event.stopPropagation();
    if (!this.userProfile?.idUser) {
      this.triggerUnauthorizedAlert(true);

      return;
    }

    try {
      await firstValueFrom(this.eventsService.connectUser({
        participantId: userId,
        userId: this.userProfile.idUser,
        eventId: this.event.id
      }));

      this.toastrService.show('Conexão realizada com sucesso!', 'success');

      this.currentUserConnections!.push({
        connectionDate: new Date().toISOString(),
        event: {
          id: this.event.id,
          name: this.event.name
        },
        connection: this.participants.find((participant) => participant.idUser === userId) as IDocumentUserProfile,
      });
    } catch (error: any) {
      this.toastrService.show('Ocorreu um erro ao tentar conectar com este usuário. Tente novamente mais tarde.', 'error');
    }
  }

  isParticipantConnected(participantId: string) {
    return this.currentUserConnections!.some((connection) => connection.connection.idUser === participantId);
  }

  isUserJoined() {
    const now = new Date();
    const isEventPassed = now > new Date(this.event.endDate);

    if (!this.userProfile || !this.userProfile.idUser || isEventPassed) {
      return true;
    }

    return this.participantsHistory.some((participant) => participant.idUser === this.userProfile?.idUser || '');
  }

  isUserPendentJoined() {
    if (!this.userProfile || !this.userProfile.idUser) {
      return false;
    }
    return this.participantsHistory.some((participant) => participant.idUser === this.userProfile?.idUser && participant.participationStatus === 'PENDENTE_APROVACAO');
  }

  async approveParticipant(idParticipant: string, participationApproved: boolean, event: Event) {
    event.stopPropagation();
    await firstValueFrom(this.eventsService.approveParticipant({
      idEvent: this.eventId!,
      idParticipant: idParticipant,
      participationApproved: participationApproved
    }));

    const participant = this.participants.find(p => p.idUser == idParticipant);
    if (participant != null)
      participant.participationStatus = 'PARTICIPANDO'
  }

  openDeleteEventModal() {
    const modalRef = this.modalService.open(DeleteEventModalComponent, { centered: true });

    modalRef.componentInstance.event = this.event;
    modalRef.componentInstance.triggerfeedbackMessage.subscribe((alert: { message: string; type: 'success' | 'error' }) => {
      this.toastrService.show(alert.message, alert.type);
      if (alert.type === 'success') {
        this.matDialog.close(true);
      }
    });
  }

  openCreateEventModal() {
    let modalRef;
    if (this.event.type === 'COMUNIDADE') {
      modalRef = this.modalService.open(CreateCommunityModalComponent, { centered: true, size: "xl" });
    } else {
      modalRef = this.modalService.open(CreateEventModalComponent, { centered: true, size: "xl" });
    }


    modalRef.componentInstance.userProfile = this.userProfile;
    modalRef.componentInstance.event = this.event;
    modalRef.componentInstance.guests = this.guests;
    // modalRef.componentInstance.triggerfeedbackMessage.subscribe((alert: { message: string; type: 'success' | 'error' }) => {
    //   this._alert.next(alert.message);
    //   this.alertType = alert.type;

    //   if (alert.type === 'success') {
    //     this.fetchEventDetails();
    //   }
    // });

    modalRef.result.then(() => {
      this.fetchEventDetails();
    });
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (this.tooltipElement && !this.tooltipElement.nativeElement.contains(event.target)) {
      this.showTooltipProfile = false;
      this.showTooltipBackground = false;
    }
  }

  getUserProfilePlan(): void {
    this.userProfileService
      .getUserPlan()
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((response: HttpResponse<any>) => response.body),
      )
      .subscribe({
        next: (res) => {
          this.userProfilePlan = res;
        },
        error: (res) => console.error(res),
      });
  }

  getBackground(participant: any) {
    return participant.uriImageBackground ? participant.uriImageBackground : this.defaultBackgroundImage
  }

  async openParticipantProfile(participant: EventParticipant) {
    window.open(`https://sluper.bio/${participant.idUser}`);
  }

  onSelectChange() {
    if (this.selectedPreferences.length > 0 || this.participantName != '' || this.selectedOccupations.length > 0) {
      this.filterParticipants();
    } else {
      this.participants = this.participantsHistory;
    }
  }

  filterParticipants() {
    if (this.selectedPreferences.length > 0 && this.participantName != '' && this.selectedOccupations.length > 0) {
      // filtra por interesse && nome && area de atuacao
      this.participants = this.participantsHistory.filter(p =>
        p.preferences && p.name &&
        ((this.selectedPreferences.length > 0 && this.checkUserPreferences(p, 'INTERESSE', this.selectedPreferences)) &&
          (this.selectedOccupations.length > 0 && this.checkUserPreferences(p, 'AREA_ATUACAO', this.selectedOccupations)) &&
          (this.participantName != '' && p.name.toLowerCase().includes(this.participantName.toLowerCase()))));
    } else if (this.selectedPreferences.length > 0 && this.participantName != '' && this.selectedOccupations.length == 0) {
      // filtra por interesse && nome
      this.participants = this.participantsHistory.filter(p =>
        p.preferences && p.name &&
        ((this.selectedPreferences.length > 0 && this.checkUserPreferences(p, 'INTERESSE', this.selectedPreferences)) &&
          (this.participantName != '' && p.name.toLowerCase().includes(this.participantName.toLowerCase()))));
    } else if (this.selectedPreferences.length == 0 && this.participantName != '' && this.selectedOccupations.length > 0) {
      // filtra por nome && area de atuacao
      this.participants = this.participantsHistory.filter(p =>
        p.preferences && p.name &&
        ((this.selectedOccupations.length > 0 && this.checkUserPreferences(p, 'AREA_ATUACAO', this.selectedOccupations)) &&
          (this.participantName != '' && p.name.toLowerCase().includes(this.participantName.toLowerCase()))));
    } else if (this.selectedPreferences.length > 0 && this.participantName == '' && this.selectedOccupations.length > 0) {
      // filtra por interesse && area de atuacao
      this.participants = this.participantsHistory.filter(p =>
        p.preferences && p.name &&
        ((this.selectedPreferences.length > 0 && this.checkUserPreferences(p, 'INTERESSE', this.selectedPreferences)) &&
          (this.selectedOccupations.length > 0 && this.checkUserPreferences(p, 'AREA_ATUACAO', this.selectedOccupations))));
    } else {
      // filtra por qualquer que for preenchido
      this.participants = this.participantsHistory.filter(p =>
        p.preferences && p.name &&
        ((this.selectedPreferences.length > 0 && this.checkUserPreferences(p, 'INTERESSE', this.selectedPreferences)) ||
          (this.selectedOccupations.length > 0 && this.checkUserPreferences(p, 'AREA_ATUACAO', this.selectedOccupations)) ||
          (this.participantName != '' && p.name.toLowerCase().includes(this.participantName.toLowerCase()))));
    }
  }

  checkUserPreferences(participant: EventParticipant, type: string, selectedList: number[]): boolean {
    let result = false;
    const filtredList = participant.preferences?.filter(pref => pref.type == type);
    if (filtredList) {
      filtredList.forEach(p => {
        if (selectedList.includes(p.id)) {
          result = true;
        }
      });
    }

    return result;
  }

  checkParticipantInterests(event: Event, participant: EventParticipant) {
    event.stopPropagation();
    const modalRef = this.modalService.open(CheckPreferencesModalComponent, {
      fullscreen: false,
      centered: true,
    });

    modalRef.componentInstance.connectionName = participant.name;
    modalRef.componentInstance.preferences = participant.preferences;
  }

  checkParticipantPresentation(event: Event, participant: EventParticipant) {
    event.stopPropagation();
    const modalRef = this.modalService.open(UserPresentationModalComponent, { fullscreen: false, centered: true });

    modalRef.componentInstance.userPresentation = participant.presentation;
  }

  getAddress(): string {
    if (this.event.type === "REMOTO") {
      return this.event.website;
    }
    let address = this.event.address || '';
    if (this.event.city) {
      address = address.concat(', ').concat(this.event.city);
    }

    if (this.event.state) {
      address = address.concat(' ').concat(this.event.state);
    }

    if (this.event.neighborhood) {
      address = address.concat(' ').concat(this.event.neighborhood);
    }

    if (this.event.zipCode) {
      address = address.concat(' ').concat(this.event.zipCode);
    }

    if (this.event.country) {
      address = address.concat(' ').concat(this.event.country);
    }

    return address && address.length > 0 ? address : 'Não informado';
  }

  getWebsiteUrl(website: string): string {
    if (!website.startsWith('http://') && !website.startsWith('https://')) {
      return `https://${website}`;
    }
    return website;
  }


  downloadParticiapantsCSV() {
    if (this.participants) {
      const allContactTypes = new Set<string>();
      const allSocialTypes = new Set<string>();

      this.participants.forEach(participant => {
        const contactTypesCount: { [key: string]: number } = {};
        const socialTypesCount: { [key: string]: number } = {};

        participant.listContacts?.forEach(contact => {
          if (contact.type in contactTypesCount) {
            contactTypesCount[contact.type]++;
          } else {
            contactTypesCount[contact.type] = 1;
          }
        });

        participant.listContacts?.forEach(contact => {
          if (contactTypesCount[contact.type] > 1) {
            for (let i = 0; i <= contactTypesCount[contact.type]; i++) {
              if (i === 0) {
                allContactTypes.add(contact.type);
              } else {
                allContactTypes.add(contact.type + contactTypesCount[contact.type]);
              }
            }
          } else {
            allContactTypes.add(contact.type);
          }
        });

        // Contagem de tipos de contatos
        participant.listSocialNetwork?.forEach(social => {
          if (social.type in socialTypesCount) {
            socialTypesCount[social.type]++;
          } else {
            socialTypesCount[social.type] = 1;
          }
        });

        // Adicionando os tipos de contatos ao conjunto
        participant.listSocialNetwork?.forEach(social => {
          if (socialTypesCount[social.type] > 1) {
            for (let i = 0; i <= socialTypesCount[social.type]; i++) {
              if (i === 0) {
                allSocialTypes.add(social.type);
              } else {
                allSocialTypes.add(social.type + socialTypesCount[social.type]);
              }
            }
          } else {
            allSocialTypes.add(social.type);
          }
        });

      });

      // Converter os conjuntos para arrays e combinar os cabeçalhos
      const contactTypesArray = Array.from(allContactTypes).sort();
      const socialTypesArray = Array.from(allSocialTypes).sort();
      const dynamicHeaders = ['nome', ...contactTypesArray, ...socialTypesArray];

      const listParticipants = this.participants.map(participant => {
        const { listContacts, listSocialNetwork } = participant;

        // Criar um objeto para armazenar os dados de cada conexão
        const participantData: { [key: string]: string } = {
          nome: participant.name
        };

        contactTypesArray.forEach(type => {
          const contacts = listContacts?.filter(contact => contact.type === type);
          let typeFilled = false; // Variável para controlar se o tipo sem sufixo já foi preenchido
          contacts?.forEach((c, i) => {
            if (i > 0) {
              const typeMod = type.concat(String(i + 1));
              participantData[typeMod] = c ? c.value : '';
              typeFilled = true;
            } else {
              participantData[type] = c ? c.value : '';
              typeFilled = true; // Define que o tipo sem sufixo foi preenchido
            }
          });
          // Caso o tipo sem sufixo não tenha sido preenchido dentro do loop, defina como vazio
          if (!typeFilled && !participantData[type]) {
            participantData[type] = '';
          }
        });

        // Preencher os dados de contatos
        socialTypesArray.forEach(type => {
          const socials = listSocialNetwork?.filter(social => social.type === type);
          let typeFilled = false; // Variável para controlar se o tipo sem sufixo já foi preenchido
          socials?.forEach((s, i) => {
            if (i > 0) {
              const typeMod = type.concat(String(i + 1));
              participantData[typeMod] = s ? s.link : '';
              typeFilled = true;
            } else {
              participantData[type] = s ? s.link : '';
              typeFilled = true; // Define que o tipo sem sufixo foi preenchido
            }
          });
          // Caso o tipo sem sufixo não tenha sido preenchido dentro do loop, defina como vazio
          if (!typeFilled && !participantData[type]) {
            participantData[type] = '';
          }
        });

        return participantData;
      });

      // Configurar os cabeçalhos dinâmicos no options
      const csvOptions = {
        ...this.csvParticipantsOptions,
        headers: dynamicHeaders
      };

      new ngxCsv(listParticipants, 'Participantes', csvOptions);
    }
  }

  checkinParticipant(participant: EventParticipant) {
    participant.checkIn = !participant.checkIn

    this.eventsService
      .checkInParticipant(participant.idUser, this.eventId!, participant.checkIn)
      .pipe(
        filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
        map((response: HttpResponse<any>) => response.body),
      )
      .subscribe({
        next: () => {
          this.buscaParticipantes();
        },
        error: (res) => console.error(res),
      });
  }

  checkEventEnded(): boolean {
    return new Date(this.event.endDate) < new Date();
  }

  checkUserhasCheckin(): boolean {
    return this.participants.some(p => p.idUser === this.userProfile?.idUser && p.checkIn);
  }

  rateEvent(event: SluperEvent) {
    const modalRef = this.modalService.open(EventNpsComponent, {
      fullscreen: false,
      centered: true
    });

    modalRef.componentInstance.idUser = this.userProfile?.idUser;
    modalRef.componentInstance.event = event;

    modalRef.result.then((result) => {
      if (result) {
        this.disableReviewButton = true;
        this.toastrService.show('Avaliação realizada com sucesso!', 'success');
      }
    });
  }

  removeBackslashes(htmlString: string) {
    return htmlString.replace(/\\/g, '');
  }

  openEditEvent() {
    this.matDialog.close(this.event)
  }
}

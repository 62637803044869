<div id="announcement-card">


  <div id="announcement-image">
    <img src="assets/images/background-logo.png" alt="Foto empresa">

    <div class="featured">
      <h4>Nome da Empresa</h4>
      <div class="featured-stats">
        <div class="d-flex featured-stats-row">
          <i class="bi bi-star"></i>
          <div class="d-flex flex-column featured-stats-column">
            <span>Aprovações:</span>
            <h3>30</h3>
          </div>
        </div>
        <div class="d-flex featured-stats-row">
          <div class="price-svg"></div>
          <div class="d-flex flex-column featured-stats-column">
            <span>A partir de:</span>
            <h3> R$ 00,00</h3>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p class="personalized-scroll">
    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
    eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
    voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
    voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
    velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut
    enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
    consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur,
    vel illum qui dolorem eum fugiat quo voluptas nulla pariatur
  </p>

  <div class="contact-items">
    <span>Contato</span>
    <div class="contact-item">
      <img src="assets/img/whatsapp.png" alt="Whatsapp">
    </div>
  </div>

  <div class="contact-items">
    <span>Redes sociais</span>
    <div class="contact-item">
      <img src="assets/img/instgram.png" alt="Whatsapp">
      <img src="assets/img/linkedin.png" alt="Whatsapp">
      <img src="assets/img/youtube.png" class="mt-2" alt="Whatsapp">
      <img src="assets/img/facebook.png" alt="Whatsapp">
    </div>
  </div>
</div>

import { HttpResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime, filter, map } from 'rxjs';
import { IDocumentLicense } from '../model/document-license.model';
import { IDocumentUser } from '../model/document-user.model';
import { ILicense } from '../model/license.model';
import { IResponse } from '../model/response.model';
import { ITotalLicenses } from '../model/total-licenses.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import { ConfirmaDesvinculoModalComponent } from './confirma-desvinculo-modal/confirma-desvinculo-modal.component';
import { DadosModalComponent } from './dados-modal/dados-modal.component';
import { GerarLicencasModalComponent } from './gerar-licencas-modal/gerar-licencas-modal.component';
import { LicencasService } from './licencas.service';
import { ManageLicensesOwnerModalComponent } from './manage-license-owner-modal/manage-license-owner-modal.component';
import { VinculaLicencaModalComponent } from './vincula-licenca-modal/vincula-licenca-modal.component';

interface ILicenca {
  licenca: string;
  email: string;
  dataVinculo: string;
  createDate: string;
  isCardGeneratedToggle: string;
  owner: string;
}

@Component({
  selector: 'app-licencas',
  templateUrl: './licencas.component.html',
  styleUrls: ['./licencas.component.css'],
})
export class LicencasComponent {

  @Input() userProfile!: IUserProfileWithCSS | null;

  private _alert = new Subject<string>();
  alertMessage: string = '';
  alertType: string = '';
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  nome: string = '';
  licensas: ILicense[] = [];
  historicoLicensas: ILicense[] = [];

  displayedColumns: string[] = ['createDate', 'licenca', 'nome', 'email', 'emailKey', 'ativo', 'isCardGeneratedToggle', 'dataVinculo', 'owner', 'acao'];
  dataSource: MatTableDataSource<ILicense> = new MatTableDataSource(this.licensas);
  paginatedData: ILicense[] = [];
  length = 10;
  pageSize = 10;
  pageIndex = 0;
  totalLicencas: number = 0;
  licencasDisponiveis: number = 0;
  linkedLicenses: number = 0;

  verificationKey!: string;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  filterActive: string = '';
  filterCardGenerated: string = '';
  filterLinked: string = '';
  filterActiveApplied: boolean = false;
  filterCardGeneratedApplied: boolean = false;
  filterLinkedApplied: boolean = false;

  loading = false;

  constructor(
    private licencasService: LicencasService,
    private modalService: NgbModal
  ) {
    this.getLicencas();
    this.getTotalLicencas();

    this._alert.subscribe((message) => (this.alertMessage = message));
    this._alert.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  getLicencas() {
    this.loading = true;
    this.licencasService.getLicencas(1, 3000).
      pipe(
        filter((mayBeOk: HttpResponse<ILicense[]>) => mayBeOk.ok),
        map((response: HttpResponse<ILicense[]>) => response.body))
      .subscribe({
        next: (res) => {
          this.setResponse(res);
          this.loading = false;
        },
        error: (res) => {
          console.error(res);
          this.loading = false;
        }
      });
  }

  setResponse(res: ILicense[] | null) {
    if (res) {
      this.dataSource = new MatTableDataSource(res);
      this.historicoLicensas = Object.assign([], res);
      let semVinculoString = 'sem vinculo | sem vínculo';
      this.dataSource.filterPredicate = function (license, filter) {
        let dateString = new Date(license.documentLicense.createDate).toLocaleDateString('pt-br') + " - " + new Date(license.documentLicense.createDate).toLocaleTimeString('pt-br')
        return (license.documentUser != null && license.documentUser != undefined && license.documentUser.email.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
          || (license.documentUser != null && license.documentUser != undefined && license.documentUser.personal && license.documentUser.personal.name?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
          || license.documentLicense?.tag?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
          || license.documentLicense.emailOwner != null && license.documentLicense.emailOwner != undefined && license.documentLicense.emailOwner.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
          || license.documentLicense.paymentIntentId != null && license.documentLicense.paymentIntentId != undefined && license.documentLicense.paymentIntentId.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
          || license.documentLicense.license != null && license.documentLicense.license != undefined && license.documentLicense.license.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
          || license.documentLicense.createDate != null && license.documentLicense.createDate != undefined && dateString.includes(filter.toLocaleLowerCase())
          || license.documentLicense.linkDate != null && license.documentLicense.linkDate != undefined && new Date(license.documentLicense.linkDate).toLocaleDateString('pt-br').includes(filter.toLocaleLowerCase())
          || license.documentLicense.linkDate == null && semVinculoString.includes(filter.toLocaleLowerCase());
      }
      this.dataSource.data = Object.assign([], res);
      this.atualizarPaginacao();
    }
  }

  getTotalLicencas() {
    this.licencasService.getTotalLicenses().
      pipe(
        filter((mayBeOk: HttpResponse<ITotalLicenses>) => mayBeOk.ok),
        map((response: HttpResponse<ITotalLicenses>) => response.body))
      .subscribe({
        next: (res) => this.setResponseTotalLicencas(res),
        error: (res) => console.error(res)
      });
  }

  setResponseTotalLicencas(res: ITotalLicenses | null) {
    if (res) {
      this.length = res.available + res.used + res.withOwner;

      // if (this.dataSource.paginator) {
      //   this.dataSource.paginator.length = this.length;
      // }

      this.totalLicencas = this.length;
      this.licencasDisponiveis = res.available;
      this.linkedLicenses = Number(this.totalLicencas) - Number(this.licencasDisponiveis);

      if (this.paginator) {
        this.paginator.length = this.length;
      }
    }
  }

  applyFilter() {
    const filterInput = document.getElementById('filtro') as HTMLInputElement;
    const filterValue = filterInput.value;

    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.atualizarPaginacao();
  }

  openGerarLicencasModal() {
    let modalRef = this.modalService.open(GerarLicencasModalComponent, { centered: true });

    modalRef.result.then(result => {
      if (result) {
        this.getLicencas();
        this.getTotalLicencas();
      }
    })
  }

  openReplaceLicensesOwnerModal(email: string, licenseId: string) {
    let modalRef = this.modalService.open(ManageLicensesOwnerModalComponent, { centered: true, size: 'lg', animation: true });

    modalRef.componentInstance.licenseId = licenseId;
    modalRef.componentInstance.ownerEmail = email;
    modalRef.componentInstance.onSuccess = () => { this.getLicencas() };
    modalRef.result.then(result => {
      if (result) {
        this.getLicencas();
        this.getTotalLicencas();
      }
    })
  }

  pageChange(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.atualizarPaginacao();
  }

  atualizarPaginacao() {
    const startIndex = this.pageIndex * this.pageSize;
    this.paginatedData = this.dataSource.filteredData.slice(startIndex, startIndex + this.pageSize);
  }

  unlinkLicense(idLicense: string, user: string) {
    const modalRef = this.modalService.open(ConfirmaDesvinculoModalComponent, { centered: true });
    modalRef.componentInstance.license = idLicense;
    modalRef.componentInstance.user = user;

    modalRef.result.then(result => {
      if (result) {
        this.getLicencas();
        this.getTotalLicencas();
      }
    })
  }

  openVinculaLicencaModal(license: string) {
    const modalRef = this.modalService.open(VinculaLicencaModalComponent, { centered: true });
    modalRef.componentInstance.license = license;

    modalRef.result.then(result => {
      if (result) {
        this.getLicencas();
        this.getTotalLicencas();
      } else {
        this.alertType = 'danger';
        this._alert.next("Ocorreu um erro");
      }
    });
  }

  openDadosModal({ documentUser, documentLicense }: { documentUser: IDocumentUser, documentLicense: IDocumentLicense }) {
    const modalRef = this.modalService.open(DadosModalComponent, { centered: true });

    modalRef.componentInstance.documentLicense = documentLicense;
    modalRef.componentInstance.documentUser = documentUser;
  }

  openLinkLicenca(licenca: string) {
    window.open("https://sluper.bio/" + licenca, "_blank");
  }

  changeActive(idUser: string) {
    this.licencasService.activateDeactivateUser(idUser).
      pipe(
        filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
        map((response: HttpResponse<IResponse>) => response.body))
      .subscribe({
        next: (res) => this.changeActiveResponse(res),
        error: (res) => console.error(res)
      });
  }

  toggleIsCardGenerated(licenseId: string) {
    this.licencasService.toggleCardGeneratedStatus(licenseId).
      pipe(
        filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
        map((response: HttpResponse<IResponse>) => response.body))
      .subscribe({
        next: (res) => this.changeActiveResponse(res),
        error: (res) => console.error(res)
      });
  }

  changeActiveResponse(res: IResponse | null) {
    if (res != null && res.status == "SUCCESS") {
      this.getLicencas();
    }
  }

  resendLicennse(idLicense: string) {
    this.licencasService.resendLicenseEmail(idLicense).subscribe((res) => {
    });
  }

  copyLicenseToClipboard(license: string): string {
    return "https://sluper.bio/" + license;
  }

  changeFilterActive() {
    if (this.filterActive != '') {
      if (this.filterActiveApplied) {
        this.dataSource.data = Object.assign([], this.historicoLicensas);
        if (this.filterCardGeneratedApplied) {
          let licenses = this.applyFilterCardGenerated();
          this.setDatasource(licenses);
        }
        if (this.filterLinkedApplied) {
          let licenses = this.applyFilterLinked();
          this.setDatasource(licenses);
        }
      }
      let licenses = this.applyFilterActive();
      this.setDatasource(licenses);
    } else {
      this.setDatasource(this.historicoLicensas);
      this.filterActiveApplied = false;
      if (this.filterCardGeneratedApplied)
        this.changeFilterCardGenerated();
    }
    this.atualizarPaginacao();
  }

  changeFilterCardGenerated() {
    if (this.filterCardGenerated != '') {
      if (this.filterCardGeneratedApplied) {
        this.dataSource.data = Object.assign([], this.historicoLicensas);
        if (this.filterActiveApplied) {
          let licenses = this.applyFilterActive();
          this.setDatasource(licenses);
        }
        if (this.filterLinkedApplied) {
          let licenses = this.applyFilterLinked();
          this.setDatasource(licenses);
        }
      }
      let licenses = this.applyFilterCardGenerated()
      this.setDatasource(licenses);
    } else {
      this.setDatasource(this.historicoLicensas);
      this.filterCardGeneratedApplied = false;
      if (this.filterActiveApplied)
        this.changeFilterActive();
    }
    this.atualizarPaginacao();
  }

  changeFilterLinked() {
    if (this.filterLinked != '') {
      if (this.filterLinkedApplied) {
        this.dataSource.data = Object.assign([], this.historicoLicensas);
        if (this.filterCardGeneratedApplied) {
          let licenses = this.applyFilterCardGenerated();
          this.setDatasource(licenses);
        }
        if (this.filterActiveApplied) {
          let licenses = this.applyFilterActive();
          this.setDatasource(licenses);
        }
      }
      let licenses = this.applyFilterLinked();
      this.setDatasource(licenses);
    } else {
      this.setDatasource(this.historicoLicensas);
      this.filterLinkedApplied = false;
      if (this.filterCardGeneratedApplied)
        this.changeFilterCardGenerated();
    }
    this.atualizarPaginacao();
  }

  setDatasource(licenses: ILicense[]) {
    this.dataSource = new MatTableDataSource(licenses);
    let semVinculoString = 'sem vinculo | sem vínculo';
    this.dataSource.filterPredicate = function (license, filter) {
      let dateString = new Date(license.documentLicense.createDate).toLocaleDateString('pt-br') + " - " + new Date(license.documentLicense.createDate).toLocaleTimeString('pt-br')
      return (license.documentUser != null && license.documentUser != undefined && license.documentUser.email.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
        || (license.documentUser != null && license.documentUser != undefined && license.documentUser.personal && license.documentUser.personal.name?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
        || license.documentLicense.emailOwner != null && license.documentLicense.emailOwner != undefined && license.documentLicense.emailOwner.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
        || license.documentLicense.paymentIntentId != null && license.documentLicense.paymentIntentId != undefined && license.documentLicense.paymentIntentId.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
        || license.documentLicense.license != null && license.documentLicense.license != undefined && license.documentLicense.license.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
        || license.documentLicense.createDate != null && license.documentLicense.createDate != undefined && dateString.includes(filter.toLocaleLowerCase())
        || license.documentLicense.linkDate != null && license.documentLicense.linkDate != undefined && new Date(license.documentLicense.linkDate).toLocaleDateString('pt-br').includes(filter.toLocaleLowerCase())
        || license.documentLicense.linkDate == null && semVinculoString.includes(filter.toLocaleLowerCase());
    }
    this.dataSource.data = Object.assign([], licenses);
  }

  applyFilterActive() {
    if (this.filterActive == 'ativo') {
      this.filterActiveApplied = true;
      return this.dataSource.data.filter(l => l.documentUser != null && l.documentUser.active);
    } else {
      this.filterActiveApplied = true;
      return this.dataSource.data.filter(l => l.documentUser != null && !l.documentUser.active);
    }
  }

  applyFilterLinked() {
    if (this.filterLinked == 'sim') {
      this.filterLinkedApplied = true;
      return this.dataSource.data.filter(l => l.documentUser != null && l.documentUser.email);
    } else {
      this.filterLinkedApplied = true;
      return this.dataSource.data.filter(l => !l.documentUser?.email);
    }
  }

  applyFilterCardGenerated() {
    if (this.filterCardGenerated == 'gerado') {
      this.filterCardGeneratedApplied = true;
      return this.dataSource.data.filter(l => l.documentLicense != null && l.documentLicense.isCardGenerated);
    } else {
      this.filterCardGeneratedApplied = true;
      return this.dataSource.data.filter(l => l.documentLicense != null && !l.documentLicense.isCardGenerated);
    }
  }

  generateVerificationKey() {
    this.licencasService.generateVerificationKey().
      pipe(
        filter((mayBeOk: HttpResponse<IResponse>) => mayBeOk.ok),
        map((response: HttpResponse<IResponse>) => response.body))
      .subscribe({
        next: (res) => this.setVerificationKey(res),
        error: (res) => console.error(res)
      });
  }

  setVerificationKey(res: IResponse | null) {
    if (res != null) {
      this.verificationKey = res.message;
    }
  }
}

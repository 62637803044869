import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-community-manager-finance',
  templateUrl: './community-manager-finance.component.html',
  styleUrls: ['./community-manager-finance.component.css']
})
export class CommunityManagerFinanceComponent implements OnInit, AfterViewInit {
  public dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public users = [
    {
      name: 'João Silva',
      category: 'Comum',
      status: 0,
      situation: 'Pago',
      price: 29.99,
      limitDate: '2025-02-01'
    },
    {
      name: 'Maria Oliveira',
      category: 'VIP',
      status: 1,
      situation: 'Débito',
      price: 99.99,
      limitDate: '2025-01-15'
    },
    {
      name: 'Carlos Santos',
      category: 'Comum',
      status: 2,
      situation: 'Pendente',
      price: 29.99,
      limitDate: '2025-01-25'
    },
    {
      name: 'Ana Costa',
      category: 'VIP',
      status: 0,
      situation: 'Pago',
      price: 99.99,
      limitDate: '2025-03-01'
    },
    {
      name: 'Pedro Almeida',
      category: 'Comum',
      status: 1,
      situation: 'Débito',
      price: 29.99,
      limitDate: '2025-01-10'
    },
    {
      name: 'Beatriz Souza',
      category: 'VIP',
      status: 2,
      situation: 'Pendente',
      price: 99.99,
      limitDate: '2025-01-20'
    }
  ];

  displayedColumns: string[] = ['name', 'category', 'status', 'situation', 'price', 'limitDate', 'actions'];

  constructor() {
    this.dataSource = new MatTableDataSource<any>();
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.users);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  enableUser(user: any) {
    user.status = 0;
    user.situation = 'Pago';
  }

  unableUser(user: any) {
    user.status = 1;
    user.situation = 'Débito';
  }

  sendPaymentEmail(user: any) {
    // Implementar lógica de envio de email
  }


  getSituationClass(situation: string): string {
    switch (situation) {
      case 'Pago':
        return 'situation-paid';
      case 'Débito':
        return 'situation-debt';
      case 'Pendente':
        return 'situation-pending';
      default:
        return '';
    }
  }

}

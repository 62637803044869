<div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="public-event-container">
  <app-loading-spinner *ngIf="loading"></app-loading-spinner>

  <!-- <app-event-details style="width: 100%" [authorizedPage]="false" *ngIf="!loading" [eventId]="eventId"></app-event-details> -->


  <app-communities *ngIf="showCommunity" [userCss]="userProfileComplete!.documentUserCSS!"
    [userProfile]="userProfileComplete!.documentUserProfile!" [userConnections]="userConnections" [showBack]="false"
    [showSidebar]="false" [documentUser]="userProfileComplete?.documentUser" [userProfilePlan]="userProfilePlan"
    [preferencesList]="preferencesList" [occupationsList]="occupationsList" [communityItem]="communityItem"
    [userProfileComplete]="userProfileComplete"></app-communities>

</div>
<div id="announcement-card" class="personalized-scroll">
  <div class="page-title">
    <div class="d-flex align-items-center">
      <i class="bi bi-chevron-left pointer"
         (click)="handleCloseScreen()" (keydown.enter)="handleCloseScreen()"></i>
      <h4 id="mention-title">Vitrine / entrar em contato</h4>
    </div>
  </div>

  <div id="announcement-image">
    <img src="assets/images/background-logo.png" alt="Foto empresa">

    <div class="featured">
      <h4>Nome da Empresa</h4>
      <div class="featured-stats">
        <div class="d-flex featured-stats-row">
          <i class="bi bi-star"></i>
          <div class="d-flex flex-column featured-stats-column">
            <span>Aprovações:</span>
            <h3>30</h3>
          </div>
        </div>
        <div class="d-flex featured-stats-row">
          <div class="price-svg"></div>
          <div class="d-flex flex-column featured-stats-column">
            <span>A partir de:</span>
            <h3> R$ 00,00</h3>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex- flex-column about-company">
    <h3>Sobre a empresa</h3>
    <p>
      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
      aperiam,
      eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
      voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
      voluptatem sequi nesciunt. Neque porro quisquam est.
    </p>
  </div>

  <div class="row">

    <div class="col-sm-12 col-md-12 col-lg-6">
      <div id="announcement-carousel">
        <div class="carousel-container">
          <button class="nav-button left" (click)="prevImage()">
            <i class="bi bi-chevron-left"></i>
          </button>
          <img [src]="images[selectedImage]" alt="Imagem Principal" class="main-image">
          <button class="nav-button right" (click)="nextImage()">
            <i class="bi bi-chevron-right"></i>
          </button>
        </div>

        <div class="thumbnail-container">
          <img *ngFor="let img of images; let i = index"
               [src]="img"
               [class.active]="i === selectedImage"
               (click)="selectImage(i)">
        </div>
      </div>

    </div>
    <div class="col-sm-12 col-md-12 col-lg-6">
      <div class="contact-form">
        <form (submit)="onSubmit()">
          <div class="form-group">
            <label for="name">Nome:</label>
            <input type="text" id="name" [(ngModel)]="formData.name" name="name" placeholder="Seu Nome" required>
          </div>

          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" id="email" [(ngModel)]="formData.email" name="email"
                   placeholder="ex: seuemail@email.com" required>
          </div>

          <div class="form-group">
            <label for="phone">Telefone:</label>
            <input type="tel" id="phone" [(ngModel)]="formData.phone" name="phone" placeholder="(00) 0000-0000"
                   required>
          </div>

          <div class="form-group">
            <label for="contact-purpose">Objetivo do Contato:</label>
            <select id="contact-purpose" [(ngModel)]="formData.purpose" name="purpose" required>
              <option value="" disabled selected>Escolha uma opção</option>
              <option value="orcamento">Solicitação de orçamento</option>
              <option value="parceria">Parceria comercial</option>
              <option value="suporte">Suporte Técnico</option>
              <option value="informacoes">Informações sobre produtos/serviços</option>
              <option value="outros">Outros</option>
            </select>
          </div>

          <div class="form-group">
            <label for="message">Mensagem:</label>
            <textarea id="message" [(ngModel)]="formData.message" name="message"
                      placeholder="Escreva sobre uma dúvida ou demanda que deseja orçar." required></textarea>
          </div>

          <button type="submit" class="submit-button">Enviar</button>
        </form>
      </div>

    </div>
  </div>

  <div class="contact-items">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <span>Outras formas de contato</span>
        <div class="contact-item">
          <img src="assets/img/whatsapp.png" alt="Whatsapp">
          <img src="assets/img/instgram.png" alt="Instagram">
          <img src="assets/img/linkedin.png" alt="LinkedIn">
          <img src="assets/img/youtube.png" class="mt-2" alt="YouTube">
          <img src="assets/img/facebook.png" alt="Facebook">
        </div>
      </div>
      <div class="rating">
        <span>Avaliar</span>
        <div class="stars">
          <i *ngFor="let star of [1,2,3,4,5]; let i = index"
             class="bi"
             [ngClass]="i < selectedRating ? 'bi-star-fill filled' : 'bi-star'"
             (click)="rate(i + 1)">
          </i>
        </div>
      </div>
    </div>
  </div>

</div>

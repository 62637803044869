import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { CategoryChannelService, CategoryInterface, CreateChannelData, ModalDataInterface } from 'src/app/categoryAndChannel/category-channel.service';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';

@Component({
  selector: 'app-create-channel-modal',
  templateUrl: './create-channel-modal.component.html',
  styleUrls: ['./create-channel-modal.component.css'],
})
export class CreateChannelModalComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  public modalType: string = 'create';
  public loading = false
  public isEmojiPickerVisible: boolean = false;
  // public channelAllParticipants: Connection[] = [];
  // public channelParticipants: Connection[] = [];
  // public newChannelParticipants: Connection[] = [];
  public channelForm!: FormGroup;
  public isFormSubmitted: boolean = false;
  public showMore: boolean = false;
  public maxVisibleParticipants: number = 0;
  public visibleParticipantsCount: number = 2;
  public categories: CategoryInterface[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalDataInterface,
    private dialogRef: MatDialogRef<CreateChannelModalComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private categoryChannelService: CategoryChannelService,
    private toastrService: ToastService

  ) {
    this.modalType = data.sidebarOption === 'openEditChannel' ? 'edit' : 'create';
  }

  async ngOnInit() {

    this.categories = this.data.allCategories;
    this.channelForm = this.fb.group({
      channelName: ['', Validators.required],
      emoji: ['', Validators.required],
      category: ['', Validators.required]
    });

    if (this.modalType === 'edit' && this.data.channelItem) {
      this.channelForm.patchValue({
        channelName: this.data.channelItem.name,
        emoji: this.data.channelItem.icon,
        category: this.data.channelItem.category
      });

      this.channelForm.get('category')?.disable();
      await this.listChannelParticipants(this.data.channelItem.id.toString());
    } else {
      this.channelForm.patchValue({
        channelName: '',
        emoji: '',
        category: this.data.categoryItem?.id || ''
      });
    }

    if (this.data.openedFrom === 'createCategory') {
      this.channelForm.get('category')?.disable();
    }
  }


  async listChannelParticipants(channelId: string) {
    try {
      // const data = await firstValueFrom(
      //   this.categoryChannelService.getChannelUsers(channelId)
      // );
      // const allUsers = data.body
      //   ? data.body.map((item: ChannelParticipantsInterface) => {
      //     return {
      //       connectionDate: new Date().toISOString(),
      //       event: {
      //         id: '0',
      //         name: '',
      //       },
      //       connection: {
      //         idUser: item.userId || '',
      //         name: item.userProfile?.name || 'Unknown',
      //         bio: '',
      //         showImageProfile: false,
      //         publish: false,
      //         parent: '',
      //         listURI: [],
      //         uriImageProfile: item.userProfile?.uriImageProfile || '',
      //         uriImageBackground: '',
      //         listSocialNetwork: [],
      //         listButtons: [],
      //         listUriImages: [],
      //         header: {} as IHeader,
      //         listContacts: [],
      //         networkingControl: {} as INetworkingControl,
      //         listProfile: [],
      //       },
      //       preferences: null,
      //       indication: null,
      //       indicatorName: null,
      //     };
      //   })
      //   : [];

      // this.channelAllParticipants = [...allUsers]
      // this.channelParticipants = [...allUsers];

    } catch (error: any) {
      this.toastrService.show(
        'Ocorreu um erro ao tentar carregar os participantes do canal. Por favor, tente novamente.',
        'error',
      )
    }
  }

  // toggleShowMore(event: Event) {
  //   event.preventDefault();

  //   if (this.visibleParticipantsCount === 2) {
  //     this.visibleParticipantsCount = this.channelParticipants.length;
  //   } else {
  //     this.visibleParticipantsCount = 2;
  //   }
  // }


  toggleEmojiPicker() {
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
  }

  addEmoji(event: any) {
    const emojiValue = event.native || event.emoji?.native || event.emoji || 'Emoji não encontrado';
    this.channelForm.controls['emoji'].setValue(emojiValue);
    this.channelForm.controls['emoji'].markAsTouched();
    this.isEmojiPickerVisible = false;
  }

  async saveChannel() {
    this.isFormSubmitted = true;
    this.channelForm.markAllAsTouched();

    if (this.channelForm.invalid) {
      console.error('O formulário está inválido.');
      return;
    }
    if (this.data.openedFrom === 'createCategory') {
      const channelData = {
        channelName: this.channelForm.value.channelName,
        emoji: this.channelForm.value.emoji,
        category: this.channelForm.value.category,
        participants: []
      }
      this.closeModal(true, 'Atualizado com sucesso!', channelData);
      return
    }

    try {

      this.loading = true


      const data: CreateChannelData = {
        name: this.channelForm.value.channelName,
        community: this.data.communityId,
        category: this.channelForm.value.category,
        icon: this.channelForm.value.emoji
      }

      const resp = this.modalType === 'edit' ? await firstValueFrom(
        this.categoryChannelService.editChannel({ name: data.name, icon: data.icon }, this.data.channelItem!.id)
      ) : await firstValueFrom(
        this.categoryChannelService.createChannel(data))

      // if (this.newChannelParticipants.length > 0) {
      //   await this.addUserChannel(resp.body!.id.toString());
      // }

      // await this.rmUserChannel(resp.body!.id.toString());

      this.toastrService.show('Canal criado com sucesso.', 'success')
      setTimeout(() => {
        this.closeModal(true, 'Atualizado com sucesso!');
        this.loading = false
      }, 2000);

    } catch (error) {
      this.loading = false
      this.toastrService.show(this.modalType === 'edit' ? 'Erro ao editar canal.' : 'Erro ao criar canal.', 'error')
    }
  }

  // async addUserChannel(channelId: string) {
  //   try {
  //     const participants = this.newChannelParticipants;

  //     const data: { userId: string; channelId: number }[] = participants.map(participant => ({
  //       userId: participant.connection.idUser,
  //       channelId: parseInt(channelId),
  //     }));

  //     const resp = await firstValueFrom(
  //       this.categoryChannelService.addUserChannel(data)
  //     );
  //   } catch (error) {
  //     console.error('Erro ao adicionar participantes ao canal:', error);
  //     throw error; // Repassa o erro para ser tratado em outro nível
  //   }
  // }

  // async rmUserChannel(channelId: string) {
  //   try {
  //     const participantsRemoved = this.channelAllParticipants.filter(
  //       (participant: Connection) =>
  //         !this.channelParticipants.some(
  //           (remaining: Connection) =>
  //             remaining.connection.idUser === participant.connection.idUser
  //         )
  //     );

  //     for (const element of participantsRemoved) {
  //       const data = {
  //         userId: element.connection.idUser,
  //         channelId: parseInt(channelId),
  //       };

  //       const resp = await firstValueFrom(
  //         this.categoryChannelService.rmUserChannel(data)
  //       );
  //     }
  //   } catch (error) {
  //     console.error('Erro ao remover participantes do canal:', error);
  //     throw error;
  //   }
  // }



  closeModal(result: boolean, message: string, channel?: any) {
    const data = {
      result: result,
      message: message,
      channel: channel
    };
    this.dialogRef.close(data);
  }

  // openModalAddMembers() {
  //   const dialogRef = this.dialog.open(CommunitieParticipantsModalComponent, {
  //     data: { data: this.data, channelParticipants: this.channelParticipants },
  //     width: '80vw',
  //   });

  //   dialogRef.afterClosed().subscribe(
  //     (result: { result: boolean, participants?: Connection[] }) => {
  //       if (result && result.result && result.participants) {
  //         this.channelParticipants = [...this.channelParticipants, ...result.participants!]
  //         this.newChannelParticipants = [...this.newChannelParticipants, ...result.participants!]
  //         this.visibleParticipantsCount = this.channelParticipants.length;

  //         if (this.visibleParticipantsCount === 2) {
  //           this.visibleParticipantsCount = this.channelParticipants.length;
  //         } else {
  //           this.visibleParticipantsCount = 2;
  //         }
  //       }
  //     },
  //     (error) => {
  //       console.error('Error ', error);
  //     }
  //   )
  // }

}

<app-loading-spinner [customBg]="'transparent'" *ngIf="loading"></app-loading-spinner>


<div class="community-configuration-container">
  <header class="community-configuration__header">
    <div class="d-flex align-items-center">
      <i class="bi bi-chevron-left community-configuration__icon" (click)="handleBack()" (keydown)="handleBack()"></i>
      <h2 class="community-configuration__h2">Configurações da Comunidade / Membros / {{editCategoryItem.name}}</h2>
    </div>
    <div class="community-configuration__btns">
      <button class="community-configuration__remove-btn" (click)="openRemoveCategory()">
        <span class="community-configuration__remove-span">Excluir</span>
      </button>
      <button class="community-configuration__save-btn" (click)="updateCategory()">
        <span class="community-configuration__save-span">Salvar</span>
      </button>
    </div>
  </header>
  <div class="community-configuration-input">
    <div class="input-item">
      <span class="community-configuration-category-name">Nome do perfil</span>
      <input type="text" placeholder="Digite aqui o nome do perfil" [formControl]="categoryName"
        class="category-name-input" />
      <span class="error-message" *ngIf="categoryName.hasError('required') && categoryName.touched">
        Por favor, insira um nome para o perfil.
      </span>
    </div>

    <div class="input-item">
      <span class="community-configuration-category-name">Quantidade de membros</span>
      <input type="number" placeholder="Quantidade de membros" [formControl]="membersQuantity"
        class="category-quantity-input" />
      <span class="error-message" *ngIf="membersQuantity.hasError('required') && membersQuantity.touched">
        Por favor, insira a quantidade de membros.
      </span>
    </div>
    <span class="error-message" *ngIf="membersQuantity.hasError('min') && membersQuantity.touched">
      A quantidade mínima de membros é 1.
    </span>
  </div>
  <div class="community-configuration-wrapper">
    <div class="community-configuration-content">
      <div class="community-configuration-content__header">
        <h2>Membros</h2>
        <div class="d-flex align-items-center">
          <input type="text" placeholder="Procurar membros" (input)="filterMembersByName($event)" />
          <button (click)="openAddMembersModal()">Adicionar Membros</button>
        </div>
      </div>
    </div>
    <div class="people-container">
      <div class="connection-request-card" *ngFor="let member of membersDetails">
        <img *ngIf="member.uriImageProfile && member.uriImageProfile !== ''" [src]="member.uriImageProfile"
          alt="Foto do usuário" />
        <img *ngIf="!member.uriImageProfile || !member.uriImageProfile.length" src="/assets/img/imagem_perfil.png"
          alt="Foto padrão" />
        <div class="request-info">
          <div class="info">
            <span class="request-name">{{ member.name }}</span>
            <span class="request-profession multi-line" [innerHtml]="member.presentation"></span>
          </div>
        </div>
        <i class="btn bi bi-dash-square-fill quantity-people-button" (keydown)="handleRemoveMember(member.idUser)"
          (click)="handleRemoveMember(member.idUser)"></i>
      </div>
    </div>
  </div>
</div>
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AcceptConnectionRequestComponent } from 'src/app/contacts-and-connections/accept-connection-request/accept-connection-request.component';
import { RejectConnectionRequestComponent } from 'src/app/contacts-and-connections/reject-connection-request/reject-connection-request.component';
import { IConnection } from 'src/app/model/connection.model';
import { IDocumentUserProfileWithPreferences } from 'src/app/model/document-user-profile.model';

@Component({
  selector: 'app-connection-request-card',
  templateUrl: './connection-request-card.component.html',
  styleUrls: ['./connection-request-card.component.css']
})
export class ConnectionRequestCardComponent {
  @Output() refreshData = new EventEmitter();
  @Input() connection: IConnection = {
    connectionDate: '',
    event: {
      id: '',
      name: ''
    },
    connection: {
      idUser: '',
      name: '',
      bio: '',
      showImageProfile: false,
      publish: false,
      parent: '',
      listURI: [],
      uriImageProfile: '',
      uriImageBackground: '',
      listSocialNetwork: [],
      listButtons: [],
      listUriImages: [],
      header: {
        text: '',
        visible: false
      },
      listContacts: [],
      networkingControl: {
        used: 0,
        max: 0
      },
      listProfile: []
    }
  };
  defaultImageProfile = '/assets/img/imagem_perfil.png';
  @Input() userProfile: IDocumentUserProfileWithPreferences | undefined;

  constructor(
    private modalService: NgbModal,
  ) { }
  rejectConnectionRequest(event: Event, connection: IConnection) {
    event.stopPropagation();
    const modalRef = this.modalService.open(RejectConnectionRequestComponent, { fullscreen: false, centered: true });

    modalRef.componentInstance.idUser = this.userProfile?.idUser
    modalRef.componentInstance.idConnection = this.connection.connection.idUser;
    modalRef.componentInstance.nameConnection = this.connection.connection.name;

    modalRef.result.then(async (result) => {
      if (result) {
        this.refreshData.emit();
        // await this.fetchUserConnections();
        // await this.fetchUserSentContactsRequests();
        // await this.fetchUserPendingContactsRequests();
        // this._alert.next('Conexão rejeitada com sucesso.');
        // this.alertType = 'success';
      }
    });
  }


  acceptConnectionRequest(event: Event, connection: IConnection) {
    event.stopPropagation();
    const modalRef = this.modalService.open(AcceptConnectionRequestComponent, { fullscreen: false, centered: true });

    modalRef.componentInstance.idUser = this.userProfile?.idUser
    modalRef.componentInstance.idConnection = this.connection.connection.idUser;
    modalRef.componentInstance.nameConnection = this.connection.connection.name;

    modalRef.result.then(async (result) => {
      if (result) {
        this.refreshData.emit();
        // await this.fetchUserConnections();
        // await this.fetchUserSentContactsRequests();
        // await this.fetchUserPendingContactsRequests();
        // this._alert.next('Conexão aceita com sucesso.');
        // this.alertType = 'success';
      }
    });
  }

}

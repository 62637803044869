import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  private backgroundSubject = new BehaviorSubject<string>('');

  public loading$ = this.loadingSubject.asObservable();
  public background$ = this.backgroundSubject.asObservable();

  show(background?: string) {
    this.backgroundSubject.next(background || '');
    this.loadingSubject.next(true);
  }

  hide() {
    this.loadingSubject.next(false);
    this.backgroundSubject.next(''); // Reseta o background ao esconder o loading
  }
}

<div class="home-container">
  <!-- <h1>
    Salve
  </h1> -->
  <div class="profile-container">
    <div class="profile-section">
      <img class="profile-image"
        [src]="userProfile?.documentUserProfile?.uriImageProfile ? userProfile?.documentUserProfile?.uriImageProfile : defaultProfileImage"
        alt="Profile Image">

      <div class="profile-welcome-container">
        <span class="title-text">Olá, {{ userProfile?.documentUser?.personal?.name }}!</span>

        <button class="share-profile-button" (click)="shareProfile($event)">
          <i class="bi bi-share"></i>
          <span class="share-profile-text" translate>menu.compartilharPerfil</span>
        </button>
      </div>

      <div class="profile-action-container">
        <a [hidden]="true" *ngIf="userProfilePlan.planName" class="profile-plan-stamp" [style]="getPlanStampStyles()">
          <span class="profile-plan-stamp-text">
            <i class="bi bi-collection"></i>
            {{ userProfilePlan.planName.toUpperCase() }}
          </span>
        </a>

        <a [hidden]="true" target="_blank" href="https://admin.sluper.bio" *ngIf="userProfile?.documentAdmin"
          class="admin-platform-link">Acessar
          plataforma Admin</a>
      </div>
    </div>

    <div class="options-section">
      <button class="icons-button" (click)="changeMenu('opcoes')">
        <i class="bi bi-sliders menu-icon"></i>

        <span class="menu-text" translate>menu.conta</span>
      </button>

      <button class="icons-button" (click)="changeMenu('aparencia')">
        <i class="bi bi-columns-gap menu-icon"></i>

        <span class="menu-text" translate>menu.meuSluper</span>
      </button>

      <button class="icons-button" (click)="changeMenu('links')">
        <i class="bi bi-link menu-icon" style="font-size: 20px;"></i>

        <span class="menu-text" translate>menu.links</span>
      </button>

      <button class="icons-button" (click)="changeMenu('sentContacts')">
        <i class="bi bi-people menu-icon"></i>

        <span class="menu-text" translate>menu.sentContacts</span>
      </button>

      <button class="icons-button" (click)="changeMenu('business')" *ngIf="userProfile?.documentAdmin">
        <i class="bi bi-briefcase menu-icon"></i>

        <span class="menu-text" translate>menu.business</span>
      </button>

      <!-- <button class="icons-button" (click)="changeMenu('business-templates')" *ngIf="userProfile?.documentAdmin">
        <i class="bi bi-briefcase menu-icon"></i>

        <span class="menu-text" translate>menu.businessTemplates</span>
      </button> -->

      <button class="icons-button" (click)="changeMenu('dashboard')">
        <i class="bi bi-bar-chart-fill menu-icon"></i>

        <span class="menu-text" translate>menu.dashboard</span>
      </button>


      <button class="icons-button" (click)="changeMenu('events')">
        <i class="bi bi-calendar-range menu-icon"></i>

        <span class="menu-text" translate>menu.events</span>
      </button>

      <!-- <button class="icons-button" (click)="changeMenu('community')">
        <i class="bi bi-people menu-icon"></i>

        <span class="menu-text" translate>menu.community</span>
      </button> -->

      <button [hidden]="true" class="icons-button" (click)="changeMenu('sluperCoins')">
        <i class="bi bi-coin menu-icon"></i>

        <span class="menu-text" translate>menu.sluperCoins</span>
      </button>

      <button [hidden]="true" class="icons-button" (click)="changeMenu('salesDashboard')">
        <i class="bi bi-speedometer menu-icon"></i>

        <span class="menu-text" translate>menu.salesDashboard</span>
      </button>

      <button class="icons-button" (click)="changeMenu('logout')">
        <i class="bi bi-arrow-return-right menu-icon"></i>

        <span class="menu-text" translate>menu.sair</span>
      </button>
    </div>
  </div>
</div>
<!-- <div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div> -->

<app-loading-spinner [customBg]="'#fff'" *ngIf="loadingEvents || loading || loadingMyCommunity"></app-loading-spinner>

<app-event-details (backToList)="handleBackToList()" [authorizedPage]="true" [userProfile]="userProfile"
  *ngIf="content === 'event-details' && openedEvent" [currentUserConnections]="userConnections"
  [preferencesList]="preferencesList" [occupationsList]="occupationsList" [userProfilePlan]="userProfilePlan"
  [eventId]="openedEvent!.id" [documentUser]="documentUser"></app-event-details>

<div class="body-div"
  *ngIf="content === 'event-list' || content === 'event-details-community-screen' || content === 'community-screen'">
  <app-events-header
    *ngIf="content === 'event-list' || content === 'event-details-community-screen' || content === 'community-screen'"
    [userCss]="userCss" [userProfile]="userProfile" [userConnections]="userConnectionsFilteredThisMonth"
    [nextEvents]="allNextEvents" [nextCommunitys]="nextCommunitys" [documentUser]="documentUser"
    [userProfilePlan]="userProfilePlan" (triggerfeedbackMessage)="alertsCommunity($event)"
    (createEvent)="openEventTypeModal()" (createCommunity)="openCreateCommunityModal()" [communityItem]="communityItem"
    [userProfileComplete]="userProfileComplete" [allowedToCreateCommunity]="canCreateCommunity()"
    [allowedToCreateEvent]="canCreateEvent()" (openCommunityMembersModal)="mudouMenu($event)"
    (redirectToSettings)="handleRedirectToSettings($event)"></app-events-header>

  <section class="tab-selector"
    *ngIf="!loadingEvents && (content === 'event-list' || content === 'event-details-community-screen') || content === 'community-screen'">
    <div id="event-community-tab-selector">
      <div [class]="selectedTab === 1 ? 'tab selected-tab' : 'tab'" (click)="changeSelectedTab(1)">
        <h2 class="tab-title">Comunidade</h2>
      </div>

      <div [class]="selectedTab === 0 ? 'tab selected-tab' : 'tab'" (click)="changeSelectedTab(0)">
        <h2 class="tab-title">Eventos</h2>
      </div>
    </div>
    <div [formGroup]="dateRangeForm" class="search-date-input w-100 d-flex justify-content-end gap-2 align-items-center"
      *ngIf="selectedTab === 0">
      <div class="card calendar">
        <div class="card-body d-flex flex-column p-0">
          <div class="w-100" [formGroup]="dateRangeForm">
            <mat-form-field appearance="fill" class="demo-inline-calendar-card w-100" (click)="picker.open()">
              <mat-label>Escolha as datas</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Data inicial" formControlName="startDate"
                  (dateChange)="onDateChange($event, 'start', 'date')" (keydown)="blockInput($event, 'start')">
                <input matEndDate placeholder="Data final" formControlName="endDate"
                  (dateChange)="onDateChange($event, 'end', 'date')" (keydown)="blockInput($event, 'end')">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <button class="new-event-button w-100" *ngIf="isShowingDateRange" (click)="clearFilter()">Limpar</button>
        </div>
      </div>

      <div class="event-community-searchbar">
        <input class="search-input-event" type="text" placeholder="Pesquisar eventos" formControlName="searchText"
          (input)="onDateChange($event, 'text', 'text')">
        <i class="bi bi-search"></i>
      </div>
    </div>
    <!-- <div class="w-100 d-flex justify-content-end" *ngIf="selectedTab === 1">
      <div class="event-community-searchbar">
        <input class="search-input-event" type="text" placeholder="Pesquisar comunidades"
          (input)="searchCommunities($event)">
        <i class="bi bi-search"></i>
      </div>
    </div> -->

  </section>


  <app-communities style="width: calc(100% - 40px)"
    *ngIf="content === 'event-details-community-screen' && selectedTab === 1" [userCss]="userCss"
    [userProfile]="userProfile" [userConnections]="userConnections" [documentUser]="documentUser"
    [userProfilePlan]="userProfilePlan" (changeMenu)="mudouMenu($event)" (refreshData)="fetchData($event)"
    [preferencesList]="preferencesList" [occupationsList]="occupationsList" (linkRequired)="linkRequired($event)"
    [communityItem]="communityItem" [userProfileComplete]="userProfileComplete"
    (backAllCommunities)="backToAllCommunities()" [showBack]="false"
    (showAllEvents)="changeSelectedTab(0)"></app-communities>


  <app-communities *ngIf="content === 'community-screen'" [userCss]="userCss" [userProfile]="userProfile"
    [userConnections]="userConnections" [documentUser]="documentUser" [userProfilePlan]="userProfilePlan"
    (changeMenu)="mudouMenu($event)" (refreshData)="fetchData($event)" [preferencesList]="preferencesList"
    [occupationsList]="occupationsList" (linkRequired)="linkRequired($event)" [communityItem]="communityItem"
    [userProfileComplete]="userProfileComplete" (backAllCommunities)="backToAllCommunities()"
    (showAllEvents)="changeSelectedTab(0)"></app-communities>


  <section class="content-container">
    <div class="events-screen" *ngIf="!loadingEvents && content === 'event-list' && selectedTab === 0">
      <!--      <div class="div-new-events-button" *ngIf="!searchingEvents">
       <button class="new-event-button" (click)="openEventTypeModal()"
          *ngIf="(documentUser && documentUser.role === 'adm') || userProfilePlan.profilePlanLimits.includeEvents">Criar
          Evento</button>
      </div> -->

      <div class="row g-2">
        <div class="p-0">
          <div class="">
            <div class="card next-events">
              <div class="card-header">
                <h4 translate>events.nextEventsAll</h4>
                <span *ngIf="!showAllNextEvents && allNextEvents.length" class="see-more"
                  (click)="showAllNextEvents= true" translate>events.seeMore</span>
                <span *ngIf="showAllNextEvents && allNextEvents.length" class="see-more"
                  (click)="showAllNextEvents= false" translate>events.seeLess</span>
              </div>
              <button *ngIf="!showAllNextEvents && allNextEvents.length" class="scroll-btn btn-left"
                (click)="scrollLeft('next-events')">
                <i class="bi bi-chevron-left"></i>
              </button>
              <div *ngIf="allNextEvents.length" [ngClass]="!showAllNextEvents
                  ? 'card-body next-events-default-visualization'
                  : 'card-body next-events-see-more-visualization' ">
                <app-other-events-card *ngFor="let event of allNextEvents" [participationType]="2" [event]="event"
                  (join)="joinEvent($event)">
                </app-other-events-card>
              </div>
              <button *ngIf="!showAllNextEvents && allNextEvents.length" class="scroll-btn btn-right"
                (click)="scrollRight('next-events')">
                <i class="bi bi-chevron-right"></i>
              </button>
              <div class="no-items-container mt-3" *ngIf="allNextEvents.length < 1">
                <span class="no-items-label">Nenhum evento futuro</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row g-2">
        <div class="col-12  p-0">
          <div class=" h-100">
            <div class="card others-events">
              <div class="card-header">
                <h4 translate>events.title</h4>
                <span *ngIf="!showMoreOthersEvents && sectionEvents.length" class="see-more"
                  (click)="showMoreOthersEvents= true" translate>events.seeMore</span>
                <span *ngIf="showMoreOthersEvents && sectionEvents.length" class="see-more"
                  (click)="showMoreOthersEvents= false" translate>events.seeLess</span>
              </div>
              <button *ngIf="!showMoreOthersEvents  && sectionEvents.length > 1" class="scroll-btn btn-left"
                (click)="scrollLeft('others-events')">
                <i class="bi bi-chevron-left"></i>
              </button>
              <div *ngIf="sectionEvents.length"
                [ngClass]="!showMoreOthersEvents ? 'card-body next-events-default-visualization' : 'card-body next-events-see-more-visualization' ">
                <app-other-events-card *ngFor="let event of sectionEvents" [event]="event" (join)="joinEvent($event)">
                </app-other-events-card>
              </div>

              <div class="no-items-container mt-3" *ngIf="!sectionEvents.length">
                <p translate>Nenhum evento encontrado</p>
              </div>
              <button *ngIf="!showMoreOthersEvents && sectionEvents.length > 1" class="scroll-btn btn-right"
                (click)="scrollRight('others-events')">
                <i class="bi bi-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 col-lg-3 col-md-3 col-sm-12 p-0">
          <div class="card calendar">
            <div class="card-body d-flex flex-column">
              <div class="w-100" [formGroup]="dateRangeForm">
                <mat-form-field appearance="fill" class="demo-inline-calendar-card w-100" (click)="picker.open()">
                  <mat-label>Escolha as datas</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Data inicial" formControlName="startDate"
                      (dateChange)="onDateChange($event, 'start', 'date')" (keydown)="blockInput($event, 'start')">
                    <input matEndDate placeholder="Data final" formControlName="endDate"
                      (dateChange)="onDateChange($event, 'end', 'date')" (keydown)="blockInput($event, 'end')">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>
              <button class="new-event-button w-100" *ngIf="isShowingDateRange" (click)="clearFilter()">Limpar</button>
            </div>
          </div>
        </div> -->
      </div>

      <div class="row g-2" *ngIf="!searchingEvents">
        <div class="p-0">
          <div class="">
            <div class="card my-events">
              <div class="card-header">
                <h4 translate>events.myEvents</h4>
                <span *ngIf="!showMoreEventsOrganizedByYou && ownerUserEvents.length" class="see-more"
                  (click)="showMoreEventsOrganizedByYou= true" translate>events.seeMore</span>
                <span *ngIf="showMoreEventsOrganizedByYou && ownerUserEvents.length" class="see-more"
                  (click)="showMoreEventsOrganizedByYou= false" translate>events.seeLess</span>
              </div>
              <button *ngIf="!showMoreEventsOrganizedByYou && ownerUserEvents.length" class="scroll-btn btn-left"
                (click)="scrollLeft('my-events')">
                <i class="bi bi-chevron-left"></i>
              </button>
              <div *ngIf="ownerUserEvents.length"
                [ngClass]="!showMoreEventsOrganizedByYou ? 'card-body next-events-default-visualization' : 'card-body next-events-see-more-visualization' ">
                <app-other-events-card *ngFor="let event of ownerUserEvents" [participationType]="2" [event]="event"
                  (join)="joinEvent($event)">
                </app-other-events-card>
              </div>
              <button *ngIf="!showMoreEventsOrganizedByYou && ownerUserEvents.length" class="scroll-btn btn-right"
                (click)="scrollRight('my-events')">
                <i class="bi bi-chevron-right"></i>
              </button>
              <div class="no-items-container mt-3" *ngIf="ownerUserEvents.length < 1">
                <span class="no-items-label">Nenhum evento organizado por você</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-3 col-md-12 col-sm-12 p-0" *ngIf="pendingConnectionRequests.length > 0">
          <div class="card connection-request">
            <div class="card-header">
              <h4 translate>events.connectionsRequest</h4>
            </div>
            <div
              [ngClass]="!showMoreConnectionRequests ? 'card-body connection-requests-default-visualization' : 'card-body connection-requests-see-more-visualization'">
              <app-connection-request-card
                *ngFor="let connection of (showMoreConnectionRequests ? pendingConnectionRequests : pendingConnectionRequests | slice:0:3)"
                [connection]="connection" [userProfile]="userProfile" (refreshData)="fetchData($event)">
              </app-connection-request-card>
            </div>
            <div class="card-footer-see-more" *ngIf="pendingConnectionRequests.length > 3">
              <div class="line"></div>
              <span *ngIf="!showMoreConnectionRequests" class="see-more" (click)="showMoreConnectionRequests = true"
                translate>
                events.seeMore
              </span>
              <span *ngIf="showMoreConnectionRequests" class="see-more" (click)="showMoreConnectionRequests = false"
                translate>
                events.seeLess
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>



    <div class="community-screen" *ngIf="!loadingEvents && content === 'event-list' && selectedTab === 1">
      <!--  <div class="div-new-events-button">
         <button class="new-event-button" (click)="openCreateCommunityModal()"
          *ngIf="(documentUser && canICreateCommunity && documentUser.role === 'adm' ) || userProfilePlan.profilePlanLimits.includeEvents && !searchingCommunities && canICreateCommunity">Criar
          Comunidade</button>
      </div> -->
      <div class="card communities-i-participe" *ngIf="!searchingCommunities">
        <div class="card-header">
          <h4 translate>communitys.communitiesIParticipe</h4>
          <span *ngIf="!showMoreMyCommunities && nextCommunitys.length" class="see-more"
            (click)="showMoreMyCommunities= true" translate>communitys.seeMore</span>
          <span *ngIf="showMoreMyCommunities && nextCommunitys.length" class="see-more"
            (click)="showMoreMyCommunities= false" translate>communitys.seeLess</span>
        </div>
        <button *ngIf="!showMoreMyCommunities && nextCommunitys.length" class="scroll-btn btn-left"
          (click)="scrollLeft('communities-i-participe')">
          <i class="bi bi-chevron-left"></i>
        </button>
        <div *ngIf="nextCommunitys.length"
          [ngClass]="!showMoreMyCommunities ? 'card-body communities-i-participe-default-visualization' : 'card-body communities-i-participe-see-more-visualization'">
          <app-community-card class="pointer" *ngFor="let event of nextCommunitys" [event]="event"
            (click)="openCommunity(event)" (join)="null"></app-community-card>
        </div>
        <button *ngIf="!showMoreMyCommunities && nextCommunitys.length" class="scroll-btn btn-right"
          (click)="scrollRight('communities-i-participe')">
          <i class="bi bi-chevron-right"></i>
        </button>

        <div class="mt-3" *ngIf="!nextCommunitys.length">
          <p translate>Nenhuma comunidade encontrada</p>
        </div>
      </div>

      <div class="card others-communities">
        <div class="card-header">
          <h4 translate *ngIf="!searchingCommunities">communitys.othersCommunitys</h4>
          <h4 translate *ngIf="searchingCommunities">communitys.communities</h4>
          <span *ngIf="!showMoreOthersCommunities && allCommunitysFiltered.length" class="see-more"
            (click)="showMoreOthersCommunities= true" translate>communitys.seeMore</span>
          <span *ngIf="showMoreOthersCommunities && allCommunitysFiltered.length" class="see-more"
            (click)="showMoreOthersCommunities= false" translate>communitys.seeLess</span>
        </div>
        <div *ngIf="allCommunitysFiltered.length"
          [ngClass]="!showMoreOthersCommunities ? 'card-body communities-i-participe-default-visualization' : 'card-body communities-i-participe-see-more-visualization'">
          <app-other-community-card *ngFor="let event of allCommunitysFiltered" [event]="event"
            (join)="joinCommunity($event)">
          </app-other-community-card>

        </div>
        <div class="mt-3" *ngIf="!allCommunitysFiltered.length">
          <p translate>Nenhuma comunidade encontrada</p>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- <div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div>

<div class="body-div">
  <app-event-details (backToList)="handleBackToList()" [authorizedPage]="true" [userProfile]="userProfile"
    *ngIf="content === 'event-details' && openedEvent" [currentUserConnections]="userConnections"
    [preferencesList]="preferencesList" [occupationsList]="occupationsList" [userProfilePlan]="userProfilePlan"
    [eventId]="openedEvent.id" [documentUser]="documentUser"></app-event-details>

  <section class="tab-selector" *ngIf="!loadingEvents && content === 'event-list'">
    <div [class]="selectedTab === 0 ? 'tab selected-tab' : 'tab'" (click)="changeSelectedTab(0)">
      <i class="bi bi-calendar-event"></i>

      <h2 class="tab-title">Eventos</h2>
    </div>

    <div [class]="selectedTab === 1 ? 'tab selected-tab' : 'tab'" (click)="changeSelectedTab(1)">
      <i class="bi bi-people-fill"></i>

      <h2 class="tab-title">Comunidades</h2>
    </div>
  </section>

  <section class="content-container">
    <app-loading-spinner *ngIf="loadingEvents"></app-loading-spinner>
    <div class="events-list-container" *ngIf="!loadingEvents && content === 'event-list' && selectedTab === 0">
      <header class="title-container">
        <section class="title-section">
          <h1 class="title">Eventos</h1>
          <h2 class="subtitle" translate>events.subtitle</h2>
        </section>

        <button class="new-event-button" (click)="openCreateEventModal()"
          *ngIf="(documentUser && documentUser.role === 'adm') || userProfilePlan.profilePlanLimits.includeEvents">Organizar
          Evento</button>

        <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
          class="no-dash-graph-access-button"
          *ngIf="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.includeEvents !== undefined && !userProfilePlan.profilePlanLimits.includeEvents">
          <i class="bi bi-lock-fill"></i>

          <div class="no-dash-graph-messages">
            Para criar novos eventos,
            <a target="_blank"
              href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça
              upgrade do seu plano</a>
          </div>
        </a>
      </header>

      <div class="content-container">
        <section class="own-event-items-section">
          <div class="own-event-item-content">
            <div class="own-event-item-title-container">
              <span class="own-event-item-title" translate>events.nextEvents</span>
            </div>

            <div class="own-event-item-list-container">
              <div class="small-event-box" *ngFor="let event of nextEvents" (click)="handleEventClick(event.event)">
                <img class="small-event-image" [src]="event.event.imageLink" alt="my event image" />

                <div class="small-event-box-content">
                  <div class="small-event-box-content-description">
                    <span class="small-event-name">{{ event.event.name }}</span>
                    <span class="small-event-date">{{
                      event.event.startDate | date: "EEEE, d 'de' MMMM 'de' YYYY 'às' HH:mm" : '-0300' : 'pt-BR'
                      }}</span>
                  </div>
                  <span class="small-event-participation-icon">
                    <i *ngIf="event.participationStatus === 'PARTICIPANDO'" matTooltip="Participando"
                      class="bi bi-check-lg small-event-participation-icon-green"></i>
                    <i *ngIf="event.participationStatus === 'PENDENTE_APROVACAO'" matTooltip="Aguardando Aprovação"
                      class="bi bi-hourglass-split small-event-participation-icon-yellow"></i>
                  </span>
                </div>
              </div>

              <div class="no-items-container" *ngIf="nextEvents.length < 1">
                <span class="no-items-label">Nenhum evento encontrado</span>
              </div>
            </div>
          </div>

          <div class="own-event-item-content">
            <div class="own-event-item-title-container">
              <span class="own-event-item-title" translate>events.myEvents</span>
            </div>

            <div class="own-event-item-list-container">
              <div class="small-event-box" *ngFor="let event of ownerUserEvents" (click)="handleEventClick(event)">
                <img class="small-event-image" [src]="event.imageLink" alt="my event image" />

                <div class="small-event-box-content">
                  <div class="small-event-box-content-description">
                    <span class="small-event-name">{{ event.name }}</span>
                    <span class="small-event-date">{{
                      event.startDate | date: "EEEE, d 'de' MMMM 'de' YYYY 'às' HH:mm" : '-0300' : 'pt-BR'
                      }}</span>
                  </div>
                </div>
              </div>

              <div class="no-items-container" *ngIf="ownerUserEvents.length < 1">
                <span class="no-items-label">Nenhum evento encontrado</span>
              </div>
            </div>
          </div>

          <div class="own-event-item-content">
            <div class="own-event-item-title-container">
              <span class="own-event-item-title" translate>events.connections</span>

              <span (click)="changeMenu.emit('sent-contacts')" class="see-more" translate>events.seeMore</span>
            </div>

            <div class="own-event-item-list-container"
              [ngClass]="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !(userProfilePlan.profilePlanLimits.connectContactsView > 0) ? 'hidden' : ''">
              <div style="position: relative;" *ngFor="let connection of userConnections; let i = index">
                <div class="connection-box" (click)="openConnectionProfile(connection)"
                  [ngClass]="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && (userProfilePlan.profilePlanLimits.connectContactsView > 0 && i >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''">
                  <img class="connection-image"
                    [src]="connection.connection.uriImageProfile ? connection.connection.uriImageProfile : defaultImageProfile"
                    alt="my event image" />

                  <div class="connection-box-content">
                    <span class="connection-name">{{ connection.connection.name }}</span>
                    <span class="connection-from" *ngIf="connection.event">Conectado pelo <b>{{
                        connection.event.name}}</b></span>
                  </div>
                </div>
                <a class="no-connection-view" matTooltip="Para visualizar mais conexões, faça o upgrade do seu plano"
                  target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
                  matTooltipClass="mat-tooltip"
                  *ngIf="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && (userProfilePlan.profilePlanLimits.connectContactsView > 0 && i >= userProfilePlan.profilePlanLimits.connectContactsView)">
                  <i class="bi bi-lock-fill"></i>
                </a>
              </div>

              <div class="no-items-container" *ngIf="userConnections.length < 1">
                <span class="no-items-label">Nenhuma conexāo encontrada</span>
              </div>
            </div>
            <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
              class="no-connections-view-button"
              *ngIf="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContactsView !== undefined && !(userProfilePlan.profilePlanLimits.connectContactsView > 0)">
              <i class="bi bi-lock-fill"></i>

              <div class="no-dash-graph-messages">
                Para visualizar suas conexões,
                <a target="_blank"
                  href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça
                  upgrade do seu plano</a>
              </div>
            </a>
          </div>
        </section>

        <section class="explore-section">
          <div class="filters-container">
            <div class="input-group inputs event-date-field-container">
              <button class="btn btn-outline-secondary input-prepend date-field-trigger" (click)="d1.toggle()">
                <i class="bi bi-calendar3"></i>
              </button>

              <input [(ngModel)]="filterFields.date" class="form-control date-picker" id="date" name="date"
                placeholder="dd/mm/yyyy" ngbDatepicker (dateSelect)="handleFilterDateChange($event)"
                (input)="formatDate($event)" #d1="ngbDatepicker" />
            </div>

            <div class="input-group event-type-input-container">
              <div class="input-group-prepend event-type-field-prepend">
                <i class="bi bi-border-style"></i>
              </div>

              <select [(ngModel)]="filterFields.eventType" (change)="handleFilterEventTypeChange($event)"
                class="form-select event-type-field">
                <option value="void">Tipo de evento</option>
                <option value="PRESENCIAL">Presencial</option>
                <option value="REMOTO">Online</option>
              </select>
            </div>
          </div>

          <div class="events-list-by-day-container">
            <div class="no-events-container" *ngIf="filteredCommunityEvents.length < 1">
              <span class="no-events-label">Nenhum evento encontrado</span>
            </div>

            <div class="day-container" *ngFor="let eventDay of filteredCommunityEvents">
              <div class="day-indicator-container">
                <span class="day-label">{{ eventDay.date | date: "EEEE, d 'de' MMMM" : '-0300' : 'pt-BR' }}</span>
              </div>

              <div *ngFor="let event of eventDay.events" (click)="handleEventClick(event)" class="event-box">
                <img [src]="event.imageLink" alt="event-image" class="event-image" />

                <div class="event-infos-container">
                  <span class="event-date">{{ event.startDate | date: "EEEE, d 'de' MMMM 'de' YYYY 'às' HH:mm" : '-0300'
                    :
                    'pt-BR' }}</span>
                  <span class="event-name">{{ event.name }}</span>
                  <div class="event-description" [innerHTML]="event.description">{{ event.description }}</div>
                  <span class="event-location" *ngIf="event.address && event.city">{{ event.address + ', ' + event.city
                    }}</span>
                  <div class="bottom-infos">
                    <span class="event-participants-count">{{ event.numberOfParticipants + ' participantes' }}</span>

                    <div class="event-actions-buttons">
                      <button class="action-small-button" (click)="handleShareEvent($event, event)">
                        <i class="bi-share"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="events-list-container" *ngIf="!loadingEvents && content === 'event-list' && selectedTab === 1">
      <header class="title-container">
        <section class="title-section">
          <h1 class="title" translate>communitys.title</h1>
          <h2 class="subtitle" translate>communitys.subtitle</h2>
        </section>

        <button class="new-event-button" (click)="openCreateCommunityModal()"
          *ngIf="(documentUser && documentUser.role === 'adm') || userProfilePlan.profilePlanLimits.includeEvents">Criar
          Comunidade</button>

        <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
          class="no-dash-graph-access-button"
          *ngIf="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.includeEvents !== undefined && !userProfilePlan.profilePlanLimits.includeEvents">
          <i class="bi bi-lock-fill"></i>

          <div class="no-dash-graph-messages">
            Para criar novas comunidades,
            <a target="_blank"
              href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça
              upgrade do seu plano</a>
          </div>
        </a>
      </header>

      <div class="content-container">
        <section class="own-event-items-section">
          <div class="own-event-item-content">
            <div class="own-event-item-title-container">
              <span class="own-event-item-title" translate>communitys.nextCommunitys</span>
            </div>

            <div class="own-event-item-list-container">
              <div class="small-event-box" *ngFor="let event of nextCommunitys" (click)="handleEventClick(event.event)">
                <img class="small-event-image" [src]="event.event.imageLink" alt="my event image" />
                <div class="small-event-box-content">
                  <div class="small-event-box-content-description">
                    <span class="small-event-name">{{ event.event.name }}</span>
                    <span class="small-event-date">{{
                      event.event.startDate | date: "EEEE, d 'de' MMMM 'de' YYYY 'às' HH:mm" : '-0300' : 'pt-BR'
                      }}</span>
                  </div>
                  <span class="small-event-participation-icon">
                    <i *ngIf="event.participationStatus === 'PARTICIPANDO'" matTooltip="Participando"
                      class="bi bi-check-lg small-event-participation-icon-green"></i>
                    <i *ngIf="event.participationStatus === 'PENDENTE_APROVACAO'" matTooltip="Aguardando Aprovação"
                      class="bi bi-hourglass-split small-event-participation-icon-yellow"></i>
                  </span>
                </div>
              </div>

              <div class="no-items-container" *ngIf="nextCommunitys.length < 1">
                <span class="no-items-label">Nenhuma comunidade encontrada</span>
              </div>
            </div>
          </div>

          <div class="own-event-item-content">
            <div class="own-event-item-title-container">
              <span class="own-event-item-title" translate>communitys.myCommunitys</span>
            </div>

            <div class="own-event-item-list-container">
              <div class="small-event-box" *ngFor="let event of ownerUserCommunitys" (click)="handleEventClick(event)">
                <img class="small-event-image" [src]="event.imageLink" alt="my event image" />

                <div class="small-event-box-content">
                  <div class="small-event-box-content-description">
                    <span class="small-event-name">{{ event.name }}</span>
                    <span class="small-event-date">{{
                      event.startDate | date: "EEEE, d 'de' MMMM 'de' YYYY 'às' HH:mm" : '-0300' : 'pt-BR'
                      }}</span>
                  </div>
                </div>
              </div>

              <div class="no-items-container" *ngIf="ownerUserCommunitys.length < 1">
                <span class="no-items-label">Nenhuma comunidade encontrado</span>
              </div>
            </div>
          </div>

          <div class="own-event-item-content">
            <div class="own-event-item-title-container">
              <span class="own-event-item-title" translate>communitys.connections</span>

              <span (click)="changeMenu.emit('sent-contacts')" class="see-more" translate>communitys.seeMore</span>
            </div>

            <div class="own-event-item-list-container"
              [ngClass]="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && !(userProfilePlan.profilePlanLimits.connectContactsView > 0) ? 'hidden' : ''">
              <div style="position: relative;" *ngFor="let connection of userConnections; let i = index">
                <div class="connection-box" (click)="openConnectionProfile(connection)"
                  [ngClass]="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && (userProfilePlan.profilePlanLimits.connectContactsView > 0 && i >= userProfilePlan.profilePlanLimits.connectContactsView) ? 'hidden' : ''">
                  <img class="connection-image"
                    [src]="connection.connection.uriImageProfile ? connection.connection.uriImageProfile : defaultImageProfile"
                    alt="my event image" />

                  <div class="connection-box-content">
                    <span class="connection-name">{{ connection.connection.name }}</span>
                    <span class="connection-from" *ngIf="connection.event">Conectado pelo <b>{{
                        connection.event.name}}</b></span>
                  </div>
                </div>
                <a class="no-connection-view" matTooltip="Para visualizar mais conexões, faça o upgrade do seu plano"
                  target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
                  matTooltipClass="mat-tooltip"
                  *ngIf="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContacts !== undefined && (userProfilePlan.profilePlanLimits.connectContactsView > 0 && i >= userProfilePlan.profilePlanLimits.connectContactsView)">
                  <i class="bi bi-lock-fill"></i>
                </a>
              </div>

              <div class="no-items-container" *ngIf="userConnections.length < 1">
                <span class="no-items-label">Nenhuma conexāo encontrada</span>
              </div>
            </div>
            <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
              class="no-connections-view-button"
              *ngIf="!(documentUser && documentUser.role === 'adm') && userProfilePlan.profilePlanLimits.connectContactsView !== undefined && !(userProfilePlan.profilePlanLimits.connectContactsView > 0)">
              <i class="bi bi-lock-fill"></i>

              <div class="no-dash-graph-messages">
                Para visualizar suas conexões,
                <a target="_blank"
                  href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça
                  upgrade do seu plano</a>
              </div>
            </a>
          </div>
        </section>

        <section class="explore-community-section">
          <div class="no-events-container" *ngIf="allCommunitys.length < 1">
            <span class="no-events-label">Nenhuma comunidade encontrada</span>
          </div>

          <div class="own-event-item-content" *ngFor="let event of allCommunitys">
            // <div class="day-container" *ngFor="let eventDay of filteredCommunityEvents"></div>
            <div (click)="handleEventClick(event)" class="community-box">
              <img [src]="event.imageLink" alt="event-image" class="event-image" />

              <div class="community-infos-container">
                <span class="event-name">{{ event.name }}</span>
                <span class="community-description" [innerHTML]="event.description">{{ event.description }}</span>
                <div class="community-bottom-infos">
                  <div class="event-actions-buttons">
                    <button class="action-small-button" (click)="handleShareEvent($event, event)">
                      <i class="bi-share"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</div> -->
import { Component } from '@angular/core';

@Component({
  selector: 'app-information-settings',
  templateUrl: './information-settings.component.html',
  styleUrls: ['./information-settings.component.css']
})
export class InformationSettingsComponent {

}

import { formatDate } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, firstValueFrom, forkJoin, map, Subscription } from 'rxjs';

import { NotificationService } from '../bell-notification/notification.service';
import {
  CategoryChannelService,
  CategoryInterface,
  ChannelInterface,
  ModalDataInterface,
  ModalParamsInterface,
  ModalResultInterface,
} from '../categoryAndChannel/category-channel.service';
import { RemoveConnectionModalComponent } from '../contacts-and-connections/remove-connection/remove-connection.componente';
import { CreateEventModalComponent } from '../events/create-event-modal/create-event-modal.component';
import { DeleteEventModalComponent } from '../events/delete-event-modal/delete-event-modal.component';
import { EventDetailsComponent } from '../events/event-details/event-details.component';
import { EventTypeComponent } from '../events/event-type/event-type.component';
import { EventsService } from '../events/events.service';

import { Connection } from '../model/connection.model';
import {
  DocumentUserProfile,
  IDocumentUserProfileWithPreferences,
} from '../model/document-user-profile.model';
import { DocumentUser } from '../model/document-user.model';
import {
  EventParticipant,
  IEventWithParticipationStatus,
  SluperEvent,
} from '../model/event.model';
import { IDocumentPreferences } from '../model/preferences.model';
import { IUserCss, UserCss } from '../model/user-css.model';
import { IUserProfilePlan } from '../model/user-plan.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';

import { ToastService } from '../new-sluper/core/service/toast.service';
import { CadastroStorageService } from '../new-sluper/public/cadastro/cadastro-storage.service';

import { CreateCategoryModalComponent } from '../categoryAndChannel/create-category-modal/create-category-modal.component';
import { CreateChannelCategoryModalComponent } from '../categoryAndChannel/create-channel-category-modal/create-channel-category-modal.component';
import { CreateChannelModalComponent } from '../categoryAndChannel/create-channel-modal/create-channel-modal.component';
import { DeleteSideMenuOptionComponent } from '../categoryAndChannel/delete-side-menu-option/delete-side-menu-option.component';
import { MoveChannelCategoryComponent } from '../categoryAndChannel/move-channel-category/move-channel-category.component';
import { SideMenuOptionsComponent } from '../side-menu-options/side-menu-options.component';

import { ModalBackgroundColorComponent } from './modal-background-color/modal-background-color.component';
import { ModalCommunityInfoComponent } from './modal-community-info/modal-community-info.component';
import { ModalCommunityMembersComponent } from './modal-community-members/modal-community-members.component';
import { ModalCommunityRulesComponent } from './modal-community-rules/modal-community-rules.component';
import { ModalShowBioComponent } from './modal-show-bio/modal-show-bio.component';
import { ModalShowRulesComponent } from './modal-show-rules/modal-show-rules.component';
import { ModalPostInputComponent } from './post/modal-post-input/modal-post-input.component';
import { Like, Post, PostsService } from './post/posts.service';

import { OpenEventService } from '../open-event.service';
import { OpenedCommunityService } from '../opened-community.service';
import { SanitizeTextPipe } from '../sanitizeText/sanitize-text.pipe';

@Component({
  selector: 'app-communities',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.css'],
})
export class CommunitiesComponent implements OnInit, OnDestroy {
  /** ==========================
   *  @Outputs
   *  ========================== */
  @Output() changeMenu = new EventEmitter<
    | 'home'
    | 'aparencia'
    | 'links'
    | 'opcoes'
    | 'indicadores'
    | 'sentContacts'
    | 'dashboard'
    | 'salesDashboard'
    | 'sluperCoins'
    | 'events'
    | 'business'
    | 'businessTemplates'
    | 'community'
  >();
  @Output() refreshData = new EventEmitter<void>();
  @Output() backAllCommunities = new EventEmitter<void>();
  @Output() showAllEvents = new EventEmitter<void>();
  @Output() triggerfeedbackMessage = new EventEmitter<{
    message: string;
    type: 'success' | 'error';
  }>();

  /** ==========================
   *  @Inputs
   *  ========================== */
  @Input() userProfile!: DocumentUserProfile | undefined;
  @Input() documentUser!: DocumentUser | undefined;
  @Input() userCss!: any | undefined;
  @Input() userProfilePlan!: IUserProfilePlan;
  @Input() preferencesList!: IDocumentPreferences[];
  @Input() occupationsList!: IDocumentPreferences[];
  @Input() communityItem!: SluperEvent;
  @Input() userConnections!: Connection[];
  @Input() showBack?: boolean = false;
  @Input() showSidebar: boolean = true;
  @Input() userProfileComplete: IUserProfileWithCSS | null = null;

  /** ==========================
   *  @ViewChild / TemplateRefs
   *  ========================== */
  @ViewChild('modalContentChannel', { static: true })
  modalContentChannel!: TemplateRef<any>;

  /** ==========================
   *  ESTADOS DE CARREGAMENTO
   *  ========================== */
  // Em vez de 'loading' simples, usamos um objeto de estado com flags específicas
  loadingState = {
    global: false, // Carregamento geral
    connections: false, // Carregamento específico de conexões
  };

  /** ==========================
   *  VARIÁVEIS / ESTADOS
   *  ========================== */

  // Controla leitura de Descrição/Bio e Regras
  public readMoreDescription = false;
  public readMoreRules = false;
  public seeMoreCommunityMembers = false;

  // Paginação dos posts
  postsPagination = {
    page: 0,
    pageSize: 10,
    totalPages: 1,
    isLoading: false,
  };

  // Tela atual
  public screenCommunity:
    | 'initial'
    | 'mentions'
    | 'saved'
    | 'alerts'
    | 'settings'
    | 'finance'
    | 'store' = 'initial';

  // Arrays e objetos
  public sanitizedBio: SafeHtml = '';
  public iUserCss: IUserCss = new UserCss();
  public participants: EventParticipant[] = [];
  public sentConnections: Connection[] = [];
  public openedEvent: SluperEvent | null = null;
  public posts: Post[] = [];
  public likes: Like[] = [];

  // Categorias e canais
  public communityCategories: CategoryInterface[] = [];
  public channelsWithoutCategory: ChannelInterface[] = [];
  public selectedChannel: ChannelInterface | null = null;

  // Próximos eventos
  public nextEvents: SluperEvent[] = [];

  // Flags diversas
  public userIsPremium = false;
  public isUserCommunityOwner = false;

  // Subscriptions e intervalos
  private subscriptionChangedNotification: Subscription | null = null;
  private scrollListenerFn: (() => void) | null = null;
  private refreshInterval: any;
  private parentScrollElement!: HTMLElement;

  constructor(
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private eventsService: EventsService,
    private categoryChannelService: CategoryChannelService,
    private postsService: PostsService,
    private notificationService: NotificationService,
    private openEventService: OpenEventService,
    private openedCommunityService: OpenedCommunityService,
    private renderer: Renderer2,
    private el: ElementRef,
    private router: Router,
    private toastrService: ToastService,
    private cadastroStorageService: CadastroStorageService,
    private sanitizeTextPipe: SanitizeTextPipe
  ) {}

  /** =================================================================
   *  Lifecycle Hooks
   *  ================================================================ */
  async ngOnInit() {
    // Limpa possí­veis registros de cadastro
    this.cadastroStorageService.clear();

    // Verifica se a comunidade está aberta, caso contrário exibe botao "Voltar"
    if (!this.openedCommunityService.getCommunity()) {
      this.showBack = true;
    }

    // Verifica se usuário é premium
    this.userIsPremium = this.userProfilePlan?.planName === 'Plano Corporativo';

    // Scroll inicial para o topo
    const element = document.getElementById('user-profile-screen-scroll');
    if (element) element.scrollTop = 0;

    // Sanitiza a Bio da comunidade
    this.sanitizeBio();

    // Seta a comunidade selecionada no serviço
    this.openedCommunityService.setCommunity(this.communityItem.eventUrl!);
    this.openedCommunityService.setMyCommunity(this.communityItem);

    // Carrega CSS do usuário (caso exista)
    this.iUserCss = this.userCss?.css
      ? JSON.parse(this.userCss.css)
      : new UserCss();

    // Carrega dados necessários em paralelo
    await this.loadInitialData();

    // Determina se o usuário atual é dono da comunidade
    this.isUserCommunityOwner =
      this.communityItem.organizer.idUser === this.documentUser?.idUser;

    // Se houver um eventId em openEventService, faz o join na comunidade
    const eventId = this.openEventService.getId();
    if (eventId && this.openEventService.getType() === 'COMUNIDADE') {
      if (
        this.verifyUserCanParticipate() &&
        this.communityItem.type === 'COMUNIDADE'
      ) {
        try {
          await this.joinCommunity();
        } catch (error) {
          console.error(error);
        } finally {
          this.openEventService.removeId();
        }
      }
    }

    // Listener para notificações de refresh
    this.setupNotificationListener();

    // Configura o scroll do elemento container se precisar de "infinite scroll"
    this.setupParentScrollListener();

    // Atualiza o evento periodicamente (a cada 60s)
    this.refreshInterval = setInterval(() => {
      this.refreshEvent();
    }, 60_000);
  }

  ngOnDestroy(): void {
    if (this.subscriptionChangedNotification) {
      this.subscriptionChangedNotification.unsubscribe();
      this.subscriptionChangedNotification = null;
    }
    if (this.scrollListenerFn) {
      this.scrollListenerFn();
      this.scrollListenerFn = null;
    }
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  redirectToPage(
    page:
      | 'home'
      | 'aparencia'
      | 'links'
      | 'opcoes'
      | 'indicadores'
      | 'sentContacts'
      | 'dashboard'
      | 'salesDashboard'
      | 'sluperCoins'
      | 'events'
      | 'business'
      | 'businessTemplates'
      | 'community'
  ) {
    this.changeMenu.emit(page);
  }

  /** =================================================================
   *  MÉTODOS DE SETUP
   *  ================================================================ */
  private setupNotificationListener() {
    if (!this.subscriptionChangedNotification) {
      this.subscriptionChangedNotification =
        this.notificationService.notifications$.subscribe(data => {
          if (data) {
            // Recarrega dados quando chega notificação
            this.ngOnInit();
          }
        });
    }
  }

  private setupParentScrollListener() {
    this.parentScrollElement = document.getElementById(
      'user-profile-screen-scroll'
    ) as HTMLElement;
    if (this.parentScrollElement) {
      this.scrollListenerFn = this.renderer.listen(
        this.parentScrollElement,
        'scroll',
        event => {
          // Caso deseje "infinite scroll", descomente:
          // this.onParentScroll(event);
        }
      );
    }
  }

  /** =================================================================
   *  MÉTODOS DE CARREGAMENTO INICIAL
   *  ================================================================ */
  private async loadInitialData() {
    try {
      this.loadingState.global = true;

      // Executa em paralelo:
      await Promise.all([
        this.fetchEventDetails(),
        this.fetchFutureUserEvents(),
        this.getSentConnections(),
        this.fetchCategoriesData(),
      ]);
    } catch (error: any) {
      this.toastrService.show(
        'Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
        'error'
      );
    } finally {
      this.loadingState.global = false;
    }
  }

  /** =================================================================
   *  MÉTODOS GERAIS
   *  ================================================================ */
  sanitizeBio() {
    this.sanitizedBio = this.sanitizer.bypassSecurityTrustHtml(
      this.communityItem.description || ''
    );
  }

  verifyUserIsPremium(): boolean {
    return this.userIsPremium;
  }

  verifyUserCanParticipate(): boolean {
    if (!this.participants.length) return false;
    const userExists = this.participants.some(
      participant => participant.idUser === this.userProfile?.idUser
    );
    return !userExists;
  }

  /** =================================================================
   *  MÉTODOS DE EVENTOS / COMUNIDADE
   *  ================================================================ */
  private async fetchEventDetails() {
    try {
      const eventDetails = await firstValueFrom(
        this.eventsService.eventById(this.communityItem.id)
      );

      if (!eventDetails.body?.event) {
        this.toastrService.show(
          'Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
          'error'
        );
        return;
      }

      // Exclui o próprio usuário da lista local de participants
      this.participants = eventDetails.body.participants
        .filter(
          (p: EventParticipant) => p && p.idUser !== this.userProfile?.idUser
        )
        .reverse();
      // this.participants = [...this.participants, ...eventDetails.body.participants];
    } catch (error: any) {
      this.toastrService.show(
        'Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
        'error'
      );
    }
  }

  private async fetchFutureUserEvents() {
    if (!this.userProfile) return;

    try {
      const response = await this.eventsService
        .eventByCompanyId(this.communityItem.id)
        .toPromise();

      if (!response?.body) return;
      const eventsFetched = response.body as SluperEvent[];
      this.nextEvents = eventsFetched.filter(
        (ev: SluperEvent) => new Date(ev.endDate) > new Date()
      );
    } catch (error: any) {
      this.toastrService.show(
        'Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
        'error'
      );
    }
  }

  private async joinCommunity() {
    const eventUrl = this.openedCommunityService.getCommunity();
    try {
      this.loadingState.global = true;

      this.eventsService
        .joinEvent(this.communityItem.id, this.userProfile!.idUser)
        .pipe(
          filter((res: HttpResponse<any>) => res.ok),
          map((res: HttpResponse<any>) => res.body)
        )
        .subscribe({
          next: async () => {
            await this.fetchEventDetails();
            this.loadingState.global = false;
          },
          error: err => {
            console.error('Erro: ', err);
            this.toastrService.show(
              'Ocorreu um erro ao tentar participar da comunidade. Contate o organizador da comunidade.',
              'error'
            );
            this.loadingState.global = false;
            this.backAllCommunities.emit();

            if (eventUrl && eventUrl.length) {
              this.router.navigateByUrl('login/' + eventUrl);
            }
          },
        });
    } catch (error: any) {
      this.loadingState.global = false;
      this.toastrService.show(
        'Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
        'error'
      );

      if (eventUrl && eventUrl.length) {
        this.router.navigateByUrl('login/' + eventUrl);
      }
    }
  }

  async refreshEvent() {
    try {
      const response = await firstValueFrom(
        this.eventsService.eventById(this.communityItem.id)
      );
      if (response.body) {
        this.openedEvent = response.body.event;
        this.communityItem = response.body.event;
      }
    } catch (error) {
      console.error('Erro ao atualizar o evento:', error);
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
      }
    }
  }

  /** =================================================================
   *  MÉTODOS DE POSTS / PAGINAÇÃO
   *  ================================================================ */
  // Chamado ao selecionar canal
  async onSelectChannel(channel: ChannelInterface) {
    this.selectedChannel = channel;
    // Limpa posts e busca a página 0
    this.posts = [];
    this.postsPagination.page = 0;
    this.postsPagination.totalPages = 1;
    // Carrega posts
    await this.fetchPosts(true);
  }

  async fetchPosts(refresh = false) {
    if (!this.selectedChannel) return;

    // Se já está carregando ou não há mais páginas, evita sobrecarga
    if (this.postsPagination.isLoading) return;
    if (
      !refresh &&
      this.postsPagination.page >= this.postsPagination.totalPages
    )
      return;

    // Se for refresh, reinicia parâmetros
    if (refresh) {
      this.postsPagination.page = 0;
      this.posts = [];
      this.postsPagination.totalPages = 1;
    }

    this.loadingState.global = true;
    this.postsPagination.isLoading = true;

    try {
      /** Exemplo usando forkJoin para buscar posts + likes ao mesmo tempo */
      const dataFetch$ = this.postsService.getPostsByChannel({
        page: this.postsPagination.page,
        pageSize: this.postsPagination.pageSize,
        channelId: this.selectedChannel.id,
      });

      const likesFetch$ = this.postsService.getUserLikes(
        this.userProfile!.idUser
      );

      const [postsResp, likesResp] = await firstValueFrom(
        forkJoin([dataFetch$, likesFetch$])
      );

      // Trata posts
      const sortedNewPosts: Post[] = (postsResp.body?.items || []).sort(
        (a: Post, b: Post) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      // Atualiza totalPages
      this.postsPagination.totalPages = postsResp.body?.totalPages || 1;

      // Evita duplicação
      sortedNewPosts.forEach(p => {
        if (!this.posts.find(existing => existing.id === p.id)) {
          p.favorite = false;
          this.posts.push(p);
        }
      });

      // Atualiza page
      this.postsPagination.page++;

      // Trata likes
      this.likes = likesResp.body || [];
      await this.getUserFavoritesPosts();
      this.mergePostsLikes();
    } catch (error) {
      this.toastrService.show(
        'Ocorreu um erro ao carregar os posts. Tente novamente mais tarde.',
        'error'
      );
    } finally {
      this.postsPagination.isLoading = false;
      this.loadingState.global = false;
    }
  }

  // Se preferir buscar o like do post individualmente, pode alterar este método
  private mergePostsLikes() {
    this.posts.forEach(post => {
      const postLikes = this.likes.filter(
        like => like.post?.id === post.id && !like.comment
      );
      post.like = postLikes.length > 0 ? postLikes[0] : undefined;

      post.comments.forEach(comment => {
        const commentLikes = this.likes.filter(
          like => like.comment?.id === comment.id
        );
        comment.like = commentLikes.length > 0 ? commentLikes[0] : undefined;

        if (comment.answers) {
          comment.answers.forEach(answer => {
            const answerLikes = this.likes.filter(
              like => like.comment?.id === answer.id
            );
            answer.like = answerLikes.length > 0 ? answerLikes[0] : undefined;
          });
        }
      });
    });
  }

  // Exemplo de "infinite scroll"
  onParentScroll(event: any) {
    const element = event.target;
    const isAtBottom =
      element.scrollHeight - element.scrollTop <= element.clientHeight + 100;
    if (isAtBottom) {
      this.fetchPosts(false);
    }
  }

  // Quando o <app-post> emite que houve exclusão do post
  deletePost(postId: number) {
    this.posts = this.posts.filter(post => post.id !== postId);
  }

  // Quando o <app-post> emite refresh dos posts
  onRefreshPosts(_: any) {
    this.fetchPosts(true);
  }

  // Quando o <app-post> emite refresh de likes
  onRefreshLikes(_: any) {
    // Opção A: recarrega somente likes
    this.postsService
      .getUserLikes(this.userProfile!.idUser)
      .subscribe(response => {
        this.likes = response.body || [];
        this.mergePostsLikes();
      });
    // Opção B: recarregar posts completamente (se quiser):
    // this.fetchPosts(true);
  }

  // "Scrolla" até um post específico
  scrollToPost(index: number): void {
    const postElement = document.getElementById(`post-${index}`);
    const containerElement = document.getElementById(
      'user-profile-screen-scroll'
    );

    if (postElement && containerElement) {
      const containerRect = containerElement.getBoundingClientRect();
      const postRect = postElement.getBoundingClientRect();
      const offset =
        postRect.top - containerRect.top + containerElement.scrollTop;
      containerElement.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  }

  trackByPostId(index: number, post: Post): number {
    return post.id;
  }

  /** =================================================================
   *  MÉTODOS DE CONEXÃO (Connections)
   *  ================================================================ */
  private async getSentConnections() {
    try {
      const response = await this.eventsService
        .getSentConnectionRequests(this.userProfile!.idUser)
        .pipe(
          filter((res: HttpResponse<Connection[]>) => res.ok),
          map((res: HttpResponse<Connection[]>) => res.body || [])
        )
        .toPromise();

      if (response) {
        this.sentConnections = response;
      }
    } catch (error: any) {
      console.error(error);
    }
  }

  isConnectParticipant(id: string): 'connected' | 'disconnected' | 'pending' {
    const isPending = this.sentConnections.some(
      conn => conn.connection.idUser === id
    );
    if (isPending) return 'pending';

    const isConnected = this.userConnections.some(
      conn => conn.connection?.idUser === id
    );
    return isConnected ? 'connected' : 'disconnected';
  }

  async handleConnect(
    event: Event,
    userId: string,
    connection: IDocumentUserProfileWithPreferences | EventParticipant,
    index: number
  ) {
    event.stopPropagation();
    if (!this.userProfile?.idUser) return;

    try {
      // Exemplo de requisição
      await firstValueFrom(
        this.eventsService.requestConnection({
          participantId: userId,
          userId: this.userProfile.idUser,
          eventId: this.communityItem.id,
        })
      );

      // Atualiza lista de conexões pendentes
      await this.getSentConnections();

      this.toastrService.show(
        'Solicitação de conexão enviada com sucesso!',
        'success'
      );
      // Se necessário, atualizar local:
      // this.userConnections.push(...)
    } catch (error: any) {
      console.error('Erro ao tentar conectar: ', error);
      this.toastrService.show(
        'Ocorreu um erro ao tentar conectar com este usuário.',
        'error'
      );
    }
  }

  async getUserFavoritesPosts(): Promise<void> {
    // Dispara loading
    this.loadingState.global = true;

    return new Promise<void>((resolve, reject) => {
      this.eventsService
        .getFavoritePosts({
          userId: this.userProfile!.idUser,
          communityId: this.communityItem.id,
        })
        .subscribe({
          next: postIds => {
            // Marca favoritos localmente

            this.posts.forEach(post => {
              post.favorite = postIds.includes(post.id);
            });
            this.loadingState.global = false;
            resolve(); // <--- finaliza a Promise com sucesso
          },
          error: error => {
            this.toastrService.show(
              'Ocorreu um erro ao buscar os posts favoritos',
              'error'
            );
            console.error('Erro ao buscar posts favoritos:', error);
            this.loadingState.global = false;
            reject(error); // <--- finaliza a Promise com erro
          },
        });
    });
  }

  async handleRemoveConnection(event: Event, userId: string) {
    event.stopPropagation();

    const modalRef = this.modalService.open(RemoveConnectionModalComponent, {
      fullscreen: false,
      centered: true,
    });
    const participant = this.participants.find(p => p.idUser === userId);

    modalRef.componentInstance.idUser = this.userProfile?.idUser;
    modalRef.componentInstance.idConnection = userId;
    modalRef.componentInstance.nameConnection = participant?.name;

    try {
      const result = await modalRef.result;
      if (result) {
        // Remove local
        this.userConnections = this.userConnections.filter(
          conn => conn.connection.idUser !== userId
        );
        this.sentConnections = this.sentConnections.filter(
          conn => conn.connection.idUser !== userId
        );

        await this.fetchEventDetails();
        this.toastrService.show('Conexão removida com sucesso', 'success');
      }
    } catch (error: any) {
      console.error('Erro ao remover conexão: ', error);
      this.toastrService.show(
        'Ocorreu um erro ao tentar remover a conexão.',
        'error'
      );
    }
  }

  /** =================================================================
   *  MÉTODOS DE CATEGORIAS E CANAIS
   *  ================================================================ */
  private async fetchCategoriesData() {
    try {
      // Carrega categorias e canais em paralelo
      this.loadingState.global = true;

      const [categoriesRes, channelsRes] = await Promise.all([
        firstValueFrom(
          this.categoryChannelService.getCategories(this.communityItem.id)
        ),
        firstValueFrom(
          this.categoryChannelService.getAllChannelsByCommunity(
            this.communityItem.id
          )
        ),
      ]);

      this.communityCategories = categoriesRes.body || [];
      const allChannels = channelsRes.body || [];
      this.channelsWithoutCategory = allChannels.filter(c => !c.category);
    } catch (error: any) {
      this.toastrService.show(
        'Ocorreu um erro ao carregar Categorias/Canais. Tente novamente mais tarde.',
        'error'
      );
    } finally {
      this.loadingState.global = false;
    }
  }

  /** =================================================================
   *  AÇÕES E MODAIS
   *  ================================================================ */
  goBack() {
    this.backAllCommunities.emit();
  }

  // Troca de tela (initial, mentions, saved etc.)
  async onSelectScreen(
    screen:
      | 'initial'
      | 'mentions'
      | 'saved'
      | 'alerts'
      | 'settings'
      | 'finance'
      | 'store'
  ) {
    this.screenCommunity = screen;
    if (screen === 'initial') {
      await this.loadInitialData();
    }
  }

  openModalInputPost() {
    if (!this.showSidebar) return;
    const modalRef = this.modalService.open(ModalPostInputComponent, {
      centered: true,
    });
    modalRef.componentInstance.userProfile = this.userProfile;
    modalRef.componentInstance.communityItem = this.communityItem;
    modalRef.componentInstance.selectedChannel = this.selectedChannel;
    modalRef.componentInstance.participants = this.participants;

    modalRef.result
      .then(async result => {
        if (result && result.result) {
          await this.fetchPosts(true);
          await this.fetchCategoriesData();
        }
      })
      .catch(error => console.error('Modal dismissed with error:', error));
  }

  openModalCommunityRules() {
    const modalRef = this.modalService.open(ModalCommunityRulesComponent, {
      centered: true,
    });
    modalRef.componentInstance.userProfile = this.userProfile;
    modalRef.componentInstance.communityItem = this.communityItem;

    modalRef.result
      .then(result => {
        if (result) {
          this.sendOutputAndGetNewData();
        }
      })
      .catch(error => console.error('Modal dismissed with error:', error));
  }

  openModalSeeCommunityRules() {
    const modalRef = this.modalService.open(ModalShowRulesComponent, {
      centered: true,
    });
    modalRef.componentInstance.communityItem = this.communityItem;
    // Nenhum refresh específico ao fechar
  }

  openModalSeeCommunityBio() {
    const modalRef = this.modalService.open(ModalShowBioComponent, {
      centered: true,
    });
    modalRef.componentInstance.communityItem = this.communityItem;
    // Nenhum refresh específico ao fechar
  }

  openModalCommunityMembers(type: 'add' | 'connect' | 'delete') {
    const dialogRef = this.dialog.open(ModalCommunityMembersComponent, {
      data: {
        participants: this.participants.filter(
          p => p.idUser !== this.userProfile!.idUser
        ),
        userProfile: this.userProfile,
        userProfilePlan: this.userProfilePlan,
        userConnections: this.userConnections,
        type,
        communityItem: this.communityItem,
      },
      height: '80vh',
      width: '80vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.refreshEvent();
        this.refreshData.emit();
        await this.getSentConnections();
      }
    });
  }

  openModalEditCommunity() {
    const modalRef = this.modalService.open(ModalCommunityInfoComponent, {
      centered: true,
    });
    modalRef.componentInstance.userProfile = this.userProfile;
    modalRef.componentInstance.communityItem = this.communityItem;

    modalRef.result
      .then(result => {
        if (result) {
          this.sendOutputAndGetNewData();
        }
      })
      .catch(error => console.error('Modal dismissed with error:', error));
  }

  openModalBackgroundColor() {
    const dialogRef = this.dialog.open(ModalBackgroundColorComponent, {
      data: {
        userProfile: this.userProfile,
        userProfilePlan: this.userProfilePlan,
        userCss: this.userCss,
        communityItem: this.communityItem,
      },
      width: '700px',
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.sendOutputAndGetNewData();
        }
      },
      error => console.error('Erro ao fechar o modal:', error)
    );
  }

  openEventTypeModal() {
    const modalRef = this.modalService.open(EventTypeComponent, {
      centered: true,
    });
    modalRef.result
      .then((res: { status: boolean; response?: 'PRESENCIAL' | 'REMOTO' }) => {
        if (res.status && res.response) {
          this.openCreateEventModal(res.response);
        }
      })
      .catch(error => console.error('Modal dismissed with error:', error));
  }

  openCreateEventModal(type: 'PRESENCIAL' | 'REMOTO', event?: SluperEvent) {
    const modalRef = this.modalService.open(CreateEventModalComponent, {
      centered: true,
      size: 'xl',
    });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.userProfile = this.userProfile;
    modalRef.componentInstance.userConnections = this.userConnections;
    modalRef.componentInstance.communityItem = this.communityItem;
    modalRef.componentInstance.event = event ? event : null;
    modalRef.componentInstance.eventType = 'COMMUNITY-EVENT';
    modalRef.componentInstance.triggerfeedbackMessage.subscribe(
      (alert: any) => {
        this.toastrService.show(alert.message, alert.type);
        if (alert.type === 'success') {
          this.refreshData.emit();
          this.loadInitialData(); // Recarrega dados
        }
      }
    );
  }

  openDeleteEventModal() {
    const modalRef = this.modalService.open(DeleteEventModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.event = this.communityItem;
    modalRef.componentInstance.triggerfeedbackMessage.subscribe(
      (alert: any) => {
        this.toastrService.show(alert.message, alert.type);
        if (alert.type === 'success') {
          localStorage.removeItem('isShowCreateCommunity');
          this.openedCommunityService.clearAllData();
          this.goBack();
          this.sendOutputAndGetNewData();
          this.router.navigateByUrl('/comunidade');
        }
      }
    );
  }

  sendOutputAndGetNewData() {
    this.refreshData.emit();
    setTimeout(() => {
      this.sanitizeBio();
    });
  }

  /** =================================================================
   *  SIDE MENU OPTIONS (Categorias e Canais)
   *  ================================================================ */
  openSideMenuOptionsModal(sideModalResponse: ModalParamsInterface) {
    const dialogRef = this.dialog.open(SideMenuOptionsComponent, {
      data: sideModalResponse,
      width: '270px',
    });

    dialogRef.afterClosed().subscribe(
      async (result: ModalResultInterface) => {
        if (!result) return;

        const newData: ModalDataInterface = {
          ...sideModalResponse,
          ...result,
          allParticipants: this.userConnections,
          communityId: this.communityItem.id,
          categoryItem: sideModalResponse.categoryItem,
          channelItem: sideModalResponse.channelItem,
        };

        if (newData && newData.sidebarOption) {
          switch (newData.sidebarOption) {
            case 'openDeleteCategory':
            case 'openDeleteChannel':
              this.openDeleteSidebarItemModal(newData);
              break;
            case 'openEditCategory':
              this.openCreateCategoryModal(newData);
              break;
            case 'openEditChannel':
              this.openCreateChannelModal(newData);
              break;
            case 'openMoveChannel':
              this.openMoveChannelModal(newData);
              break;
          }
        }
      },
      error => console.error('Erro ao fechar o modal:', error)
    );
  }

  openDeleteSidebarItemModal(data: ModalDataInterface) {
    const dialogRef = this.dialog.open(DeleteSideMenuOptionComponent, {
      data,
      maxWidth: '500px',
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result?.success) {
          this.fetchCategoriesData();
        }
      },
      error => console.error('Erro ao fechar o modal:', error)
    );
  }

  openCreateCategoryModal(data: ModalDataInterface) {
    const dialogRef = this.dialog.open(CreateCategoryModalComponent, {
      data,
      height: '80vh',
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result?.result) {
          this.fetchCategoriesData();
        }
      },
      error => console.error('Erro ao fechar o modal:', error)
    );
  }

  openCreateChannel(event: ModalParamsInterface) {
    const newData: ModalDataInterface = {
      ...event,
      sidebarOption:
        event.modalType === 'channel'
          ? 'openCreateChannel'
          : 'openCreateCategory',
      success: true,
      communityId: this.communityItem.id,
      allParticipants: this.userConnections,
      allCategories: this.communityCategories,
    };

    if (event.modalType === 'channel') {
      this.openCreateChannelModal(newData);
    } else {
      this.openCreateCategoryModal(newData);
    }
  }

  openCreateChannelModal(data: ModalDataInterface) {
    const dialogRef = this.dialog.open(CreateChannelModalComponent, {
      data,
      height: '80vh',
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result?.result) {
          this.fetchCategoriesData();
        }
      },
      error => console.error('Erro ao fechar o modal:', error)
    );
  }

  openMoveChannelModal(data: ModalDataInterface) {
    const dialogRef = this.dialog.open(MoveChannelCategoryComponent, {
      data,
      maxWidth: '500px',
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(
      () => {
        this.fetchCategoriesData();
      },
      error => console.error('Erro ao fechar o modal:', error)
    );
  }

  openCreateChannelCategoryModal() {
    this.modalService.open(CreateChannelCategoryModalComponent, {
      centered: true,
      size: 'xl',
    });
  }

  /** =================================================================
   *  MÉTODOS AUXILIARES
   *  ================================================================ */
  isDeploy(): boolean {
    const currentUrl = window.location.href;
    return currentUrl.includes('perfil.sluper.bio');
  }

  isSluperEvent(event: any): event is SluperEvent {
    return event && 'startDate' in event && 'endDate' in event;
  }

  joinEvent(event: SluperEvent | IEventWithParticipationStatus) {
    // Decide se é SluperEvent ou IEventWithParticipationStatus
    const sluperEvent = this.isSluperEvent(event) ? event : event.event;
    this.handleEventClick(sluperEvent);
  }

  handleEventClick(event: SluperEvent) {
    this.openedEvent = event;
    this.openModalShowEvent();
  }

  openModalShowEvent() {
    const dialogRef = this.dialog.open(EventDetailsComponent, {
      data: {
        authorizedPage: true,
        userProfile: this.userProfile,
        currentUserConnections: this.userConnections,
        preferencesList: this.preferencesList,
        occupationsList: this.occupationsList,
        userProfilePlan: this.userProfilePlan,
        eventId: this.openedEvent!.id,
        documentUser: this.documentUser,
        userProfileComplete: this.userProfileComplete,
      },
      height: '80vh',
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(
      async result => {
        // Se o usuário clicou em "Editar evento", abre o modal de criar evento
        if (typeof result === 'object') {
          this.openCreateEventModal('PRESENCIAL', result);
        }
        // Se retornou true, recarrega lista de eventos
        if (result === true) {
          this.fetchFutureUserEvents();
        }
        this.refreshData.emit();
      },
      error => console.error('Erro ao fechar o modal:', error)
    );
  }

  openParticipantProfile(participant: EventParticipant) {
    window.open(`https://sluper.bio/${participant.idUser}`, '_blank');
  }

  getFormattedDate(date: string): string {
    if (!date) return '';
    const dateObj = new Date(date);
    const day = formatDate(dateObj, 'dd', 'pt-BR');
    const month = formatDate(dateObj, 'MMM', 'pt-BR').replace('.', '');
    const year = formatDate(dateObj, 'yyyy', 'pt-BR');
    return `${day} de ${month}, ${year}`;
  }

  toCapitalCase(text: string): string {
    return text
      .toLocaleLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  truncateTextSemHtml(text: string, maxLength: number = 15): string {
    const textPlain = this.sanitizeTextPipe.transform(text);
    return textPlain.length > maxLength
      ? textPlain.slice(0, maxLength) + '...'
      : textPlain;
  }
}

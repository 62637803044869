<div class="app-community-card" [ngStyle]="{ 'background-image': 'url(' + event?.event?.imageLink + ')' }">

  <h4 class="app-community-card-title multi-line">{{ event?.event?.name }}</h4>

  <div class="community-footer">
    <div class="information">
      <h5 class="made-by">Criado por:</h5>
      <div class="made-by-container">
        <img class="made-by-image"
          [src]="event?.event?.organizer?.uriImageProfile ? event?.event?.organizer?.uriImageProfile : 'assets/img/default_avatar_1.png'"
          alt="profile-pic" />
        <span class="made-by-name pointer" (click)="openParticipantProfile($event,  event?.event?.organizer!.idUser)">{{
          event?.event?.organizer?.name || 'Sem nome' }}</span>
      </div>
    </div>
    <button (click)="joinEvent()" [ngClass]="{
      'app-community-card-button join': event?.participationStatus === 'NAO_PARTICIPANDO',
      'app-community-card-button joining': event?.participationStatus === 'PARTICIPANDO',
      'app-community-card-button waiting': event?.participationStatus === 'PENDENTE_APROVACAO'
    }">
      {{
      event?.participationStatus === 'PARTICIPANDO' ? 'Participando' :
      event?.participationStatus === 'PENDENTE_APROVACAO' ? 'Aguardando Aprovação' :
      'Participar'
      }}
    </button>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OpenedCommunityService } from '../opened-community.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    private authService: AuthService,
    private router: Router,
    private openedCommunityService: OpenedCommunityService
  ) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const communityId = this.openedCommunityService.getCommunity();

    if (this.authService.isAuthenticated()) {
      const isPrivate = !!communityId; // Se há um ID salvo, é privado
      const currentUrl = this.router.url;

      if (isPrivate) {
        // Redireciona para rotas privadas se necessário
        if (!currentUrl.includes(communityId)) {
          return this.router.createUrlTree([`/login/${communityId}`]);
        }
      } else {
        // Permite rotas comuns
        if (currentUrl.includes('/login/') || currentUrl.includes('/comunidade/')) {
          return this.router.createUrlTree(['/login']);
        }
      }

      return true;
    }

    // Redireciona não autenticados para o login comum ou privado
    return this.handleRedirect();
  }



  /**
   * Lógica para redirecionar o usuário com base no estado da comunidade ativa.
   */
  private handleRedirect(): UrlTree {
    const communityId = this.openedCommunityService.getCommunity();

    if (communityId) {
      // Redireciona para as rotas com o parâmetro
      return this.router.createUrlTree([`/login/${communityId}`]);
    } else {
      // Redireciona para o login padrão sem parâmetros
      return this.router.createUrlTree(['/login']);
    }
  }


}

import { Component } from '@angular/core';

@Component({
  selector: 'app-user-company-advertisement',
  templateUrl: './user-company-advertisement.component.html',
  styleUrls: ['./user-company-advertisement.component.css']
})
export class UserCompanyAdvertisementComponent {

}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { CategoryChannelService, CategoryInterface, ModalDataInterface } from '../category-channel.service';
@Component({
  selector: 'app-move-channel-category',
  templateUrl: './move-channel-category.component.html',
  styleUrls: ['./move-channel-category.component.css']
})
export class MoveChannelCategoryComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  public modalType: string = 'category';
  public categories: CategoryInterface[] = []
  public selectedCategory: CategoryInterface | null = null;
  public loading = false
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalDataInterface,
    private dialogRef: MatDialogRef<MoveChannelCategoryComponent>,
    private categoryChannelService: CategoryChannelService,
    private toastrService: ToastService

  ) {
    this.modalType = this.data.modalType
    this.categories = this.data.allCategories.filter((category: CategoryInterface) => category.id.toString() !== this.data.channelItem!.category?.toString())
  }

  ngOnInit(): void {
  }
  selectCategory(item: CategoryInterface) {
    this.selectedCategory = item;
  }

  async moveChannel() {

    if (!this.selectedCategory) {
      this.toastrService.show('Selecione uma categoria para continuar.', 'error');

      return;
    }

    try {
      this.loading = true
      const data: { idChannel: string, idCategory: string } = {
        idChannel: this.data.channelItem!.id,
        idCategory: this.selectedCategory.id
      }

      const resp = await firstValueFrom(
        this.categoryChannelService.moveChannel(data)
      );

      this.toastrService.show('Canal movido com sucesso.', 'success');

      setTimeout(() => {
        this.closeModal(true, 'Atualizado com sucesso!');
        this.loading = false
      }, 2000);

    } catch (error) {
      this.loading = false
      this.toastrService.show('Erro ao mover o canal.', 'error');
    }
  }

  closeModal(result: boolean, message: string) {
    const data = {
      result: result,
      message: message,
    };
    this.dialogRef.close(data);
  }


}

import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';

@Component({
  selector: 'app-manager-company-advertisement',
  templateUrl: './manager-company-advertisement.component.html',
  styleUrls: ['./manager-company-advertisement.component.css']
})
export class ManagerCompanyAdvertisementComponent implements OnInit {
  @Output() closeCompanyAdvertisement = new EventEmitter();
  @Output() submittedCompanyAdvertisement = new EventEmitter();
  @ViewChild('imageSelectionModal') imageSelectionModal: any;
  public minDate: string;
  public toggleConfig = {
    value: true,
    name: '',
    disabled: false,
    height: 35,
    width: 90,
    margin: 0,
    fontSize: 15,
    speed: 300,
    color: {
      checked: '#F6F6F6',
      unchecked: '#F6F6F6',
    },
    switchColor: {
      checked: '#7B7B7B',
      unchecked: '#7B7B7B',
    },
    labels: {
      unchecked: 'Sim',
      checked: 'Não',
    },
    checkedLabel: '',
    uncheckedLabel: '',
    fontColor: {
      checked: '#7B7B7B',
      unchecked: '#7B7B7B',
    },
    textAlign: 'center',
  };

  advertisementForm: FormGroup;

  public imageItems: {
    image: string, imageEvent: string | null, imageBlob: File | null,
    temporary: string, temporaryEvent: string | null, temporaryBlob: File | null,
  } = {
      image: '',
      imageEvent: '',
      imageBlob: null,
      temporary: '',
      temporaryEvent: '',
      temporaryBlob: null
    };

  imageConfig: { isSelectionImageModalOpen: boolean, cropMode: boolean, imageUpdated: boolean } = {
    isSelectionImageModalOpen: false,
    cropMode: false,
    imageUpdated: false,
  };

  public logoConfig = {
    showImage: true,
    showTooltip: false,
  };

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastService,
    private modalService: NgbModal,
  ) {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    this.minDate = `${year}-${month}-${day}`;
    this.advertisementForm = this.fb.group({
      banner: [null, Validators.required],
      companyName: ['', Validators.required],
      description: ['', [Validators.required, Validators.minLength(10)]],
      contact: ['', [Validators.required, this.contactValidator]],
      startDate: [null, [Validators.required, this.startDateValidator]],
      endDate: [null],
      startTime: [null, Validators.required],
      endTime: [null],
      isFeatured: [this.toggleConfig.value],
    });
  }

  ngOnInit(): void {
    const startDateControl = this.advertisementForm.get('startDate');
    const endDateControl = this.advertisementForm.get('endDate');
    const startTimeControl = this.advertisementForm.get('startTime');
    const endTimeControl = this.advertisementForm.get('endTime');

    this.advertisementForm.get('isFeatured')?.valueChanges.subscribe((value) => {
      this.toggleConfig.value = value;
    });

    // Observa mudanças na data final
    endDateControl?.valueChanges.subscribe((endDate) => {
      if (!endDate) {
        endTimeControl?.disable(); // Desativa a hora final
        endTimeControl?.setValue(null);
      } else {
        endTimeControl?.enable(); // Reativa a hora final
      }
    });

    // Validações Dinâmicas para Data e Hora
    this.advertisementForm.valueChanges.subscribe(() => {
      const startDate = startDateControl?.value;
      const startTime = startTimeControl?.value;
      const endDate = endDateControl?.value;
      const endTime = endTimeControl?.value;

      // Validações da Data Inicial
      if (startDate) {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Apenas data
        const startDateObj = new Date(startDate);

        if (startDateObj < today) {
          startDateControl?.setErrors({ invalidStartDate: true });
        } else {
          startDateControl?.setErrors(null);
        }
      }

      // Validações de Hora e Relacionamento Data/Hora
      if (startDate && startTime) {
        const startDateTime = new Date(`${startDate}T${startTime}`);

        // Hora inicial não pode ser no passado
        if (startDateTime < new Date()) {
          startTimeControl?.setErrors({ invalidStartTime: true });
        } else {
          startTimeControl?.setErrors(null);
        }
      }

      if (startDate && startTime && endDate && endTime) {
        const startDateTime = new Date(`${startDate}T${startTime}`);
        const endDateTime = new Date(`${endDate}T${endTime}`);

        // Hora final não pode ser antes da inicial
        if (endDateTime < startDateTime) {
          endTimeControl?.setErrors({ invalidEndTime: true });
        } else {
          endTimeControl?.setErrors(null);
        }
      }
    });
  }


  contactValidator(control: FormControl): ValidationErrors | null {
    const value = control.value || '';
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^\+?\d{10,15}$/;

    if (emailRegex.test(value) || phoneRegex.test(value)) {
      return null; // Válido
    }

    return { invalidContact: true }; // Inválido
  }

  startDateValidator(control: FormControl): ValidationErrors | null {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Zera o horário para considerar apenas a data
    const inputDate = new Date(control.value);

    if (control.value && inputDate < today) {
      return { invalidStartDate: true };
    }

    return null;
  }

  handleCloseCompanyAdvertisement(): void {
    this.closeCompanyAdvertisement.emit();
  }

  submitForm(): void {
    if (this.advertisementForm.invalid) {
      this.advertisementForm.markAllAsTouched();
      return;
    }
    this.submittedCompanyAdvertisement.emit();
  }

  changeEndTime(event: Event): void {
    if (!this.advertisementForm.get('endDate')?.value) {
      this.advertisementForm.get('endTime')?.setValue(null);
      this.toastrService.show('Por favor, selecione uma data final antes de definir uma hora final.', 'warning');
    }
  }

  openImageSelectionModal(): void {
    this.imageConfig.isSelectionImageModalOpen = true;
    this.imageConfig.cropMode = false;
    this.imageItems.imageEvent = null;

    const modalRef = this.modalService.open(this.imageSelectionModal, {
      size: 'lg',
      centered: true,
    });

    modalRef.result
      .then(() => {
        this.imageConfig.isSelectionImageModalOpen = false;
      })
      .catch(() => {
        this.imageConfig.isSelectionImageModalOpen = false;
      });
  }

  fileChangeEventImage(event: any, minWidth: number, minHeight: number): void {
    const file: File = event.target.files[0];
    if (!file) {
      console.error('Nenhum arquivo selecionado');
      return;
    }

    const maxSizeInBytes = 2 * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
      this.toastrService.show('Tamanho do arquivo é maior do que o permitido. Por favor, selecione uma imagem de até 2MB.', 'warning');
      this.removeSelectedImage();
      this.modalService.dismissAll();
      console.error('Tamanho do arquivo é maior do que o permitido. Por favor, selecione uma imagem de até 2MB.');

      return;
    }

    const reader = new FileReader();

    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result as string;

      image.onload = () => {
        this.imageConfig.cropMode = true;
        this.imageItems.temporaryEvent = event;
        this.imageConfig.imageUpdated = true;
      };
    };

    reader.readAsDataURL(file);
  }

  imageCropped(event: ImageCroppedEvent): void {
    const blob = event.blob;
    if (blob && blob.size) {
      const reader = new FileReader();

      reader.onload = () => {
        this.imageItems.temporary = reader.result as string;
      };

      reader.readAsDataURL(blob);
    }
  }

  dataURItoBlob(dataURI: string, mimeType: string = 'image/png'): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeType });
  }

  confirmImageSelection(): void {
    this.imageItems.image = this.imageItems.temporary;

    const croppedImage = this.imageItems.image;

    if (!croppedImage || !this.imageConfig.imageUpdated) {
      return;
    }

    try {
      const currentTime = Date.now();
      const fileName = `${'image'}${currentTime}.png`;
      const blob = this.dataURItoBlob(croppedImage, 'image/png');
      const blobFile = new File([blob], fileName, {
        type: 'image/png',
        lastModified: currentTime,
      });
      this.imageItems.imageBlob = blobFile;
      this.advertisementForm.get('banner')?.setValue(blobFile);
      this.modalService.dismissAll();
    } catch (e) {
      this.toastrService.show('Ocorreu um erro ao confirmar a seleção de imagem, tente novamente mais tarde.', 'error');
    }
  }

  removeSelectedImage(): void {
    this.imageConfig.cropMode = false;
    this.imageItems.image = '';
    this.imageItems.imageEvent = null;
    this.imageConfig.imageUpdated = false;
  }
}

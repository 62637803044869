<app-loading-spinner [customBg]="'transparent'" *ngIf="loading"></app-loading-spinner>

<div class="modal-container">
  <div class="modal-header">
    <h2>Criar Perfil de membros</h2>
  </div>

  <div class="modal-body">
    <div class="input-item">
      <input type="text" placeholder="Digite aqui o nome do perfil de membros" [formControl]="categoryName"
        class="category-name-input" />
      <span class="error-message" *ngIf="categoryName.hasError('required') && categoryName.touched">
        Por favor, insira um nome para a categoria.
      </span>
    </div>

    <div class="input-item">
      <input type="number" placeholder="Quantidade de membros" [formControl]="membersQuantity"
        class="category-quantity-input" />
      <span class="error-message" *ngIf="membersQuantity.hasError('required') && membersQuantity.touched">
        Por favor, insira a quantidade de membros.
      </span>
    </div>
    <span class="error-message" *ngIf="membersQuantity.hasError('min') && membersQuantity.touched">
      A quantidade mínima de membros é 1.
    </span>

    <div *ngIf="selectedMembersToAdd.length === 0">
      <div class="add-members-container" (click)="openAddMembersModal()">
        <i class="btn bi bi-plus-square-fill category-icon"></i>
        <span class="category-span">Adicionar Membros</span>
      </div>
    </div>

    <div *ngIf="selectedMembersToAdd.length > 0">
      <div class="add-more-members-container">
        <span class="members-added__span">
          Membros adicionados
        </span>

        <div class="add-members-container" (click)="openAddMembersModal()">
          <i class="btn bi bi-plus-square-fill category-icon"></i>
          <span class="category-span">Adicionar mais membros</span>
        </div>
      </div>

      <div class="people-container">
        <div class="connection-request-card" *ngFor="let member of selectedMembersToAdd">
          <img *ngIf="member.uriImageProfile && member.uriImageProfile !== ''" [src]="member.uriImageProfile"
            alt="Foto do usuário" />
          <img *ngIf="!member.uriImageProfile || !member.uriImageProfile.length" src="/assets/img/imagem_perfil.png"
            alt="Foto padrão" />
          <div class="request-info">
            <div class="info">
              <span class="request-name">
                {{ member.name }}
              </span>
              <span class="request-profession multi-line" [innerHtml]="member.presentation"> </span>
            </div>
          </div>
          <i class="btn bi bi-dash-square-fill quantity-people-button" (click)="removeMember(member)"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="create-category-member-divider"></div>

  <div class="modal-actions">
    <button class="cancel-button" (click)="closeModal()">
      <span class="cancel-span">Cancelar</span>
    </button>
    <button class="create-button" (click)="createMembersCategory()">
      <span class="create-span"> Criar </span>
    </button>
  </div>
</div>
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { Connection } from 'src/app/model/connection.model';
import { IHeader } from 'src/app/model/header.model';
import { INetworkingControl } from 'src/app/model/networking-control.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { CategoryChannelService, ChannelInterface, ChannelParticipantsInterface, CreateChannelData, ModalDataInterface } from '../category-channel.service';
import { CommunitieParticipantsModalComponent } from '../communitie-participants-modal/communitie-participants-modal.component';
import { CreateChannelModalComponent } from '../create-channel-modal/create-channel-modal.component';

@Component({
  selector: 'app-create-category-modal',
  templateUrl: './create-category-modal.component.html',
  styleUrls: ['./create-category-modal.component.css']
})
export class CreateCategoryModalComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  public categoryName: FormControl = new FormControl('', Validators.required);
  public modalType: string = 'create';
  public loading = false
  public channels: ChannelInterface[] = [];
  public newChannels: ChannelInterface[] = [];
  public channelAllParticipants: Connection[] = [];
  public channelParticipants: Connection[] = [];
  public newChannelParticipants: Connection[] = [];
  public showMore: boolean = false;
  public maxVisibleParticipants: number = 0;
  public visibleParticipantsCount: number = 2;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalDataInterface,
    private dialogRef: MatDialogRef<CreateCategoryModalComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private categoryChannelService: CategoryChannelService,
    private toastrService: ToastService
  ) {
    this.modalType = data.sidebarOption === 'openEditCategory' ? 'edit' : 'create';
  }

  async ngOnInit(): Promise<void> {
    if (this.modalType === 'edit' && this.data.categoryItem) {
      this.categoryName.setValue(this.data.categoryItem.name);
      this.channels = this.data.categoryItem.channels
      await this.listCategoryParticipants(this.data.categoryItem.id.toString());
    }
  }


  async listCategoryParticipants(categoryId: string) {
    try {
      const data = await firstValueFrom(
        this.categoryChannelService.getCategoryUsers(categoryId)
      );
      const allUsers = data.body
        ? data.body.map((item: ChannelParticipantsInterface) => {
          return {
            connectionDate: new Date().toISOString(),
            event: {
              id: '0',
              name: '',
            },
            connection: {
              idUser: item.userId || '',
              name: item.userProfile?.name || 'Unknown',
              bio: '',
              showImageProfile: false,
              publish: false,
              parent: '',
              listURI: [],
              uriImageProfile: item.userProfile?.uriImageProfile || '',
              uriImageBackground: '',
              listSocialNetwork: [],
              listButtons: [],
              listUriImages: [],
              header: {} as IHeader,
              listContacts: [],
              networkingControl: {} as INetworkingControl,
              listProfile: [],
            },
            preferences: null,
            indication: null,
            indicatorName: null,
          };
        })
        : [];

      this.channelAllParticipants = [...allUsers]
      this.channelParticipants = [...allUsers];
    } catch (error: any) {
      this.toastrService.show(
        'Ocorreu um erro ao tentar carregar os participantes do canal. Por favor, tente novamente.',
        'error'
      )
    }
  }
  async saveCategory() {
    this.categoryName.markAllAsTouched();

    if (this.categoryName.invalid) {
      console.error('O formulário está inválido.');
      return;
    }
    try {
      this.loading = true
      const data: { name: string, communityId: string } = {
        name: this.categoryName.value,
        communityId: this.data.communityId
      }

      const resp = this.modalType === 'edit' ?
        await firstValueFrom(
          this.categoryChannelService.editCategory(data, this.data.categoryItem!.id.toString())
        )
        : await firstValueFrom(
          this.categoryChannelService.createCategory(data)
        );


      if (this.newChannels.length > 0 && resp.body && resp.body.id) {
        await this.saveChannel(resp.body.id)
      }

      if (this.newChannelParticipants.length > 0 && resp.body && resp.body.id) {
        await this.addUserChannel(resp.body.id.toString())
      }

      await this.rmUserChannel(resp.body!.id.toString());

      this.toastrService.show(
        this.modalType === 'edit' ? 'Categoria editada com sucesso.' : 'Categoria criada com sucesso.',
        'success'
      )
      setTimeout(() => {
        this.closeModal(true, 'Atualizado com sucesso!');
        this.loading = false
      }, 2000);

    } catch (error) {
      this.loading = false
      this.toastrService.show(
        this.modalType === 'edit' ? 'Erro ao editar categoria.' : 'Erro ao criar categoria.',
        'error'
      )
    }
  }

  async saveChannel(categoryId: number) {
    this.categoryName.markAllAsTouched();

    if (this.categoryName.invalid) {
      console.error('O formulário está inválido.');
      return;
    }

    try {
      for (const [index, channel] of this.newChannels.entries()) {
        const data: CreateChannelData = {
          name: channel.name,
          community: this.data.communityId,
          category: categoryId,
          icon: channel.icon,
        };

        const resp = await firstValueFrom(
          this.categoryChannelService.createChannel(data)
        );

        // if (channel.users.length > 0) {
        //   await this.addUserChannel(resp.body!.id.toString(), index);
        // }
      }
    } catch (error) {
      console.error('Erro ao criar um dos canais:', error);
      const errorMessage = this.modalType === 'edit'
        ? 'Erro ao editar categoria.'
        : 'Erro ao criar categoria.';
      this.toastrService.show(
        errorMessage,
        'error'
      )
      throw error;
    }
  }

  async addUserChannel(categoryId: string) {
    try {
      const participants = this.newChannelParticipants

      const data: { userId: string; categoryId: number }[] = participants.map(participant => ({
        userId: participant.connection.idUser,
        categoryId: parseInt(categoryId),
      }));

      const resp = await firstValueFrom(
        this.categoryChannelService.addUserCategory(data)
      );

    } catch (error) {
      console.error('Erro ao adicionar participantes ao canal:', error);
      throw error; // Repassa o erro para ser tratado em outro nível
    }
  }

  async rmUserChannel(categoryId: string) {
    try {
      const participantsRemoved = this.channelAllParticipants.filter(
        (participant: Connection) =>
          !this.channelParticipants.some(
            (remaining: Connection) =>
              remaining.connection.idUser === participant.connection.idUser
          )
      );

      for (const element of participantsRemoved) {
        const data = {
          userId: element.connection.idUser,
          categoryId: parseInt(categoryId),
        };

        const resp = await firstValueFrom(
          this.categoryChannelService.rmUserCategory(data)
        );
      }
    } catch (error) {
      console.error('Erro ao remover participantes do canal:', error);
      throw error;
    }
  }



  rmChannel(channel: any) {
    this.newChannels = this.newChannels.filter((c: any) => c.id != channel);
  }
  closeModal(result: boolean, message: string) {
    const data = {
      result: result,
      message: message,
    };
    this.dialogRef.close(data);
  }

  openCreateChannelModal() {
    this.data.openedFrom = 'createCategory'
    const dialogRef = this.dialog.open(CreateChannelModalComponent, {
      data: this.data,
      height: '80vh',
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(
      async (result: { result: boolean, message: string, channel?: any }) => {
        if (result && result.result && result.channel) {
          const newChannel: ChannelInterface = {
            id: this.generateUniqueChannelId(),
            count: 0,
            icon: result.channel.emoji,
            name: result.channel.channelName,
            users: result.channel.participants,
            category: this.data.categoryItem ? parseInt(this.data.categoryItem.id) : 0,
            community: this.data.communityId || '0',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          };
          this.newChannels.push(newChannel);
        }

      },
      error => {
        console.error('Erro ao fechar o modal:', error);
      }
    );
  }

  openModalAddMembers() {
    const dialogRef = this.dialog.open(CommunitieParticipantsModalComponent, {
      data: { data: this.data, channelParticipants: this.channelParticipants },
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(
      (result: { result: boolean, participants?: Connection[] }) => {
        if (result && result.result && result.participants) {
          this.channelParticipants = [...this.channelParticipants, ...result.participants!]
          this.newChannelParticipants = [...this.newChannelParticipants, ...result.participants!]
          this.visibleParticipantsCount = this.channelParticipants.length;

          if (this.visibleParticipantsCount === 2) {
            this.visibleParticipantsCount = this.channelParticipants.length;
          } else {
            this.visibleParticipantsCount = 2;
          }
        }
      },
      (error) => {
        console.error('Error ', error);
      }
    )
  }

  rmCommunityParticipants(person: any) {
    this.channelParticipants.splice(this.channelParticipants.indexOf(person), 1);
  }

  toggleShowMore(event: Event) {
    event.preventDefault();

    if (this.visibleParticipantsCount === 2) {
      this.visibleParticipantsCount = this.channelParticipants.length;
    } else {
      this.visibleParticipantsCount = 2;
    }
  }


  generateUniqueChannelId() {
    let newId: number;
    do {
      newId = Math.floor(Math.random() * 1000000);
    } while (this.channels.some(channel => channel.id.toString() === newId.toString()));
    return newId.toString();
  }
}

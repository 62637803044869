import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { EventParticipant, SluperEvent } from 'src/app/model/event.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { DocumentUserProfile } from '../../../../model/document-user-profile.model';
import {
  AddMembersModalComponent,
  SelectableEventParticipant,
} from '../add-members-modal/add-members-modal.component';
import { MembersCategoriesService } from '../members-settings/members-categories/members-categories-service/members-categories.service';

@Component({
  selector: 'app-create-category-members-modal',
  templateUrl: './create-category-members-modal.component.html',
  styleUrls: ['./create-category-members-modal.component.css'],
})
export class CreateCategoryMembersModalComponent {
  public categoryName = new FormControl('', Validators.required);
  public membersQuantity = new FormControl('10', [
    Validators.required,
    Validators.min(1),
  ]);
  public selectedMembersToAdd: SelectableEventParticipant[] = [];
  public loading: boolean = false;
  public communityItem: SluperEvent = {} as SluperEvent;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      members: EventParticipant[];
      communityItem: SluperEvent;
      userProfile: DocumentUserProfile | undefined;
    },
    private readonly dialogRef: MatDialogRef<CreateCategoryMembersModalComponent>,
    private readonly dialog: MatDialog,
    private readonly toastrService: ToastService,
    private readonly membersCategoriesService: MembersCategoriesService
  ) {
    this.communityItem = this.data.communityItem;
  }

  openAddMembersModal() {
    const dialogRef = this.dialog.open(AddMembersModalComponent, {
      width: '80vw',
      data: {
        allMembers: this.data.members.filter(
          (member: EventParticipant) =>
            member.idUser !== this.data.userProfile?.idUser
        ),
        categoryParticipants: [...this.selectedMembersToAdd],
        communityId: this.communityItem.id,
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const selectedMembersToAdd = [
          ...this.selectedMembersToAdd,
          ...result.filter(
            (newMember: SelectableEventParticipant) =>
              !this.selectedMembersToAdd.some(
                existingMember => existingMember.idUser === newMember.idUser
              )
          ),
        ];

        if (+selectedMembersToAdd.length > +this.membersQuantity.value!) {
          this.toastrService.show('Quantidade de membros excedida', 'warning');
          this.membersQuantity.setValue(selectedMembersToAdd.length.toString());
        }
        this.selectedMembersToAdd = selectedMembersToAdd;
      }
    });
  }

  removeMember(member: SelectableEventParticipant) {
    this.selectedMembersToAdd = this.selectedMembersToAdd.filter(
      selectedMember => selectedMember.idUser !== member.idUser
    );
  }

  async createMembersCategory() {
    this.categoryName.markAsTouched();
    this.membersQuantity.markAsTouched();

    if (this.categoryName.invalid || this.membersQuantity.invalid) return;

    try {
      this.loading = true;

      const data = {
        name: this.categoryName.value ?? '',
        quantity: this.membersQuantity.value ?? '',
        members: this.selectedMembersToAdd.map(member => member.idUser),
        communityId: this.communityItem.id,
        description: '',
      };

      const resp = await firstValueFrom(
        this.membersCategoriesService.createCategoryMembers(data)
      );
      this.toastrService.show(
        'Perfil de membros criado com sucesso',
        'success'
      );
      setTimeout(() => {
        this.loading = false;
        this.closeModal(true, resp);
      }, 2000);
    } catch (error) {
      const err = error as HttpErrorResponse;
      this.loading = false;
      if (
        err.error.message ===
        "Já existe uma categoria ativa com esse nome."
      ) {
        this.toastrService.show(
          'Já existe um perfil de membros ativo com esse nome.',
          'error'
        );
      } else {
        this.toastrService.show('Erro ao criar perfil de membros', 'danger');
      }
    }
  }

  closeModal(success: boolean = false, data?: any): void {
    this.dialogRef.close({ success, data });
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SanitizeTextPipe } from 'src/app/sanitizeText/sanitize-text.pipe';
import { Post } from '../../post/posts.service';

@Component({
  selector: 'app-people-liked-post-modal',
  templateUrl: './people-liked-post-modal.component.html',
  styleUrls: ['./people-liked-post-modal.component.css']
})
export class PeopleLikedPostModalComponent {
  public post: Post = {} as Post
  constructor(
    public dialogRef: MatDialogRef<PeopleLikedPostModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { post: Post },
    private sanitizeTextPipe: SanitizeTextPipe

  ) {
    this.post = this.data.post
  }


  closeModal() {
    this.dialogRef.close({ success: true });
  }

  async openParticipantProfile(id: string) {
    window.open(`https://sluper.bio/${id}`);
  }

  truncateTextSemHtml(text: string | null): string {
    const textPlain = this.sanitizeTextPipe.transform(text || '');
    return textPlain || 'Sem apresentação';
  }
}

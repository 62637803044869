import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-codigo-verificacao',
  templateUrl: './codigo-verificacao.component.html',
  styleUrls: ['./codigo-verificacao.component.css']
})
export class CodigoVerificacaoComponent {
  @ViewChild('codigo1') codigo1!: ElementRef<HTMLInputElement>;
  @ViewChild('codigo2') codigo2!: ElementRef<HTMLInputElement>;
  @ViewChild('codigo3') codigo3!: ElementRef<HTMLInputElement>;
  @ViewChild('codigo4') codigo4!: ElementRef<HTMLInputElement>;
@Input() isJustifyContentEvenly = false
  inputs: HTMLInputElement[] = [];

  ngAfterViewInit() {
    this.inputs = [this.codigo1.nativeElement, this.codigo2.nativeElement, this.codigo3.nativeElement, this.codigo4.nativeElement];
  }

  onInput(event: Event, nextInput: HTMLInputElement | null): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length === 1 && nextInput) {
      nextInput.focus();
    }
  }

  onKeydown(event: KeyboardEvent, prevInput: HTMLInputElement | null): void {
    if (event.key === 'Backspace' && !(event.target as HTMLInputElement).value && prevInput) {
      prevInput.focus();
    }
  }

  onPaste(event: ClipboardEvent, nextInput: HTMLInputElement | null): void {
    event.preventDefault();
    const pasteData = (event.clipboardData || (window as any).clipboardData).getData('text');
    pasteData.split('').forEach((char: string, index: number) => {
      if (this.inputs[index]) {
        this.inputs[index].value = char;
      }
    });

    // Focus no último campo preenchido
    const lastInput = this.inputs[Math.min(pasteData.length, this.inputs.length) - 1];
    lastInput.focus();
  }

  getCodigoVerificacao(): string {
    return this.inputs.map(input => input.value).join('');
  }
}

<div class="profile-pic-container">
    <img *ngIf="profileImage" [src]="profileImage" alt="Foto de Perfil" class="rounded-circle"
        (click)="toggleChangeProfile()">

    <div *ngIf="showChangeOptions" class="change-profile-options position-absolute top-50 start-50 translate-middle">
        <button class="btn btn-sm mb-2" (click)="onChangeProfilePicture()">
            <i class="bi bi-camera-fill icon-camera"></i>
            <span class="text-normal-16-700">Adicionar Foto</span>
            <p>Largura máxima 720px e tamanho máximo 2MB.</p>
        </button>
    </div>
</div>
import { HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { AppNavigateService } from 'src/app/app-navigate.service';
import { LoadingService } from '../../core/service/loading.service';
import { ToastService } from '../../core/service/toast.service';
import { LoginService } from '../login/login.service';
import { CadastroStorageService } from './cadastro-storage.service';
import { ConfiguracaoCadastroStorageService } from './configuracao-cadastro/configuracao-cadastro-storage.service';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.css']
})
export class CadastroComponent {
  email = new FormControl('', [Validators.required, Validators.email]);
  username = new FormControl('', [Validators.required]);

  constructor(
    private appNavigateService: AppNavigateService,
    private loginService: LoginService,
    private cadastroStorageService: CadastroStorageService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private configCadastroStorage: ConfiguracaoCadastroStorageService,
    private router: Router,

  ) { }

  cadastrar() {
    if (this.email.valid && this.username.valid) {
      this.loadingService.show();
      this.cadastroStorageService.username = String(this.username.value);
      this.cadastroStorageService.email = String(this.email.value);

      const license = localStorage.getItem('license');
      if (license && license.length) {
        this.router.navigate(['/chave-ativacao/' + license]);
        this.loadingService.hide();
        return
      }

      this.loginService.generateLicense(this.email.value)
        .pipe(
          filter((mayBeOk: HttpResponse<any>) => mayBeOk.ok),
          map((response: HttpResponse<any>) => response.body)
        )
        .subscribe({
          next: () => {
            this.configCadastroStorage.resetNewRegister();
            this.loadingService.hide();
            this.toastService.showInfo("Foi enviado um link e um código de verificação para o seu email.")
            this.appNavigateService.navegarEnvioEmailCriacaoConta();
          },
          error: (err) => {
            this.loadingService.hide();
            this.toastService.showError("Erro ao gerar o código. Tente novamente.")
          }
        });
    } else {
      this.toastService.showWarning("Por favor, preencha todos os campos corretamente")
    }
  }

}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { CategoryChannelService, ModalDataInterface } from '../category-channel.service';
@Component({
  selector: 'app-delete-side-menu-option',
  templateUrl: './delete-side-menu-option.component.html',
  styleUrls: ['./delete-side-menu-option.component.css']
})
export class DeleteSideMenuOptionComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;
  public modalType: string = 'category';
  public loading = false
  count = 10
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalDataInterface,
    private dialogRef: MatDialogRef<DeleteSideMenuOptionComponent>,
    private categoryChannelService: CategoryChannelService,
    private toastrService: ToastService

  ) {
    this.modalType = this.data.modalType
  }

  async ngOnInit() {

  }

  closeModal(): void {
    const returnData = { success: true, data: null };
    this.dialogRef.close(returnData);
  }

  async deleteOption() {
    try {
      this.loading = true

      const resp = this.modalType === 'category' ?
        await firstValueFrom(
          this.categoryChannelService.deleteCategory(this.data.categoryItem!.id.toString())
        )
        : await firstValueFrom(
          this.categoryChannelService.deleteChannel(this.data.channelItem!.id.toString())
        )
      this.toastrService.show(this.modalType === 'category' ? 'Categoria deletada com sucesso.' : 'Canal deletado com sucesso.', 'success')
      setTimeout(() => {
        this.closeModal();
        this.loading = false
      }, 2000);

    } catch (error) {
      this.loading = false
      this.toastrService.show(this.modalType === 'category' ? 'Erro ao remover categoria.' : 'Erro ao remover canal.', 'error')

    }

  }
}

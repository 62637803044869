import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomCommunityPhotosService {
  private logoSubject: BehaviorSubject<string | null>;
  private backgroundSubject: BehaviorSubject<string | null>;

  constructor() {
    // Inicializar com valores do localStorage (se disponíveis)
    const storedLogo = localStorage.getItem('communityLogo');
    const storedBackground = localStorage.getItem('communityBackground');
    this.logoSubject = new BehaviorSubject<string | null>(storedLogo);
    this.backgroundSubject = new BehaviorSubject<string | null>(storedBackground);
  }

  // Observables para monitorar alterações
  get logo$(): Observable<string | null> {
    return this.logoSubject.asObservable();
  }

  get background$(): Observable<string | null> {
    return this.backgroundSubject.asObservable();
  }

  // Métodos para definir os valores e notificá-los
  setLogo(logo: string): void {
    this.logoSubject.next(logo);
    localStorage.setItem('communityLogo', logo);
  }

  setBackground(background: string): void {
    this.backgroundSubject.next(background);
    localStorage.setItem('communityBackground', background);
  }

  // Métodos para recuperar os valores atuais
  getLogo(): string | null {
    return this.logoSubject.value || localStorage.getItem('communityLogo');
  }

  getBackground(): string | null {
    return this.backgroundSubject.value || localStorage.getItem('communityBackground');
  }

  // Limpar dados
  clear(): void {
    this.logoSubject.next(null);
    this.backgroundSubject.next(null);
    localStorage.removeItem('communityLogo');
    localStorage.removeItem('communityBackground');
  }
}

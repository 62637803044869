import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastr: ToastrService) {
    // Configuração global para a posição
    this.toastr.toastrConfig.positionClass = 'toast-top-center';
  }

  showSuccess(message: string, title: string = 'Sucesso') {
    this.toastr.success(message, title);
  }

  showError(message: string, title: string = 'Erro') {
    this.toastr.error(message, title);
  }

  showInfo(message: string, title: string = 'Info') {
    this.toastr.info(message, title);
  }

  showWarning(message: string, title: string = 'Aviso') {
    this.toastr.warning(message, title);
  }

  // Função showMessage para exibir uma mensagem com tipo e mensagem apenas
  show(message: string, type: 'success' | 'error' | 'danger' | 'info' | 'warning'): void {
    switch (type) {
      case 'success':
        this.toastr.success(message);
        break;
      case 'error':
      case 'danger':
        this.toastr.error(message);
        break;
      case 'info':
        this.toastr.info(message);
        break;
      case 'warning':
        this.toastr.warning(message);
        break;
      default:
        this.toastr.info(message);
        break;
    }
  }

}

<!-- <div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div> -->
<app-loading-spinner [bgTransparent]="true" *ngIf="loading"></app-loading-spinner>

<form [formGroup]="channelForm" (ngSubmit)="saveChannel()" novalidate>
  <div class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ modalType === 'create' ? 'Criar Canal' : 'Editar Canal' }}
      </h4>
    </div>
    <div class="modal-body">
      <!-- Nome do Canal -->
      <label class="input-label" for="channelName">Nome do canal</label>
      <div class="input-group mb-3">
        <input id="channelName" placeholder="Nome do canal" type="text" class="form-control"
          formControlName="channelName" />
      </div>
      <span
        *ngIf="channelForm.get('channelName')?.invalid && (channelForm.get('channelName')?.touched || isFormSubmitted)"
        class="text-danger">
        O nome do canal é obrigatório.
      </span>

      <!-- Select de Categorias-->
      <label class="input-label" for="category" *ngIf="data.sidebarOption !== 'openCreateCategory'">Categoria</label>
      <div class="input-group mb-3" *ngIf="data.sidebarOption !== 'openCreateCategory'">
        <select id="category" class="form-select" formControlName="category">
          <option value="" disabled>
            Selecione uma categoria
          </option>
          <option *ngFor="let category of categories" [value]="category.id">
            {{ category.name }}
          </option>
        </select>
      </div>
      <span
        *ngIf=" data.sidebarOption !== 'openCreateCategory'&& channelForm.get('category')?.invalid && (channelForm.get('category')?.touched || isFormSubmitted)"
        class="text-danger">
        A categoria é obrigatória.
      </span>

      <!-- Emoji Picker -->
      <i class="btn bi mb-3" [ngClass]="isEmojiPickerVisible ? 'bi-x' : 'bi-emoji-smile'" (click)="toggleEmojiPicker()"
        style="font-size: 1.5rem; cursor: pointer">
      </i>
      <div *ngIf="isEmojiPickerVisible">
        <emoji-mart title="Escolha seu emoji…" emoji="point_up" (emojiClick)="addEmoji($event)"
          [style]="{ position: 'absolute', top: '20px' }" set="apple" [isNative]="true">
        </emoji-mart>
      </div>
      <span *ngIf="channelForm.get('emoji')?.invalid && (channelForm.get('emoji')?.touched || isFormSubmitted)"
        class="text-danger">
        O emoji é obrigatório.
      </span>
      <div *ngIf="channelForm.get('emoji')?.value" class="selected-emoji">Emoji selecionado: {{
        channelForm.get('emoji')?.value }}</div>

      <!-- Participantes -->
      <!-- <div class="d-flex justify-content-between align-items-center">
        <div class="channel-participants-container">
          <span>Membros adicionados</span>
        </div>
        <div class="channel-icon-container" (click)="openModalAddMembers()">
          <i class="btn bi bi-plus-square-fill channel-icon"></i>
          <span>Adicionar membros</span>
        </div>
      </div>
      <div class="communities-participants" *ngIf="channelParticipants.length">
        <section class="event-fields-section">
          <div class="people-container">
            <div class="connection-request-card"
              *ngFor="let person of channelParticipants | slice: 0 : visibleParticipantsCount">
              <img *ngIf="person.connection.uriImageProfile && person.connection.uriImageProfile !== ''"
                [src]="person.connection.uriImageProfile" alt="" />
              <img *ngIf="!person.connection.uriImageProfile || !person.connection.uriImageProfile.length"
                src="/assets/img/imagem_perfil.png" alt="" />

              <div class="request-info">
                <div class="info">
                  <span class="request-name">{{ person.connection.name }}</span>
                  <span class="request-profession multi-line" [innerHTML]="person.connection.presentation">{{
                    person.connection.presentation
                    }}</span>
                </div>
              </div>
              <i class="btn bi bi-dash-square-fill quantity-people-button"
                (click)="rmCommunityParticipants(person)"></i>
            </div>
          </div>
        </section>
        <div *ngIf="channelParticipants.length > 2">
          <button class="btn show-more-button" (click)="toggleShowMore($event)">
            {{ visibleParticipantsCount === 2 ? 'Ver Mais' : 'Ver Menos' }}
          </button>
        </div>
      </div>
      <div *ngIf="channelParticipants.length === 0">
        <span class="no-items">Nenhum participante encontrado</span>
      </div> -->
    </div>
    <div class="modal-footer">
      <button type="button" class="item-delete-button" (click)="closeModal(false, '')">Cancelar</button>
      <button type="submit" class="item-cancel-button">
        {{
        modalType === 'create' && data.openedFrom !== 'createCategory'
        ? 'Criar Canal'
        : data.openedFrom === 'createCategory'
        ? 'Adicionar canal'
        : 'Salvar alterações'
        }}
      </button>
    </div>
  </div>
</form>
<div class="connection-request-card">
    <img [src]="connection.connection.uriImageProfile ? connection.connection.uriImageProfile : defaultImageProfile"
        alt="">

    <div class="request-info">
        <div class="info">
            <span class="request-name">{{connection.connection.name}}</span>
            <span class="request-profession">
                <!-- Não implementado -->
            </span>
        </div>

        <div class="request-options">
            <span class="request-decline">Recusar</span>
            <span class="request-accept">Aceitar</span>
        </div>
    </div>

</div>
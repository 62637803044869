import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Connection } from '../model/connection.model';
import { SluperEvent } from '../model/event.model';

export interface ModalDataInterface {
    success: boolean;
    sidebarOption: string;
    allCategories: CategoryInterface[];
    modalType: 'category' | 'channel';
    allParticipants: Connection[];
    openedFrom: string;
    communityId: string;
    categoryItem: CategoryInterface | null;
    channelItem: ChannelInterface | null;
}
export interface ModalParamsInterface {
    categoryItem: CategoryInterface | null;
    channelItem: ChannelInterface | null;
    allCategories: CategoryInterface[];
    modalType: 'category' | 'channel';
    openedFrom: string;
}
export interface ModalResultInterface {
    success: boolean;
    sidebarOption: string;
}
export interface CreateCategoryResponse {
    id: number;
    name: string;
    communityId: string;
}

export interface CreateChannelData {
    name: string;
    category: number | null;
    icon: string;
    community: string;
}


export interface CategoryInterface {
    id: string;
    name: string;
    community: SluperEvent;
    channels: ChannelInterface[];
    createdAt: string;
    updatedAt: string | null;
}
export interface ChannelInterface {
    id: string;
    name: string;
    category: number | null;
    icon: string;
    community: string;
    createdAt: string;
    updatedAt: string | null;
    count: number;
    users: Connection[];
    postQty?: number;
}
export interface ChannelParticipantsInterface {
    id: number;
    channelId: number;
    userId: string;
    userProfile: {
        name: string;
        email: string;
        uriImageProfile: string;
    }
}




@Injectable({
    providedIn: 'root',
})
export class CategoryChannelService {
    baseUrl = environment.eventsApiUrl;
    baseAdmUrl = environment.administradorUrl;
    baseProfileUrl = environment.profileUrl;

    constructor(
        protected http: HttpClient,
        protected jwtHelper: JwtHelperService,
        protected router: Router,
    ) { }


    getCategories(communityId: string): Observable<HttpResponse<CategoryInterface[]>> {
        return this.http.get<CategoryInterface[]>(
            `${this.baseUrl}/category/byEvent/${communityId}`,
            { observe: 'response' },
        );
    }

    createCategory(data: { name: string, communityId: string }): Observable<HttpResponse<CreateCategoryResponse>> {
        return this.http.post<CreateCategoryResponse>(this.baseUrl + '/category', { name: data.name, eventId: data.communityId }, {
            observe: 'response',
        });
    }
    editCategory(data: { name: string, communityId: string }, categoryId: string): Observable<HttpResponse<CreateCategoryResponse>> {
        return this.http.put<CreateCategoryResponse>(this.baseUrl + `/category/${categoryId}`, { name: data.name }, {
            observe: 'response',
        });
    }

    deleteCategory(categoryId: string): Observable<HttpResponse<CreateCategoryResponse>> {
        return this.http.delete<CreateCategoryResponse>(`${this.baseUrl}/category/${categoryId}`, {
            observe: 'response',
        });
    }

    getChannelsByCategory(communityId: string): Observable<HttpResponse<CategoryInterface[]>> {
        return this.http.get<CategoryInterface[]>(
            `${this.baseUrl}/category/byEvent/${communityId}`,
            { observe: 'response' },
        );
    }

    getAllChannelsByCommunity(communityId: string): Observable<HttpResponse<ChannelInterface[]>> {
        return this.http.get<ChannelInterface[]>(
            `${this.baseUrl}/channel/byCommunity/${communityId}`,
            { observe: 'response' },
        );
    }

    getCategoryUsers(categoryId: string): Observable<HttpResponse<ChannelParticipantsInterface[]>> {
        return this.http.get<ChannelParticipantsInterface[]>(
            `${this.baseUrl}/category/${categoryId}/users`,
            { observe: 'response' },
        );
    }

    createChannel(data: CreateChannelData): Observable<HttpResponse<ChannelInterface>> {
        return this.http.post<ChannelInterface>(this.baseUrl + '/channel', data, {
            observe: 'response',
        });
    }
    editChannel(data: { name: string, icon: string }, channelId: string): Observable<HttpResponse<ChannelInterface>> {
        return this.http.put<ChannelInterface>(this.baseUrl + `/channel/${channelId}`, { name: data.name, icon: data.icon }, {
            observe: 'response',
        });
    }

    moveChannel(data: { idChannel: string, idCategory: string }): Observable<HttpResponse<any>> {
        return this.http.put(this.baseUrl + `/channel/${data.idChannel}/category/${data.idCategory}`, null, {
            observe: 'response',
        });
    }

    deleteChannel(channelId: string): Observable<HttpResponse<ChannelInterface>> {
        return this.http.delete<ChannelInterface>(`${this.baseUrl}/channel/${channelId}`, {
            observe: 'response',
        });
    }

    addUserCategory(data: { userId: string; categoryId: number }[]): Observable<HttpResponse<CategoryInterface>> {
        return this.http.post<CategoryInterface>(this.baseUrl + '/category/addUsers', data, {
            observe: 'response',
        });
    }

    rmUserCategory(data: { userId: string; categoryId: number }): Observable<HttpResponse<CategoryInterface>> {
        return this.http.delete<CategoryInterface>(`${this.baseUrl}/category/${data.categoryId}/${data.userId}`, {
            observe: 'response',
        });
    }




}
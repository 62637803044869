import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-welcome-create-community',
  templateUrl: './welcome-create-community.component.html',
  styleUrls: ['./welcome-create-community.component.css']
})
export class WelcomeCreateCommunityComponent {

  constructor(
    private dialogRef: MatDialogRef<WelcomeCreateCommunityComponent>,

  ) { }
  closeModal(response: boolean): void {
    this.dialogRef.close(response);
  }


}
